import { IClass } from './Class';
import { PaginationRequest } from './dto/PaginationRequest.dto';
import { ISchool } from './School';
import { IStudentCourse } from './StudentCourse';
import { IStudentStudySubject } from './StudySubject';
import { isMultipleRoles, isRole, UserRole } from './UserRole';
export class User {
  id = 0;
  userName = '';
  fullName = '';
  emailAddress = '';
  dateOfBirth = new Date();
  role: UserRole = UserRole.Unknown;
  phoneNumber = '';
  externalSchoolId = '';
  externalClassId = '';
  isMainContactPerson = false;
  currentRole: UserRole = 0;
  switchRole: UserRole = 0;
  studentCourses: IStudentCourse[] = [];
  studentStudySubjects: IStudentStudySubject[] = [];
  hasSpecialNeeds = false;
  class?: IClass = undefined;
  school?: ISchool = undefined;
  isActive?: boolean;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  static fromUserDto(u: IUserDto) {
    const newUser = new this(u);

    if (
      newUser.currentRole === UserRole.Unknown &&
      !newUser.hasMultipleRoles()
    ) {
      newUser.currentRole = newUser.role;
    } else {
      newUser.currentRole = UserRole.Admin;
      newUser.switchRole = newUser.role / newUser.currentRole;
    }

    return newUser;
  }

  hasMultipleRoles = () => isMultipleRoles(this.role);
  hasRole = (role: UserRole) => isRole(this.role, role);
  hasSwitchRole = (role: UserRole) => isRole(this.switchRole, role);
  isCurrentlyRole = (role: UserRole) => isRole(this.currentRole, role);
  getAssignedCourse = (projectId?: number) =>
    this.studentCourses.find(
      (course) => course.finalAssignedCourse?.projectId === projectId
    );
}

export interface IUserDto {
  id?: number;
  userName: string;
  fullName: string;
  emailAddress: string;
  dateOfBirth: Date;
  role: UserRole;
  phoneNumber: string;
  externalSchoolId?: string;
  externalClassId?: string;
  isMainContactPerson?: boolean;
  currentRole?: UserRole;
  studentCourses?: IStudentCourse[];
  studentStudySubjects?: IStudentStudySubject[];
  hasSpecialNeeds: boolean;
  class?: IClass;
  school?: ISchool;
  isActive?: boolean;
}
export interface IAllocationResultDto {
  firstPriority: number;
  secondPriority: number;
  thirdPriority: number;
}
export interface UserPaginationRequest extends PaginationRequest {
  externalSchoolId?: string;
}
