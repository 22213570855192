import { KarDateType } from '@/types/commons';
import { IProject, INIT_PROJECT } from '@/types/Project';

export type ProjectAction = {
  type: ProjectActionType;
  payload?: string | KarDateType | IProject;
};

export enum ProjectActionType {
  SET = 'SET',
  SET_PROJECT_NAME = 'SET_PROJECT_NAME',
  SET_AVAILABLE_START = 'SET_AVAILABLE_START',
  SET_AVAILABLE_END = 'SET_AVAILABLE_END',
  SET_REGISTERING_START = 'SET_REGISTERING_START',
  SET_REGISTERING_END = 'SET_REGISTERING_END',
  SET_COURSE_START = 'SET_COURSE_START',
  SET_COURSE_END = 'SET_COURSE_END',
  RESET = 'RESET',
  SET_STUDENT_REGISTRATION_TEXT = 'SET_STUDENT_REGISTRATION_TEXT',
  SET_STUDENT_INTRODUCTION_TEXT = 'SET_STUDENT_INTRODUCTION_TEXT',
}

const reducer = (state: IProject, action: ProjectAction): IProject => {
  switch (action.type) {
    case ProjectActionType.SET:
      return { ...(action.payload as IProject) };

    case ProjectActionType.RESET:
      return { ...INIT_PROJECT };

    case ProjectActionType.SET_PROJECT_NAME:
      return { ...state, projectName: action.payload as string };

    case ProjectActionType.SET_AVAILABLE_START:
      return { ...state, availablePeriodStartDate: action.payload as Date };

    case ProjectActionType.SET_AVAILABLE_END:
      return { ...state, availablePeriodEndDate: action.payload as Date };

    case ProjectActionType.SET_REGISTERING_START:
      return { ...state, registeringPeriodStartDate: action.payload as Date };

    case ProjectActionType.SET_REGISTERING_END:
      return { ...state, registeringPeriodEndDate: action.payload as Date };

    case ProjectActionType.SET_COURSE_START:
      return { ...state, coursePeriodStartDate: action.payload as Date };

    case ProjectActionType.SET_COURSE_END:
      return { ...state, coursePeriodEndDate: action.payload as Date };

    case ProjectActionType.SET_STUDENT_REGISTRATION_TEXT:
      return { ...state, studentRegistrationText: action.payload as string };

    case ProjectActionType.SET_STUDENT_INTRODUCTION_TEXT:
      return { ...state, studentIntroductionText: action.payload as string };

    default:
      return { ...INIT_PROJECT };
  }
};

export default reducer;
