import courseStyle from '@/views/style/courses';
import React, { forwardRef } from 'react';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ICourse from '@/types/Course';
import { getDateTime } from '@/utils/timeUtils';
import { Button, Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  numberStudent: number;
  oldCourse: ICourse;
  newCourse: ICourse;
  open: boolean;
  handleClose: () => void;
}

const NotifySuccessMovingStudent = (props: IComponentProps) => {
  const { numberStudent, oldCourse, newCourse, open, handleClose } = props;
  const courseClasses = courseStyle();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
    >
      <div className={courseClasses.notifySuccessWarraper}>
        <div className={courseClasses.displayFlex}>
          <CheckCircleOutlineOutlinedIcon
            classes={{
              root: clsx(
                courseClasses.notifySuccessTitle,
                courseClasses.fontWeight500,
                courseClasses.marginRight20
              ),
            }}
          />
          <div>
            <div
              className={clsx(
                courseClasses.notifySuccessTitle,
                courseClasses.fontWeight500,
                courseClasses.fontSize18
              )}
            >{`Antall deltaker som flyttet til nytt arrangment: ${numberStudent}`}</div>
            <div className={clsx(courseClasses.notifySuccessTitle)}>
              {`Fra: ${oldCourse.studySubject.studySubjectName}, ${
                oldCourse.school.schoolName
              }, ${getDateTime(oldCourse.startDate, 'dd LLL')}. ${getDateTime(
                oldCourse.startDate,
                'HH:mm'
              )} - ${getDateTime(oldCourse.endDate, 'HH:mm')}`}
            </div>
            <div className={courseClasses.notifySuccessTitle}>
              {`Til: ${newCourse.studySubject.studySubjectName}, ${
                newCourse.school.schoolName
              }, ${getDateTime(newCourse.startDate, 'dd LLL')}. ${getDateTime(
                newCourse.startDate,
                'HH:mm'
              )} - ${getDateTime(newCourse.endDate, 'HH:mm')}`}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            courseClasses.marginTop26,
            courseClasses.displayFlex,
            courseClasses.justifyContentFlexEnd
          )}
        >
          {/*<div*/}
          {/*  className={clsx(*/}
          {/*    courseClasses.notifySuccessTitle,*/}
          {/*    courseClasses.fontWeight500,*/}
          {/*    courseClasses.marginRight40*/}
          {/*  )}*/}
          {/*>*/}
          {/*  Gå til nytt tildelt arrangment*/}
          {/*</div>*/}
          <Button  variant="text" onClick={handleClose}>
            Ok
          </Button>

        </div>
      </div>
    </Dialog>
  );
};

export default NotifySuccessMovingStudent;
