import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ArrowBack, HomeOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import { UserRole } from '../../../../types/UserRole';
import { ReactComponent as Logo } from './../../../../assets/OsloskolenLogo-hvit.svg';
import {
  adminMenuItems,
  adviserMenuItems,
  highSchoolContactMenuItems,
  MenuItem,
  studentMenuItems,
} from './DrawerMenuVariants/menuItems';
import ProjectMenu from './DrawerMenuVariants/ProjectMenu';

const useStyles = makeStyles(({ palette }) => ({
  drawer: {
    '& .MuiSvgIcon-root': {
      fill: palette.primary.main,
    },
  },
  logo: {
    height: '36px',
    margin: '0 15px',
    fill: palette.primary.main,
  },
  list: {
    padding: 0,
  },
  minWidthDrawerMenu: {
    minWidth: '250px',
  },
}));

const DrawerMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userStore, stateStore, schoolStore } = useStores();
  const { drawerMenuIsOpen, setDrawerMenuIsOpen } = stateStore;
  const { user } = userStore;
  const { currentSchool } = schoolStore;

  useEffect(() => {
    if (!currentSchool && user?.externalSchoolId)
      schoolStore.getSchoolByExternalId(user?.externalSchoolId);
  }, [currentSchool, schoolStore, user?.externalSchoolId]);

  const handleMenuClick = (path: string) => {
    history.push(path);
    setDrawerMenuIsOpen(false);
  };

  const mapToListItem = (item: MenuItem, i: number): JSX.Element => (
    <div key={'menuItem-' + i}>
      <ListItem button onClick={() => handleMenuClick(item.path)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </ListItem>
      <Divider />
    </div>
  );

  const getMenuItems = () => {
    if (user?.currentRole === UserRole.Admin)
      return adminMenuItems.map(mapToListItem);

    if (user?.currentRole === UserRole.Adviser)
      return adviserMenuItems.map(mapToListItem);

    if (user?.currentRole === UserRole.HighSchoolContact)
      return highSchoolContactMenuItems.map(mapToListItem);

    if (user?.currentRole === UserRole.Student)
      return studentMenuItems.map(mapToListItem);
  };

  return (
    <Drawer
      open={drawerMenuIsOpen}
      onClose={() => setDrawerMenuIsOpen(false)}
      elevation={10}
      className={classes.drawer}
      classes={{ paper: classes.minWidthDrawerMenu }}
    >
      <header>
        <IconButton onClick={() => setDrawerMenuIsOpen(false)}>
          <ArrowBack />
        </IconButton>
        <Logo className={classes.logo} />
      </header>
      <Divider />
      <main>
        <List className={classes.list}>
          <ListItem button onClick={() => handleMenuClick('/home')}>
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText
              primary={currentSchool?.schoolName ?? 'Karriereenheten'}
            />
          </ListItem>
          <Divider />
          {(user?.currentRole === UserRole.Admin ||
            user?.currentRole === UserRole.HighSchoolContact) && (
            <ProjectMenu goTo={handleMenuClick} />
          )}
          {getMenuItems()}
        </List>
      </main>
    </Drawer>
  );
};

export default observer(DrawerMenu);
