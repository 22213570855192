import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette }) => ({
  card: {
    padding: '16px',
    border: `1px solid ${palette.background.default}`,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: palette.primary[50],
      border: '1px solid ' + palette.primary[600],
      '& $editButton': {
        visibility: 'visible',
      },
    },
  },
  schoolName: {
    color: palette.primary.dark,
    fontWeight: 500,
  },
  deactivated: {
    color: palette.error.main,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editButton: {
    visibility: 'hidden',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contactName: {
    lineHeight: '48px',
  },
}));

export default useStyle;
