import { useStores } from '@/stores';
import courseStyle from '@/views/style/courses';
import { observer } from 'mobx-react';
import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { IUserDto } from '@/types/User';
import CustomEditor from '@/components/CustomEditor';
import Loading from '@/components/Loading';
import { useEffect } from 'react';
import useEditCourseStyles from './style';
import EditCourseInformation from './EditCourseInformation';
import EditCourseTimes from './EditCourseTimes';
import { CreateUpdateCourse } from '@/types/Course';

interface IComponentProps {
  isFromAdminPanel: boolean;
}
const EditCourse = ({ isFromAdminPanel }: IComponentProps) => {
  const courseClasses = courseStyle();
  const classes = useEditCourseStyles();
  const { courseStore, userStore, errorStore, stateStore } = useStores();
  const { course } = courseStore;

  useEffect(() => {
    if (course?.id) userStore.getAllHighSchoolContactByCourseId(course.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course?.id]);

  const handleEditCourse = async (): Promise<void> => {
    if (courseStore.course && courseStore.course.id) {
      await courseStore.updateCourseVGS(
        courseStore.course.id,
        CreateUpdateCourse.fromICourse(courseStore.course)
      );
      errorStore.showErrorMessage('Kurset ble oppdatert.', 'success');
    }
    if (isFromAdminPanel) {
      stateStore.setVgsEditCourseDrawerIsOpen(false);
    }
  };

  const cancelEditCourse = (): void => {
    if (isFromAdminPanel) {
      stateStore.setVgsEditCourseDrawerIsOpen(false);
    } else {
      courseStore.resetCourse();
      courseStore.resetCourseId();
    }
  };

  const setCourseHolderId = (courseHolderId: number) => {
    course &&
      courseStore.setCourse({
        ...course,
        courseHolderId,
      });
  };

  const setRoomNumber = (roomNumber: string) => {
    course &&
      courseStore.setCourse({
        ...course,
        roomNumber,
      });
  };

  const setCourseInfo = (courseInfo: string) => {
    course &&
      courseStore.setCourse({
        ...course,
        courseInfo,
      });
  };

  return (
    <>
      {course ? (
        <>
          <div className={classes.root}>
            <div className={courseClasses.courseOptionHeader}>
              <Typography variant="h4">Legg til infomasjon</Typography>
              <ClearIcon
                className={courseClasses.pointer}
                onClick={cancelEditCourse}
              />
            </div>
            <EditCourseInformation />
            <div className={courseClasses.informationInputs}>
              <EditCourseTimes />

              <FormControl className={courseClasses.widthFull}>
                <InputLabel id="contact-person-select-label">
                  Kontakt person
                </InputLabel>
                <Select
                  value={
                    course?.courseHolderId > 0 ? course.courseHolderId : ''
                  }
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setCourseHolderId(e.target.value as number)
                  }
                  labelId="contact-person-select-label"
                >
                  {userStore.listHighSchoolContactOfCourse.map(
                    (user: IUserDto) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.fullName}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <TextField
                label="Oppmøtested"
                value={course?.roomNumber}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setRoomNumber(e.target.value as string)
                }
                classes={{ root: courseClasses.widthFull }}
              />

              <CustomEditor
                content={course?.courseInfo}
                setContent={setCourseInfo}
              />
            </div>
          </div>

          <div className={courseClasses.optionButtons}>
            <Button color="primary" onClick={handleEditCourse}>
              Lagre
            </Button>
            <Button
              variant="text"
              onClick={cancelEditCourse}
              style={{ marginLeft: '12px' }}
            >
              Avbryt
            </Button>
          </div>
        </>
      ) : (
        <div style={{ minHeight: '100vh' }}>
          <Loading loading={true} />
        </div>
      )}
    </>
  );
};

export default observer(EditCourse);
