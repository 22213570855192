import { CreateOrUpdateStudentCourseDto } from '@/types/StudentCourse';
import { newHttpInstance } from '@/utils/httpUtils';
import { AxiosInstance } from 'axios';

class StudentCourseService {
  prefix = '/api/StudentCourse';
  http: AxiosInstance;

  constructor() {
    this.http = newHttpInstance(this.prefix);
  }

  public async updateStudentCourse(
    id: number,
    model: CreateOrUpdateStudentCourseDto
  ): Promise<boolean> {
    const result = await this.http.put(`/${id}`, model);
    return result.data;
  }

  async manualAssignStudentToCourse(
    studentId: number | undefined,
    courseId: number | undefined
  ) {
    await this.http.put('/manualAssignStudentToCourse', {
      studentId,
      courseId,
    });
  }

  // public async movingAssign(
  //   studentId: number,
  //   oldCourseId: number,
  //   newCourseId: number
  // ): Promise<void> {
  //   await this.http.put('/movingAssign', {
  //     studentId,
  //     oldCourseId,
  //     newCourseId,
  //   });
  // }

  public async movingAssignMul(
    studentIds: number[],
    oldCourseId: number,
    newCourseId: number
  ): Promise<void> {
    await this.http.put('/movingAssignMul', {
      studentIds,
      oldCourseId,
      newCourseId,
    });
  }
}

export default new StudentCourseService();
