import {
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import {
  MailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  ArrowBack,
} from '@material-ui/icons';
import { FormEvent, useState } from 'react';
import { useStores } from '../../../../stores';
import useLoginStyle from '../style';
import ForgotPasswordDialog from './ForgotPasswordDialog';

interface IEmailLoginProps {
  email: string;
  goBack: () => void;
}

const EmailLogin = ({ email, goBack }: IEmailLoginProps) => {
  const { accountStore, errorStore } = useStores();
  const { showErrorMessage } = errorStore;

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await accountStore.login(email, password, rememberMe);
    } catch (error: any) {
      showErrorMessage(error.message);
    }
  };

  const classes = useLoginStyle();

  return (
    <>
      <form onSubmit={login} className={classes.form}>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={goBack}
          className={classes.backButton}
        >
          Tilbake
        </Button>
        <TextField
          inputProps={{
            'aria-label': 'username-input',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlined />
              </InputAdornment>
            ),
          }}
          label="E-post"
          disabled
          required
          value={email}
        />
        <TextField
          inputProps={{ 'aria-label': 'password-input' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Passord"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.currentTarget.value)}
          required
        />
        <div className={classes.rememberMeContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'remember-me' }}
              />
            }
            label="Husk meg"
          />
          <Link
            underline="always"
            onClick={() => setOpenForgotPasswordDialog(true)}
          >
            <Typography>Glemt passord?</Typography>
          </Link>
        </div>
        <Button type="submit" color="secondary">
          Logg inn
        </Button>
      </form>
      <ForgotPasswordDialog
        open={openForgotPasswordDialog}
        setOpen={setOpenForgotPasswordDialog}
      />
    </>
  );
};

export default EmailLogin;
