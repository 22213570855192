import { SchoolStatus } from '@/types/enums/SchoolStatus';
import { Box, Button } from '@material-ui/core';
import React from 'react';
import useStyle from './style';
import clsx from 'clsx';

interface IComponentProps {
  filter: SchoolStatus;
  handleChangeFilter: (value: SchoolStatus) => void;
}

const ShoolStatusFilter = (props: IComponentProps) => {
  const { filter, handleChangeFilter } = props;
  const classes = useStyle();

  return (
    <Box className={classes.boxFilter}>
      <div className={clsx(classes.marginLeftAuto, classes.paddingOnBox)}>
        <Button
          variant="contained"
          className={clsx(classes.btn, {
            [classes.btnActive]: filter === SchoolStatus.ALL,
          })}
          onClick={async () => {
            if (filter !== SchoolStatus.ALL) {
              await handleChangeFilter(SchoolStatus.ALL);
            }
          }}
        >
          Alle
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.btn, {
            [classes.btnActive]: filter === SchoolStatus.ACTIVATE,
          })}
          onClick={async () => {
            if (filter !== SchoolStatus.ACTIVATE) {
              await handleChangeFilter(SchoolStatus.ACTIVATE);
            }
          }}
        >
          Aktive
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.btn, {
            [classes.btnActive]: filter === SchoolStatus.DEACTIVATE,
          })}
          onClick={async () => {
            if (filter !== SchoolStatus.DEACTIVATE) {
              await handleChangeFilter(SchoolStatus.DEACTIVATE);
            }
          }}
        >
          Deaktiverte
        </Button>
      </div>
    </Box>
  );
};

export default ShoolStatusFilter;
