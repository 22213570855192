import CustomDayPickerRange from '@/components/CustomDayPickerRange';
import { KarDateType } from '@/types/commons';
import { getDateFromKarDateType } from '@/utils/timeUtils';
import {
  Typography,
  Button,
  Collapse,
  ClickAwayListener,
} from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { DateTimeFormatOptions } from 'luxon';
import { useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import useStyles from './style';

interface ICollapsableDateRangePickerProps {
  title: string;
  subtitle?: string;
  startDate: KarDateType;
  setStartDate: (date: Date) => void;
  endDate: KarDateType;
  setEndDate: (date: Date) => void;
}

const dateOptions: DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const CollapsableDateRangePicker = ({
  title,
  subtitle,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: ICollapsableDateRangePickerProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const classes = useStyles();

  const innerStartDate = getDateFromKarDateType(startDate);
  const innerEndDate = getDateFromKarDateType(endDate);

  const dateRange: RangeModifier = {
    from: innerStartDate ?? new Date(),
    to: innerEndDate ?? new Date(),
  };

  const handleSelectionChange = (range: RangeModifier) => {
    if (range.from) setStartDate(range.from);
    if (range.to) setEndDate(range.to);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
      <div className={classes.wrapper}>
        <Typography variant="h5">{title}</Typography>
        {!!subtitle && <Typography variant="body1">{subtitle}</Typography>}
        <Button
          onClick={() => setShowDatePicker(!showDatePicker)}
          endIcon={<CalendarTodayOutlined />}
          className={classes.dateButton}
        >
          {innerStartDate?.toLocaleDateString('no', dateOptions)} -{' '}
          {innerEndDate?.toLocaleDateString('no', dateOptions)}
        </Button>
        <Collapse in={showDatePicker}>
          <CustomDayPickerRange
            selection={dateRange}
            setSelection={handleSelectionChange}
            hideDateDisplay
          />
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

export default CollapsableDateRangePicker;
