import { useStores } from '@/stores';
import MessageTemplate from '@/types/MessageTemplate';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { observer } from 'mobx-react';
import { FC } from 'react';
import useStyles from './style';
import ToolBar from './ToolBar';

const MessageTemplateWYSIWYG: FC = () => {
  const { messageTemplateStore } = useStores();
  const { currentMessageTemplate, setCurrentMessageTemplate } =
    messageTemplateStore;

  const classes = useStyles();

  const editor = useEditor({
    extensions: [StarterKit],
    content: currentMessageTemplate?.emailBody,
    onUpdate: (content) => {
      setCurrentMessageTemplate(
        MessageTemplate.fromJson({
          ...currentMessageTemplate,
          emailBody: content.editor.getHTML(),
        })
      );
    },
  });

  return (
    <>
      <div className={classes.editorWrapper}>
        <ToolBar editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </>
  );
};

export default observer(MessageTemplateWYSIWYG);
