import { useStores } from '@/stores';
import EditCourse from '@/views/app/VGSadmin/courses/components/CourseOptions/EditCourse';
import { Drawer } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import useStyle from './style';

interface IComponentProps {
  handleCloseVgsEditCourseDrawer: () => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  setCourseHolderId: (courseHolderId: number) => void;
  setRoomNumber: (roomNumber: string) => void;
  setCourseInfo: (courseInfo: string) => void;
  handleEditCourse: () => Promise<void>;
  cancelEditCourse: () => void;
}

const VGSEditCourseDrawer = (props: IComponentProps) => {
  const {
    handleCloseVgsEditCourseDrawer,
  } = props;
  const { stateStore } = useStores();
  const { vgsEditCourseDrawerIsOpen } = stateStore;

  const classes = useStyle();

  return (
    <Drawer
      open={vgsEditCourseDrawerIsOpen}
      onClose={() => handleCloseVgsEditCourseDrawer()}
      anchor="right"
    >
      <div className={classes.content}>
        <EditCourse isFromAdminPanel={true}
        />
      </div>
    </Drawer>
  );
};

export default observer(VGSEditCourseDrawer);
