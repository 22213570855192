import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette, typography }) => ({
  content: {
    width: '350px',
    padding: '24px 0 24px 24px',
    ...flexCol,
    gap: '6px',
    overflow: 'auto',
    justifyContent: 'flex-start',
  },

  title: {
    color: palette.primary[900],
  },

  paddingRight24: {
    paddingRight: '24px',
  },

  listContactPerson: {
    marginTop: '15px',
    overflowX: 'auto',
  },

  listContactPersonPadding: {
    paddingRight: '15px',
  },

  itemContactPerson: {
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
}));

export default useStyle;
