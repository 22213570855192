import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  contactPersonChip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '36px',
  },
}));

export default useStyle;
