import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  main: {
    margin: '20px 115px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      margin: '10px 5px',
    },
  },

  header: {
    fontSize: '16px',
    fontWeight: 500,
  },
  projectInfo: {
    marginTop: '8px',
  },

  projectHeader: {
    margin: '0 12px 0 8px',
    fontSize: 20,
    fontWeight: 500,
  },
  iconGroup: {},
  projectList: {
    '& .MuiListItemText-root': {
      marginLeft: '18px',
      display: 'block',
      width: '150px',
      marginRight: '24px',
      flex: 'none',
    },
    '& .MuiListItemText-root:last-child': {
      marginLeft: '18px',
      display: 'block',
      width: '300px',
      marginRight: '24px',
      flex: 'none',
    },
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& .MuiListItem-root': {
      padding: '16px',
    },
    padding: 0,
  },

  stepContainer: {
    // width: '200px',
  },
  stepper: {
    padding: '0 !important',
    backgroundColor: 'transparent',
    minWidth: '800px',
    width: '100%',
  },
  contentTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentText: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '500px',
  },
  twoLines: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '34px',
    },
    fontSize: '12px',
  },
  donutChart: {
    marginLeft: '5%',
    // float: 'right',
  },
  barCharts: {
    margin: '0 24px 24px 32px',
    // float: 'right',
  },

  dividerSchool: {
    margin: '24px 0 12px 0',
    width: '100px',
    height: '2px !important',
    backgroundColor: palette.primary.dark,
  },

  schoolContent: {
    display: 'flex',
  },

  schoolTitle: {
    fontSize: 25,
    fontWeight: 500,
    color: palette.primary.dark,
  },

  schoolInfo: {
    margin: '20px 24px 0 0',
    width: '369px',
  },

  schoolName: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.primary.dark,
  },

  schoolAddress: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.primary.main,
  },

  schoolButton: {
    backgroundColor: 'transparent',
    borderRadius: '20px',
    border: `1px solid ${palette.primary.main}`,
    color: palette.primary.main,
    '&:hover': {
      color: '#ffffff',
    },
  },

  contactPerson: {
    margin: '36px 0px 24px 0',
    fontSize: 16,
    fontWeight: 400,
    color: palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
  },

  contactPersonIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '60px',
  },
}));

export default useStyle;
