import { useStores } from '@/stores';
import IMessagePaginationRequest from '@/types/dto/Messages/MessagePaginationRequest.dto';
import { DESC } from '@/types/dto/PaginationRequest.dto';
import Message from '@/types/Message';
import { getDateTime } from '@/utils/timeUtils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import AdminHeader from '../common/AdminHeader';
import AdminSearchBar from '../common/AdminSearchBar';
import MessageDrawer from './MessageDrawer';
import MessageStatusIcons from './MessageStatusIcons';
import useMessagesStyle from './style';

const Messages: FC = () => {
  const classes = useMessagesStyle();

  const [page, setPage] = useState(0);
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  const [request, setRequest] = useState<IMessagePaginationRequest>({
    skip: 0,
    take: 50,
    search: '',
    orderBy: 'created',
    orderDirection: DESC,
  });

  const setRequestAndRefresh = (newRequest: IMessagePaginationRequest) => {
    setRequest(newRequest);
    getMessages(newRequest);
  };

  const { messageStore } = useStores();
  const { messages, count, getMessages } = messageStore;

  useEffect(() => {
    getMessages(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSearch = (search: string) =>
    setRequestAndRefresh({ ...request, search });

  const handlePageChange = (_: any, newPageNumber: number) => {
    setPage(newPageNumber);
    setRequestAndRefresh({
      ...request,
      skip: newPageNumber * (request.take ?? 50),
    });
  };

  const handleRowsPerPageChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const newRowsPerPage = Number(e.target.value);
    setRequestAndRefresh({ ...request, take: newRowsPerPage });
  };

  return (
    <>
      <header>
        <AdminHeader title="Utgående meldinger" />
        <AdminSearchBar
          handleSearchValueChange={setSearch}
          label="Søk i utgående meldinger"
        />
      </header>
      <main className={classes.main}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Til</TableCell>
              <TableCell>Meldingstype</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((message) => (
              <TableRow
                key={message.id}
                onClick={() => setCurrentMessage(message)}
              >
                <TableCell>{message.user.fullName}</TableCell>
                <TableCell>{message.template.name}</TableCell>
                <TableCell>
                  {getDateTime(message.createdDate, 'yyyy.LL.dd T')}
                </TableCell>
                <TableCell>
                  <MessageStatusIcons message={message} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[1, 25, 50, 100, 250]}
          count={count}
          rowsPerPage={request.take ?? 50}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage="Kurs per side:"
          nextIconButtonText="Neste side"
          backIconButtonText="Forrige side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} av ${count !== -1 ? count : `mer enn ${to}`}`
          }
          className={classes.table}
        />
      </main>
      <MessageDrawer message={currentMessage} setMessage={setCurrentMessage} />
    </>
  );
};

export default observer(Messages);
