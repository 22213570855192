import React from 'react';
import DayPicker, {
  DateUtils,
  NavbarElementProps,
  RangeModifier,
} from 'react-day-picker';
import './index.css';
import useStyles from './style';
import clsx from 'clsx';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { getDateTime } from '@/utils/timeUtils';

interface ICalendarNavbar {
  navbarElementProps: NavbarElementProps;
  changeMonth: (month: Date) => void;
}

const calendarNavbarStyles = makeStyles(({ palette }) => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: palette.background.default,
    },
    color: palette.primary[900],
    '& .MuiSelect-icon': {
      color: palette.primary[500],
    },
  },
  select: {},
  iconControl: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
  marginRight: {
    marginRight: '10px',
  },
  pointer: { cursor: 'pointer' },
  selectPadding: {
    padding: '15px 10px 12px 10px',
  },
}));

const CalendarNavbar = (props: ICalendarNavbar) => {
  const { navbarElementProps, changeMonth } = props;
  const { month, onPreviousClick, onNextClick, className } = navbarElementProps;

  const classes = calendarNavbarStyles();

  const handleChange = (m: string) => {
    changeMonth(new Date(month.getFullYear(), MONTHS.indexOf(m)));
  };

  return (
    <div className={className}>
      <Select
        disableUnderline
        value={MONTHS[month.getMonth()]}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
          handleChange(e.target.value as string)
        }
        className={clsx(classes.root, classes.select)}
        inputProps={{
          className: classes.selectPadding,
        }}
      >
        {MONTHS.map((m: string) => (
          <MenuItem key={m} value={m}>{`${m} ${month.getFullYear()}`}</MenuItem>
        ))}
      </Select>

      <div className={classes.iconControl}>
        <ChevronLeftOutlinedIcon
          className={clsx(classes.marginRight, classes.pointer)}
          onClick={() => onPreviousClick()}
        />
        <ChevronRightOutlinedIcon
          className={classes.pointer}
          onClick={() => onNextClick()}
        />
      </div>
    </div>
  );
};

interface IComponentProps {
  selection: RangeModifier;
  setSelection: (range: RangeModifier) => void;
  hideDateDisplay?: boolean;
}

const MONTHS = [
  'Jan', // January
  'Feb', // February
  'Mar', // March
  'Apr', // April
  'Mai', // May
  'Jun', // June
  'Jul', // July
  'Aug', // August
  'Sep', // September
  'Okt', // October
  'Nov', // November
  'Des', // December
];

const WEEKDAYS_LONG = [
  'Søndag', //0, Sunday
  'Mandag', //1, Monday
  'Tirsdag', //2, Tuesday
  'Onsdag', //3, Wednesday
  'Torsdag', //4, Thursday
  'Fredag', //5, Friday
  'Lørdag', //6, Saturday
];
const WEEKDAYS_SHORT = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];

const CustomDayPickerRange = (props: IComponentProps) => {
  const { selection, setSelection, hideDateDisplay } = props;
  const classes = useStyles();

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, selection);
    setSelection(range);
  };

  const [initMonth, setInitMonth] = React.useState<Date>(new Date());
  const changeMonth = (month: Date): void => {
    setInitMonth(month);
  };

  const [selectionText, setSelectionText] = React.useState<string>('');
  React.useEffect(() => {
    if (selection.from && selection.to) {
      const selectionText = `${getDateTime(
        selection.from,
        'dd. LLL.'
      )}${getDateTime(selection.from, 'yyyy')} - ${getDateTime(
        selection.to,
        'dd. LLL.'
      )}${getDateTime(selection.to, 'yyyy')}`;

      setSelectionText(selectionText.toLocaleLowerCase());
    }
  }, [selection]);

  return (
    <>
      <div>
        {!hideDateDisplay && !!selectionText && (
          <div className={classes.boxOutputRange}>
            <div>{selectionText}</div>
          </div>
        )}
        <div className={clsx(classes.optionsDatePicker)}>
          <DayPicker
            showWeekNumbers
            showOutsideDays
            disabledDays={[{ daysOfWeek: [6, 0] }]}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            firstDayOfWeek={1}
            className="CustomDayPickerRange"
            numberOfMonths={1}
            selectedDays={selection}
            modifiers={{
              start: selection.from ? selection.from : undefined,
              end: selection.to ? selection.to : undefined,
            }}
            onDayClick={handleDayClick}
            month={initMonth}
            captionElement={() => <></>}
            navbarElement={(navbarElementProps: NavbarElementProps) => (
              <CalendarNavbar
                navbarElementProps={navbarElementProps}
                changeMonth={changeMonth}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default CustomDayPickerRange;
