import React from 'react';
import useStyles from './style';
import { IStatisticRegistration } from '@/types/StudentStudySubject';
import clsx from 'clsx';
import SlicePieChart from './slice';

interface ISlicePieChart {
  offset: number;
  sliceSize: number;
  colorHEX: string;
}

interface IComponentProps {
  statistic: IStatisticRegistration;
}

const CustomCircleChart = (props: IComponentProps) => {
  const { statistic } = props;
  const classes = useStyles();

  const [colors] = React.useState<string[]>(['#9C3535', '#CFD8DC']);
  const [slices, setSlices] = React.useState<ISlicePieChart[]>([]);

  const calculateSliceSize = React.useCallback(
    (numberStudent: number): number => {
      return Math.round((numberStudent / statistic.totalStudent) * 360);
    },
    [statistic]
  );

  const getSlicePieChart = React.useCallback(
    (
      offset: number,
      numberStudent: number,
      colorHEX: string
    ): ISlicePieChart[] => {
      const result: ISlicePieChart[] = [];
      const maxSliceSize = -180;
      const sliceSize = calculateSliceSize(numberStudent);

      if (sliceSize > Math.abs(maxSliceSize)) {
        result.push({
          offset,
          sliceSize: 0,
          colorHEX,
        });

        result.push({
          offset: offset + Math.abs(maxSliceSize),
          sliceSize: maxSliceSize + (sliceSize - Math.abs(maxSliceSize)),
          colorHEX,
        });
      } else {
        result.push({
          offset,
          sliceSize: maxSliceSize + sliceSize,
          colorHEX,
        });
      }

      return result;
    },
    [calculateSliceSize]
  );

  const calculateSlices = React.useCallback(() => {
    let result: ISlicePieChart[] = [];

    let offset = 0;
    const first = getSlicePieChart(
      offset,
      statistic.totalStudent - statistic.registeredStudent,
      colors[0]
    );

    result = result.concat(first);
    offset += calculateSliceSize(
      statistic.totalStudent - statistic.registeredStudent
    );

    const second = getSlicePieChart(
      offset,
      statistic.registeredStudent,
      colors[1]
    );

    result = result.concat(second);
    setSlices(result);
  }, [statistic, colors, calculateSliceSize, getSlicePieChart]);

  React.useEffect(() => {
    calculateSlices();
  }, [calculateSlices]);

  const getPercent = (): number => {
    const div =
      ((statistic.totalStudent - statistic.registeredStudent) * 100) /
      statistic.totalStudent;
    return Math.round(div * 100) / 100;
  };

  return (
    <>
      <div className={classes.chartContent}>
        <div
          className={clsx(
            classes.chartSize,
            classes.displayFlex,
            classes.alignItemsCenter
          )}
        >
          <div className={clsx(classes.pieID, classes.pie)}>
            {slices.map((slice) => (
              <SlicePieChart
                key={`${slice.offset}_${slice.sliceSize}_${slice.colorHEX}`}
                offset={slice.offset}
                sliceSize={slice.sliceSize}
                colorHEX={slice.colorHEX}
              />
            ))}
            <div className={classes.pieInner}>
              <div
                className={clsx(
                  classes.displayFlex,
                  classes.widthFull,
                  classes.heightFull,
                  classes.alignItemsCenter,
                  classes.justifyContentCenter
                )}
              >
                <div
                  className={clsx(
                    classes.displayFlex,
                    classes.alignItemsCenter,
                    classes.justifyContentCenter,
                    classes.pieInnerContent
                  )}
                >
                  <div className={classes.textAlignCenter}>
                    <div
                      className={clsx(
                        classes.whiteSpaceNowrap,
                        classes.pieInnerTextColor,
                        classes.pieInnerTextPercent
                      )}
                    >{`${getPercent()}%`}</div>
                    <div
                      className={clsx(
                        classes.whiteSpaceNowrap,
                        classes.pieInnerTextColor,
                        classes.pieInnerTextLabel
                      )}
                    >
                      Ikke påmeldt
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCircleChart;
