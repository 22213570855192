//import { PaginationRequest } from '@/types/dto/PaginationRequest.dto';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import { IUserDto, User, UserPaginationRequest } from '../types/User';
import http, { prepareGetQuery } from '../utils/httpUtils';
import BaseService from './BaseService';

class UserService extends BaseService<User> {
  constructor() {
    super('user');
  }

  async getCurrentUser(silent: boolean): Promise<IUserDto | undefined> {
    try {
      const res = await http.get('/api/user/current');
      return res.data;
    } catch (error: any) {
      if (!silent) console.error('Error fetching current user: ' + error);
    }
  }
  async allocationAllStudents() {
    const res = await http.get('/api/user/allocateAllStudents');
    return res.data;
  }

  async setHasSpecialNeeds(userId: number, hasSpecialNeeds: boolean) {
    await this.http.put('/set-has-special-needs', {
      userId,
      hasSpecialNeeds,
    });
  }

  /*public async getUserByCourseIdAndExternalClassIdAndExternalSchoolId(
    id: number,
    externalSchoolId: string,
    externalClassId: string,
    model: PaginationRequest
  ): Promise<IUserDto[]> {
    const result = await this.http.get(
      `course/${id}/${externalSchoolId}/${externalClassId}/student${prepareGetQuery(
        model as Record<string, unknown>
      )}`
    );
    return result.data;
  }*/

  public async getStudentById(
    id: number,
    courseId?: number,
    isMoveStudent?: boolean
  ): Promise<IUserDto> {
    const result = await this.http.get(`course/student/${id}`, {
      params: {
        courseId,
        isMoveStudent,
      },
    });
    return result.data;
  }

  public async getAllStudentByCourseId(courseId: number): Promise<IUserDto[]> {
    const result = await this.http.get(`course/${courseId}/all/student`);
    return result.data;
  }

  public async getAllStudentMinifiedByCourseId(
    courseId: number,
    studentName?: string
  ): Promise<IUserDto[]> {
    const result = await this.http.get(
      `course/${courseId}/all/student/minified`,
      {
        params: {
          studentName,
        },
      }
    );
    return result.data;
  }

  public async getUserStudentCourses(userId: number) {
    const result = await this.http.get(`${userId}/student-courses`);
    return result.data;
  }

  public async downloadAllStudents(
    externalSchoolId?: string,
    projectId?: number
  ) {
    const response = await this.http.get('/student-csv-export', {
      responseType: 'blob',
      params: {
        externalSchoolId,
        projectId,
      },
    });
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    console.log(response.headers);
    link.download = 'alle-elever-' + new Date().toLocaleDateString() + '.csv';
    link.click();
  }

  public async getAllHighSchoolContactByCourseId(
    id: number
  ): Promise<IUserDto[]> {
    const result = await this.http.get(`course/${id}/all/high-school-contact`);
    return result.data;
  }

  public async getContactPersonByExternalSchoolId(
    externalSchoolId: string
  ): Promise<User> {
    const result = await this.http.get(
      `course/contact-person/byExternalSchoolId/${externalSchoolId}`
    );
    return result.data;
  }

  public async importStudents(
    externalSchoolId: string,
    file: File
  ): Promise<number> {
    const formData = new FormData();
    formData.append('file', file);
    const result = await this.http.post(
      `/import-students/${externalSchoolId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return result.data;
  }

  public async downloadImportStudentsTemplate() {
    const response = await this.http.get('/import-students/template', {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'elev-import-mal.xlsx';
    link.click();
  }

  public async getContactPersonsByExternalSchoolId(
    externalSchoolId: string
  ): Promise<User[]> {
    const result = await this.http.get(
      `course/contact-persons/byExternalSchoolId/${externalSchoolId}`
    );
    return result.data;
  }

  public async getListUser(
    model: UserPaginationRequest
  ): Promise<PaginationResult<User>> {
    const result = await this.http.get(
      `${prepareGetQuery(model as Record<string, unknown>)}`
    );
    return result.data;
  }

  public async getAllStudentAspirationByProjectId(
    projectId: number,
    externalSchoolId?: string,
    studentName?: string
  ): Promise<IUserDto[]> {
    const result = await this.http.get(
      `project/${projectId}/all/student/aspiration`,
      {
        params: {
          externalSchoolId,
          studentName,
        },
      }
    );
    return result.data;
  }
}

export default new UserService();
