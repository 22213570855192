import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette }) => ({
  root: {
    padding: '16px',
    color: palette.primary.dark,
    backgroundColor: palette.grey['200'],
    minHeight: 'calc(100vh - 48px)',
  },
  wrapper: {
    maxWidth: '600px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: 'auto',
  },
  header: {
    fontWeight: 500,
    marginBottom: '16px',
  },
  card: {
    padding: '16px',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    alignItems: 'flex-start',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },
  loadingButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyle;
