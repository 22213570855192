import {
  TextField,
  InputAdornment,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { MailOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import useLoginStyle from '../style';
import EmailLogin from './EmailLogin';

const LoginForm = () => {
  const { accountStore, userStore } = useStores();
  const { user } = userStore;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [isEmailLogin, setIsEmailLogin] = useState(false);

  const classes = useLoginStyle();

  useEffect(() => {
    if (user) history.push('/home');
  });

  useEffect(() => {
    userStore.getCurrentUser(true);
  }, [userStore]);

  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down('sm'));

  const goToEmailLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsEmailLogin(true);
  };

  const goBack = () => setIsEmailLogin(false);

  return (
    <div className={classes.login}>
      {isEmailLogin ? (
        <EmailLogin email={email} goBack={goBack} />
      ) : (
        <>
          {!isSmall ? (
            <Typography variant="h3" className={classes.loginHeader}>
              Logg på kurs i utdanningsprogram Oslo
            </Typography>
          ) : null}
          <Button
            onClick={accountStore.redirectToFeide}
            aria-label="sign-in-with-feide-button"
            color="secondary"
          >
            Logg på med Feide
          </Button>
          <form onSubmit={goToEmailLogin} className={classes.form}>
            <Typography variant="h5">Har du ikke Feide?</Typography>
            <TextField
              inputProps={{ 'aria-label': 'email-input' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined />
                  </InputAdornment>
                ),
              }}
              label="E-post"
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
              type="email"
            />
            <Button type="submit">Neste</Button>
          </form>
          <Typography variant="body1" className={classes.noAccount}>
            Har du ikke konto? Kontakt din rådgiver.
          </Typography>
        </>
      )}
    </div>
  );
};

export default observer(LoginForm);
