import { Radio } from '@material-ui/core';
import React from 'react';
import useStyles from './style';

interface IComponentProps {
  name: string;
  value: any;
  isChecked: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CustomRadio = (props: IComponentProps) => {
  const { name, value, isChecked, handleChange, disabled } = props;

  const classes = useStyles();

  return (
    <>
      <Radio
        checked={!!isChecked}
        onChange={handleChange}
        value={value}
        name={`radio-${name}`}
        classes={{ root: classes.root }}
        disabled={disabled}
      />
    </>
  );
};

export default CustomRadio;
