import { SnackbarOrigin } from '@material-ui/core';
import { Color } from '@material-ui/lab';
import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';

class ErrorStore {
  public rootStore: RootStore;

  public open = false;
  public message = '';
  public severity: Color = 'error';
  public duration = 5000;
  public anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  showErrorMessage = (
    message: string,
    severity?: Color,
    duration?: number,
    anchorOrigin?: SnackbarOrigin
  ) => {
    this.message = message;

    if (severity) this.severity = severity;
    if (duration) this.duration = duration;
    if (anchorOrigin) this.anchorOrigin = anchorOrigin;

    this.open = true;
  };

  @action
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    this.open = false;
    this.message = '';
    this.severity = 'error';
    this.duration = 5000;
    this.anchorOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };
  };
}

export default ErrorStore;
