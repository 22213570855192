import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  filters: {
    minWidth: '10vw',
    margin: '0 6px',
  },
  exportButton: {
    height: '48px',
    width: '48px',
  },
  menuItem: {
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
  },
  maxWidthSelectCourse: {
    maxWidth: '250px',
    minWidth: '250px',
  },
}));

export default useStyles;
