import { makeStyles } from '@material-ui/core';

const useStudentStyle = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.grey['200'],
    minHeight: 'calc(100vh - 48px)',
  },
  wrapper: {
    maxWidth: '600px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: 'auto',
  },
  header: {
    fontWeight: 500,
    color: palette.primary.dark,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  card: {
    padding: '16px',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    alignItems: 'flex-start',
  },
  editButton: {
    alignSelf: 'flex-end',
  },
  readMore: {
    textAlign: 'center',
  },
}));

export default useStudentStyle;
