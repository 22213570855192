import { useStores } from '@/stores';
import StudentRegistrationStatus from '@/types/enums/StudentRegistrationStatus';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  FilledInput,
} from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useStyles from './style';
import NotifyMessage from '@/components/NotifyMessage';
import ICourse from '@/types/Course';
import { getDateTime } from '@/utils/timeUtils';
import { Priority } from '@/types/StudentStudySubject';
import clsx from 'clsx';

interface IStudentTableFilterProps {
  classId?: string;
  setClassId?: (classId: string) => void;
  schoolId?: string;
  setSchoolId?: (schoolId: string) => void;
  courseId?: string;
  setCourseId?: (courseId: string) => void;
  priority?: string;
  setPriority?: (priority: string) => void;
  status: StudentRegistrationStatus;
  setStatus: (status: StudentRegistrationStatus) => void;
  all: boolean;
}

const StudentTableFilters: FC<IStudentTableFilterProps> = ({
  classId,
  setClassId,
  schoolId,
  setSchoolId,
  courseId,
  setCourseId,
  priority,
  setPriority,
  status,
  setStatus,
  all,
}) => {
  const { schoolStore, userStore, projectStore, courseStore } = useStores();
  const { currentSchool, secondarySchools, getPopulatedSecondarySchools } =
    schoolStore;
  const { user } = userStore;
  const { currentProject } = projectStore;
  const classes = useStyles();
  const [courses, setCourses] = useState<ICourse[]>([]);

  const getAllCourse = useCallback(async () => {
    if (
      all &&
      courses.length === 0 &&
      projectStore.currentProject &&
      projectStore.currentProject.id
    ) {
      const courses = await courseStore.getAllByProjectId(
        projectStore.currentProject.id
      );
      setCourses(courses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStore.currentProject]);

  useEffect(() => {
    getAllCourse();
  }, [getAllCourse]);

  useEffect(() => {
    if (all && secondarySchools.length === 0) {
      getPopulatedSecondarySchools();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (schoolId) schoolStore.getSchoolByExternalId(schoolId);

    setClassId && setClassId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId]);

  const [exportLoading, setExportLoading] = useState(false);

  const downloadAllStudents = async () => {
    try {
      setExportLoading(true);
      const externalSchoolId = all ? '' : user?.externalSchoolId || 'error';
      let projectId = 0;
      if (currentProject && currentProject.id) {
        projectId = currentProject.id;
      }

      await userStore.downloadAllStudents(externalSchoolId, projectId);
      setExportLoading(false);
    } catch (err) {
      setExportLoading(false);
      setOpenNotifyMessage(true);
    }
  };

  const handleClassIdChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: ReactNode
  ) => setClassId && setClassId(event.target.value as string);

  const handleSchoolIdChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: ReactNode
  ) => setSchoolId && setSchoolId(event.target.value as string);

  const handleStatusChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: ReactNode
  ) => setStatus(event.target.value as StudentRegistrationStatus);

  const handleCourseIdChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: ReactNode
  ): void => {
    setCourseId && setCourseId(event.target.value as string);
  };

  const handlePriorityChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: ReactNode
  ): void => {
    setPriority && setPriority(event.target.value as string);
  };

  const [openNotifyMessage, setOpenNotifyMessage] = useState<boolean>(false);
  const handleCloseNotifyMessage = (): void => {
    setOpenNotifyMessage(false);
  };

  return (
    <>
      {all && (
        <>
          <FormControl
            variant="filled"
            className={clsx(classes.filters, classes.maxWidthSelectCourse)}
          >
            <InputLabel id="school-filter-label">Kurs</InputLabel>
            <Select
              labelId="course-filter-label"
              value={courseId}
              onChange={handleCourseIdChange}
              input={<FilledInput classes={{ root: classes.menuItem }} />}
            >
              {courses.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {`${c.studySubject?.studySubjectName} - ${
                    c.school?.schoolName
                  } - ${getDateTime(c.startDate, 'yyyy.LL.dd T')}`}
                </MenuItem>
              ))}
              <MenuItem key={''} value={''}>
                Vis alle
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.filters}>
            <InputLabel id="school-filter-label">Skole</InputLabel>
            <Select
              labelId="school-filter-label"
              value={schoolId}
              onChange={handleSchoolIdChange}
            >
              {secondarySchools.filter(value => !value.deactivated).map((s) => (
                <MenuItem key={s.externalId} value={s.externalId}>
                  {s.schoolName}
                </MenuItem>
              ))}
              <MenuItem key={''} value={''}>
                Vis alle
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <FormControl variant="filled" className={classes.filters}>
        <InputLabel id="class-filter-label">Klasse</InputLabel>
        <Select
          labelId="class-filter-label"
          value={classId}
          onChange={handleClassIdChange}
          disabled={!currentSchool}
        >
          {currentSchool?.classes &&
            currentSchool.classes.map((c) => (
              <MenuItem key={c.externalId} value={c.externalId}>
                {c.fullName}
              </MenuItem>
            ))}
          <MenuItem key={''} value={''}>
            Vis alle
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="filled" className={classes.filters}>
        <InputLabel id="priority-filter-label">Prioritet tildelt</InputLabel>
        <Select
          labelId="priority-filter-label"
          value={priority}
          onChange={handlePriorityChange}
        >
          <MenuItem value={Priority.First}>1. prioritet</MenuItem>
          <MenuItem value={Priority.Second}>2. prioritet</MenuItem>
          <MenuItem value={Priority.Third}>3. prioritet</MenuItem>
          <MenuItem value={''}>Visa alle</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="filled" className={classes.filters}>
        <InputLabel id="status-filter-label">Påmeldingsstatus</InputLabel>
        <Select
          labelId="status-filter-label"
          value={status}
          onChange={handleStatusChange}
        >
          <MenuItem value={StudentRegistrationStatus.UNREGISTERED}>
            Ikke meldt
          </MenuItem>
          <MenuItem value={StudentRegistrationStatus.REGISTERED}>
            Meldt
          </MenuItem>
          <MenuItem value={StudentRegistrationStatus.UNKNOWN}>
            Vis alle
          </MenuItem>
        </Select>
      </FormControl>
      <IconButton
        onClick={downloadAllStudents}
        disabled={exportLoading}
        className={classes.exportButton}
      >
        {exportLoading ? <CircularProgress /> : <GetAppOutlined />}
      </IconButton>

      <NotifyMessage
        open={openNotifyMessage}
        message={'Fail !!!'}
        severity={'error'}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessage}
      />
    </>
  );
};

export default observer(StudentTableFilters);
