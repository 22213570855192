import { makeStyles } from '@material-ui/core';
import { ReactComponent as OsloskolenLogo } from '../assets/OsloskolenLogo-hvit.svg';

const useStyle = makeStyles(({ palette }) => ({
    logo: {
        height: '36px',
        margin: '5px 15px',
        fill: palette.primary.dark
    }
}))

const Logo = () => {
    const { logo } = useStyle();

    return(
        <OsloskolenLogo className={logo} />
    )
}

export default Logo;