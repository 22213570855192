import { makeStyles } from '@material-ui/core';

const config = {
  height: 184,
  width: 94,
  heightLabel: 74,
  widthColumn: 20,
  paddingTop: 10,
};

const useStyles = makeStyles(({ palette }) => ({
  /**Item */
  itemBarChart: {
    position: 'relative',
  },
  itemBarChartSize: {
    height: `${config.height}px`,
    width: `${config.width}px`,
  },
  itemBarChartContent: {
    height: `${config.height - config.heightLabel}px`,
  },
  itemBarChartLabel: {
    height: `${config.heightLabel}px`,
  },
  itemBarChartContentColumns: {
    width: `${config.widthColumn * 2}px`,
    paddingTop: `${config.paddingTop}px`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  itemBarChartContentWidthColumn: {
    width: `${config.widthColumn}px`,
  },
  capacityColumn: {
    backgroundColor: palette.primary.light,
  },
  joinColumn: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #C6CADE',
  },
  joinOverloadColumn: {
    backgroundColor: palette.warning.dark,
  },
  positionRelative: {
    position: 'relative',
  },
  textColumn: {
    transform: 'rotate(-90deg)',
    height: '20px',
    lineHeight: '20px',
    fontSize: '12px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: '5px',
    color: palette.primary[900],
  },
  textColorOverload: {
    color: palette.primary.A100,
  },
  labelColumn: {
    textAlign: 'center',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '8px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  labelTooltip: {
    color: palette.primary.A100,
    backgroundColor: palette.primary[200],
    width: `${config.width}px`,
    margin: 0,
    padding: '5px 4px',
    textAlign: 'center',
  },
  highSchoolNameBox: {
    backgroundColor: palette.text.primary,
  },
  highSchoolName: {
    width: '64px',
    fontSize: '14px',
    color: palette.primary.A100,
    textAlign: 'center',
  },
  /**End Item */
  /**Legend */
  legendBarChartSize: {
    height: `${config.height - config.heightLabel}px`,
    width: `${config.width * 2}px`,
  },
  circleLegend: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  legendColorOverload: {
    color: palette.warning.dark,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  marginRight40: {
    marginRight: '40px',
  },
  /**End Legend */
  displayFlex: {
    display: 'flex',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  pointer: { cursor: 'pointer' },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  alignItemsFlexEnd: {
    alignItems: 'flex-end',
  },
  widthFull: {
    width: '100%',
  },
  heightFull: {
    height: '100%',
  },
  positionAbsolute: {
    position: 'absolute',
    top: 0,
  },
  overflowXAuto: {
    overflowX: 'auto',
  },
}));

export { config, useStyles };
