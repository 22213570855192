import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '&:hover': {
      backgroundColor: palette.background.default,
    },
  },
  size: {
    width: '20px',
    height: '20px',
  },
  color: {
    color: palette.primary[600],
  },
  visibility: {
    visibility: 'hidden',
  },
}));

export default useStyles;
