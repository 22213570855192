import { Button, makeStyles } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { getNorwegianRole, UserRole } from "../../../../types/UserRole";

interface IRoleButtonProps {
    role: UserRole,
    onClick: (role: UserRole) => void
}

const useStyle = makeStyles(({ palette }) => ({
    active: {
        color: palette.primary.dark,
        fontWeight: 600
    },
    icon: {
        height: '20px'
    }
}))

const RoleButton = ({ role, onClick }: IRoleButtonProps) => {
    const { userStore } = useStores();
    const { user } = userStore;

    const classes = useStyle()

    return(
        <Button variant={'text'} size='small' onClick={() => onClick(role)}>
            <span className={user?.isCurrentlyRole(role) ? classes.active : ''}>
                {getNorwegianRole(role)}
                {user?.isCurrentlyRole(role) && <CheckCircle className={classes.icon} /> }
            </span>
        </Button>
    )
}

export default observer(RoleButton);