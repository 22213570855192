import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import Row from './row';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';
import clsx from 'clsx';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';
import CustomSelect from '@/components/CustomSelect';
import { CourseStatus } from '@/types/enums/CourseStatus';
import ICourse from '@/types/Course';

interface IComponentProps {
  chooseCourse: (id: number) => void;
  chooseStudent: (courseId: number, studentId: number) => void;
  studySubjectId: number | string;
  handleSearchStudySubjectId: (studySubjectId: number | string) => void;
  externalHighSchoolId: string;
  handleExternalHighSchoolId: (externalHighSchoolId: string) => void;
  courseStatus: CourseStatus | string;
  handleCourseStatus: (courseStatus: CourseStatus | string) => void;
  editCourse: (id: number) => void;
  deleteCourse: (id: number) => Promise<void>;
  duplicateCourse: (id: number) => Promise<void>;
  checkBoxChooseCourse: (isChecked: boolean, courseId: number) => void;
  checkBoxChooseAll: string;
  checkBoxChooseAllCourse: (isChecked: boolean) => void;
  vgsCourseId: number;
  chooseCourseShowInfoVGS: (courseId: number) => void;
  //loadingDetail: boolean;
  handleOpenMovingMulDialog: (studentIds: number[], course: ICourse) => void;
  handleOpenDialogFilterDate: () => void;
}

const CourseTable = (props: IComponentProps) => {
  const {
    chooseCourse,
    chooseStudent,
    studySubjectId,
    handleSearchStudySubjectId,
    externalHighSchoolId,
    handleExternalHighSchoolId,
    courseStatus,
    handleCourseStatus,
    editCourse,
    deleteCourse,
    duplicateCourse,
    checkBoxChooseCourse,
    checkBoxChooseAll,
    checkBoxChooseAllCourse,
    vgsCourseId,
    chooseCourseShowInfoVGS,
    //loadingDetail,
    handleOpenMovingMulDialog,
    handleOpenDialogFilterDate,
  } = props;

  const courseClasses = courseStyle();

  const { courseStore, projectStore, studySubjectStore, schoolStore } =
    useStores();
  const { totalCount } = courseStore;
  const { totalAllocated, currentProject } = projectStore;
  const [numberOfCourses, setNumberOfCourses] = React.useState<number>(0);
  const [numberOfAllocation, setNumberOfAllocation] = React.useState<number>(0);

  useEffect(() => {
    if (currentProject && currentProject.id) {
      projectStore.getTotalAllocated(currentProject.id);
      setNumberOfAllocation(totalAllocated);
      setNumberOfCourses(totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStore, currentProject, courseStore.totalCount]);

  const beforeHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation();
    checkBoxChooseAllCourse(event.target.checked);
  };

  return (
    <>
      <TableContainer className={courseClasses.backgroundColorFFF}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: clsx(courseClasses.padding0, courseClasses.borderTable),
                }}
              ></TableCell>
              <TableCell classes={{ root: courseClasses.checkboxPadding }}>
                <CustomCheckBox
                  value={SELECT_ALL}
                  isChecked={checkBoxChooseAll === SELECT_ALL}
                  handleChange={beforeHandleChange}
                />
              </TableCell>
              <TableCell classes={{ root: courseClasses.buttonDetailPadding }}>
                <CustomIconButton />
              </TableCell>

              <TableCell
                classes={{
                  root: clsx(courseClasses.tableCellStudySubject),
                }}
              >
                <div className={courseClasses.heighHeadCell}>
                  <CustomSelect
                    label={'Alle kurs'}
                    studySubjectId={studySubjectId}
                    studySubjects={studySubjectStore.studySubjectHeadCell}
                    handleSearchStudySubjectId={handleSearchStudySubjectId}
                  />
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Kapasitet
                </p>
              </TableCell>

              <TableCell
                onClick={handleOpenDialogFilterDate}
                className={courseClasses.pointer}
              >
                <div className={courseClasses.heighHeadCell}>
                  <p className={courseClasses.margin0}>Date</p>
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Tidspunkt
                </p>
              </TableCell>

              <TableCell
                classes={{
                  root: clsx(courseClasses.tableCellHighSchool),
                }}
              >
                <div className={courseClasses.heighHeadCell}>
                  <CustomSelect
                    label={'Alle VGS skoler'}
                    externalSchoolId={externalHighSchoolId}
                    schools={schoolStore.highSchoolHeadCell}
                    handleExternalSchoolId={handleExternalHighSchoolId}
                  />
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Kontakt person
                </p>
              </TableCell>

              <TableCell>
                <div className={courseClasses.heighHeadCell}>
                  <CustomSelect
                    label={'Alle'}
                    status={courseStatus}
                    courseStatus={courseStore.courseStatusHeadCell}
                    handleCourseStatus={handleCourseStatus}
                  />
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Status
                </p>
              </TableCell>

              <TableCell></TableCell>
              <TableCell
                className={clsx(
                  courseClasses.displayFlex,
                  courseClasses.justifyContentFlexEnd
                )}
              >
                <div className={courseClasses.positionRelative}>
                  <div className={courseClasses.heighHeadCell}>
                    <p
                      className={clsx(
                        courseClasses.margin0,
                        courseClasses.whiteSpaceNowrap
                      )}
                    >
                      {numberOfCourses + ' arrangementer'}
                    </p>
                  </div>
                  <p
                    className={clsx(
                      courseClasses.margin0,
                      courseClasses.stepLabel,
                      courseClasses.whiteSpaceNowrap
                    )}
                  >
                    {numberOfAllocation + ' tildelt elever'}
                  </p>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!courseStore.loadingCourseTable ? (
              <>
                {courseStore.courses.length > 0 ? (
                  <>
                    {courseStore.courses.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        colSpan={9}
                        chooseCourse={chooseCourse}
                        chooseStudent={chooseStudent}
                        editCourse={editCourse}
                        deleteCourse={deleteCourse}
                        duplicateCourse={duplicateCourse}
                        checkBoxChooseCourse={checkBoxChooseCourse}
                        vgsCourseId={vgsCourseId}
                        chooseCourseShowInfoVGS={chooseCourseShowInfoVGS}
                        //loadingDetail={loadingDetail}
                        handleOpenMovingMulDialog={handleOpenMovingMulDialog}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Typography variant="h3">
                          Ingen arrangementer satt opp
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <div
                      className={clsx(
                        courseClasses.displayFlex,
                        courseClasses.justifyContentCenter
                      )}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default observer(CourseTable);
