import { useStores } from '@/stores';
import { QuestionRight } from '@/types/enums/SurveyOption';
import { SurveyQuestionType } from '@/types/enums/SurveyQuestionType';
import { IStudentSurvey } from '@/types/Survey';
import { ISurveyQuestion } from '@/types/SurveyQuestion';
import DescriptionSurvey from '@/views/app/admin/userSurvey/Detail/DescriptionSurvey';
import Question from '@/views/app/admin/userSurvey/Detail/question';
import AnswerItem from '@/views/app/admin/userSurvey/SurveyQuestion/answer';
import {
  Button,
  CircularProgress,
  Divider,
  Grid
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as LoginIllustration } from '../../../assets/login-illustration.svg';
import evaluationStyle from './style';

interface UrlParams {
  projectId: string;
  uuid: string;
}

const Evaluation = () => {
  const { projectId, uuid } = useParams<UrlParams>();
  const { userSurveyStore } = useStores();
  const classes = evaluationStyle();

  const [studentSurvey, setStudentSurvey] = useState<IStudentSurvey | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const fetchData = async (projectId: number): Promise<void> => {
    setLoading(true);
    const surveyFromDB = await userSurveyStore.getSurveyByProjectId(
      projectId,
      uuid
    );
    if (surveyFromDB) {
      setStudentSurvey(surveyFromDB);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      fetchData(+projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleChangeAnswer = (
    content: string,
    questionId: number,
    answerId: number,
    isChecked?: boolean
  ): void => {
    if (studentSurvey && studentSurvey.survey) {
      const survey = studentSurvey.survey;
      const question = survey.questions.find((q) => q.id === questionId);

      if (question) {
        const answers = question.answers;

        switch (question.type) {
          case SurveyQuestionType.INPUT:
            if (answers[0]) {
              answers[0].content = content;
            }
            break;
          case SurveyQuestionType.TEXT_AREA:
            if (answers[0]) {
              answers[0].content = content;
            }
            break;
          case SurveyQuestionType.RADIO:
            const answerRadioChecked = answers.find((a) => a.isChecked);
            if (answerRadioChecked && !!isChecked) {
              answerRadioChecked.isChecked = false;
            }

            const answerRadio = answers.find((a) => a.id === answerId);
            if (answerRadio) {
              answerRadio.isChecked = !!isChecked;
              answerRadio.content = content;
            }
            break;
          case SurveyQuestionType.CHECKBOX:
            const answerCheckbox = answers.find((a) => a.id === answerId);
            if (answerCheckbox) {
              answerCheckbox.isChecked = !!isChecked;
              answerCheckbox.content = content;
            }
            break;
          default:
            break;
        }

        setStudentSurvey({ ...studentSurvey });
      }
    }
  };

  const [mapOfQuestionIdToError, setMapOfQuestionIdToError] = useState<
    Map<number, boolean>
  >(new Map<number, boolean>());

  const _validationQuestions = (): boolean => {
    let isError = false;
    if (studentSurvey && studentSurvey.survey) {
      const questions = studentSurvey.survey.questions;
      const results = questions
        .filter((question) => question.isRequired)
        .map((question) => {
          let isResult = false;
          if (question.id) {
            if (
              question.type === SurveyQuestionType.INPUT ||
              question.type === SurveyQuestionType.TEXT_AREA
            ) {
              isResult = question.answers[0] && !question.answers[0].content;
            }

            if (
              question.type === SurveyQuestionType.RADIO ||
              question.type === SurveyQuestionType.CHECKBOX
            ) {
              const answers = question.answers;
              const answer = answers.find((a) => a.isChecked);
              isResult = !answer;
            }

            mapOfQuestionIdToError.set(question.id, isResult);
            setMapOfQuestionIdToError(mapOfQuestionIdToError);
          }

          return isResult;
        });

      isError = results.reduce((a, b) => a || b, false);
      setStudentSurvey({ ...studentSurvey });
    }

    return isError;
  };

  const handleEvaluation = async (): Promise<void> => {
    const isError = _validationQuestions();

    if (!isError && studentSurvey && studentSurvey.survey) {
      _resetSurvey();
      await userSurveyStore.evaluationUpdateSurvey(uuid, studentSurvey.survey);
      history.push(`/evaluation-thank`);
    }
  };

  const _resetSurvey = (): void => {
    if (studentSurvey && studentSurvey.survey) {
      const time = new Date().getTime();
      studentSurvey.survey.questions.map((q) => {
        q.answers.map((a) => {
          a.id = 0;
          a.time = time.toString();

          return a;
        });

        return q;
      });

      setStudentSurvey({ ...studentSurvey });
    }
  };

  return (
    <div className={classes.main}>
      <div
        className={clsx({
          [classes.container]: studentSurvey && !studentSurvey.isAnswered,
        })}
      >
        {!loading ? (
          <>
            {studentSurvey && studentSurvey.survey ? (
              <>
                <div
                  className={clsx(
                    classes.backgroundColorFFF,
                    classes.containerQuestion
                  )}
                >
                  <div className={classes.titleSurvey}>
                    {studentSurvey.survey.title}
                  </div>
                  <DescriptionSurvey
                    content={studentSurvey.survey.description}
                  />

                  <div className={classes.marginTop20}>
                    {studentSurvey.survey.questions.map(
                      (question: ISurveyQuestion) => (
                        <div
                          key={question.id ?? 0}
                          className={clsx(classes.marginTop20, {
                            [classes.errorQuestion]:
                              !!mapOfQuestionIdToError.get(question.id ?? 0),
                          })}
                        >
                          <Question question={question}>
                            <AnswerItem
                              questionId={question.id ?? 0}
                              type={question.type}
                              answers={question.answers}
                              handleChangeAnswer={handleChangeAnswer}
                              right={QuestionRight.ONLY_ANSWER}
                              notTitle={true}
                            />
                          </Question>
                        </div>
                      )
                    )}
                  </div>

                  <>
                    <Divider
                      variant="fullWidth"
                      className={clsx(
                        classes.marginTop40,
                        classes.dividerStyle
                      )}
                    />
                    <div
                      className={clsx(classes.fieldRequire, classes.marginTop5)}
                    >
                      *Må fylles ut
                    </div>
                  </>

                  <div
                    className={clsx(classes.displayFlex, classes.marginTop20)}
                  >
                    {studentSurvey && !studentSurvey.isAnswered && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          await handleEvaluation();
                        }}
                      >
                        Lagre
                      </Button>
                    )}
                    <Button
                      variant="text"
                      onClick={() => {
                        history.push(`/`);
                      }}
                      style={{ marginLeft: '12px' }}
                    >
                      Avbryt
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <Grid
                container
                direction="row"
                spacing={0}
                className={classes.rootGrid}
              >

                <Grid item xs={12} className={classes.banner}>
                  <div>Hei, enten du har svart på brukerundersøkelsen</div>
                  eller brukerundersøkelse med id = {uuid} finnes ikke
                  <LoginIllustration className={classes.loginIllustration} />
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.justifyContentCenter,
              classes.minHeight
            )}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Evaluation;
