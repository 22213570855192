import { makeStyles } from '@material-ui/core';

export const configStyle = {
  widthTitle: 150,
  widthButtonDeleteQuestion: 150,
  paddingContentQuestion: 100,
  widthButtonDeleteAnswerOption: 24,
  widthSurvey: 40,
};

const evaluationStyle = makeStyles(({ breakpoints, palette }) => ({
  main: {
    backgroundColor: palette.grey[50],
  },
  container: {
    padding: '0 116px',
    paddingTop: '20px',
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  minHeight: {
    minHeight: '100vh',
  },
  containerQuestion: {
    padding: '20px 120px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  rootGrid: {
    height: '100vh',
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.primary.light, //TODO - Change to primary['50'] once UK-24 is merged
    maxHeight: '100%',
  },
  bannerHeaderText: {
    color: palette.primary.dark,
    fontWeight: 500,
    marginTop: 'calc(100vh * 0.1)',
    [breakpoints.down('sm')]: {
      marginTop: 'calc(100vh * 0.03)',
    },
  },
  loginIllustration: {
    width: '100%',
    height: 'auto',
  },
  titleSurvey: {
    color: palette.common.black,
    fontSize: '28px',
  },
  dividerStyle: {
    backgroundColor: '#75b86e',
    height: '3px !important',
  },
  marginTop5: {
    marginTop: '5px',
  },
  marginTop40: {
    marginTop: '40px',
  },
  fieldRequire: {
    color: '#d9534f',
  },
  errorQuestion: {
    padding: '12px 16px',
    border: '1px solid #f00',
  },
}));

export default evaluationStyle;
