import { CreateOrUpdateStudentCourseDto } from '@/types/StudentCourse';
import { RootStore } from '.';
import studentCourseService from '@/services/StudentCourseService';
import { action } from 'mobx';

class StudentCourseStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  manualAssignStudentToCourse = async (
    studentId: number | undefined,
    courseId: number | undefined
  ): Promise<void> => {
    await studentCourseService.manualAssignStudentToCourse(studentId, courseId);
  };

  public async updateStudentCourse(
    id: number,
    model: CreateOrUpdateStudentCourseDto
  ): Promise<boolean> {
    return await studentCourseService.updateStudentCourse(id, model);
  }

  // public async movingAssign(
  //   studentId: number,
  //   oldCourseId: number,
  //   newCourseId: number
  // ): Promise<void> {
  //   return await studentCourseService.movingAssign(
  //     studentId,
  //     oldCourseId,
  //     newCourseId
  //   );
  // }

  public async movingAssignMul(
    studentIds: number[],
    oldCourseId: number,
    newCourseId: number
  ): Promise<void> {
    return await studentCourseService.movingAssignMul(
      studentIds,
      oldCourseId,
      newCourseId
    );
  }
}

export default StudentCourseStore;
