import { CircularProgress, Typography } from '@material-ui/core';
import { CSSProperties, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStores } from '../../stores';

const style: CSSProperties = {
  display: 'grid',
  placeItems: 'center',
  marginTop: '40vh',
};

const CompleteOIDCLogin = () => {
  const { userStore, accountStore } = useStores();
  const history = useHistory();

  const search = useLocation().search;

  const getUser = async () => {
    const code = new URLSearchParams(search).get('code');

    try {
      if (code) {
        await accountStore.oidcCallback(code);
      }

      await userStore.getCurrentUser();
      history.push('/home');
    } catch (err: any) {
      history.push('/');
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={style}>
      <CircularProgress />
      <Typography variant="h3">Du blir logget inn.</Typography>
    </div>
  );
};

export default CompleteOIDCLogin;
