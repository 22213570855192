import ICourse, {
  CreateUpdateCourse,
  IListRequestQuery,
  State,
} from '@/types/Course';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import { CourseStatus } from '@/types/enums/CourseStatus';
import { IUserDto } from '@/types/User';
import { AxiosInstance } from 'axios';
import { PaginationRequest } from '../types/dto/PaginationRequest.dto';
import {
  newHttpInstance,
  prepareGetQuery,
  prepareGetQueryFromArray,
} from '../utils/httpUtils';

class CourseService {
  prefix = '/api/Course';
  http: AxiosInstance;

  constructor() {
    this.http = newHttpInstance(this.prefix);
  }

  public async getList(
    model: IListRequestQuery
  ): Promise<PaginationResult<ICourse>> {
    const result = await this.http.get(
      `${prepareGetQuery(model as Record<string, unknown>)}`
    );
    return result.data;
  }

  public async getStudentsForDetailCourse(
    id: number,
    model: PaginationRequest
  ): Promise<PaginationResult<IUserDto>> {
    const result = await this.http.get(
      `${id}/detail${prepareGetQuery(model as Record<string, unknown>)}`
    );
    return result.data;
  }

  public async getUpdateCourseById(id: number): Promise<CreateUpdateCourse> {
    const result = await this.http.get(`/${id}/update`);
    return result.data;
  }

  public async createCourse(model: CreateUpdateCourse): Promise<boolean> {
    const result = await this.http.post(``, model);
    return result.data;
  }

  public async updateCourse(
    id: number,
    model: CreateUpdateCourse
  ): Promise<CreateUpdateCourse> {
    const result = await this.http.put(`/${id}`, model);
    return result.data;
  }

  public async getCourseById(id: number): Promise<ICourse> {
    const result = await this.http.get(`/${id}`);
    return result.data;
  }

  public async getListVGS(
    model: IListRequestQuery
  ): Promise<PaginationResult<ICourse>> {
    const result = await this.http.get(
      `vgs${prepareGetQuery(model as Record<string, unknown>)}`
    );
    return result.data;
  }

  public async updateCourseVGS(
    id: number,
    model: CreateUpdateCourse
  ): Promise<ICourse> {
    const result = await this.http.put(`vgs/${id}`, model);
    return result.data;
  }

  public async getCourseStatusByProjectId(
    projectId: number,
    externalSchoolId?: string
  ): Promise<CourseStatus[]> {
    const result = await this.http.get(`byProjectId/${projectId}`, {
      params: {
        externalSchoolId,
      },
    });

    return result.data;
  }
  public async deleteCourse(id: number): Promise<State> {
    const result = await this.http.delete(`${id}`);
    return result.data;
  }

  public async duplicateCourse(id: number): Promise<State> {
    const result = await this.http.post(`duplicate/${id}`);
    return result.data;
  }

  public async removeAllAllocations(courseIds: number[]): Promise<State> {
    const result = await this.http.get(
      `remove-all-allocations${prepareGetQueryFromArray(
        'courseIds',
        courseIds
      )}`
    );
    return result.data;
  }

  public async deleteListCourse(
    projectId: number,
    courseIds: number[]
  ): Promise<State> {
    const result = await this.http.get(
      `byProjectId/${projectId}/delete-list-course${prepareGetQueryFromArray(
        'courseIds',
        courseIds
      )}`
    );
    return result.data;
  }

  public async undoCourse(projectId: number, state: State): Promise<boolean> {
    const result = await this.http.post(
      `byProjectId/${projectId}/undo-course`,
      state
    );
    return result.data;
  }

  public async redoCourse(projectId: number, state: State): Promise<boolean> {
    const result = await this.http.post(
      `byProjectId/${projectId}/redo-course`,
      state
    );
    return result.data;
  }

  public async getCourseExcelExport(projectId: number): Promise<void> {
    const response = await this.http.get(
      `byProjectId/${projectId}/excel-export`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });

    const now = new Date();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `course-export-${now.getTime()}.xlsx`;
    link.click();
  }

  public async updateCourseToUndoOrRedo(
    id: number,
    model: CreateUpdateCourse
  ): Promise<State> {
    const result = await this.http.put(`/${id}/undo-or-redo`, model);
    return result.data;
  }

  public async getCoursesMinified(
    projectId: number,
    studySubjectId?: number
  ): Promise<ICourse[]> {
    const result = await this.http.get(`byProjectId/${projectId}/minified`, {
      params: {
        studySubjectId,
      },
    });
    return result.data;
  }

  public async listParticipantOfCourseExport(
    projectId: number,
    courseId: number
  ): Promise<void> {
    const response = await this.http.get(
      `vgs/byProjectId/${projectId}/byCourseId/${courseId}/export-participant`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });

    const now = new Date();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `participant-export--${now.getTime()}.xlsx`;
    link.click();
  }

  public async updateStatusMultipleCourse(
    models: CreateUpdateCourse[]
  ): Promise<State> {
    const result = await this.http.put(`update-status-multiple-course`, models);
    return result.data;
  }

  public async getAllByProjectId(projectId: number): Promise<ICourse[]> {
    const result = await this.http.get(`byProjectId/${projectId}/get-all`);
    return result.data;
  }
}

export default new CourseService();
