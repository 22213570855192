import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';
import courseStyle from '@/views/style/courses';
import {
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  DialogContent,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  statusNew: CourseStatus;
  handleCloseDialog: () => void;
  updateStatusMultipleCourse: () => Promise<void>;
  setStatusNew: (status: CourseStatus) => void;
}

const ChangeMultipleStatusDialog = (props: IComponentProps) => {
  const {
    open,
    statusNew,
    handleCloseDialog,
    updateStatusMultipleCourse,
    setStatusNew,
  } = props;
  const courseClasses = courseStyle();
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        fullWidth
      >
        <div className={courseClasses.movingMulDialog}>
          <DialogTitle classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.displayFlex,
                courseClasses.alignItemsCenter
              )}
            >
              <div
                className={clsx(
                  courseClasses.textMovingMulDialog,
                  courseClasses.fontSize18
                )}
              >
                Endre status
              </div>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                classes={{ root: courseClasses.marginLeftAuto }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <Select
              disableUnderline
              value={statusNew}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setStatusNew(e.target.value as CourseStatus)
              }
              classes={{
                root: courseClasses.movingMulDialogSelect,
                icon: courseClasses.color500,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: courseClasses.movingMulDialogPager,
                  list: courseClasses.movingMulDialogList,
                },
              }}
              className={clsx(
                courseClasses.widthFull,
                courseClasses.marginTop10
              )}
            >
              {Object.keys(CourseStatus)
                .filter(
                  (k: string) => typeof CourseStatus[k as any] === 'string'
                )
                .map((k1: string) => +k1)
                .map((k2: number) => (
                  <MenuItem key={k2} value={k2}>
                    {getStatusDisplay(k2)}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>

          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.displayFlex,
                courseClasses.marginTop20
              )}
            >
              <div className={courseClasses.marginLeftAuto}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await updateStatusMultipleCourse();
                  }}
                >
                  Endre status
                </Button>
                <Button
                  variant="text"
                  onClick={handleCloseDialog}
                  style={{ marginLeft: '12px' }}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default ChangeMultipleStatusDialog;
