import { useStores } from '@/stores';
import { StudentStudySubject } from '@/types/StudySubject';
import { User } from '@/types/User';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import StudentRegistrationRow from './StudentRegistrationRow';
import useStyles from './style';

interface IStudentRegistrationTableProps {
  loading: boolean;
  students: User[];
  page: number;
  handlePageChange: (e: any, page: number) => void;
  rowsPerPage: number;
  handleRowsPerPageChange: (e: any) => void;
  all: boolean;
  addOrUpdate: (models: StudentStudySubject[]) => Promise<void>;
}

const StudentRegistrationTable = ({
  students,
  page,
  handlePageChange,
  rowsPerPage,
  handleRowsPerPageChange,
  loading,
  all,
  addOrUpdate,
}: IStudentRegistrationTableProps) => {
  const { schoolStore } = useStores();
  const { totalStudentCount, noPrioritiesCount } = schoolStore;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Navn</TableCell>
          <TableCell>Klasse</TableCell>
          {all && <TableCell>Skole</TableCell>}
          <TableCell align="right">Påmeldingsstatus</TableCell>
          <TableCell align="right">
            <div>
              <Typography variant="body1">
                {totalStudentCount} elever totalt
              </Typography>
              <Typography variant="body1">
                {noPrioritiesCount} uten meldt prioritering
              </Typography>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableCell colSpan={6} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </TableCell>
        ) : (
          <>
            {students.map((student) => (
              <StudentRegistrationRow
                student={student}
                key={'tableRow-' + student.id}
                all={all}
                addOrUpdate={addOrUpdate}
              />
            ))}
          </>
        )}
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        count={totalStudentCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Studenter per side:"
        nextIconButtonText="Neste side"
        backIconButtonText="Forrige side"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} av ${count !== -1 ? count : `mer enn ${to}`}`
        }
      />
    </Table>
  );
};

export default observer(StudentRegistrationTable);
