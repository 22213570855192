import React from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useStores } from '@/stores';
import userSurveyStyle from '../style';
import AdminHeader from '../../common/AdminHeader';
import { ISurvey } from '@/types/Survey';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Survey from './survey';
import ListProject from './list-project';
import { PER_PAGE_OPTIONS } from '@/types/dto/PaginationRequest.dto';
import { ProjectAssignDto } from '@/types/ProjectSurvey';

interface UrlParams {
  surveyId: string;
}

const SurveyDetail = () => {
  const { surveyId } = useParams<UrlParams>();
  const { userSurveyStore } = useStores();

  const history = useHistory();
  const classes = userSurveyStyle();

  const [survey, setSurvey] = React.useState<ISurvey | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchData = async (surveyId: number): Promise<void> => {
    setLoading(true);
    const surveyFromDB = await userSurveyStore.getSurveyById(surveyId);
    setSurvey(surveyFromDB);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(+surveyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const openEditSurvey = (): void => {
    history.push(`/user-surveys/${surveyId}/edit`);
  };

  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(+PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [list, setList] = React.useState<ProjectAssignDto[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [loadingList, setLoadingList] = React.useState<boolean>(false);

  const getList = React.useCallback(async () => {
    setLoadingList(true);
    const result = await userSurveyStore.getListProjectAssign({
      skip,
      take,
      surveyId: +surveyId,
    });

    setList(result.data);
    setTotal(result.count);
    setLoadingList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSurveyStore, skip, take]);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const changePageOption = (take: number): void => {
    handleChoosePage(1);
    setTake(take);
  };

  const handleChoosePage = (page: number): void => {
    setSkip((page - 1) * take);
    setCurrentPage(page);
  };

  const downloadProjectSurvey = async (projectId: number): Promise<void> => {
    if (surveyId) {
      await userSurveyStore.getProjectSurveyExcelExport(projectId, +surveyId);
    }
  };

  return (
    <>
      <AdminHeader
        title={
          <Link to="/user-surveys" className={classes.buttonSurveyHome}>
            Brukerundersøkelse
          </Link>
        }
      />

      <div className={classes.container}>
        {!loading ? (
          <>
            {survey && (
              <div className={clsx(classes.displayFlex)}>
                <div
                  className={clsx(
                    classes.containerSurvey,
                    classes.backgroundColorFFF
                  )}
                >
                  <Survey survey={survey} openEditSurvey={openEditSurvey} />
                </div>
                <div className={classes.containerListProject}>
                  <ListProject
                    list={list}
                    total={total}
                    loading={loadingList}
                    currentPage={currentPage}
                    take={take}
                    changePageOption={changePageOption}
                    handleChoosePage={handleChoosePage}
                    downloadProjectSurvey={downloadProjectSurvey}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.justifyContentCenter,
              classes.minHeight
            )}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(SurveyDetail);
