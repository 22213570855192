import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  contentStudentRowSelect: {
    //height: '52px',
    width: '296px',
  },
  select: {
    '&:focus': {
      backgroundColor: palette.background.default,
    },
  },
  icon: {
    color: palette.primary[500],
  },
  menuItem: {
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    padding: '10px 12px',
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
  },
  title: {
    color: palette.primary[900],
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '6px',
  },
}));

export default useStyles;
