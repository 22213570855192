import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      margin: '10px 0',
    },
    width: '302px',
    marginBottom: '84px',
  },
  addressWrapper: {
    ...flexRow,
    gap: '6px',
  },
}));

export default useStyle;
