import { makeStyles } from "@material-ui/core";

interface IUseStylesProps {
    open: boolean
}

export const useUserInformationStyles = makeStyles(({ palette, breakpoints }) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 1.5em',
        alignSelf: 'stretch',
        transition: 'all 0.25s ease',
        backgroundColor: (props: IUseStylesProps) => props.open ? palette.info.light : palette.primary.main,
        color: (props: IUseStylesProps) => props.open ? palette.primary.dark : '#fff',
        '&:hover': {
            backgroundColor: palette.info.light,
            cursor: 'pointer',
            color: palette.primary.dark
        },
        gap: '15px'
    },
    name: {
        fontSize: '16px',
        lineHeight: '22px'
    },
    role: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    personCircle: {
        backgroundColor: palette.primary.light,
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

}));

export const useUserInformationDropdownStyles = makeStyles(({ breakpoints }) => ({
    root: {
        position: 'fixed',
        right: 0,
        [`${breakpoints.up('xs')}`]: { 
            top: 48, 
        }, 
        padding: '10px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '350px',
        alignItems: 'center'
    },
    main: {
        margin: '25px 0'
    },
    settingsButton: {

    },
    iconSpan: {
        marginLeft: '5px'
    },
}))


