import MessageTemplate from '@/types/MessageTemplate';
import BaseService from './BaseService';

class MessageTemplateService extends BaseService<MessageTemplate> {
  constructor() {
    super('messageTemplate');
  }
}

export default new MessageTemplateService();
