import { User } from '@/types/User';
import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
  AccessibilityNewOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  MailOutlined,
  PhoneIphoneOutlined,
  PersonAddDisabledOutlined,
  CommentOutlined,
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import StudentRowDropdown from '../../common/StudentRowDropdown';
import ContactPersonCell from './ContactPersonCell';
import useStyles from './style';
import TimeCell from './TimeCell';
import { useStores } from '@/stores';
import CustomEditorReadOnly from '@/components/CustomEditorReadOnly';
import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';

interface IStudentCourseRowProps {
  student: User;
  all: boolean;
  handleOpenManualAssignDialog: (student: User) => void;
}

const StudentCourseRow = ({
  student,
  all,
  handleOpenManualAssignDialog,
}: IStudentCourseRowProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });
  const studentCourse = student.studentCourses[0] ?? null;
  const { courseStore, stateStore } = useStores();
  const chooseCourseShowInfoVGS = (): void => {
    if (studentCourse != null && studentCourse.finalAssignedCourseId != null) {
      courseStore.getCourseById(studentCourse.finalAssignedCourseId);
      stateStore.setVgsCourseInfoDrawerIsOpen(true);
    }
  };
  const assignedStudentStudySubject = student.studentStudySubjects.find(
    (sub) =>
      sub.studySubjectId === studentCourse?.finalAssignedCourse?.studySubjectId
  );

  const beforeHandleOpenManualAssignDialog = (): void => {
    handleOpenManualAssignDialog(student);
  };

  const getStatus = (): string => {
    if (studentCourse && studentCourse.finalAssignedCourse) {
      const courseStatus = studentCourse.finalAssignedCourse.status;

      if (courseStatus === CourseStatus.CANCELLED) {
        return getStatusDisplay(courseStatus);
      }
    }

    return '';
  };

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div>
            <Typography variant="body1" className={classes.studentName}>
              {student.fullName}
            </Typography>
            <Typography variant="body1">
              {
                studentCourse?.finalAssignedCourse?.studySubject
                  ?.studySubjectName
              }
            </Typography>
          </div>
        </TableCell>
        <TableCell>{student.class?.fullName}</TableCell>
        {all && <TableCell>{student.school?.schoolName}</TableCell>}
        <TableCell style={{ color: 'red' }}>{getStatus()}</TableCell>
        <TableCell>
          <TimeCell course={studentCourse} />
        </TableCell>
        <TableCell>
          <ContactPersonCell course={studentCourse} />
        </TableCell>
        <TableCell align="right">
          {!!assignedStudentStudySubject ? (
            <Chip
              className={classes.thinChip}
              label={`${assignedStudentStudySubject.priority}. prioritet`}
            ></Chip>
          ) : (
            'Ikke definert'
          )}
        </TableCell>
        <TableCell align="right" className={classes.whiteSpaceNowrap}>
          {student.hasSpecialNeeds && (
            <span className={classes.spaceIcon}>
              <AccessibilityNewOutlined />
            </span>
          )}
          {!(studentCourse && studentCourse.attendance) && (
            <span className={classes.spaceIcon}>
              <PersonAddDisabledOutlined />
            </span>
          )}
          {studentCourse && studentCourse.note && (
            <Tooltip
              interactive
              classes={{
                tooltip: classes.boxTooltipComment,
              }}
              title={<CustomEditorReadOnly content={studentCourse.note} />}
            >
              <IconButton>
                <CommentOutlined />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={chooseCourseShowInfoVGS}>
            <InsertDriveFileOutlinedIcon />
          </IconButton>
          <IconButton href={'tel:' + student.phoneNumber} target="_blank">
            <PhoneIphoneOutlined />
          </IconButton>
          <IconButton href={'mailto:' + student.emailAddress} target="_blank">
            <MailOutlined />
          </IconButton>
        </TableCell>
      </TableRow>
      <StudentRowDropdown
        open={open}
        student={student}
        assignedStudentStudySubject={assignedStudentStudySubject}
        all={all}
        handleOpenManualAssign={beforeHandleOpenManualAssignDialog}
      />
    </>
  );
};

export default observer(StudentCourseRow);
