import { useStores } from '@/stores';
import IStudySubject from '@/types/StudySubject';
import { Button } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import AdminHeader from '../../admin/common/AdminHeader';
import AdminSearchBar from '../../admin/common/AdminSearchBar';
import StudySubjectCard from './StudySubjectCard';
import StudySubjectDrawer from './StudySubjectDrawer';
import StudySubjectFilter from './StudySubjectFilter';
import useStyles from './style';

interface IStudySubjectsProps {
  isAdmin?: boolean;
}

const StudySubjects: FC<IStudySubjectsProps> = ({ isAdmin = false }) => {
  const classes = useStyles();
  const { studySubjectStore, stateStore } = useStores();
  const { allStudySubjects, getAllStudySubjects } = studySubjectStore;
  const { setStudySubjectDrawerIsOpen } = stateStore;

  const [searchString, setSearchString] = useState('');
  const [chosenCategory, setChosenCategory] = useState(0);
  const [deactivatedFilterValue, setDeactivatedFilterValue] = useState(
    isAdmin ? 'all' : 'active'
  );
  const [isHideFilterValue, setIsHideFilterValue] = useState(
    isAdmin ? 'all' : 'isHide'
  );

  useEffect(() => {
    if (allStudySubjects.length === 0) {
      getAllStudySubjects(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchFilter = (sub: IStudySubject) =>
    searchString
      ? sub.studySubjectName.toLowerCase().includes(searchString.toLowerCase())
      : true;
  const categoryFilter = (sub: IStudySubject) =>
    chosenCategory > 0 ? sub.category === chosenCategory : true;

  const deactivatedFilter = (sub: IStudySubject) => {
    if (deactivatedFilterValue === 'all') {
      return true;
    } else if (deactivatedFilterValue === 'active') {
      return !sub.deactivated;
    }
    return sub.deactivated;
  };

  const isHideFilter = (sub: IStudySubject) => {
    if (isHideFilterValue === 'all') {
      return true;
    } else if (isHideFilterValue === 'isHide') {
      return !sub.isHide;
    }
    return sub.isHide;
  };

  const filteredStudySubjects = allStudySubjects
    .filter(
      (sub) =>
        searchFilter(sub) && categoryFilter(sub) && deactivatedFilter(sub)
    )
    .filter((sub) => isHideFilter(sub))
    .sort((a, b) =>
      a.studySubjectName.toLowerCase() > b.studySubjectName.toLowerCase()
        ? 1
        : -1
    );

  return (
    <>
      <main>
        <AdminHeader title="Kurs" />
        <AdminSearchBar
          label="Søk etter kurs"
          handleSearchValueChange={setSearchString}
          children={
            isAdmin && (
              <Button
                variant="text"
                onClick={() => setStudySubjectDrawerIsOpen(true)}
              >
                Legg til kurs
                <AddOutlined />
              </Button>
            )
          }
        />
        <div className={classes.listWrapper}>
          <StudySubjectFilter
            isAdmin={isAdmin}
            chosenCategory={chosenCategory}
            setChosenCategory={setChosenCategory}
            deactivatedFilterValue={deactivatedFilterValue}
            setDeactivatedFilterValue={setDeactivatedFilterValue}
            isHideFilterValue={isHideFilterValue}
            setIsHideFilterValue={setIsHideFilterValue}
          />
          {filteredStudySubjects.map((sub) => (
            <StudySubjectCard studySubject={sub} isAdmin={isAdmin} />
          ))}
        </div>
      </main>
      <StudySubjectDrawer />
    </>
  );
};

export default observer(StudySubjects);
