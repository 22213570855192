import { CKEditor } from '@ckeditor/ckeditor5-react';
import './index.css';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Base64CustomUploadAdapter } from './Base64CustomUploadAdapter';

interface IComponentProps {
  content: string | null | undefined;
  setContent?: (content: string) => void;
  disabled?: boolean;
}

const CustomSurveyEditor = (props: IComponentProps) => {
  const { content, setContent, disabled } = props;
  const config = {
    extraPlugins: [Base64CustomUploadAdapter],
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        '|',
        'alignment',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'insertTable',
        '|',
        'uploadImage',
        'insertImage',
        '|',
        'mediaEmbed',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'blockQuote',
        '|',
        'specialCharacters',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo',
      ],
      shouldNotGroupWhenFull: true,
    },
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
      ],
    },
    mediaEmbed: {
      // configuration...
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'toggleTableCaption',
        'tableProperties',
        'tableCellProperties',
      ],
    },
    language: 'nb',
    fontFamily: {
      supportAllValues: true,
    },
  };

  return (
    <>
      <div className="custom-survey-editor">
        <CKEditor
          editor={Editor}
          disabled={disabled}
          data={content ?? ''}
          onReady={(editor: any) => {
            if (editor) {
              editor.setData(content ?? '');
            }
          }}
          onChange={(event: any, editor: any) => {
            if (setContent) {
              const html = editor.getData();
              setContent(html || '<p></p>');
            }
          }}
          config={config}
        />
      </div>
    </>
  );
};

export default CustomSurveyEditor;
