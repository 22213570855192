enum MessageTemplateAttribute {
  USER_NAME = '{user.name}',
  LIST_OF_COURSES = '{courses}',
  COURSE_NAME = '{course.subject.name}',
  COURSE_SCHOOL_ADDRESS = '{course.school.address}',
  COURSE_SCHOOL_NAME = '{course.school.name}',
  COURSE_ROOM_NUMBER = '{course.roomNumber}',
  COURSE_DATE_TIME = '{course.dateTime}',
  END_OF_BOOKING = '{project.registrationPeriodEndDate}',
  EVALUATION_LINK = '{evaluationLink}',
  PORTAL_LINK = '{portalLink}',
}

export default MessageTemplateAttribute;
