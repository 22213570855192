// import { useStores } from '../../stores';
// import { UserRole } from '../../types/UserRole';
// import AdminDashboard from './admin/AdminDashboard/AdminDashboard';
// import StudentDashboard from './student/StudentDashboard/StudentDashboard';
import VGSDashboard from '../VGSadmin/dashboard';
import GUDashboard from '../GUadmin/dashboard';

import { useStores } from '@/stores';
import { UserRole } from '@/types/UserRole';
import AdminDashboard from '@/views/app/admin/AdminDashboard/AdminDashboard';
import StudentDashboard from '@/views/app/student/StudentDashboard/StudentDashboard';

const Dashboard = () => {
  const { userStore } = useStores();
  const { user } = userStore;

  if (user?.isCurrentlyRole(UserRole.Student)) {
    return <StudentDashboard />;
  }

  if (user?.isCurrentlyRole(UserRole.Adviser)) {
    return <GUDashboard />;
  }

  if (user?.isCurrentlyRole(UserRole.HighSchoolContact)) {
    return <VGSDashboard />;
  }
  // if (user?.isCurrentlyRole(UserRole.Admin)) return <AdminDashboard />
  if (user?.isCurrentlyRole(UserRole.Admin)) return <AdminDashboard />;

  return <></>;
};

export default Dashboard;
