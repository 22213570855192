import { makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import { UserRole } from '../../../../types/UserRole';
import RoleButton from './RoleButton';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '30%',
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
  },
  fullscreen: {
    width: '100vw',
    heigh: '100vh',
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },
  h1: {
    margin: 'auto',
  },
}));

const RoleSelector = ({ fullscreen = false }) => {
  const { stateStore, userStore } = useStores();
  const { user } = userStore;
  const history = useHistory();

  const classes = useStyles();

  const onClick = (role: UserRole) => {
    userStore.setCurrentRole(role);
    history.push('/temp');
    history.push('/home');
    stateStore.setUserInformationDropdownIsOpen(false);
  };

  return (
    <div
      className={classes.flex + ' ' + (fullscreen ? classes.fullscreen : '')}
    >
      {fullscreen && (
        <Typography variant="h3" className={classes.h1}>
          Velg en rolle for å gå videre
        </Typography>
      )}
      {user?.hasSwitchRole(UserRole.Student) && (
        <RoleButton role={UserRole.Student} onClick={onClick} />
      )}
      {user?.hasSwitchRole(UserRole.Adviser) && (
        <RoleButton role={UserRole.Adviser} onClick={onClick} />
      )}
      {user?.hasSwitchRole(UserRole.HighSchoolContact) && (
        <RoleButton role={UserRole.HighSchoolContact} onClick={onClick} />
      )}
      {user?.hasSwitchRole(UserRole.Admin) && (
        <RoleButton role={UserRole.Admin} onClick={onClick} />
      )}
    </div>
  );
};

export default observer(RoleSelector);
