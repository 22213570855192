import {
  IStatisticAllocation,
  IStatisticRegistration,
  IStatisticSubjectCapacity,
} from '@/types/StudentStudySubject';
//import { IUserDto } from '@/types/User';
import { newHttpInstance } from '@/utils/httpUtils';
import { AxiosInstance } from 'axios';

class DashboardService {
  studySubjectPrefix = '/api/StudySubject';
  userPrefix = '/api/User';
  http: AxiosInstance;

  constructor() {
    this.http = newHttpInstance();
  }

  public async getStatisticCapacityVGS(
    projectId: number,
    externalSchoolId: string
  ): Promise<IStatisticSubjectCapacity[]> {
    const result = await this.http.get(
      `${this.studySubjectPrefix}/byProjectId/${projectId}/vgs/statistic-capacity/byExternalSchoolId/${externalSchoolId}`
    );
    return result.data;
  }

  /*public async getAllHighSchoolContactByStudySubjectId(
    studySubjectId: number,
    externalSchoolId?: string
  ): Promise<IUserDto[]> {
    const result = await this.http.get(
      `${this.userPrefix}/all/high-school-contact/byStudySubjectId/${studySubjectId}`,
      {
        params: {
          externalSchoolId,
        },
      }
    );
    return result.data;
  }*/

  public async getStatisticRegistrationGU(
    projectId: number,
    externalSchoolId: string
  ): Promise<IStatisticRegistration> {
    const result = await this.http.get(
      `${this.studySubjectPrefix}/byProjectId/${projectId}/gu/statistic-registration/byExternalSchoolId/${externalSchoolId}`
    );
    return result.data;
  }

  public async getStatisticAllocationGU(
    projectId: number,
    externalSchoolId: string
  ): Promise<IStatisticAllocation> {
    const result = await this.http.get(
      `${this.studySubjectPrefix}/byProjectId/${projectId}/gu/statistic-allocation/byExternalSchoolId/${externalSchoolId}`
    );
    return result.data;
  }
}

export default new DashboardService();
