import { ISurveyQuestion } from './SurveyQuestion';

export interface ISurvey {
  id?: number;
  name: string;
  title: string;
  description: string;
  isSent: boolean;
  questions: ISurveyQuestion[];
  createdDate?: Date;
}

export const INIT_SURVEY: ISurvey = {
  name: '',
  title: '',
  description: '',
  isSent: false,
  questions: [],
};

export interface IStudentSurvey {
  isAnswered: boolean;
  survey?: ISurvey;
}
