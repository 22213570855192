import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '380px',
      padding: '0 24px',
      width: '380px',
      gap: '24px',
    },
  },
  title: {
    fontSize: '22px',
    marginTop: '24px',
    color: palette.primary.dark,
  },
  inputs: {
    maxHeight: '50px',
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
    marginBottom: '50px',
  },
  noMarginHelperText: {
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  buttonWrapper: {
    marginTop: '40px',
    '& button': {
      height: '48px',
      width: '155px',
    },
  },
}));

export default useStyles;
