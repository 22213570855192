import { flexCol } from '@/style/common';
import Message, { MessageStatus } from '@/types/Message';
import { Button, Drawer, makeStyles, Typography } from '@material-ui/core';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FC, useEffect } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  drawerContent: {
    padding: '16px',
    ...flexCol,
    gap: '16px',
    minWidth: '400px',
    maxWidth: '800px',
  },
  emailBody: {
    backgroundColor: palette.grey[200],
    padding: '6px',
    borderRadius: '4px',
  },
}));

const MessageDrawer: FC<{
  message: Message | null;
  setMessage: (m: Message | null) => void;
}> = ({ message, setMessage }) => {
  const classes = useStyles();

  const editor = useEditor({
    extensions: [StarterKit],
    content: message?.emailBody,
  });

  useEffect(() => {
    if (editor) editor.commands.setContent(message?.emailBody ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Drawer open={!!message} onClose={() => setMessage(null)} anchor="right">
      <div className={classes.drawerContent}>
        <Typography variant="h3">
          Melding til {message?.user.fullName}
        </Typography>
        {message?.emailStatus === MessageStatus.NotSent ? (
          <Typography variant="h4">Email ble ikke sendt</Typography>
        ) : (
          <div>
            <Typography variant="h4">Email</Typography>
            <Typography variant="h5">{message?.emailSubject}</Typography>
            <EditorContent editor={editor} className={classes.emailBody} />
          </div>
        )}

        {message?.smsStatus === MessageStatus.NotSent ? (
          <Typography variant="h4">SMS ble ikke sendt</Typography>
        ) : (
          <div>
            <Typography variant="h4">SMS</Typography>
            <Typography variant="body1">{message?.sms}</Typography>
          </div>
        )}
        <Button onClick={() => setMessage(null)}>Lukk</Button>
      </div>
    </Drawer>
  );
};

export default MessageDrawer;
