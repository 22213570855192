import courseTemplateService from '@/services/CourseTemplateService';
import {
  CreateUpdateCourseTemplate,
  ICourseTemplate,
} from '@/types/CourseTemplate';
import { RootStore } from '.';
import {action} from "mobx";

class CourseTemplateStore {
  rootStore: RootStore;
  courseTemplatesForCurrentSchool: ICourseTemplate[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public async createCourseTemplate(
    model: CreateUpdateCourseTemplate
  ): Promise<boolean> {
    return await courseTemplateService.createCourseTemplate(model);
  }

  public async updateCourseTemplate(
    id: number,
    model: CreateUpdateCourseTemplate
  ): Promise<boolean> {
    return await courseTemplateService.updateCourseTemplate(id, model);
  }

  public async getCourseTemplate(
    studySubjectId: number,
    externalSchoolId: string
  ): Promise<ICourseTemplate> {
    return await courseTemplateService.getCourseTemplate(
      studySubjectId,
      externalSchoolId
    );
  }

  @action
  public async getCourseTemplates(
      externalSchoolId: string
  ): Promise<void> {
    this.courseTemplatesForCurrentSchool =  await courseTemplateService.getCourseTemplates(
        externalSchoolId
    );
  }
}

export default CourseTemplateStore;
