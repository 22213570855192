import { flexRow } from '@/style/common';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import { ReactNode } from 'react';

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    backgroundColor: palette.primary.light,
    ...flexRow,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '96px',
    padding: '20px 115px',
    [breakpoints.down('md')]: {
      padding: '10px 5px',
    },
  },
  header: ({ titlePadding }: { titlePadding?: number }) => ({
    ...flexRow,
    alignItems: 'center',
    gap: '16px',
    '& .MuiDivider-root': {
      backgroundColor: palette.primary.main,
      height: '50%',
      minHeight: '30px',
    },
    paddingLeft: titlePadding ? titlePadding : 10,
    color: palette.primary.dark,
  }),
  headerText: {
    fontWeight: 400,
  },
}));

interface IAdminHeaderProps {
  title?: ReactNode;
  actions?: ReactNode;
  titlePadding?: number;
}

const AdminHeader = ({ title, actions, titlePadding }: IAdminHeaderProps) => {
  const classes = useStyle({ titlePadding });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <HomeOutlined />
        <Divider orientation="vertical" />
        <Typography variant="h2" className={classes.headerText}>
          {title}
        </Typography>
      </div>
      <div>{actions}</div>
    </div>
  );
};

export default AdminHeader;
