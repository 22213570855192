import { useStores } from '@/stores';
import React, { useCallback, useEffect, useState } from 'react';
import VGSStudySubjectDrawer from '../../common/StudySubjects/VGSStudySubjectDrawer';
import { Color } from '@material-ui/lab';
import { CreateUpdateCourseTemplate } from '@/types/CourseTemplate';
import NotifyMessage from '@/components/NotifyMessage';
import { observer } from 'mobx-react';
import CourseTemplates from '@/views/app/VGSadmin/courses/components/Templates';
import { CreateOrUpdateCourseTemplateDialog } from '@/views/app/VGSadmin/study-subjects/CreateOrUpdateCourseTemplateDialog';

const VGSStudySubjects = () => {
  const {
    stateStore,
    courseTemplateStore,
    studySubjectStore,
    projectStore,
    userStore,
  } = useStores();
  const { vgsStudySubjectDrawerIsOpen, setVgsStudySubjectDrawerIsOpen } =
    stateStore;
  const { currentProject } = projectStore;
  const { chosenStudySubject } = studySubjectStore;
  const { user } = userStore;

  const [createUpdateCourseTemplate, setCreateUpdateCourseTemplate] =
    useState<CreateUpdateCourseTemplate>(new CreateUpdateCourseTemplate());
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmShown, setIsConfirmShown] = useState<boolean>(false);
  const handleClose = (): void => {
    setIsConfirmShown(false);
    setCreateUpdateCourseTemplate(new CreateUpdateCourseTemplate());
    setErrorRegisterFormValue({ ...errorRegisterFormValueInit });
    setVgsStudySubjectDrawerIsOpen(false);
  };

  useEffect(() => {
    if (!projectStore.currentProject) {
      projectStore.getCurrent();
    }
  }, [projectStore]);

  const getCourseTemplate = useCallback(async () => {
    if (
      currentProject?.id &&
      user?.externalSchoolId &&
      chosenStudySubject &&
      vgsStudySubjectDrawerIsOpen
    ) {
      setLoading(true);
      const courseTemplate = await courseTemplateStore.getCourseTemplate(
        chosenStudySubject.id,
        user.externalSchoolId
      );

      let temp = {
        ...createUpdateCourseTemplate,
        projectId: currentProject.id,
        studySubjectId: chosenStudySubject.id,
        externalSchoolId: user.externalSchoolId,
      };
      if (courseTemplate) {
        temp = CreateUpdateCourseTemplate.fromICourseTemplate(courseTemplate);
      }

      setCreateUpdateCourseTemplate({ ...temp });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, user, chosenStudySubject, vgsStudySubjectDrawerIsOpen]);

  const getAllContactPerson = useCallback(() => {
    if (user?.externalSchoolId) {
      userStore.setListContactPerson([]);
      userStore.getContactPersonsByExternalSchoolId(user?.externalSchoolId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getCourseTemplate();
  }, [getCourseTemplate]);

  useEffect(() => {
    getAllContactPerson();
  }, [getAllContactPerson]);

  const setCourseHolderId = (courseHolderId: number): void => {
    setCreateUpdateCourseTemplate({
      ...createUpdateCourseTemplate,
      courseHolderId,
    });
  };

  const setRoomNumber = (roomNumber: string): void => {
    setCreateUpdateCourseTemplate({
      ...createUpdateCourseTemplate,
      roomNumber,
    });
  };

  const setCourseInfo = (courseInfo: string): void => {
    setCreateUpdateCourseTemplate({
      ...createUpdateCourseTemplate,
      courseInfo,
    });
  };

  const [openNotifyMessage, setOpenNotifyMessage] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Color>('success');
  const [message, setMessage] = useState<string>('Success !!!');
  const handleCloseNotifyMessage = (): void => {
    setOpenNotifyMessage(false);
  };
  const [errorRegisterFormValueInit] = useState<Record<string, boolean>>({
    courseHolderId: false,
    roomNumber: false,
  });
  const [errorRegisterFormValue, setErrorRegisterFormValue] = useState<
    Record<string, boolean>
  >({
    courseHolderId: false,
    roomNumber: false,
  });

  const handleCreateOrUpdateCourseTemplate = (): void => {
    if (currentProject?.id && user?.externalSchoolId) {
      const temp = {
        ...createUpdateCourseTemplate,
        projectId: currentProject.id,
        externalSchoolId: user.externalSchoolId,
      };
      setCreateUpdateCourseTemplate({ ...temp });
      setIsConfirmShown(true);
    }
  };

  const _isErrorRegister = (): boolean => {
    const temp = { ...errorRegisterFormValueInit };
    let isError = false;

    if (createUpdateCourseTemplate.courseHolderId <= 0) {
      temp.courseHolderId = true;
      isError = true;
    }

    if (!createUpdateCourseTemplate.roomNumber) {
      temp.roomNumber = true;
      isError = true;
    }

    setErrorRegisterFormValue(temp);
    return isError;
  };

  const handleConfirm = async (): Promise<void> => {
    if (!_isErrorRegister()) {
      try {
        if (createUpdateCourseTemplate.id) {
          await courseTemplateStore.updateCourseTemplate(
            createUpdateCourseTemplate.id,
            createUpdateCourseTemplate
          );
        } else {
          await courseTemplateStore.createCourseTemplate(
            createUpdateCourseTemplate
          );
        }

        setCreateUpdateCourseTemplate(new CreateUpdateCourseTemplate());
        setMessage('Success !!!');
        setSeverity('success');
      } catch (error: any) {
        console.log(`Error create or update CourseTemplate: ${error}`);
        setMessage('Fail !!!');
        setSeverity('error');
      } finally {
        setVgsStudySubjectDrawerIsOpen(false);
        setOpenNotifyMessage(true);
        setIsConfirmShown(false);
      }
    }
  };

  return (
    <>
      <CourseTemplates schoolId={user?.externalSchoolId} />
      <VGSStudySubjectDrawer
        handleClose={handleClose}
        setCourseHolderId={setCourseHolderId}
        setRoomNumber={setRoomNumber}
        setCourseInfo={setCourseInfo}
        errorRegisterFormValue={errorRegisterFormValue}
        createUpdateCourseTemplate={createUpdateCourseTemplate}
        handleCreateOrUpdateCourseTemplate={handleCreateOrUpdateCourseTemplate}
        loading={loading}
      />
      <NotifyMessage
        open={openNotifyMessage}
        message={message}
        severity={severity}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessage}
      />
      <CreateOrUpdateCourseTemplateDialog
        open={isConfirmShown}
        onClose={() => handleClose()}
        onAgree={() => handleConfirm()}
      />
    </>
  );
};

export default observer(VGSStudySubjects);
