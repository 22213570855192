import { ConfirmDialog } from '@/components/ConfirmDialog';
import { UserRole } from '@/types/UserRole';
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DeleteForeverRounded, EditRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks';
import { useStores } from '../../../../stores';
import { IProject } from '../../../../types/Project';
import { generateStringFromToDate } from '../../../../utils/timeUtils';
import { NoCurrentProject } from './NoCurrentProject';

interface ICustomTableColumn {
  key: string;
  label: string;
}

const columns: ICustomTableColumn[] = [
  { key: 'id', label: 'Id' },
  { key: 'projectName', label: 'Navn' },
];

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    padding: '16px 115px',
    [breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  table: {
    backgroundColor: '#fff',
  },
  tableRow: {
    borderLeft: '3px solid transparent',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      borderLeft: '3px solid ' + palette.primary.light,
    },
  },
}));

const ProjectList = () => {
  const { projectStore, userStore } = useStores();
  const history = useHistory();
  const isArchived = useQuery('isArchived');

  const [isConfirmShown, setIsConfirmShown] = React.useState<boolean>(false);
  const [selectedDeleteProject, setSelectedDeletedProject] =
    React.useState<IProject | null>(null);

  const handleDeleteConfirm = async () => {
    await projectStore.deleteProject(selectedDeleteProject?.id ?? 0);
    setIsConfirmShown(false);
  };

  const { tableList: projects } = projectStore;

  const goToCourse = (id = 0) => {
    history.push(`projects/${id}/courses`);
  };

  const openEditProject = (project: IProject) => {
    projectStore.openProjectPanel(project);
  };

  React.useEffect(() => {
    isArchived
      ? projectStore.getArchivedProjects()
      : projectStore.getActiveProjectsForTable();
  }, [isArchived, projectStore]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.key}>{col.label}</TableCell>
            ))}
            <TableCell>Synlighetsperiode</TableCell>
            <TableCell>Påmeldingsperiode</TableCell>
            <TableCell>Kursperiode</TableCell>
            {userStore.user?.currentRole === UserRole.Admin && (
              <TableCell align="right">Action</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.length ? (
            <>
              {projectStore.tableList.map((project) => (
                <TableRow
                  key={`row-${project.id}`}
                  className={classes.tableRow}
                >
                  {columns.map((col) => (
                    <TableCell
                      onClick={() => goToCourse(project.id)}
                      key={`table-list-project-${project.id}-${col.key}`}
                    >
                      {project[col.key as keyof IProject]}
                    </TableCell>
                  ))}
                  <TableCell onClick={() => goToCourse(project.id)}>
                    {generateStringFromToDate({
                      from: project.availablePeriodStartDate,
                      to: project.availablePeriodEndDate,
                    })}
                  </TableCell>

                  <TableCell onClick={() => goToCourse(project.id)}>
                    {generateStringFromToDate({
                      from: project.registeringPeriodStartDate,
                      to: project.registeringPeriodEndDate,
                    })}
                  </TableCell>

                  <TableCell onClick={() => goToCourse(project.id)}>
                    {generateStringFromToDate({
                      from: project.coursePeriodStartDate,
                      to: project.coursePeriodEndDate,
                    })}
                  </TableCell>
                  {userStore.user?.currentRole === UserRole.Admin &&  (
                    <>
                      <TableCell align="right">
                        <IconButton onClick={() => openEditProject(project)}>
                          <EditRounded />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedDeletedProject(project);
                            setIsConfirmShown(true);
                          }}
                        >
                          <DeleteForeverRounded />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <NoCurrentProject isArchived={!!isArchived} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <ConfirmDialog
        open={isConfirmShown}
        onClose={() => setIsConfirmShown(false)}
        onAgree={() => handleDeleteConfirm()}
        content={'Er du sikker på at du vil slette dette prosjektet?'}
      />
    </div>
  );
};

export default observer(ProjectList);
