import { flexCol } from '@/style/common';
import { semiBold } from '@/style/fontWeights';
import { makeStyles } from '@material-ui/core';

interface Props {
  open: boolean;
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  rowWrapper: {
    ...flexCol,
  },
  tableRow: {
    transition: '0.3s ease all',
    borderLeft: '3px solid',
    borderColor: ({ open }: Props) =>
      open ? palette.primary.main : 'transparent',
    backgroundColor: ({ open }: Props) =>
      open ? palette.primary[50] : 'transparent',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.primary[50],
    },
    '& .MuiTableCell-root': {
      padding: '12px 16px',
      [breakpoints.down('sm')]: {
        padding: '4px',
      },
    },
  },
  thinChip: {
    height: '25px',
    lineHeight: '25px',
  },
  studentName: {
    fontWeight: semiBold,
  },
  boxTooltipComment: {
    padding: 0,
    width: '20rem',
    color: 'initial',
    fontSize: 'initial',
    fontWeight: 'normal',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  spaceIcon: {
    marginLeft: '5px',
    '&:last-child': {
      marginLeft: '0px',
    },
  },
}));

export default useStyles;
