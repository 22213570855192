import { Step, StepLabel, Stepper } from '@material-ui/core';
import useStyles from './style';

interface IStudentStepperProps {
  activeStep: number;
}

const StudentStepper = ({ activeStep }: IStudentStepperProps) => {
  const classes = useStyles();

  return (
    <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
      <Step active={activeStep === 1} completed={activeStep > 1}>
        <StepLabel>Registreringsperiode</StepLabel>
      </Step>
      <Step active={activeStep === 2} completed={activeStep > 2}>
        <StepLabel>Kursperiode</StepLabel>
      </Step>
    </Stepper>
  );
};

export default StudentStepper;
