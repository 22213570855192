import { IStudentCourse } from '@/types/StudentCourse';
import { Tooltip, Typography } from '@material-ui/core';
import SchoolHoverCard from './SchoolHoverCard';
import useStyles from './style';

interface IContactPersonCellProps {
  course?: IStudentCourse;
}

const ContactPersonCell = ({ course }: IContactPersonCellProps) => {
  const school = course?.finalAssignedCourse?.school;
  const contactPerson = course?.finalAssignedCourse?.courseHolder;

  const classes = useStyles();

  return (
    <Tooltip
      interactive
      PopperProps={{
        className: classes.tooltip,
      }}
      title={
        !!school && (
          <SchoolHoverCard school={school} contactPerson={contactPerson} />
        )
      }
    >
      <div>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {school && school.schoolName}
        </Typography>
        <Typography variant="body1">
          {contactPerson && contactPerson.fullName}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default ContactPersonCell;
