import { ConfirmDialog } from '@/components/ConfirmDialog';
import CustomPagination from '@/components/CustomPagination';
import { useStores } from '@/stores';
import { PER_PAGE_OPTIONS } from '@/types/dto/PaginationRequest.dto';
import { ISurvey } from '@/types/Survey';
import { UserRole } from '@/types/UserRole';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { DeleteForeverRounded, EditRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import userSurveyStyle from '../style';
import clsx from 'clsx';
import AdminHeader from '../../common/AdminHeader';

interface ICustomTableColumn {
  key: string;
  label: string;
}

const columns: ICustomTableColumn[] = [
  { key: 'id', label: 'Id' },
  { key: 'name', label: 'Navn' },
];

const UserSurveyList = () => {
  const { userSurveyStore, userStore } = useStores();
  const classes = userSurveyStyle();
  const history = useHistory();

  const [isConfirmShown, setIsConfirmShown] = React.useState<boolean>(false);
  const [selectedDeleteSurvey, setSelectedDeletedSurvey] =
    React.useState<ISurvey | null>(null);

  const handleDeleteConfirm = async (): Promise<void> => {
    if (selectedDeleteSurvey && selectedDeleteSurvey.id) {
      await userSurveyStore.deleteSurvey(selectedDeleteSurvey.id);
      setIsConfirmShown(false);
      getList();
    }
  };

  const openEditSurvey = (survey: ISurvey): void => {
    if (survey.id) {
      history.push(`/user-surveys/${survey.id}/edit`);
    }
  };

  const openNewSurvey = (): void => {
    history.push(`/user-surveys/new`);
  };

  const openDetailSurvey = (surveyId: number): void => {
    history.push(`/user-surveys/${surveyId}/detail`);
  };

  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(+PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const getList = React.useCallback(() => {
    userSurveyStore.getList({
      skip,
      take,
    });
  }, [userSurveyStore, skip, take]);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const changePageOption = (take: number): void => {
    handleChoosePage(1);
    setTake(take);
  };

  const handleChoosePage = (page: number): void => {
    setSkip((page - 1) * take);
    setCurrentPage(page);
  };

  return (
    <>
      <AdminHeader
        title={
          <Link to="/user-surveys" className={classes.buttonSurveyHome}>
            Brukerundersøkelse
          </Link>
        }
        actions={
          <div className={classes.buttonNewSurvey}>
            <Button variant="contained" color="primary" onClick={openNewSurvey}>
              Ny brukerundersøkelse
            </Button>
          </div>
        }
      />

      <div className={classes.container}>
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col.key}>{col.label}</TableCell>
                ))}

                {userStore.user?.role === UserRole.Admin && (
                  <TableCell align="right">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!userSurveyStore.loadingTableList ? (
                <>
                  {userSurveyStore.tableList.length > 0 ? (
                    <>
                      {userSurveyStore.tableList.map((survey) => (
                        <>
                          <TableRow
                            key={`row-${survey.id}`}
                            className={classes.tableRow}
                            onClick={() => {
                              if (survey.id) {
                                openDetailSurvey(survey.id);
                              }
                            }}
                          >
                            {columns.map((col) => (
                              <TableCell key={`${survey.id}-${col.key}`}>
                                {survey[col.key as keyof ISurvey]}
                              </TableCell>
                            ))}

                            {userStore.user?.role === UserRole.Admin && (
                              <>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={(
                                      event: React.MouseEvent<HTMLElement>
                                    ) => {
                                      event.stopPropagation();
                                      openEditSurvey(survey);
                                    }}
                                  >
                                    <EditRounded />
                                  </IconButton>
                                  <IconButton
                                    onClick={(
                                      event: React.MouseEvent<HTMLElement>
                                    ) => {
                                      event.stopPropagation();
                                      setSelectedDeletedSurvey(survey);
                                      setIsConfirmShown(true);
                                    }}
                                  >
                                    <DeleteForeverRounded />
                                  </IconButton>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={
                            userStore.user?.role === UserRole.Admin ? 3 : 2
                          }
                        >
                          <Typography variant="h3">
                            Ingen undersøkelse satt opp
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={userStore.user?.role === UserRole.Admin ? 3 : 2}
                    >
                      <div
                        className={clsx(
                          classes.displayFlex,
                          classes.justifyContentCenter
                        )}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>

          <div className={classes.marginTop10}>
            <CustomPagination
              currentPage={currentPage}
              totalRows={userSurveyStore.totalCount}
              perPage={take}
              handleChoosePage={handleChoosePage}
              changePageOption={changePageOption}
            />
          </div>

          <ConfirmDialog
            open={isConfirmShown}
            onClose={() => setIsConfirmShown(false)}
            onAgree={() => handleDeleteConfirm()}
            content={'Er du sikker på at du vil slette dette denne brukerundersøkelsen?'}
          />
        </div>
      </div>
    </>
  );
};

export default observer(UserSurveyList);
