import CustomPagination from '@/components/CustomPagination';
import { ProjectAssignDto } from '@/types/ProjectSurvey';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import userSurveyStyle from '../style';

interface IComponentProps {
  list: ProjectAssignDto[];
  total: number;
  loading: boolean;
  currentPage: number;
  take: number;
  changePageOption: (take: number) => void;
  handleChoosePage: (page: number) => void;
  downloadProjectSurvey: (projectId: number) => Promise<void>;
}

const ListProject = (props: IComponentProps) => {
  const {
    list,
    total,
    loading,
    currentPage,
    take,
    changePageOption,
    handleChoosePage,
    downloadProjectSurvey,
  } = props;
  const classes = userSurveyStyle();

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Prosjekt</TableCell>
            <TableCell>Antall utsendt</TableCell>
            <TableCell>Antall svar</TableCell>
            <TableCell
              className={clsx(
                classes.widthFull,
                classes.displayFlex,
                classes.justifyContentCenter
              )}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? (
            <>
              {list.length > 0 ? (
                <>
                  {list.map((p) => (
                    <>
                      <TableRow
                        key={`row-${p.projectId}`}
                        className={classes.tableRow}
                      >
                        <TableCell>{p.projectName}</TableCell>
                        <TableCell>{p.numberSent}</TableCell>
                        <TableCell>{p.numberResponse}</TableCell>
                        <TableCell>
                          <div
                            className={clsx(
                              classes.widthFull,
                              classes.displayFlex,
                              classes.justifyContentCenter,
                              classes.pointer
                            )}
                            onClick={async () => {
                              await downloadProjectSurvey(p.projectId);
                            }}
                          >
                            <GetAppIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="h3">
                        Ingen prosjekt satt opp
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <div
                  className={clsx(
                    classes.displayFlex,
                    classes.justifyContentCenter
                  )}
                >
                  <CircularProgress />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className={classes.marginTop10}>
        <CustomPagination
          currentPage={currentPage}
          totalRows={total}
          perPage={take}
          handleChoosePage={handleChoosePage}
          changePageOption={changePageOption}
        />
      </div>
    </div>
  );
};

export default observer(ListProject);
