export interface ISurveyAnswer {
  id?: number;
  surveyQuestionId?: number;
  content: string;
  isChecked: boolean;
  createdDate?: Date;
  time?: string;
}

export const INIT_SURVEY_ANSWER: ISurveyAnswer = {
  content: '',
  isChecked: false,
};
