import { useStores } from '@/stores';
import { Drawer } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import useStyle from './style';
import CourseInfo from '@/views/app/GUadmin/Students/VGSCourseInfoDrawer/CourseInfo';

const VGSCourseInfoDrawer = () => {
  const { stateStore } = useStores();
  const { vgsCourseInfoDrawerIsOpen, setVgsCourseInfoDrawerIsOpen } =
    stateStore;

  const classes = useStyle();
  const onClose = (): void => {
    setVgsCourseInfoDrawerIsOpen(false);
  };

  return (
    <Drawer open={vgsCourseInfoDrawerIsOpen} onClose={onClose} anchor="right">
      <div className={classes.content}>
        <CourseInfo />
      </div>
    </Drawer>
  );
};

export default observer(VGSCourseInfoDrawer);
