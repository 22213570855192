import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: 0,
    '& .MuiTooltip-tooltip': {
      padding: 0,
    },
  },
}));

export default useStyles;
