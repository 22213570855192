import { CircularProgress } from '@material-ui/core';
import useStyles from './style';
import clsx from 'clsx';

interface IComponentProps {
  loading?: boolean;
}

const Loading = (props: IComponentProps) => {
  const { loading } = props;
  const classes = useStyles();

  return (
    <>
      {loading && (
        <div
          style={{ minHeight: '100vh' }}
          className={clsx(
            classes.displayFlex,
            classes.alignItemsCenter,
            classes.justifyContentCenter,
            classes.widthFull,
            classes.heightFull
          )}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default Loading;
