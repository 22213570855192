import IStudySubject from './StudySubject';

export enum Priority {
  First = 1,
  Second = 2,
  Third = 3,
}

export interface IStudentStudySubjectDto {
  studentId: number;
  studySubjectId: number;
  projectId: number;
  priority: Priority;
}

export const showPriority = (priority: Priority): string => {
  return 'P' + priority;
};

export interface IStatisticSubjectCapacity {
  studySubject: IStudySubject;
  capacity: number;
  numberJoinedStudent: number;
  highSchoolName: string;
}

export interface IStatisticRegistration {
  totalStudent: number;
  registeredStudent: number;
}

export interface IStatisticAllocation {
  totalCourse: number;
  totalAssignedStudent: number;
  numberFirstPriorityStudent: number;
  numberSecondPriorityStudent: number;
  numberThirdPriorityStudent: number;
}
