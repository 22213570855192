import {
  BusinessOutlined,
  FolderOutlined,
  PersonOutlined,
  SchoolOutlined,
  SendOutlined,
  SettingsOutlined,
  StarOutlined,
  QuestionAnswerOutlined,
} from '@material-ui/icons';

export interface MenuItem {
  path: string;
  name: string | JSX.Element;
  icon?: JSX.Element;
  children?: MenuItem[];
}

const settingsMenuItem = {
  path: '/settings',
  name: 'Brukere',
  icon: <SettingsOutlined />,
};

export const adminMenuItems: MenuItem[] = [
  {
    path: '/vgs',
    name: 'Videregående skoler',
    icon: <BusinessOutlined />,
  },
  {
    path: '/gu',
    name: 'Ungdomsskoler',
    icon: <SchoolOutlined />,
  },
  {
    path: '/all-students',
    name: 'Elever',
    icon: <PersonOutlined />,
  },
  {
    path: '/study-subjects',
    name: 'Kurs',
    icon: <StarOutlined />,
  },
  settingsMenuItem,
  {
    path: '/templates',
    name: 'Meldingsmaler',
    icon: <FolderOutlined />,
  },
  {
    path: '/messages',
    name: 'Utgående meldinger',
    icon: <SendOutlined />,
  },
  {
    path: '/user-surveys',
    name: 'Brukerundersøkelse',
    icon: <QuestionAnswerOutlined />,
  },
];

export const highSchoolContactMenuItems: MenuItem[] = [
  {
    path: '/study-subjects',
    name: 'Kurs',
    icon: <StarOutlined />,
  },
  settingsMenuItem,
];

export const adviserMenuItems: MenuItem[] = [
  {
    path: '/students',
    name: 'Elever',
    icon: <PersonOutlined />,
  },
  settingsMenuItem,
];

export const studentMenuItems: MenuItem[] = [settingsMenuItem];
