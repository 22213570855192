import { IUserDto } from '@/types/User';
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';

interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
  setStudentId: (id: number) => void;
  listStudent: IUserDto[];
  label?: string;
}

const CustomAutoSearchStudent = (props: IComponentProps) => {
  const { search, setSearch, setStudentId, listStudent, label } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      onChange={(
        event: React.ChangeEvent<unknown>,
        newValue: IUserDto | string
      ) => {
        if (typeof newValue === 'object' && newValue.id) {
          setStudentId(newValue.id);
        }
      }}
      inputValue={search}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      freeSolo
      id="search_info_student"
      disableClearable
      options={listStudent as IUserDto[]}
      getOptionLabel={(student) =>
        typeof student === 'string' ? student : student.fullName
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? 'Søk etter elev navn'}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
            className: classes.heightSearch,
          }}
        />
      )}
    />
  );
};

export default CustomAutoSearchStudent;
