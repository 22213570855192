import React from 'react';
import clsx from 'clsx';
import { IStatisticSubjectCapacity } from '@/types/StudentStudySubject';
import { config, useStyles } from './style';
import { Tooltip } from '@material-ui/core';

interface IComponentProps {
  maxCapacity: number;
  data: IStatisticSubjectCapacity;
  onClickLabel?: (studySubjectId: number) => void;
}

const ItemBarChart = (props: IComponentProps) => {
  const { maxCapacity, data, onClickLabel } = props;
  const classes = useStyles();

  const [heightCapacity, setHeightCapacity] = React.useState<number>(0);
  const [heightJoin, setHeightJoin] = React.useState<number>(0);
  const [overload, setOverload] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const calculateHeight = (value: number) => {
      const maxHeight = config.height - config.heightLabel - config.paddingTop;
      if (value >= maxCapacity) {
        return maxHeight;
      }

      return Math.ceil((value / maxCapacity) * maxHeight);
    };

    if (data.numberJoinedStudent > data.capacity) {
      setOverload(true);
    }

    setHeightCapacity(calculateHeight(data.capacity));
    setHeightJoin(calculateHeight(data.numberJoinedStudent));
  }, [maxCapacity, data.capacity, data.numberJoinedStudent]);

  const afterOnClickLabel = (
    event: React.MouseEvent<HTMLElement>,
    studySubjectId: number
  ) => {
    event?.stopPropagation();

    if (onClickLabel) {
      onClickLabel(studySubjectId);
    }
  };

  return (
    <>
      <div className={classes.itemBarChart} onClick={() => setOpen(!open)}>
        <div className={classes.itemBarChartSize}>
          <div className={clsx(classes.itemBarChartContent, classes.widthFull)}>
            <div
              className={clsx(
                classes.itemBarChartContentColumns,
                classes.heightFull,
                classes.displayFlex
              )}
            >
              <div
                className={clsx(
                  classes.heightFull,
                  classes.displayFlex,
                  classes.alignItemsFlexEnd,
                  classes.itemBarChartContentWidthColumn
                )}
              >
                <div
                  className={clsx(classes.widthFull, classes.positionRelative, {
                    [classes.joinColumn]: !overload,
                    [classes.joinOverloadColumn]: overload,
                  })}
                  style={{ height: heightJoin }}
                >
                  <div
                    className={clsx(classes.textColumn, {
                      [classes.textColorOverload]: overload,
                    })}
                  >
                    {data.numberJoinedStudent}
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  classes.heightFull,
                  classes.displayFlex,
                  classes.alignItemsFlexEnd,
                  classes.itemBarChartContentWidthColumn
                )}
              >
                <div
                  className={clsx(
                    classes.capacityColumn,
                    classes.positionRelative,
                    classes.widthFull
                  )}
                  style={{ height: heightCapacity }}
                >
                  <div className={classes.textColumn}>{data.capacity}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(classes.itemBarChartLabel, classes.widthFull)}>
            <div className={clsx(classes.labelColumn, classes.heightFull)}>
              <Tooltip
                interactive
                classes={{
                  tooltip: classes.labelTooltip,
                }}
                title={data.studySubject.studySubjectName}
                placement="top"
              >
                <span
                  className={clsx({ [classes.pointer]: onClickLabel })}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    afterOnClickLabel(event, data.studySubject.id)
                  }
                >
                  {data.studySubject.studySubjectName}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className={clsx(classes.itemBarChartSize, classes.displayNone, {
            [classes.positionAbsolute]: open,
            [classes.displayBlock]: open,
          })}
        >
          <div
            className={clsx(
              classes.widthFull,
              classes.heightFull,
              classes.highSchoolNameBox,
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.justifyContentCenter
            )}
          >
            <div className={classes.highSchoolName}>{data.highSchoolName}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemBarChart;
