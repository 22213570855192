import { makeStyles } from '@material-ui/core';

const useStudentInformationStyle = makeStyles(() => ({
  root: {
    padding: '16px',
    maxWidth: '600px',
    margin: 'auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  loadingButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStudentInformationStyle;
