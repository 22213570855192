import { ControlledInputText } from '@/components/ControlledInputText';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import userSurveyStyle from '../style';
import clsx from 'clsx';
import CustomSurveyEditor from '@/components/CustomSurveyEditor';
import { INIT_SURVEY, ISurvey } from '@/types/Survey';
import { ISurveyQuestion, INIT_SURVEY_QUESTION } from '@/types/SurveyQuestion';
import { CircularProgress, Button } from '@material-ui/core';
import QuestionItem from './question';
import { SurveyQuestionType } from '@/types/enums/SurveyQuestionType';
import AddSurveyQuestionDialog from './AddSurveyQuestionDialog';
import { ISurveyAnswer, INIT_SURVEY_ANSWER } from '@/types/SurveyAnswer';
import AdminHeader from '../../common/AdminHeader';
import { QuestionRight, SurveyOption } from '@/types/enums/SurveyOption';

interface IComponentProps {
  option: SurveyOption;
}
interface UrlParams {
  surveyId: string;
}

interface QuestionHistory {
  questionId: number;
  mapOfTypeToAnswers: Map<SurveyQuestionType, ISurveyAnswer[]>;
}

const SurveyQuestion = (props: IComponentProps) => {
  const { option } = props;
  const { surveyId } = useParams<UrlParams>();
  const { userSurveyStore } = useStores();
  const history = useHistory();

  const classes = userSurveyStyle();

  const [mapOfFakeQuestionId, setMapOfFakeQuestionId] = React.useState<
    Map<number, number>
  >(new Map<number, number>());
  const [mapOfFakeAnswerId, setMapOfFakeAnswerId] = React.useState<
    Map<number, number>
  >(new Map<number, number>());

  const [survey, setSurvey] = React.useState<ISurvey>(INIT_SURVEY);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchData = async (surveyId: number): Promise<void> => {
    setLoading(true);
    const surveyFromDB = await userSurveyStore.getSurveyById(surveyId);
    setSurvey(surveyFromDB);
    setLoading(false);
  };

  React.useEffect(() => {
    if (option === SurveyOption.EDIT) {
      fetchData(+surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const setSurveyTitle = (title: string): void => {
    setSurvey({ ...survey, title });
  };

  const setSurveyName = (name: string): void => {
    setSurvey({ ...survey, name });
  };

  const setSurveyDescription = (content: string): void => {
    setSurvey({ ...survey, description: content });
  };

  const setQuestionTitle = (questionId: number, title: string): void => {
    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      question.title = title;
      setSurvey({ ...survey });
    }
  };

  const setQuestionIsRequired = (
    questionId: number,
    isRequired: boolean
  ): void => {
    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      question.isRequired = isRequired;
      setSurvey({ ...survey });
    }
  };

  const setQuestionSubTitle = (questionId: number, subTitle: string): void => {
    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      question.subTitle = subTitle;
      setSurvey({ ...survey });
    }
  };

  const [questionHistories, setQuestionHistories] = React.useState<
    QuestionHistory[]
  >([]);
  const setQuestionType = (
    questionId: number,
    type: SurveyQuestionType
  ): void => {
    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      const history = questionHistories.find(
        (h) => h.questionId === questionId
      );

      if (history) {
        const oldMap = history.mapOfTypeToAnswers;
        const oldAnswers = oldMap.get(type);
        if (oldAnswers) {
          oldMap.set(question.type, question.answers);
          question.answers = oldAnswers;
        } else {
          _getAnswers(type, question);
        }
      } else {
        const newMap = new Map<SurveyQuestionType, ISurveyAnswer[]>();
        newMap.set(question.type, question.answers);

        const newHistory: QuestionHistory = {
          questionId,
          mapOfTypeToAnswers: newMap,
        };
        questionHistories.push(newHistory);
        _getAnswers(type, question);
      }

      question.type = type;
      setSurvey({ ...survey });
      setQuestionHistories([...questionHistories]);
    }
  };

  const _getAnswers = (
    type: SurveyQuestionType,
    question: ISurveyQuestion
  ): void => {
    if (
      type === SurveyQuestionType.INPUT ||
      type === SurveyQuestionType.TEXT_AREA
    ) {
      const answers = _getAllAnswers();
      const maxAnswerId = _findMax(answers.map((a) => a.id ?? 0));
      question.answers = [
        {
          ...INIT_SURVEY_ANSWER,
          id: maxAnswerId + 1,
        },
      ];
      mapOfFakeAnswerId.set(maxAnswerId + 1, maxAnswerId + 1);
      setMapOfFakeAnswerId(mapOfFakeAnswerId);
    }

    if (
      type === SurveyQuestionType.RADIO ||
      type === SurveyQuestionType.CHECKBOX
    ) {
      question.answers = [];
    }
  };

  /** Add survey question */
  const [openSurveyQuestionDialog, setOpenSurveyQuestionDialog] =
    React.useState<boolean>(false);
  const handleOpenSurveyQuestionDialog = (): void => {
    setOpenSurveyQuestionDialog(true);
  };
  const handleCloseSurveyQuestionDialog = (): void => {
    setOpenSurveyQuestionDialog(false);
  };
  const [question, setQuestion] = React.useState<ISurveyQuestion>({
    ...INIT_SURVEY_QUESTION,
  });

  const handleAddQuestion = async (): Promise<void> => {
    if (
      question.type === SurveyQuestionType.INPUT ||
      question.type === SurveyQuestionType.TEXT_AREA
    ) {
      const answers = _getAllAnswers();
      const maxAnswerId = _findMax(answers.map((a) => a.id ?? 0));
      question.answers = [
        {
          ...INIT_SURVEY_ANSWER,
          id: maxAnswerId + 1,
        },
      ];
      mapOfFakeAnswerId.set(maxAnswerId + 1, maxAnswerId + 1);
      setMapOfFakeAnswerId(mapOfFakeAnswerId);
    }

    const maxQuestionId = _findMax(survey.questions.map((q) => q.id ?? 0));
    const questionNew = { ...question, id: maxQuestionId + 1 };
    survey.questions = [...survey.questions, questionNew];

    mapOfFakeQuestionId.set(maxQuestionId + 1, maxQuestionId + 1);
    setMapOfFakeQuestionId(mapOfFakeQuestionId);

    setSurvey({ ...survey });
    setQuestion({ ...INIT_SURVEY_QUESTION });
    handleCloseSurveyQuestionDialog();
  };

  const handleAddAnswer = async (questionId: number): Promise<void> => {
    const answers = _getAllAnswers();
    const maxAnswerId = _findMax(answers.map((a) => a.id ?? 0));
    const answer: ISurveyAnswer = {
      ...INIT_SURVEY_ANSWER,
      id: maxAnswerId + 1,
    };

    mapOfFakeAnswerId.set(maxAnswerId + 1, maxAnswerId + 1);
    setMapOfFakeAnswerId(mapOfFakeAnswerId);

    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      question.answers = [...question.answers, answer];
      setSurvey({ ...survey });
    }
  };

  const handleChangeAnswer = (
    content: string,
    questionId: number,
    answerId: number,
    isChecked?: boolean
  ): void => {
    const question = survey.questions.find((q) => q.id === questionId);

    if (question) {
      const answers = question.answers;

      switch (question.type) {
        case SurveyQuestionType.INPUT:
          if (answers[0]) {
            answers[0].content = content;
          }
          break;
        case SurveyQuestionType.TEXT_AREA:
          if (answers[0]) {
            answers[0].content = content;
          }
          break;
        case SurveyQuestionType.RADIO:
          const answerRadioChecked = answers.find((a) => a.isChecked);
          if (answerRadioChecked && !!isChecked) {
            answerRadioChecked.isChecked = false;
          }

          const answerRadio = answers.find((a) => a.id === answerId);
          if (answerRadio) {
            answerRadio.isChecked = !!isChecked;
            answerRadio.content = content;
          }
          break;
        case SurveyQuestionType.CHECKBOX:
          const answerCheckbox = answers.find((a) => a.id === answerId);
          if (answerCheckbox) {
            answerCheckbox.isChecked = !!isChecked;
            answerCheckbox.content = content;
          }
          break;
        default:
          break;
      }

      setSurvey({ ...survey });
    }
  };
  /** Add survey question */

  const handleUpdateOrCreateSurvey = async (): Promise<void> => {
    _resetSurvey();

    if (survey.id) {
      await userSurveyStore.update(survey);
      setQuestionHistories([]);
      setMapOfFakeQuestionId(new Map<number, number>());
      setMapOfFakeAnswerId(new Map<number, number>());
      await fetchData(+surveyId);
    } else {
      await userSurveyStore.create(survey);
      history.push(`/user-surveys`);
    }
  };

  const _resetSurvey = (): void => {
    survey.questions.map((q) => {
      if (mapOfFakeQuestionId.get(q.id ?? 0)) {
        q.id = 0;
      }

      q.answers.map((a) => {
        if (mapOfFakeAnswerId.get(a.id ?? 0)) {
          a.id = 0;
        }

        return a;
      });

      return q;
    });

    setSurvey({ ...survey });
  };

  const handleDeleteQuestion = async (questionId: number): Promise<void> => {
    const question = survey.questions.find((q) => q.id === questionId);

    if (question) {
      if (!mapOfFakeQuestionId.get(question.id ?? 0)) {
        await userSurveyStore.deleteQuestion(questionId);
      }

      const indexOldQuestion = survey.questions
        .map((q) => q.id)
        .indexOf(questionId);
      if (indexOldQuestion > -1) {
        survey.questions.splice(indexOldQuestion, 1);
      }
      const indexOldQuestionHistory = questionHistories
        .map((q) => q.questionId)
        .indexOf(questionId);
      if (indexOldQuestionHistory > -1) {
        questionHistories.splice(indexOldQuestionHistory, 1);
      }

      if (mapOfFakeQuestionId.get(question.id ?? 0)) {
        const answerIds = question.answers.map((a) => a.id ?? 0);
        answerIds.map((answerId) => {
          mapOfFakeAnswerId.delete(answerId);
          return answerId;
        });
        mapOfFakeQuestionId.delete(questionId);
      }

      setSurvey({ ...survey });
      setQuestionHistories([...questionHistories]);
    }
  };

  const handleDeleteAnswer = async (
    questionId: number,
    answerId: number
  ): Promise<void> => {
    if (!mapOfFakeAnswerId.get(answerId)) {
      await userSurveyStore.deleteAnswer(answerId);
    }

    const question = survey.questions.find((q) => q.id === questionId);
    if (question) {
      const indexOldAnswer = question.answers
        .map((a) => a.id ?? 0)
        .indexOf(answerId);

      if (indexOldAnswer > -1) {
        question.answers.splice(indexOldAnswer, 1);
      }

      const questionHistory = questionHistories.find(
        (h) => h.questionId === questionId
      );
      if (questionHistory) {
        const answers = questionHistory.mapOfTypeToAnswers.get(question.type);
        if (answers) {
          const indexOldHistoryAnswer = answers
            .map((a) => a.id ?? 0)
            .indexOf(answerId);
          if (indexOldHistoryAnswer > -1) {
            answers.splice(indexOldHistoryAnswer, 1);
          }
        }
      }

      if (mapOfFakeAnswerId.get(answerId)) {
        mapOfFakeAnswerId.delete(answerId);
      }

      setSurvey({ ...survey });
      setQuestionHistories([...questionHistories]);
    }
  };

  const _findMax = (values: number[]): number => {
    if (!values.length) {
      return 0;
    }

    return values.reduce((a: number, b: number) => Math.max(a, b));
  };

  const _getAllAnswers = (): ISurveyAnswer[] => {
    let result: ISurveyAnswer[] = [];
    const all: ISurveyAnswer[][] = survey.questions.map((q) => q.answers);
    for (let i = 0; i < all.length; i++) {
      result = result.concat(all[i]);
    }

    return result;
  };

  const goListSurvey = (): void => {
    history.push(`/user-surveys`);
  };

  return (
    <>
      <AdminHeader
        title={
          <Link to="/user-surveys" className={classes.buttonSurveyHome}>
            Brukerundersøkelse
          </Link>
        }
        actions={
          <>
            <div className={classes.buttonNewSurvey}>
              <Button
                variant="contained"
                color="primary"
                onClick={goListSurvey}
              >
                Se arkiverte
              </Button>
            </div>
          </>
        }
      />

      <div className={classes.container}>
        {!loading ? (
          <>
            <div
              className={clsx(
                classes.backgroundColorFFF,
                classes.containerQuestion
              )}
            >
              <div className={clsx(classes.displayFlex)}>
                <div
                  className={clsx(classes.questionTitle, classes.marginTop10)}
                >
                  Tittel
                </div>
                <div className={classes.questionContent}>
                  <ControlledInputText
                    value={survey.title}
                    setValue={setSurveyTitle}
                    customClass={clsx(classes.widthFull)}
                    inputProps={{
                      disableUnderline: true,
                      classes: { input: classes.surveyInput },
                    }}
                  />
                </div>
              </div>
              {option === SurveyOption.CREATE && (
                <div className={clsx(classes.displayFlex, classes.marginTop10)}>
                  <div
                    className={clsx(classes.questionTitle, classes.marginTop10)}
                  >
                    Navn
                  </div>
                  <div className={classes.questionContent}>
                    <ControlledInputText
                      value={survey.name}
                      setValue={setSurveyName}
                      customClass={clsx(classes.widthFull)}
                      inputProps={{
                        disableUnderline: true,
                        classes: { input: classes.surveyInput },
                      }}
                    />
                  </div>
                </div>
              )}
              <div className={clsx(classes.displayFlex, classes.marginTop10)}>
                <div
                  className={clsx(classes.questionTitle, classes.marginTop10)}
                >
                  Beskrivelse
                </div>
                <div className={classes.questionContent}>
                  <CustomSurveyEditor
                    content={survey.description}
                    setContent={setSurveyDescription}
                  />
                </div>
              </div>

              <div className={classes.marginTop20}>
                {survey.questions.map((question: ISurveyQuestion) => (
                  <div
                    key={question.id ?? 0}
                    className={clsx(classes.marginTop20)}
                  >
                    <QuestionItem
                      key={question.id ?? 0}
                      question={question}
                      setQuestionTitle={setQuestionTitle}
                      setQuestionIsRequired={setQuestionIsRequired}
                      setQuestionSubTitle={setQuestionSubTitle}
                      setQuestionType={setQuestionType}
                      handleAddAnswer={handleAddAnswer}
                      handleChangeAnswer={handleChangeAnswer}
                      handleDeleteQuestion={handleDeleteQuestion}
                      handleDeleteAnswer={handleDeleteAnswer}
                      right={QuestionRight.ONLY_CREATE_EDIT}
                    />
                  </div>
                ))}

                <div className={classes.addSurveyQuestion}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenSurveyQuestionDialog}
                  >
                    Legg til spørsmål
                  </Button>
                </div>
              </div>

              <div className={clsx(classes.displayFlex, classes.marginTop20)}>
                <div className={classes.marginLeftAuto}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await handleUpdateOrCreateSurvey();
                    }}
                  >
                    Lagre
                  </Button>
                  <Button
                    variant="text"
                    onClick={goListSurvey}
                    style={{ marginLeft: '12px' }}
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.justifyContentCenter,
              classes.minHeight
            )}
          >
            <CircularProgress />
          </div>
        )}

        <AddSurveyQuestionDialog
          question={question}
          open={openSurveyQuestionDialog}
          handleClose={handleCloseSurveyQuestionDialog}
          setQuestion={setQuestion}
          handleAddQuestion={handleAddQuestion}
        />
      </div>
    </>
  );
};

export default observer(SurveyQuestion);
