import { useStores } from '@/stores';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React from 'react';

const ErrorMessage = () => {
  const { errorStore } = useStores();
  const { open, message, severity, duration, anchorOrigin, handleClose } =
    errorStore;

  return message ? (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  ) : (
    <></>
  );
};

export default observer(ErrorMessage);
