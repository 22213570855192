import { useStores } from '@/stores';
import {
  getStudySubjectCategoryName,
  ISchoolStudySubjectInfo,
  StudySubjectCategory,
} from '@/types/StudySubject';
import {
  getCapacityChart,
  getProjectProgress,
  getStudentChart,
  getStudentCourseChart,
} from '@/utils/adminDashboard.utils';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider, Grid,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ArrowForward, MailOutline, PhoneAndroid } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import Chart from 'react-apexcharts';
import AdminSearchBar from '../common/AdminSearchBar';
import useStyle from './AdminDashboardStyle';

const AdminDashboard = () => {
  const [, setSearchValue] = React.useState('');
  const classes = useStyle();
  const { adminDashboardStore } = useStores();

  const [steps] = React.useState([
    {
      title: 'Planlegging',
      completed: true,
    },
    {
      title: 'Tildeling',
      completed: false,
    },
    {
      title: 'Kursperiode',
      completed: false,
    },
    {
      title: 'Evaluering',
      completed: false,
    },
  ]);

  const [step, setStep] = React.useState<number>(0);
  const [studentChart, setStudentChart] = React.useState<any>(null);
  const [studentCourseChart, setStudentCourseChart] = React.useState<any>(null);
  const [capacityChart, setCapacityChart] = React.useState<any>(null);
  const [studySubjectSelected, setStudySubjectSelected] = React.useState<
    string | null
  >(null);

  const adminGetCurrentProject = React.useCallback(() => {
    adminDashboardStore.adminGetCurrentProject();
  }, [adminDashboardStore]);

  const adminGetStatisticProject = React.useCallback(() => {
    adminDashboardStore.adminGetStudentStatistic();
    adminDashboardStore.adminGetStudentCourseStatistic();
    adminDashboardStore.adminGetStudySubjectStatistic();
  }, [adminDashboardStore]);

  React.useEffect(() => {
    adminGetCurrentProject();
    if (adminDashboardStore.project.id) {
      adminGetStatisticProject();
    }
  }, [
    adminGetCurrentProject,
    adminGetStatisticProject,
    adminDashboardStore.project.id,
  ]);

  React.useEffect(() => {
    const currentProjectStep = getProjectProgress(adminDashboardStore.project);
    setStep(currentProjectStep);

    const chart = getStudentChart(
      adminDashboardStore.totalStudent,
      adminDashboardStore.registeredStudent
    );

    const studentCourseChart = getStudentCourseChart(
      adminDashboardStore.totalStudent,
      adminDashboardStore.totalAssignedStudent,
      adminDashboardStore.numberFirstPriorityStudent,
      adminDashboardStore.numberSecondPriorityStudent,
      adminDashboardStore.numberThirdPriorityStudent
    );

    const capacityChartByStudySubjectTypes: any[] = [[], [], []];
    // eslint-disable-next-line array-callback-return
    adminDashboardStore.capacityStore.map((x) => {
      if (x.studySubject.category === StudySubjectCategory.Unknown) {
        capacityChartByStudySubjectTypes[0].push(x);
      }
      if (x.studySubject.category === StudySubjectCategory.Studieforberedende) {
        capacityChartByStudySubjectTypes[1].push(x);
      }
      if (x.studySubject.category === StudySubjectCategory.Yrkesfaglig) {
        capacityChartByStudySubjectTypes[2].push(x);
      }
    });

    const capacityChart = capacityChartByStudySubjectTypes.map((x) => {
      if (x.length > 0) {
        return getCapacityChart(x);
      }
      return null;
    });
    // eslint-disable-next-line array-callback-return
    capacityChart.map((x, i) => {
      if (x) {
        x.options.chart.events.dataPointSelection = (
          event: any,
          chartContext: any,
          config: any
        ) => {
          adminDashboardStore.adminGetSubjectSchool(
            capacityChartByStudySubjectTypes[i].filter(
              (c: any) => c.capacity > 0
            )[config.dataPointIndex].studySubject.id
          );
          setStudySubjectSelected(
            capacityChartByStudySubjectTypes[i].filter(
              (c: any) => c.capacity > 0
            )[config.dataPointIndex].studySubject.studySubjectName
          );
        };
      }
    });

    setStudentChart(chart);
    setStudentCourseChart(studentCourseChart);
    setCapacityChart(capacityChart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminDashboardStore.project,
    adminDashboardStore.registeredStudent,
    adminDashboardStore.totalStudent,
    adminDashboardStore.totalAssignedStudent,
    adminDashboardStore.totalCourse,
    adminDashboardStore.numberFirstPriorityStudent,
    adminDashboardStore.numberSecondPriorityStudent,
    adminDashboardStore.numberThirdPriorityStudent,
    adminDashboardStore.capacityStore,
    adminDashboardStore.schoolStore,
  ]);

  return (
    <>
      <AdminSearchBar
        handleSearchValueChange={setSearchValue}
        label="Søk"
      ></AdminSearchBar>
      <main className={classes.main}>
        <Typography className={classes.header}>Aktuelt prosjekt</Typography>
        <Card className={classes.projectInfo}>
          <CardHeader
            className={classes.projectHeader}
            // action={
            //   <div className={classes.iconGroup}>
            //     <PublishOutlined />
            //     <MoreHoriz />
            //   </div>
            // }
            title={
              <>
                <Typography className={classes.projectHeader}>
                  {adminDashboardStore.project.projectName} <ArrowForward />
                </Typography>
              </>
            }
          />
          <Divider />

          <List className={classes.projectList}>
            <ListItem>
              <ListItemText primary="Status" />
              <div className={classes.stepContainer}>
                <Stepper activeStep={step} className={classes.stepper}>
                  {steps.map((item, index) => (
                    <Step
                      key={item.title}
                      completed={index < step ? true : false}
                    >
                      <StepLabel>{item.title}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Påmelding" />
              <div className={classes.contentTextContainer}>
                <div className={classes.contentText}>
                  <div className={classes.twoLines}>
                    <Typography>{adminDashboardStore.totalStudent}</Typography>
                    Totalt antall elever
                  </div>
                  <div className={classes.twoLines}>
                    <Typography>
                      {adminDashboardStore.registeredStudent}
                    </Typography>
                    Antall påmeldte elever
                  </div>
                  <div className={classes.twoLines}>
                    <Typography>
                      {adminDashboardStore.totalStudent -
                        adminDashboardStore.registeredStudent}
                    </Typography>
                    Antall elever uten påmelding
                  </div>
                </div>
              </div>
              <div className={classes.donutChart}>
                {studentChart && (
                  <Chart
                    options={studentChart.options}
                    series={studentChart.series}
                    type="donut"
                    width="200"
                  />
                )}
              </div>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Tildeling" />
              <div className={classes.contentTextContainer}>
                <div className={classes.contentText}>
                  <div className={classes.twoLines}>
                    <Typography>
                      {adminDashboardStore.totalAssignedStudent}
                    </Typography>
                    Antall tildelte elever
                  </div>
                  <div className={classes.twoLines}>
                    <Typography>{adminDashboardStore.totalCourse}</Typography>
                    Antall arrangementer
                  </div>
                  <div className={classes.twoLines}>
                    <Typography>
                      {adminDashboardStore.numberFirstPriorityStudent}
                    </Typography>
                    Tildelt med 1. prioritert
                  </div>
                </div>
              </div>
              <div className={classes.donutChart}>
                {studentCourseChart && (
                  <Chart
                    options={studentCourseChart.options}
                    series={studentCourseChart.series}
                    type="pie"
                    width="340"
                  />
                )}
              </div>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Kapasitet vs. påmeldt" />
            </ListItem>
            <div className={classes.barCharts}>
              {capacityChart &&
                capacityChart.length > 0 &&
                capacityChart.map((x: any, i: number) => (
                  <>
                    {x && (
                      <>
                        <Typography>
                          {getStudySubjectCategoryName(i)}
                        </Typography>
                        <Chart
                          options={x.options}
                          series={x.series}
                          type="bar"
                          width="100%"
                          height="500"
                        />
                      </>
                    )}
                  </>
                ))}
            </div>
          </List>
        </Card>

        {adminDashboardStore.schoolStore && studySubjectSelected && (
          <>
            <div className={classes.dividerSchool} />
            <div className={classes.schoolTitle}>
              {`${studySubjectSelected} vgs skoler`}
            </div>
            <Grid container spacing={3}>

              {adminDashboardStore.schoolStore.map(
                (school: ISchoolStudySubjectInfo) => (
                  <Card className={classes.schoolInfo}>
                    <CardContent>
                      <Typography className={classes.schoolName} gutterBottom>
                        {school.schoolContact.schoolName}
                      </Typography>
                      <Tooltip title={school.address} placement="top-start">
                        <Typography className={classes.schoolAddress}>
                          <u>Adress</u>
                        </Typography>
                      </Tooltip>
                      <Typography className={classes.contactPerson}>
                        {school.contactPeople.contactName ?? 'No Contact'}
                        <div className={classes.contactPersonIcons}>
                          <Tooltip
                            title={school.contactPeople.emailAddress}
                            placement="top"
                          >
                            <MailOutline />
                          </Tooltip>
                          <Tooltip
                            title={school.contactPeople.phoneNumber}
                            placement="top"
                          >
                            <PhoneAndroid />
                          </Tooltip>
                        </div>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button className={classes.schoolButton}>
                        {studySubjectSelected}
                      </Button>
                    </CardActions>
                  </Card>
                )
              )}
            </Grid>
          </>
        )}
      </main>
    </>
  );
};

export default observer(AdminDashboard);
