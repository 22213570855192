import { useStores } from '@/stores';
import { UserRole } from '@/types/UserRole';
import { observer } from 'mobx-react';
import React from 'react';
import AdminCourses from '@/views/app/admin/courses';
import VGSAdminCourses from '@/views/app/VGSadmin/courses';

const CourseDashboard = () => {
  const { userStore } = useStores();
  const { user } = userStore;

  //if (user?.isCurrentlyRole(UserRole.Student)) return <StudentDashboard />;
  // if (user?.isCurrentlyRole(UserRole.Adviser)) return <GUDashboard />
  if (user?.isCurrentlyRole(UserRole.HighSchoolContact)) {
    return <VGSAdminCourses />;
  }

  if (user?.isCurrentlyRole(UserRole.Admin)) {
    return <AdminCourses />;
  }

  return <></>;
};

export default observer(CourseDashboard);
