import NotifyMessage from '@/components/NotifyMessage';
import { useStores } from '@/stores';
import ICourse from '@/types/Course';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';
import StudentRegistrationStatus from '@/types/enums/StudentRegistrationStatus';
import { coursePeriodIsActive } from '@/types/Project';
import { StudentStudySubject } from '@/types/StudySubject';
import { User } from '@/types/User';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AdminHeader from '../../admin/common/AdminHeader';
import AdminSearchBar from '../../admin/common/AdminSearchBar';
import VGSCourseInfoDrawer from '../../GUadmin/Students/VGSCourseInfoDrawer';
import StudentTableFilters from './common/StudentTableFilters';
import StudentCourseTable from './StudentCourseTable';
import ManualAssignAllocationForStudentWithoutRegisterDialog from './StudentCourseTable/StudentCourseRow/ManualAssignAllocationForStudentWithoutRegisterDialog';
import StudentRegistrationTable from './StudentRegistrationTable';
import StudentStepper from './StudentStepper';
import useStyles from './style';
//import { User } from '@/types/User';

interface IStudentsProps {
  all?: boolean;
}

const Students: FC<IStudentsProps> = ({ all = false }) => {
  const {
    projectStore,
    schoolStore,
    userStore,
    errorStore,
    studySubjectStore,
    studentCourseStore,
    courseStore,
  } = useStores();
  const {
    connectedUsers,
    getStudents,
    isSetHasSpecialNeeds,
    setIsSetHasSpecialNeeds,
  } = schoolStore;
  const { user } = userStore;
  const { breakpoints } = useTheme();
  const isSmallerThanMedium = useMediaQuery(breakpoints.up('md'));

  const [studentNameFilter, setStudentNameFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [classId, setClassId] = useState('');
  const [schoolId, setSchoolId] = useState(
    schoolStore.externalIdSecondarySchool
  );
  const [registrationStatus, setRegistrationStatus] = useState(
    StudentRegistrationStatus.UNKNOWN
  );
  const [filterCourseId, setFilterCourseId] = useState('');
  const [filterPriority, setFilterPriority] = useState('');

  useEffect(() => {
    if (!projectStore.currentProject) {
      projectStore.getCurrent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [filteredStudents, setFilteredStudents] =
  //   useState<User[]>(connectedUsers);
  const timeoutRef = useRef<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 300)
  );

  const searchStudent = useCallback(() => {
    // const result = connectedUsers.filter((u) => {
    //   if (!studentNameFilter) {
    //     return true;
    //   }
    //   return u.fullName.toLowerCase().includes(studentNameFilter.toLowerCase());
    // });

    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );

      //setFilteredStudents(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentNameFilter]);

  const filteredStudentsByFullName = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      searchStudent();
    }, 1000);
  }, [searchStudent]);

  useEffect(() => {
    filteredStudentsByFullName();
  }, [filteredStudentsByFullName]);

  useEffect(() => {
    if (isSetHasSpecialNeeds) {
      searchStudent();
      setIsSetHasSpecialNeeds(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetHasSpecialNeeds]);

  const handlePageChange = (e: any, newPage: number) => {
    setPage(newPage);
    if (projectStore.currentProject) {
      fetchStudents(
        newPage,
        rowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  const handleRowsPerPageChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const newRowsPerPage = +e.target.value;
    setRowsPerPage(newRowsPerPage);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        newRowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  const fetchStudents = async (
    p: number,
    rows: number,
    classId: string,
    status: StudentRegistrationStatus,
    schoolId: string,
    courseId: string,
    priority: string,
    projectId?: number
  ) => {
    if (!all && !user?.externalSchoolId) {
      errorStore.showErrorMessage(
        'Du er ikke tilkoblet en skole og kan ikke se elever. Kontakt administrator hvis dette er feil.'
      );
      return;
    }

    setLoading(true);
    schoolStore.connectedUsers = [];
    await getStudents({
      externalSchoolId: all ? '' : user?.externalSchoolId || 'error',
      projectId: projectId ?? 0,
      search: studentNameFilter,
      take: rows,
      skip: p * rows,
      classId,
      schoolId,
      registrationStatus: status,
      courseId,
      priority,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
    schoolStore.getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStore.currentProject]);

  const handleClassIdChange = (newClassId: string) => {
    setClassId(newClassId);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        newClassId,
        registrationStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  const handleSchoolIdChange = (newSchoolId: string) => {
    setSchoolId(newSchoolId);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        registrationStatus,
        newSchoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  const handleCourseIdChange = (newCourseId: string): void => {
    setFilterCourseId(newCourseId);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        newCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  const handlePriorityChange = (newPriority: string): void => {
    setFilterPriority(newPriority);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        registrationStatus,
        schoolId,
        filterCourseId,
        newPriority,
        projectStore.currentProject.id
      );
    }
  };

  const handleRegistrationStatusChange = (
    newStatus: StudentRegistrationStatus
  ) => {
    setRegistrationStatus(newStatus);
    if (projectStore.currentProject) {
      fetchStudents(
        page,
        rowsPerPage,
        classId,
        newStatus,
        schoolId,
        filterCourseId,
        filterPriority,
        projectStore.currentProject.id
      );
    }
  };

  useEffect(() => {
    if (
      projectStore.currentProject &&
      !coursePeriodIsActive(projectStore.currentProject)
    ) {
      studySubjectStore.getAllStudySubjects();
    }
  }, [studySubjectStore, projectStore, projectStore.currentProject]);

  const classes = useStyles();

  const [openNotifyMessage, setOpenNotifyMessage] = useState<boolean>(false);
  const handleCloseNotifyMessage = (): void => {
    setOpenNotifyMessage(false);
  };

  const addOrUpdate = async (models: StudentStudySubject[]): Promise<void> => {
    const result = await studySubjectStore.addOrUpdate(models);
    if (result) {
      setOpenNotifyMessage(true);
      if (projectStore.currentProject) {
        fetchStudents(
          page,
          rowsPerPage,
          classId,
          registrationStatus,
          schoolId,
          filterCourseId,
          filterPriority,
          projectStore.currentProject.id
        );
      }
    }
  };

  /** ManualAssign */
  const getStudySubject = useCallback(() => {
    if (projectStore.currentProject && projectStore.currentProject.id) {
      studySubjectStore.getStudySubjectByProjectId(
        +projectStore.currentProject.id
      );
    }
  }, [studySubjectStore, projectStore.currentProject]);

  useEffect(() => {
    getStudySubject();
  }, [getStudySubject]);

  const [student, setStudent] = useState<User | undefined>(undefined);
  const [openManualAssign, setOpenManualAssign] = useState(false);
  const handleOpenManualAssignDialog = (student: User): void => {
    setStudent(student);
    setOpenManualAssign(true);
  };
  const handleCloseDialog = (): void => {
    setStudent(undefined);
    setOpenManualAssign(false);
  };
  const handleChooseCourseId = (newCourseId: number | string): void => {
    setCourseId(newCourseId);
  };
  const handleManualAssign = async (): Promise<void> => {
    if (student && courseId !== SELECT_ALL) {
      await studentCourseStore.manualAssignStudentToCourse(
        student.id,
        +courseId
      );
      setStudySubjectId(SELECT_ALL);
      setCourseId(SELECT_ALL);
      setCourses([]);
      handleCloseDialog();
      setOpenNotifyMessage(true);

      if (projectStore.currentProject) {
        fetchStudents(
          page,
          rowsPerPage,
          classId,
          registrationStatus,
          schoolId,
          filterCourseId,
          filterPriority,
          projectStore.currentProject.id
        );
      }
    }
  };

  const [studySubjectId, setStudySubjectId] = useState<number | string>(
    SELECT_ALL
  );
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [courseId, setCourseId] = useState<number | string>(SELECT_ALL);
  const [loadingNewCourse, setLoadingNewCourse] = useState<boolean>(false);
  const handleChooseStudySubjectId = async (
    studySubjectId: number | string
  ): Promise<void> => {
    try {
      setLoadingNewCourse(true);
      setCourseId(SELECT_ALL);
      setCourses([]);
      setStudySubjectId(studySubjectId);
      if (projectStore.currentProject?.id) {
        const courses = await courseStore.getCoursesMinified(
          projectStore.currentProject.id,
          studySubjectId !== SELECT_ALL ? +studySubjectId : undefined
        );
        setCourses(courses);
        setLoadingNewCourse(false);
      }
    } catch (error: any) {
      console.log(
        `Error get list course by projectId and studySubjectId: ${error}`
      );
      setLoadingNewCourse(false);
    }
  };
  /** ManualAssign */

  return (
    <>
      <AdminHeader
        title={projectStore.currentProject?.projectName}
        actions={
          isSmallerThanMedium ? (
            <StudentStepper
              activeStep={
                projectStore.currentProject &&
                coursePeriodIsActive(projectStore.currentProject)
                  ? 2
                  : 1
              }
            />
          ) : (
            <Typography variant="h6">
              {projectStore.currentProject &&
              coursePeriodIsActive(projectStore.currentProject)
                ? 'Kursperiode'
                : 'Registreringsperiode'}
            </Typography>
          )
        }
      />
      <AdminSearchBar
        label="Søk etter elev ved navn"
        handleSearchValueChange={setStudentNameFilter}
        searchStudent={searchStudent}
      >
        <StudentTableFilters
          all={all}
          classId={classId}
          setClassId={handleClassIdChange}
          schoolId={schoolId}
          setSchoolId={handleSchoolIdChange}
          status={registrationStatus}
          setStatus={handleRegistrationStatusChange}
          courseId={filterCourseId}
          setCourseId={handleCourseIdChange}
          priority={filterPriority}
          setPriority={handlePriorityChange}
        />
      </AdminSearchBar>
      <div className={classes.content}>
        {projectStore.currentProject ? (
          coursePeriodIsActive(projectStore.currentProject) ? (
            <StudentCourseTable
              loading={loading}
              students={connectedUsers}
              page={page}
              handlePageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              all={all}
              handleOpenManualAssignDialog={handleOpenManualAssignDialog}
            />
          ) : (
            <StudentRegistrationTable
              loading={loading}
              students={connectedUsers}
              page={page}
              handlePageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              all={all}
              addOrUpdate={addOrUpdate}
            />
          )
        ) : (
          <Typography variant="h2" className={classes.error}>
            Ingen aktive prosjekter
          </Typography>
        )}
      </div>
      <NotifyMessage
        open={openNotifyMessage}
        message={'Success !!!'}
        severity={'success'}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessage}
      />

      {student && (
        <ManualAssignAllocationForStudentWithoutRegisterDialog
          open={openManualAssign}
          handleCloseDialog={handleCloseDialog}
          student={student}
          studySubjects={studySubjectStore.studySubjectHeadCell}
          studySubjectId={studySubjectId}
          courses={courses}
          courseId={courseId}
          handleChooseCourseId={handleChooseCourseId}
          handleChooseStudySubjectId={handleChooseStudySubjectId}
          manualAssign={handleManualAssign}
          loadingNewCourse={loadingNewCourse}
        />
      )}
      <VGSCourseInfoDrawer />
    </>
  );
};

export default observer(Students);
