import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    padding: '16px',
    ...flexCol,
    gap: '6px',
    width: '60vw',
    [breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  subheader: {
    ...flexRow,
    justifyContent: 'space-between',
  },
  selectPadding: {
    padding: '12px 15px',
  },
  recipientType: {
    display: 'flex',
    alignItems: 'center',
  },
  recipientTypeLabel: {
    marginRight: '10px',
  },
}));

export default useStyles;
