import { FilledInputProps } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';

interface IComponentProps {
  label: string;
  value: ParsableDate;
  onChange: (value: MaterialUiPickersDate) => void;
  inputProps?: Partial<FilledInputProps>;
  format?: string;
  customClass?: string;
  hasError?: boolean;
}

const CustomDatePicker = (props: IComponentProps) => {
  const { label, value, onChange, inputProps, format, customClass, hasError } =
    props;
  const [error, setError] = React.useState<boolean>(false);

  const displayError = (error: React.ReactNode, value: ParsableDate): void => {
    if (error) {
      setError(true);
    }
  };

  return (
    <>
      <DatePicker
        format={format ?? 'yyyy/MM/dd'}
        label={label}
        value={value}
        onChange={onChange}
        InputProps={inputProps}
        clearable
        className={customClass ?? ''}
        error={hasError || error}
        onError={(error: React.ReactNode, value: ParsableDate) =>
          displayError(error, value)
        }
        helperText={<></>}
      />
    </>
  );
};

export default CustomDatePicker;
