import { useStores } from '@/stores';
import { getSchoolAddressLink } from '@/utils/schoolUtils';
import { Card, Grid, IconButton, Link, Typography } from '@material-ui/core';
import {
  EditOutlined,
  EventBusy,
  MailOutlined,
  PersonAddOutlined,
  PhoneIphoneOutlined,
  SchoolOutlined,
} from '@material-ui/icons';
import ISchool, { SchoolType } from '../../../../../types/School';
import HorizontalChipList from '../HorizontalChipList';
import useStyle from './style';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

interface ISchoolCardProps {
  school: ISchool;
  isChoice?: boolean;
  handleOpenContactsPersonDrawer?: (externalSchoolId: string) => void;
  redirectToScreenStudent?: (externalId: string) => void;
  redirectToScreenCourse?: (externalId: string) => void;
}

const SchoolCard = ({
  school,
  isChoice,
  handleOpenContactsPersonDrawer,
  redirectToScreenStudent,
  redirectToScreenCourse,
}: ISchoolCardProps) => {
  const classes = useStyle();
  const { schoolStore, stateStore } = useStores();

  const mainContactPerson = school.contactPeople?.filter((cp) => cp.isMain)[0];

  const openImportStudentModal = (event: React.MouseEvent<HTMLElement>) => {
    _stopPropagation(event);
    schoolStore.setEditingSchool(school);

    stateStore.setImportStudentModalIsOpen(true);
  };

  const editSchool = (event: React.MouseEvent<HTMLElement>) => {
    _stopPropagation(event);
    schoolStore.setEditingSchool(school);

    stateStore.setSchoolDrawerIsOpen(true);
  };

  const editSchoolDates = (event: React.MouseEvent<HTMLElement>) => {
    _stopPropagation(event);
    schoolStore.setEditingSchool(school);

    stateStore.setUnavailableDateDrawerIsOpen(true);
  };

  const _stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Card
        className={classes.card}
        onClick={() => {
          if (isChoice && handleOpenContactsPersonDrawer && school.externalId) {
            handleOpenContactsPersonDrawer(school.externalId);
          }
        }}
      >
        <div className={classes.header}>
          <Typography variant="h2" className={classes.schoolName}>
            {school.schoolName}
          </Typography>
          {school.deactivated && (
            <Typography variant="subtitle1" className={classes.deactivated}>
              Deaktivert
            </Typography>
          )}
          <div>
            {school.schoolType === SchoolType.SecondarySchool && (
              <>
                <IconButton
                  className={classes.editButton}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (school.externalId && redirectToScreenStudent) {
                      redirectToScreenStudent(school.externalId);
                    }
                  }}
                >
                  <PeopleAltOutlinedIcon />
                </IconButton>

                <IconButton
                  className={classes.editButton}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    openImportStudentModal(event)
                  }
                >
                  <PersonAddOutlined />
                </IconButton>

                <IconButton
                  className={classes.editButton}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    editSchoolDates(event)
                  }
                >
                  <EventBusy />
                </IconButton>
              </>
            )}
            {school.schoolType === SchoolType.HighSchool && (
              <>
                <IconButton
                  className={classes.editButton}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (school.externalId && redirectToScreenCourse) {
                      redirectToScreenCourse(school.externalId);
                    }
                  }}
                >
                  <SchoolOutlined />
                </IconButton>
              </>
            )}
            <IconButton
              className={classes.editButton}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                editSchool(event)
              }
            >
              <EditOutlined />
            </IconButton>
          </div>
        </div>
        <Typography>
          <Link
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              _stopPropagation(event)
            }
            href={getSchoolAddressLink(school)}
            target="_blank"
            underline="always"
          >
            {school.address?.street}, {school.address?.zipCode}{' '}
            {school.address?.city}
          </Link>
        </Typography>
        {!!mainContactPerson && (
          <div className={classes.contactContainer}>
            <Typography variant="h5" className={classes.contactName}>
              {mainContactPerson.user?.fullName}
            </Typography>
            <div>
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  _stopPropagation(event)
                }
                href={'mailto:' + mainContactPerson.user?.emailAddress}
              >
                <MailOutlined />
              </IconButton>
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  _stopPropagation(event)
                }
                href={'tel:' + mainContactPerson.user?.phoneNumber}
              >
                <PhoneIphoneOutlined />
              </IconButton>
            </div>
          </div>
        )}
        {school.schoolType === SchoolType.HighSchool && (
          <HorizontalChipList
            values={school.studySubjects.map(
              (ss) => ss.studySubject?.studySubjectName
            )}
          />
        )}
      </Card>
    </Grid>
  );
};

export default SchoolCard;
