import { useStores } from '@/stores';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { FormEvent, useEffect } from 'react';
import useStyle from './style';
import { DragIndicator } from '@material-ui/icons';
import { IUpdateSchoolDto } from '@/types/School';

interface IGUInformationProps {
  setLoading: (loading: boolean) => void;
}

const GUInformation = ({ setLoading }: IGUInformationProps) => {
  const classes = useStyle();
  const { schoolStore, stateStore } = useStores();
  const {
    editingSchool,
    setEditingSchool,
    highSchools,
    getPopulatedHighSchools,
    distanceAndPriorities,
    setDistanceAndPriorities,
    getDistanceAndPriotiesByGUId,
  } = schoolStore;
  const { setSchoolDrawerIsOpen } = stateStore;

  useEffect(() => {
    if (highSchools.length === 0) getPopulatedHighSchools();

    getDistanceAndPriotiesByGUId(editingSchool.externalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingSchool.externalId, highSchools.length]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const updateSchool: IUpdateSchoolDto = {
        ...editingSchool,
        distanceAndPriorities,
      };
      if (updateSchool.externalId) {
        await schoolStore.updateSchool(updateSchool);
      } else {
        await schoolStore.createSchool(updateSchool);
      }
      setSchoolDrawerIsOpen(false);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const setSchoolName = (name: string) =>
    setEditingSchool({ ...editingSchool, schoolName: name });
  const setZipCode = (zipCode: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, zipCode },
    });
  const setStreet = (street: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, street },
    });
  const setCity = (city: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, city },
    });
  const setDeactivated = (deactivated: boolean) =>
    setEditingSchool({
      ...editingSchool,
      deactivated,
    });

  const handleDragChange = (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    if (result.destination) {
      let items = Array.from(distanceAndPriorities);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination?.index, 0, reorderedItem);

      items = items.map((dp, i) => ({ ...dp, priority: i + 1 }));

      setDistanceAndPriorities(items);
    }
  };

  return (
    <>
      <form id="edit-gu-form" className={classes.form} onSubmit={handleSubmit}>
        <TextField
          label="Navn"
          required
          onChange={(e) => setSchoolName(e.currentTarget.value)}
          value={editingSchool.schoolName}
        />
        <TextField
          label="Gate"
          required
          onChange={(e) => setStreet(e.currentTarget.value)}
          value={editingSchool.address?.street}
        />
        <div className={classes.addressWrapper}>
          <TextField
            label="Postnummer"
            required
            onChange={(e) => setZipCode(e.currentTarget.value)}
            value={editingSchool.address?.zipCode}
          />
          <TextField
            label="By"
            required
            onChange={(e) => setCity(e.currentTarget.value)}
            value={editingSchool.address?.city}
          />
        </div>
        <FormControlLabel
          label="Deaktivert"
          onChange={(e) => {
            setDeactivated((e.target as HTMLInputElement).checked);
          }}
          checked={editingSchool.deactivated}
          control={<Checkbox name="mainContactPerson" color="primary" />}
        />
        <Typography variant="h4">Sett prioriteringsrekkefølge</Typography>
        <DragDropContext onDragEnd={handleDragChange}>
          <Droppable droppableId="high-schools">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {distanceAndPriorities.map((dp, index) => (
                  <Draggable
                    key={dp.highSchool.externalId}
                    draggableId={dp.highSchool.externalId ?? 'hs-' + index}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classes.dragAndDropSchools}
                      >
                        <DragIndicator className={classes.dragIndicator} />
                        {dp.highSchool.schoolName}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </form>
    </>
  );
};

export default observer(GUInformation);
