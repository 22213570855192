import { Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { PersonOutlined } from "@material-ui/icons";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "../../../../stores";
import { getNorwegianRole } from "../../../../types/UserRole";
import UserInformationDropdown from "./UserInformationDropdown";
import { useUserInformationStyles } from "./userInformationStyle";

const UserInformation = () => {
    const { userStore, stateStore } = useStores();
    const { user } = userStore;
    const { userInformationDropdownIsOpen: open, toggleUserInformationDropdownIsOpen: toggleOpen } = stateStore;
    const classes = useUserInformationStyles({ open });

    const { breakpoints } = useTheme();
    const isExtraSmall = useMediaQuery(breakpoints.down('xs'))

    return(
        <>
            <div className={classes.root} onClick={toggleOpen} aria-label='user-info'>
                {!isExtraSmall && <div>
                    <Typography className={classes.name}>{user?.fullName}</Typography>
                    <Typography className={classes.role}>{getNorwegianRole(user?.currentRole)}</Typography>
                </div>}
                <div className={classes.personCircle}>
                    <PersonOutlined />
                </div>
            </div>
            {open && <UserInformationDropdown /> }
        </>
    )
}

export default observer(UserInformation);