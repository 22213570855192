import { Button } from '@material-ui/core';
import { Editor } from '@tiptap/react';
import { FC } from 'react';
import useStyles from './style';

interface IToolBarProps {
  editor: Editor | null;
}

const ToolBar: FC<IToolBarProps> = ({ editor }) => {
  const classes = useStyles();

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`${classes.button} ${
          editor.isActive('bold') ? 'is-active' : ''
        }`}
      >
        Fet
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`${classes.button} ${
          editor.isActive('italic') ? 'is-active' : ''
        }`}
      >
        Kursiv
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${classes.button} ${
          editor.isActive('paragraph') ? 'is-active' : ''
        }`}
      >
        Paragraf
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
        }`}
      >
        h1
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
        }`}
      >
        h2
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
        }`}
      >
        h3
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 4 }) ? 'is-active' : ''
        }`}
      >
        h4
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 5 }) ? 'is-active' : ''
        }`}
      >
        h5
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={`${classes.button} ${
          editor.isActive('heading', { level: 6 }) ? 'is-active' : ''
        }`}
      >
        h6
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${classes.button} ${
          editor.isActive('bulletList') ? 'is-active' : ''
        }`}
      >
        Punktliste
      </Button>
      <Button
        variant="outlined"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${classes.button} ${
          editor.isActive('orderedList') ? 'is-active' : ''
        }`}
      >
        Talliste
      </Button>
      <Button
        onClick={() => editor.chain().focus().undo().run()}
        className={classes.button}
        variant="outlined"
      >
        Angre
      </Button>
      <Button
        onClick={() => editor.chain().focus().redo().run()}
        className={classes.button}
        variant="outlined"
      >
        Gjenta
      </Button>
    </div>
  );
};

export default ToolBar;
