import { IClass } from '@/types/Class';
import IDistanceAndPriority from '@/types/DistanceAndPriority';
import IStudentPaginationRequest from '@/types/dto/Schools/StudentPaginationRequest.dto';
import { SchoolStatus } from '@/types/enums/SchoolStatus';
import UnavailableDate from '@/types/UnavailableDate';
import { User } from '@/types/User';
import { UserRole } from '@/types/UserRole';
import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import SchoolService from '../services/SchoolService';
import School, { IUpdateSchoolDto, SchoolType } from '../types/School';

class SchoolStore {
  public rootStore: RootStore;
  public allSchools: School[] = [];
  public highSchools: School[] = [];
  public secondarySchools: School[] = [];
  public currentSchool: School | undefined;
  public editingSchool: School = new School();
  public connectedUsers: User[] = [];
  public distanceAndPriorities: IDistanceAndPriority[] = [];
  public totalStudentCount = 0;
  public noPrioritiesCount = 0;
  public firstPriorityCount = 0;
  public assignedStudentsCount = 0;
  public highSchoolHeadCell: School[] = [];
  public schoolBySchoolType: School[] = [];
  public schoolFilterByUserRole: School[] = [];
  public listClass: IClass[] = [];
  public isSetHasSpecialNeeds = false;
  public externalIdSecondarySchool = '';
  public loading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setCurrentSchool = (school: School | undefined) =>
    (this.currentSchool = school);

  @action
  setEditingSchool = (school: School) => (this.editingSchool = school);

  @action
  setUnavailableDates = (unavailableDates: UnavailableDate[]) =>
    (this.editingSchool = { ...this.editingSchool, unavailableDates });

  @action
  saveUnavailableDates = async () => {
    if (!this.editingSchool.externalId) {
      console.error('editingSchool.externalId is missing');
      return;
    }
    await SchoolService.saveUnavailableDates(
      this.editingSchool.externalId,
      this.editingSchool.unavailableDates
    );

    this.getPopulatedSecondarySchools();
  };

  @action
  resetEditingSchool = () => (this.editingSchool = new School());

  @action
  getAllSchools = async () => {
    const schools = await SchoolService.getAll();
    if (schools) this.highSchools = schools;
  };

  @action
  getPopulatedHighSchools = async (filter = SchoolStatus.ALL) => {
    this.loading = true;
    const schools = await SchoolService.getPopulatedSchools(
      SchoolType.HighSchool,
      filter
    );
    if (schools) {
      this.highSchools = schools;
    }
    this.loading = false;
  };

  @action
  getPopulatedSecondarySchools = async (filter = SchoolStatus.ALL) => {
    let schools = await SchoolService.getPopulatedSchools(
      SchoolType.SecondarySchool,
      filter
    );
    if (schools) {
      schools = schools.map((s) => ({
        ...s,
        unavailableDates: s.unavailableDates.map(UnavailableDate.mapFromDto),
      }));
      this.secondarySchools = schools;
    }
  };

  @action
  getSchoolById = async (id: number) =>
    (this.currentSchool = await SchoolService.getById(id));

  @action
  getSchoolByExternalId = async (externalId: string) => {
    this.currentSchool = await SchoolService.getByExternalId(externalId);
    await this.getClasses();
  };

  @action
  async getSchoolByUser() {
    if (this.rootStore.userStore.user?.externalSchoolId) {
      this.currentSchool = await SchoolService.getByExternalId(
        this.rootStore.userStore.user?.externalSchoolId
      );
    }
  }

  @action
  updateSchool = async (school: IUpdateSchoolDto) => {
    await SchoolService.update(school);

    if (school.schoolType === SchoolType.HighSchool)
      await this.getPopulatedHighSchools(SchoolStatus.ALL);

    if (school.schoolType === SchoolType.SecondarySchool)
      await this.getPopulatedSecondarySchools(SchoolStatus.ALL);

    this.connectedUsers = [];
  };

  @action
  createSchool = async (school: School): Promise<void> => {
    await SchoolService.add(school);

    if (school.schoolType === SchoolType.HighSchool)
      await this.getPopulatedHighSchools(SchoolStatus.ALL);
    if (school.schoolType === SchoolType.SecondarySchool)
      await this.getPopulatedSecondarySchools(SchoolStatus.ALL);

    this.connectedUsers = [];
  };

  @action
  deleteSchool = async (externalId: string): Promise<void> => {
    await SchoolService.deleteByExternalId(externalId);

    const deleteIndex = this.allSchools.findIndex(
      (s) => s.externalId === externalId
    );
    if (deleteIndex > -1) this.allSchools.splice(deleteIndex, 1);
  };

  @action
  getConnectedUsers = async (
    externalId: string,
    userRole: UserRole
  ): Promise<void> => {
    const users = await SchoolService.getConnectedUsers(externalId, userRole);

    if (users) this.connectedUsers = users;
  };

  @action
  getStudents = async (req: IStudentPaginationRequest) => {
    const response = await SchoolService.getStudents(req);
    if (response) {
      this.connectedUsers = response.data;
      this.totalStudentCount = response.count;
      this.noPrioritiesCount = response.noPrioritiesCount;
      this.firstPriorityCount = response.firstPriorityCount;
      this.assignedStudentsCount = response.assignedCount;
    }
  };

  @action
  getDistanceAndPriotiesByGUId = async (
    externalId?: string,
    addToEditingSchool = false
  ): Promise<void> => {
    const distanceAndPriorities =
      await SchoolService.getDistanceAndPrioritiesByGUId(externalId);

    if (distanceAndPriorities) {
      this.distanceAndPriorities = distanceAndPriorities;
    }
  };

  @action
  setDistanceAndPriorities = async (value: IDistanceAndPriority[]) => {
    this.distanceAndPriorities = value;
  };

  public async getAllSecondarySchoolByProjectId(
    projectId: number
  ): Promise<void> {
    const schools = await SchoolService.getAllSecondarySchoolByProjectId(
      projectId
    );
    this.secondarySchools = schools;
  }

  @action
  getClasses = async () => {
    try {
      if (!this.currentSchool || !this.currentSchool.externalId) return;
      const classes = await SchoolService.getClasses(
        this.currentSchool.externalId
      );
      if (classes) this.currentSchool.classes = classes;
    } catch (error) {
      this.rootStore.errorStore.showErrorMessage(
        'Det har oppstått ett problem med å hente klassene til skolen'
      );
    }
  };

  getHighSchoolByProjectId = async (
    projectId: number,
    externalSchoolId?: string
  ): Promise<void> => {
    const schools = await SchoolService.getHighSchoolByProjectId(
      projectId,
      externalSchoolId
    );
    this.highSchoolHeadCell = schools;
  };

  getSchoolBySchoolType = async (schoolType: SchoolType): Promise<void> => {
    const schools = await SchoolService.getSchoolBySchoolType(schoolType);
    this.schoolBySchoolType = schools;
    if (this.rootStore.userStore.user?.currentRole === UserRole.Admin) {
      this.schoolFilterByUserRole = this.schoolBySchoolType;
    }
    if (
      this.rootStore.userStore.user?.role === UserRole.HighSchoolContact ||
      this.rootStore.userStore.user?.role === UserRole.Adviser
    ) {
      this.schoolFilterByUserRole = schools.filter(
        (value) =>
          value.externalId === this.rootStore.userStore.user?.externalSchoolId
      );
    }
  };

  getClassesByExternalSchoolId = async (
    externalSchoolId: string
  ): Promise<void> => {
    const data = await SchoolService.getClasses(externalSchoolId);
    this.listClass = data;
  };

  @action
  setIsSetHasSpecialNeeds = (value: boolean) => {
    this.isSetHasSpecialNeeds = value;
  };

  @action
  setExternalIdSecondarySchool = (value: string): void => {
    this.externalIdSecondarySchool = value;
  };

  getSchoolsForAdmin = async (admin: User) => {
    this.schoolFilterByUserRole = await SchoolService.getSchoolsForAdmin(admin);;
  }
}

export default SchoolStore;
