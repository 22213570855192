import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import useStyles from './style';
import StudySubjectCard from '@/views/app/common/StudySubjects/StudySubjectCard';
import StudySubjectDrawer from '@/views/app/common/StudySubjects/StudySubjectDrawer';
import AdminHeader from '@/views/app/admin/common/AdminHeader';
import { useStores } from '@/stores';
import IStudySubject from '@/types/StudySubject';
import { Box, CircularProgress } from '@material-ui/core';

interface IStudySubjectsProps {
  schoolId?: string;
}

const CourseTemplates: FC<IStudySubjectsProps> = ({ schoolId }) => {
  const classes = useStyles();
  const { schoolStore } = useStores();

  const [studySubs, setStudySubs] = useState<IStudySubject[]>([]);

  useEffect(() => {
    if (!schoolStore.highSchools.length) {
      schoolStore.getPopulatedHighSchools();
    }
    const ss = schoolStore.highSchools.find((s) => s.externalId === schoolId);
    if (ss) {
      const studySubjects = ss.studySubjects;
      setStudySubs(studySubjects.map((ss) => ss.studySubject));
    }
  }, [schoolStore, schoolStore.highSchools, schoolId]);

  return (
    <>
      <main>
        <AdminHeader title="Kurs" />
        <div className={classes.listWrapper}>
          {!schoolStore.loading ? (
            <>
              {studySubs.map((sub) => (
                <StudySubjectCard studySubject={sub} isAdmin={false} />
              ))}
            </>
          ) : (
            <Box
              sx={{
                minHeight: '20vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
      </main>
      <StudySubjectDrawer />
    </>
  );
};

export default observer(CourseTemplates);
