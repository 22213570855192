import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    padding: '12px 24px',
    color: palette.primary.dark,
  },
  main: {
    padding: '24px',
    ...flexCol,
    gap: '16px',
    color: palette.primary.dark,
  },
  rowFlex: {
    ...flexRow,
    gap: '6px',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
