import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    padding: '24px',
  },
  headerWrapper: {
    ...flexRow,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  header: {
    color: palette.primary.dark,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

export default useStyles;
