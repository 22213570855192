import { useStores } from '@/stores';
import { flexCol } from '@/style/common';
import { Button, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundError = () => {
  const history = useHistory();
  const { userStore } = useStores();
  const { user, getCurrentUser } = userStore;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function tryGetCurrentUser() {
      if (!user) {
        setIsLoading(true);
        await getCurrentUser();
      }
      setIsLoading(false);
    }

    tryGetCurrentUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ ...flexCol, alignItems: 'center', marginTop: '10vh' }}>
      <h1>Denne siden ble ikke funnet.</h1>
      {isLoading ? (
        <>
          <CircularProgress />
          <h2>Vi prøver å logge deg inn</h2>
        </>
      ) : (
        <h2>Trykk på knappen for å gå til innloggingssiden</h2>
      )}
      <Button onClick={() => history.push('/')}>Logg inn manuelt</Button>
    </div>
  );
};

export default NotFoundError;
