import axios, { AxiosError, AxiosResponse } from 'axios';

export const newHttpInstance = (baseUrl = '') => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_BASE_URL + baseUrl,
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (!error.response) {
        return Promise.reject(500);
      }
      const { data } = error.response;
      if (error.response.status === 401) {
        if (
          !(
            window.location.pathname === '/' ||
            window.location.pathname === '/api/account/logout'
          )
        ) {
          await http.post('/api/account/logout');
          window.location.replace('/');
        }
      }
      return Promise.reject(data);
    }
  );

  return instance;
};

const http = newHttpInstance();

export default http;

export const prepareGetQuery = (filter: Record<string, unknown>) => {
  const keys = Object.keys(filter);
  const filterValues = keys
    .filter((key) => !!filter[key] || filter[key] === 0)
    .map((key) => {
      if (filter[key] instanceof Date) {
        return `${key}=${(filter[key] as Date).toISOString()}`;
      }

      if (typeof filter[key] === 'object' && filter[key] !== null) {
        const entityObject: any = filter[key];
        const queryString = [];
        if (Array.isArray(entityObject)) {
          for (const value of entityObject) {
            queryString.push(`${key}[]=${value}`);
          }
        } else {
          for (const prop in entityObject) {
            queryString.push(`${key}[${prop}]=${entityObject[prop]}`);
          }
        }

        return queryString.join('&');
      }
      return `${key}=${filter[key]}`;
    });

  const behind = filterValues.join('&');

  if (behind) return `?${behind}`;

  return '';
};

export const prepareGetQueryFromArray = (
  queryName: string,
  data: unknown[]
): string => {
  const queryString = data.map((d: unknown, index: number) => {
    return `${queryName}[${index}]=${d}`;
  });

  return `?${queryString.join('&')}`;
};
