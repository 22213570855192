import { SurveyQuestionType } from '@/types/enums/SurveyQuestionType';
import { ISurveyAnswer } from '@/types/SurveyAnswer';
import React from 'react';
import userSurveyStyle from '../style';
import clsx from 'clsx';
import { ControlledInputText } from '@/components/ControlledInputText';
import { Button } from '@material-ui/core';
import CustomRadio from '@/components/CustomRadio';
import CustomCheckBox from '@/components/CustomCheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { QuestionRight } from '@/types/enums/SurveyOption';

interface IComponentProps {
  questionId: number;
  type: SurveyQuestionType;
  answers: ISurveyAnswer[];
  handleAddAnswer?: (questionId: number) => Promise<void>;
  handleChangeAnswer: (
    content: string,
    questionId: number,
    answerId: number,
    isChecked?: boolean
  ) => void;
  handleDeleteAnswer?: (questionId: number, answerId: number) => Promise<void>;
  right?: QuestionRight;
  notTitle?: boolean;
}

const AnswerItem = (props: IComponentProps) => {
  const {
    questionId,
    type,
    answers,
    handleAddAnswer,
    handleChangeAnswer,
    handleDeleteAnswer,
    right,
    notTitle,
  } = props;
  const classes = userSurveyStyle();

  return (
    <>
      {type === SurveyQuestionType.INPUT ||
      type === SurveyQuestionType.TEXT_AREA ? (
        <>
          {answers.map((answer) => (
            <div
              key={answer.id ?? 0}
              className={clsx(classes.displayFlex, classes.alignItemsCenter)}
            >
              <div className={!notTitle ? classes.questionTitle : ''}></div>
              <div className={classes.questionContent}>
                <div className={classes.widthAnswerOption}>
                  <ControlledInputText
                    value={answer.content}
                    setValue={(value: string) =>
                      handleChangeAnswer(
                        value,
                        questionId,
                        answer.id ?? 0,
                        !!answer.isChecked
                      )
                    }
                    customClass={clsx(classes.widthFull)}
                    inputProps={{
                      disableUnderline: true,
                      classes: {
                        input:
                          type === SurveyQuestionType.INPUT
                            ? classes.questionInput
                            : '',
                        multiline:
                          type === SurveyQuestionType.TEXT_AREA
                            ? classes.questionTextArea
                            : '',
                      },
                    }}
                    hasMultiline={type === SurveyQuestionType.TEXT_AREA}
                    variant={
                      type === SurveyQuestionType.TEXT_AREA
                        ? 'standard'
                        : 'filled'
                    }
                    minRows={3}
                    maxRows={10}
                    disabled={right === QuestionRight.ONLY_CREATE_EDIT}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {answers.map((answer) => (
            <div
              key={answer.id ?? 0}
              className={clsx(classes.displayFlex, classes.alignItemsCenter)}
            >
              <div className={!notTitle ? classes.questionTitle : ''}>
                {type === SurveyQuestionType.RADIO ? (
                  <div className={classes.displayFlex}>
                    <div
                      className={clsx(
                        classes.marginLeftAuto,
                        classes.spaceCheck
                      )}
                    >
                      <CustomRadio
                        name={questionId.toString()}
                        value={answer.id ?? 0}
                        isChecked={!!answer.isChecked}
                        handleChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeAnswer(
                            answer.content,
                            questionId,
                            answer.id ?? 0,
                            event.target.checked
                          )
                        }
                        disabled={right === QuestionRight.ONLY_CREATE_EDIT}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.displayFlex}>
                    <div
                      className={clsx(
                        classes.marginLeftAuto,
                        classes.spaceCheck
                      )}
                    >
                      <CustomCheckBox
                        value={answer.id ?? 0}
                        isChecked={!!answer.isChecked}
                        handleChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeAnswer(
                            answer.content,
                            questionId,
                            answer.id ?? 0,
                            event.target.checked
                          )
                        }
                        disabled={right === QuestionRight.ONLY_CREATE_EDIT}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.questionContent}>
                <div className={classes.widthAnswerOption}>
                  <div
                    className={clsx(
                      classes.displayFlex,
                      classes.alignItemsCenter
                    )}
                  >
                    <div
                      className={clsx(
                        classes.spaceCheck,
                        classes.widthContentAnswerOption
                      )}
                    >
                      <ControlledInputText
                        value={answer.content}
                        setValue={(value: string) =>
                          handleChangeAnswer(
                            value,
                            questionId,
                            answer.id ?? 0,
                            !!answer.isChecked
                          )
                        }
                        customClass={clsx(classes.widthFull)}
                        inputProps={{
                          disableUnderline: true,
                          classes: { input: classes.questionInput },
                        }}
                        disabled={right === QuestionRight.ONLY_ANSWER}
                      />
                    </div>

                    {right !== QuestionRight.ONLY_ANSWER && (
                      <div
                        className={clsx(
                          classes.pointer,
                          classes.widthButtonDeleteAnswerOption
                        )}
                        onClick={async () => {
                          if (handleDeleteAnswer) {
                            await handleDeleteAnswer(
                              questionId,
                              answer.id ?? 0
                            );
                          }
                        }}
                      >
                        <DeleteIcon
                          classes={{ root: classes.buttonDeleteAnswerOption }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {right !== QuestionRight.ONLY_ANSWER && (
            <div className={classes.addSurveyQuestion}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (handleAddAnswer) {
                    handleAddAnswer(questionId);
                  }
                }}
              >
                Legg til nytt svaralternativ
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AnswerItem;
