import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  buttonStatus: {
    border: `1px solid ${palette.primary.main}`,
    borderRadius: '50px',
    color: palette.primary.main,
    padding: '4px 12px',
    width: 'fit-content',
  },
  buttonStatusPending: {
    background:
      'linear-gradient(0deg, rgba(255, 230, 0, 0.2), rgba(255, 230, 0, 0.2)), #FFFFFF',
    borderColor: palette.text.hint,
    color: palette.text.hint,
  },
  buttonStatusCancelled: {
    backgroundColor: `${palette.warning.light} !important`,
    borderColor: palette.warning.dark,
    color: palette.warning[50],
  },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
