import {
  getStudySubjectCategoryName,
  StudySubjectCategory,
} from '@/types/StudySubject';
import { Chip } from '@material-ui/core';
import useStyles from './style';

interface IStudySubjectCategoryChipProps {
  label?: string;
  category: StudySubjectCategory;
  isActive?: boolean;
  setCurrentCategory: (category: StudySubjectCategory) => void;
}

const StudySubjectCategoryChip = ({
  label,
  category,
  isActive = false,
  setCurrentCategory,
}: IStudySubjectCategoryChipProps) => {
  const classes = useStyles();

  return (
    <Chip
      label={label ?? getStudySubjectCategoryName(category)}
      className={isActive ? classes.activeChip : classes.chip}
      clickable={false}
      onClick={() => setCurrentCategory(category)}
    />
  );
};

export default StudySubjectCategoryChip;
