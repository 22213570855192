import {
    Button,
    TextField,
    Typography,
} from '@material-ui/core';
import React, {FormEvent, useEffect, useState} from 'react';
import {useStores} from '../../../../stores';
import useLoginStyle from '../style';
import {useHistory} from "react-router-dom";


const ResetPassword = () => {
    const history = useHistory();
    const {accountStore} = useStores();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validLink, setValidLink] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);


    useEffect(() => {
        (async function () {
            const boolValue = await accountStore.isValidLink();
            setValidLink(boolValue);
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const resetPassword = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await accountStore.resetPassword(urlParams.get('email'), password, urlParams.get('time'), urlParams.get('token'))
        history.push('/');
    };

    const classes = useLoginStyle();

    return (
        <div className={classes.resetPassword}>
            {!validLink ? (
                <Typography variant="h3" className={classes.loginHeader}>
                    Hei, lenken er ikke gyldig eller ikke tilgjengelig lenger.
                </Typography>
            ) : (
                <>
                    <Typography variant="h3" className={classes.loginHeader}>
                        Tilbakestill passord
                    </Typography>
                    <form onSubmit={resetPassword} className={classes.form}>

                        <TextField
                            label="Nytt passord"
                            type="password"
                            required
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                        <TextField
                            label="Bekreft passord"
                            type="password"
                            required
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                            error={confirmPassword !== '' && password !== confirmPassword ? true : false}
                            helperText={confirmPassword !== '' && password !== confirmPassword ? "Bekreft passord stemmer ikke" : ""}
                        />

                        <Button type="submit" color="secondary"
                                disabled={password === '' || password !== confirmPassword}>
                            Endre passord
                        </Button>
                    </form>
                </>
            )}
        </div>
    );
};

export default ResetPassword;
