import React from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useStores } from '@/stores';
import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';
import { DayModifiers } from 'react-day-picker';
import School from '@/types/School';
import IStudySubject from '@/types/StudySubject';
import { PermIdentity, RoomOutlined } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { CreateUpdateCourse } from '@/types/Course';
import CalendarCourse from '@/views/app/admin/common/CalendarCourse';
import { getDateFromKarDateType } from '@/utils/timeUtils';
import clsx from 'clsx';
import CustomCheckBox from '@/components/CustomCheckBox';
import courseStyle from '@/views/style/courses';

interface IComponentProps {
  numberCoursePerDay: number;
  isCheckedChooseExtraCapacity: boolean;
  handleChangeChooseExtraCapacity: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setNumberCoursePerDay: (num: number) => void;
  resetNumberCoursePerDay: () => void;
  setStatus: (value: CourseStatus) => void;
  setSchool: (value: string) => void;
  setStudySubject: (value: number) => void;
  setCapacity: (value: number) => void;
  setExtraCapacity: (value: number) => void;
  selectedDays: Date | Date[];
  handleSelectedDays: (day: Date, modifiers: DayModifiers) => void;
  handleCreateCourse: () => Promise<void>;
  cancelCreateCourse: () => void;
}

const CreateEditCourse = (props: IComponentProps) => {
  const {
    numberCoursePerDay,
    isCheckedChooseExtraCapacity,
    handleChangeChooseExtraCapacity,
    setNumberCoursePerDay,
    resetNumberCoursePerDay,
    setStatus,
    setSchool,
    setStudySubject,
    setCapacity,
    setExtraCapacity,
    selectedDays,
    handleSelectedDays,
    handleCreateCourse,
    cancelCreateCourse,
  } = props;

  const courseClasses = courseStyle();
  const { courseStore, schoolStore, studySubjectStore, projectStore } =
    useStores();

  return (
    <>
      <div className={courseClasses.courseOptionPadding}>
        <div className={courseClasses.courseOptionWidth}>
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.alignItemsCenter,
              courseClasses.marginBottom21
            )}
          >
            <div
              className={clsx(
                courseClasses.optionsTitleSize,
                courseClasses.fontWeight500
              )}
            >
              {!courseStore.currentCourse.id
                ? 'Opprett arrangement'
                : 'Arrangement info'}
            </div>
            <div className={courseClasses.marginLeftAuto}>
              <ClearIcon
                className={courseClasses.pointer}
                onClick={cancelCreateCourse}
              />
            </div>
          </div>

          <div className={courseClasses.marginBottom16}>
            <FormControl variant="filled" className={courseClasses.widthFull}>
              <Select
                value={courseStore.currentCourse.status}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setStatus(e.target.value as CourseStatus)
                }
              >
                {Object.keys(CourseStatus)
                  .filter(
                    (k: string) => typeof CourseStatus[k as any] === 'string'
                  )
                  .map((k1: string) => +k1)
                  .map((k2: number) => (
                    <MenuItem key={k2} value={k2}>
                      {getStatusDisplay(k2)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className={courseClasses.marginBottom16}>
            <FormControl variant="filled" className={courseClasses.widthFull}>
              <Select
                value={courseStore.currentCourse.externalSchoolId}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setSchool(e.target.value as string)
                }
              >
                {schoolStore.highSchools.filter(school => !school.deactivated).map((school: School) => (
                  <MenuItem key={school.externalId} value={school.externalId}>
                    {school.schoolName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={courseClasses.marginBottom16}>
            <FormControl variant="filled" className={courseClasses.widthFull}>
              <Select
                value={courseStore.currentCourse.studySubjectId}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setStudySubject(e.target.value as number)
                }
              >
                {studySubjectStore.studySubjectsBySchoolExternalId.map(
                  (studySubject: IStudySubject) => (
                    <MenuItem key={studySubject.id} value={studySubject.id}>
                      {studySubject.studySubjectName}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>

          {!courseStore.currentCourse.id && (
            <div className={courseClasses.marginBottom16}>
              <FormControl className={courseClasses.widthFull}>
                <TextField
                  label="Arrangement per dag"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RoomOutlined />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearIcon
                          classes={{ root: courseClasses.pointer }}
                          onClick={resetNumberCoursePerDay}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={numberCoursePerDay}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setNumberCoursePerDay(e.target.value as number)
                  }
                />
              </FormControl>
            </div>
          )}
          <div className={courseClasses.marginBottom16}>
            <FormControl className={courseClasses.widthFull}>
              <TextField
                label="Kapasitet per arrangement"
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermIdentity />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ClearIcon
                        classes={{ root: courseClasses.pointer }}
                        onClick={() =>
                          setCapacity(new CreateUpdateCourse().capacity)
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                value={courseStore.currentCourse.capacity}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setCapacity(e.target.value as number)
                }
              />
            </FormControl>
          </div>
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.alignItemsCenter
            )}
          >
            <CustomCheckBox
              isChecked={isCheckedChooseExtraCapacity}
              handleChange={handleChangeChooseExtraCapacity}
            />
            <Typography className={courseClasses.marginLeft19}>
              Mulighet til overblastning av arrangementer
            </Typography>
            <TextField
              label="Antall"
              variant="filled"
              className={courseClasses.width83}
              value={courseStore.currentCourse.extraCapacity}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setExtraCapacity(e.target.value as number)
              }
            />
          </div>
          <CalendarCourse
            fromMonth={getDateFromKarDateType(
              projectStore.currentProject?.coursePeriodStartDate ?? new Date()
            )}
            toMonth={getDateFromKarDateType(
              projectStore.currentProject?.coursePeriodEndDate ?? new Date()
            )}
            selectedDays={selectedDays}
            handleSelectedDays={handleSelectedDays}
          />
          {courseStore.currentCourse.id && (
            <Typography className={courseClasses.capacityText}>
              Kapasitet: {courseStore.currentCourse.capacity}
            </Typography>
          )}
        </div>
      </div>

      <div
        className={clsx(
          courseClasses.displayFlex,
          courseClasses.optionButtons,
          courseClasses.marginTop47
        )}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateCourse}
        >
          {!courseStore.currentCourse.id ? 'Opprett arrangement' : 'Lagre'}
        </Button>
        <Button
          variant="text"
          onClick={cancelCreateCourse}
          style={{ marginLeft: '12px' }}
        >
          Avbryt
        </Button>
      </div>
    </>
  );
};

export default observer(CreateEditCourse);
