import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import IOpenable from '../../../../types/interfaces/IOpenable';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SavedDialog = ({ open, setOpen }: IOpenable) => {
  const history = useHistory();

  const { studySubjectStore, projectStore } = useStores();
  const { studentStudySubjects } = studySubjectStore;
  const { currentProject } = projectStore;
  const finish = () => {
    setOpen(false);
    history.push('/home');
  };

  useEffect(() => {
    if (!currentProject) projectStore.getCurrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography variant="h4">Du valgte:</Typography>
      </DialogTitle>
      <DialogContent>
        {studentStudySubjects.map((sub) => (
          <Typography key={sub.priority + '-prio'}>
            {sub.priority}. prioritet: {sub.studySubject.studySubjectName}
          </Typography>
        ))}
        {currentProject?.registeringPeriodEndDate && (
          <Typography style={{ marginTop: '20px' }}>
            Du kan endre disse frem til{' '}
            {new Date(
              currentProject.registeringPeriodEndDate
            ).toLocaleDateString('no-NO', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          Avbryt
        </Button>
        <Button onClick={finish}>Fullfør</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SavedDialog;
