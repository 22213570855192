import React from 'react';
import { observer } from 'mobx-react';
import { DayModifiers } from 'react-day-picker';
import { CourseStatus } from '@/types/enums/CourseStatus';
import CreateEditCourse from './CreateEditCourse';
import ListStudent from './ListStudent';
import InfoStudent from './InfoStudent';
import { CourseOptionAdmin } from '@/types/enums/CourseOption';
import { useStores } from '@/stores';

interface IComponentProps {
  tabMode: CourseOptionAdmin;
  /** CreateEditCourse */
  numberCoursePerDay: number;
  isCheckedChooseExtraCapacity: boolean;
  handleChangeChooseExtraCapacity: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setNumberCoursePerDay: (num: number) => void;
  resetNumberCoursePerDay: () => void;
  setStatus: (value: CourseStatus) => void;
  setSchool: (value: string) => void;
  setStudySubject: (value: number) => void;
  setCapacity: (value: number) => void;
  setExtraCapacity: (value: number) => void;
  selectedDays: Date | Date[];
  handleSelectedDays: (day: Date, modifiers: DayModifiers) => void;
  handleCreateCourse: () => Promise<void>;
  cancelCreateCourse: () => void;
  /** CreateEditCourse */
  /** List Student */
  externalSchoolId: string;
  setExternalSchoolId: (externalSchoolId: string) => void;
  handleOpenManualAssignDialog: () => void;
  /** List Student */
  /** Info Student */
  searchInfoStudent: string;
  setSearchInfoStudent: (search: string) => void;
  setStudentId: (id: number) => void;
  //handleOpenMovingDialog: () => void;
  /** Info Student */
}

const CourseOptions = (props: IComponentProps) => {
  const {
    tabMode,
    /** CreateEditCourse */
    numberCoursePerDay,
    isCheckedChooseExtraCapacity,
    handleChangeChooseExtraCapacity,
    setNumberCoursePerDay,
    resetNumberCoursePerDay,
    setStatus,
    setSchool,
    setStudySubject,
    setCapacity,
    setExtraCapacity,
    selectedDays,
    handleSelectedDays,
    handleCreateCourse,
    cancelCreateCourse,
    /** CreateEditCourse */
    /** List Student */
    externalSchoolId,
    setExternalSchoolId,
    handleOpenManualAssignDialog,
    /** List Student */
    /** Info Student */
    searchInfoStudent,
    setSearchInfoStudent,
    setStudentId,
    //handleOpenMovingDialog,
    /** Info Student */
  } = props;

  const { courseStore } = useStores();

  return (
    <>
      {tabMode === CourseOptionAdmin.CREATE_EDIT && (
        <CreateEditCourse
          numberCoursePerDay={numberCoursePerDay}
          isCheckedChooseExtraCapacity={isCheckedChooseExtraCapacity}
          handleChangeChooseExtraCapacity={handleChangeChooseExtraCapacity}
          setNumberCoursePerDay={setNumberCoursePerDay}
          resetNumberCoursePerDay={resetNumberCoursePerDay}
          setStatus={setStatus}
          setSchool={setSchool}
          setStudySubject={setStudySubject}
          setCapacity={setCapacity}
          setExtraCapacity={setExtraCapacity}
          selectedDays={selectedDays}
          handleSelectedDays={handleSelectedDays}
          handleCreateCourse={handleCreateCourse}
          cancelCreateCourse={cancelCreateCourse}
        />
      )}

      {tabMode === CourseOptionAdmin.LIST_STUDENT && (
        <ListStudent
          externalSchoolId={externalSchoolId}
          setExternalSchoolId={setExternalSchoolId}
          handleOpenManualAssignDialog={handleOpenManualAssignDialog}
        />
      )}
      {tabMode === CourseOptionAdmin.INFO_STUDENT &&
        courseStore.courseId !== courseStore.initCourseId && (
          <InfoStudent
            search={searchInfoStudent}
            setSearch={setSearchInfoStudent}
            setStudentId={setStudentId}
            //handleOpenMovingDialog={handleOpenMovingDialog}
          />
        )}
    </>
  );
};

export default observer(CourseOptions);
