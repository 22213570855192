import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
  },
  // size: {
  //   width: '20px',
  //   height: '20px',
  // },
  // color: {
  //   color: palette.primary[600],
  // },
  // visibility: {
  //   visibility: 'hidden',
  // },
}));

export default useStyles;
