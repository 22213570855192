import { useStores } from '@/stores';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { ChangeEvent, useState } from 'react';

const ImportStudentsModal = () => {
  const { schoolStore, stateStore, userStore, errorStore } = useStores();
  const { editingSchool, resetEditingSchool } = schoolStore;
  const { importStudentModalIsOpen, setImportStudentModalIsOpen } = stateStore;

  const cleanupClose = () => {
    resetEditingSchool();
    setFile(undefined);
  };

  const [file, setFile] = useState<File | undefined>();

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement;

    if (!!target.files && target.files.length > 0) {
      setFile(target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (editingSchool.externalId && !!file) {
      const updatedCount = await userStore.importStudents(
        editingSchool.externalId,
        file
      );
      errorStore.showErrorMessage(
        updatedCount + ' elever ble lagt til i ' + editingSchool.schoolName,
        'success'
      );
      setImportStudentModalIsOpen(false);
      cleanupClose();
    }
  };

  return (
    <Dialog
      open={importStudentModalIsOpen}
      onClose={() => setImportStudentModalIsOpen(false)}
      TransitionProps={{
        onExited: cleanupClose,
      }}
    >
      <DialogContent>
        <Typography variant="h3">
          Importer elever for {editingSchool.schoolName}
        </Typography>
        <InputLabel htmlFor="upload-file-button">
          <Input
            id="upload-file-button"
            inputProps={{ accept: '.xlsx, .xls' }}
            type="file"
            onChange={handleFileChange}
            hidden
          />
          <Button
            style={{ width: '100%', marginTop: '16px' }}
            startIcon={<InsertDriveFileOutlined style={{ color: '#000' }} />}
            component="span"
            color="secondary"
          >
            {!!file ? file.name : 'Last opp Excel fil'}
          </Button>
          <Button
            variant="text"
            style={{ width: '100%', marginBottom: '16px' }}
            onClick={userStore.downloadImportStudentsTemplate}
          >
            Last ned mal
          </Button>
        </InputLabel>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setImportStudentModalIsOpen(false)}
          variant="text"
        >
          Avbryt
        </Button>
        <Button disabled={!file} onClick={handleSubmit}>
          Importer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ImportStudentsModal);
