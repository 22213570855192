import { IAdminStatisticSubjectCapacity } from '@/types/StudySubject';

export const getProjectProgress = (project: any): number => {
  const currentTime = new Date();
  let currentStep = 0;

  if (project.registeringPeriodStartDate && project.registeringPeriodEndDate) {
    if (
      currentTime > new Date(project.registeringPeriodStartDate) &&
      currentTime < new Date(project.registeringPeriodEndDate)
    ) {
      currentStep = 1;
    }
  }
  if (project.availablePeriodStartDate && project.availablePeriodEndDate) {
    if (
      currentTime > new Date(project.availablePeriodStartDate) &&
      currentTime < new Date(project.availablePeriodEndDate)
    ) {
      currentStep = 2;
    }
  }
  if (project.coursePeriodStartDate && project.coursePeriodEndDate) {
    if (
      currentTime > new Date(project.coursePeriodStartDate) &&
      currentTime < new Date(project.coursePeriodEndDate)
    ) {
      currentStep = 3;
    }
  }
  if (project.evaluationPeriodStartDate && project.evaluationPeriodEndDate) {
    if (
      currentTime > new Date(project.evaluationPeriodStartDate) &&
      currentTime < new Date(project.evaluationPeriodEndDate)
    ) {
      currentStep = 4;
    }
  }

  return currentStep;
};

export const getStudentChart = (
  totalStudents: number,
  registeredStudents: number
) => {
  const chart = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '75%',
            background: 'transparent',
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (val: string) => {
          return val + '%';
        },
      },
      colors: ['#9C3535', '#CFD8DC'],
      legend: {
        show: false,
      },
      labels: ['Ikke på meldt elever', 'Påmeldt elever'],
    },
    series: [totalStudents - registeredStudents, registeredStudents],
  };

  return chart;
};

export const getStudentCourseChart = (
  totalStudent: number,
  totalAssignedStudent: number,
  numberFirstPriorityStudent: number,
  numberSecondPriorityStudent: number,
  numberThirdPriorityStudent: number
) => {
  const chart = {
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ['#848DB8', '#A2A8C8', '#C6CADE', '#9C3535'],
      legend: {
        show: true,
        formatter: (seriesName: any, opts: any) => {
          return `${seriesName} : ${opts.w.globals.series[opts.seriesIndex]}`;
        },
      },
      labels: [
        '1. prioritert',
        '2. prioritert',
        '3. prioritert',
        'Ikke tildelt',
      ],
    },
    series: [
      numberFirstPriorityStudent,
      numberSecondPriorityStudent,
      numberThirdPriorityStudent,
      totalStudent - totalAssignedStudent,
    ],
  };

  return chart;
};

export const getCapacityChart = (
  capacityStore: IAdminStatisticSubjectCapacity[]
) => {
  const capacityChart = {
    series: [
      {
        name: 'Kapasitet',
        data: capacityStore
          .filter((c) => c.capacity > 0)
          .map((i) => {
            return i.capacity;
          }),
      },
      {
        name: 'Påmeldt',
        data: capacityStore
          .filter((n, i) => capacityStore[i].capacity > 0)
          .map((i) => {
            return i.numberJoinedStudent;
          }),
      },
    ],
    options: {
      plotOptions: {
        bar: {
          columnWidth: '40px',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
        offsetY: -16,
      },
      colors: ['#C6CADE', '#A2A8C8'],
      legend: {
        position: 'top',
        show: true,
        markers: {
          fillColors: ['#C6CADE', '#A2A8C8'],
        },
      },
      xaxis: {
        categories: capacityStore
          .filter((c) => c.capacity > 0)
          .map((i) => {
            return i.studySubject.studySubjectName.split(' ');
          }),
        labels: {
          show: true,
          rotate: 0,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 100,
        },
      },
      tooltip: {
        enabled: true,
      },
      chart: {
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            //
          },
        },
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: 'Capacity',
            },
            svg: {
              filename: 'Capacity',
            },
            png: {
              filename: 'Capacity',
            },
          },
          autoSelected: 'zoom',
        },
      },
    },
  };

  return capacityChart;
};
