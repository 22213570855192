import { observer } from 'mobx-react';
import React, { ReactNode, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useStores } from '../stores';
import { UserRole } from '../types/UserRole';

interface IRouteGuardProps {
  roles: UserRole;
  children: ReactNode;
}

const RouteGuard = ({ roles, children }: IRouteGuardProps) => {
  const { userStore } = useStores();
  const { user } = userStore;

  useEffect(() => {
    if (!user || !user.isCurrentlyRole(roles)) userStore.removeUser();
  });

  return (
    <>
      {user && user.isCurrentlyRole(roles) ? (
        children
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

export default observer(RouteGuard);
