import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';
import { IUserDto } from '@/types/User';
import { getDateTime } from '@/utils/timeUtils';
import ICourse from '@/types/Course';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  handleCloseManualAssignDialog: () => void;
  student: IUserDto;
  course: ICourse;
  manualAssignStudentToCourse: () => Promise<void>;
}

const ManualAssignDialog = (props: IComponentProps) => {
  const {
    open,
    handleCloseManualAssignDialog,
    student,
    course,
    manualAssignStudentToCourse,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseManualAssignDialog()}
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle>Tildel elev</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6">
            Skal {student.fullName} tildeles{' '}
            {course?.studySubject.studySubjectName} ved{' '}
            {course?.school.schoolName} i{' '}
            {getDateTime(course?.startDate, 'dd. LLL.')}?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCloseManualAssignDialog}>
          Avbryt
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => await manualAssignStudentToCourse()}
        >
          Tildel elev
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualAssignDialog;
