import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';
import React from 'react';
import useStyles from './style';
import clsx from 'clsx';

interface IComponentProps {
  status: CourseStatus;
}

const ButtonCourseStatus = (props: IComponentProps) => {
  const classes = useStyles();
  const { status } = props;

  return (
    <div
      className={clsx(
        classes.buttonStatus,
        classes.whiteSpaceNowrap,
        classes.backgroundColorFFF,
        classes.alignItemsCenter,
        {
          [classes.buttonStatusCancelled]: status === CourseStatus.CANCELLED,
        }
      )}
    >
      {getStatusDisplay(status)}
    </div>
  );
};

export default ButtonCourseStatus;
