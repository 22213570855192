import { ISurvey } from '@/types/Survey';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import userSurveyStyle from '../style';
import Answer from './answer';
import DescriptionSurvey from './DescriptionSurvey';
import Question from './question';

interface IComponentProps {
  survey: ISurvey;
  openEditSurvey: () => void;
}

const Survey = (props: IComponentProps) => {
  const { survey, openEditSurvey } = props;
  const classes = userSurveyStyle();
  return (
    <>
      <div className={classes.titleSurvey}>{survey.title}</div>
      <DescriptionSurvey content={survey.description} />
      <div className={classes.marginTop10}>
        {survey.questions.map((question) => (
          <div key={question.id ?? 0} className={classes.marginTop30}>
            <Question question={question}>
              <Answer
                questionId={question.id ?? 0}
                type={question.type}
                answers={question.answers}
              />
            </Question>
          </div>
        ))}
      </div>
      <>
        <Divider
          variant="fullWidth"
          className={clsx(classes.marginTop40, classes.dividerStyle)}
        />
        <div className={clsx(classes.fieldRequire, classes.marginTop5)}>
          *Må fylles ut
        </div>
      </>

      <div
        className={clsx(
          classes.marginTop20,
          classes.fieldRequire,
          classes.fontSize18,
          classes.pointer,
          classes.marginBottom10
        )}
        onClick={openEditSurvey}
      >
        Rediger
      </div>
    </>
  );
};

export default Survey;
