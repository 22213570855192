import {
  CircularProgress,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import DayPicker, { DayModifiers, NavbarElementProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './index.css';
import React from 'react';
import { getDateTime } from '@/utils/timeUtils';
import clsx from 'clsx';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

interface ICalendarNavbar {
  navbarElementProps: NavbarElementProps;
  fromMonth: Date;
  toMonth: Date;
  changeMonth: (month: Date) => void;
}

const calendarNavbarStyles = makeStyles(({ palette }) => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: palette.background.default,
    },
    color: palette.primary[900],
    '& .MuiSelect-icon': {
      color: palette.primary[500],
    },
  },
  select: {},
  iconControl: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
  marginRight: {
    marginRight: '10px',
  },
  pointer: { cursor: 'pointer' },
  selectPadding: {
    padding: '15px 10px 12px 10px',
  },
}));

const CalendarNavbar = (props: ICalendarNavbar) => {
  const { navbarElementProps, changeMonth } = props;
  const { month, onPreviousClick, onNextClick, className } = navbarElementProps;

  const classes = calendarNavbarStyles();

  const handleChange = (m: string) => {
    changeMonth(new Date(month.getFullYear(), MONTHS.indexOf(m)));
  };

  return (
    <div className={className}>
      <Select
        disableUnderline
        value={MONTHS[month.getMonth()]}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
          handleChange(e.target.value as string)
        }
        className={clsx(classes.root, classes.select)}
        inputProps={{
          className: classes.selectPadding,
        }}
      >
        {MONTHS.map((m: string) => (
          <MenuItem key={m} value={m}>{`${m} ${month.getFullYear()}`}</MenuItem>
        ))}
      </Select>

      <div className={classes.iconControl}>
        <ChevronLeftOutlinedIcon
          className={clsx(classes.marginRight, classes.pointer)}
          onClick={() => onPreviousClick()}
        />
        <ChevronRightOutlinedIcon
          className={classes.pointer}
          onClick={() => onNextClick()}
        />
      </div>
    </div>
  );
};

interface IComponentProps {
  fromMonth?: Date | null;
  toMonth?: Date | null;
  selectedDays: Date | Date[];
  handleSelectedDays: (day: Date, modifiers: DayModifiers) => void;
}

const MONTHS = [
  'Jan', // January
  'Feb', // February
  'Mar', // March
  'Apr', // April
  'Mai', // May
  'Jun', // June
  'Jul', // July
  'Aug', // August
  'Sep', // September
  'Okt', // October
  'Nov', // November
  'Des', // December
];

const WEEKDAYS_LONG = [
  'Søndag', //0, Sunday
  'Mandag', //1, Monday
  'Tirsdag', //2, Tuesday
  'Onsdag', //3, Wednesday
  'Torsdag', //4, Thursday
  'Fredag', //5, Friday
  'Lørdag', //6, Saturday
];
const WEEKDAYS_SHORT = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];

const useStyles = makeStyles(({ palette }) => ({
  optionsDatePicker: {
    width: '100%',
    minHeight: '287px',
    background: palette.grey[100],
    borderRadius: '4px',
    margin: '0 auto',
    marginTop: '12px',
    padding: '16px',
    '& .MuiTypography-body1': {
      fontSize: '14px',
      lineHeight: '22px',
      margin: '0 4px 0 8px',
      color: palette.primary[900],
    },
    '& .MuiPickersCalendarHeader-switchHeader': {
      display: 'none',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CalendarCourse = (props: IComponentProps) => {
  const { fromMonth, toMonth, selectedDays, handleSelectedDays } = props;
  const classes = useStyles();
  const [coursePeriod, setCoursePeriod] = React.useState<string>('');
  const [initMonth, setInitMonth] = React.useState<Date>(new Date());

  React.useEffect(() => {
    if (fromMonth && toMonth) {
      const coursePeriodCal = `${getDateTime(
        fromMonth,
        'dd. LLL.'
      )} - ${getDateTime(toMonth, 'dd. LLL.')}${getDateTime(toMonth, 'yyyy')}`;

      setCoursePeriod(coursePeriodCal.toLocaleLowerCase());
    }
  }, [fromMonth, toMonth]);

  React.useEffect(() => {
    if (!Array.isArray(selectedDays)) {
      setInitMonth(selectedDays);
    } else {
      if (fromMonth) {
        setInitMonth(new Date(fromMonth));
      }
    }
  }, [fromMonth, selectedDays]);

  const changeMonth = (month: Date): void => {
    setInitMonth(month);
  };

  return (
    <>
      <div
        className={clsx(classes.optionsDatePicker, {
          [classes.loading]: !(fromMonth && toMonth),
        })}
      >
        {fromMonth && toMonth ? (
          <>
            <Typography>
              Velg aktuelt dager i kursperioden
              <p>{coursePeriod}</p>
            </Typography>
            <DayPicker
              showWeekNumbers
              showOutsideDays
              disabledDays={[{ daysOfWeek: [6, 0] }]}
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              selectedDays={selectedDays}
              onDayClick={(day: Date, modifiers: DayModifiers) =>
                handleSelectedDays(day, modifiers)
              }
              month={initMonth}
              fromMonth={fromMonth}
              toMonth={toMonth}
              captionElement={() => <></>}
              navbarElement={(navbarElementProps: NavbarElementProps) => (
                <CalendarNavbar
                  navbarElementProps={navbarElementProps}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                  changeMonth={changeMonth}
                />
              )}
              className="CalendarCourse"
            />
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    </>
  );
};

export default CalendarCourse;
