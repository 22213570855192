import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

interface IComponentProps {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
}

const Component = ({ open, onClose, onAgree }: IComponentProps) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-description">
            <Typography variant="h4">Ved lagring oppdateres teksten på alle arrangement av dette utdanningsprogrammet.</Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Avbryt
          </Button>
          <Button onClick={onAgree} color="secondary">
            Large
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const CreateOrUpdateCourseTemplateDialog = Component;
