import { useStores } from '@/stores';
import courseStyle from '@/views/style/courses';
import { observer } from 'mobx-react';
import React from 'react';
import { TextField } from '@material-ui/core';
import Loading from '@/components/Loading';
import useEditCourseStyles from './style';
import EditCourseInformation from '@/views/app/VGSadmin/courses/components/CourseOptions/EditCourse/EditCourseInformation';
import EditCourseTimes from '@/views/app/VGSadmin/courses/components/CourseOptions/EditCourse/EditCourseTimes';
import CustomEditorReadOnly from '@/components/CustomEditorReadOnly';

const CourseInfo = () => {
  const courseClasses = courseStyle();
  const classes = useEditCourseStyles();
  const { courseStore } = useStores();
  const { course } = courseStore;

  return (
    <>
      {course ? (
        <>
          <div className={classes.root}>
            <EditCourseInformation />
            <div className={courseClasses.informationInputs}>
              <EditCourseTimes
                isDisableStartDate={true}
                isDisableEndDate={true}
              />
              <TextField
                label=" Kontakt person"
                value={
                  course.courseHolder
                    ? course.courseHolder.fullName
                    : 'Ikke definert'
                }
                classes={{ root: courseClasses.widthFull }}
              />
              <TextField
                label="Oppmøtested"
                value={course?.roomNumber}
                classes={{ root: courseClasses.widthFull }}
              />

              <CustomEditorReadOnly content={course?.courseInfo} />
            </div>
          </div>
        </>
      ) : (
        <div style={{ minHeight: '100vh' }}>
          <Loading loading={true} />
        </div>
      )}
    </>
  );
};

export default observer(CourseInfo);
