import { useStores } from '@/stores';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';

const AvailableTemplateData: FC = () => {
  const { messageTemplateStore } = useStores();
  const { currentMessageTemplate } = messageTemplateStore;

  const availableData = currentMessageTemplate?.getAvailableAttributes();

  return (
    <>
      <Typography variant="h3">Tilgjengelig data for denne malen</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nøkkel</TableCell>
            <TableCell>Forklaring</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availableData &&
            availableData.map(({ key, description }) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{description}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AvailableTemplateData;
