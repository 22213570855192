import { makeStyles } from '@material-ui/core';

const useLoginStyle = makeStyles(({ palette, breakpoints }) => ({
  rootGrid: {
    height: '100vh',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginGrid: {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 15px 15px',
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25% 25% 0',
    gap: '16px',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: '15px 10px',
    },
  },
  resetPassword: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1% 25% 0',
    gap: '16px',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: '15px 10px',
    },
  },
  loginHeader: {
    color: palette.primary.dark,
    textAlign: 'center',
    fontWeight: 400,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '5vh',
    [breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  rememberMeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noAccount: {
    margin: 'auto',
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.primary.light, //TODO - Change to primary['50'] once UK-24 is merged
    maxHeight: '100%',
  },
  logoHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  bannerHeaderText: {
    color: palette.primary.dark,
    fontWeight: 500,
    marginTop: 'calc(100vh * 0.1)',
    [breakpoints.down('sm')]: {
      marginTop: 'calc(100vh * 0.03)',
    },
  },
  bannerSubheaderText: {
    color: palette.primary.dark,
    fontWeight: 400,
  },
  loginIllustration: {
    width: '100%',
    height: 'auto',
  },
  backButton: {
    width: '90px',
  },
}));

export default useLoginStyle;
