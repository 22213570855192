//import { PaginationRequest } from '@/types/dto/PaginationRequest.dto';
import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import UserService from '../services/UserService';
import {
  IAllocationResultDto,
  IUserDto,
  User,
  UserPaginationRequest,
} from '../types/User';
import { UserRole } from '../types/UserRole';

class UserStore {
  public rootStore: RootStore;
  public user?: User;
  public allocationResult: IAllocationResultDto;
  public listStudentOfCourse: IUserDto[];
  public student?: IUserDto;
  public studentId: number;
  public initStudentId = 0;
  public listHighSchoolContactOfCourse: IUserDto[];
  public loading: boolean;
  public listMinifiedStudentOfCourse: IUserDto[];
  public contactPerson?: User;
  public listContactPerson: User[];
  public listUsers: User[];
  public totalCountListUser: number;
  public createOrUpdate: User;
  public studentAspiration?: IUserDto;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.allocationResult = {
      thirdPriority: 0,
      firstPriority: 0,
      secondPriority: 0,
    };
    this.listStudentOfCourse = [];
    this.listHighSchoolContactOfCourse = [];
    this.loading = false;
    this.listMinifiedStudentOfCourse = [];
    this.studentId = 0;
    this.listContactPerson = [];
    this.listUsers = [];
    this.totalCountListUser = 0;
    this.createOrUpdate = new User();
  }

  @action
  setUser = (user: User) => (this.user = user);

  @action
  removeUser = () => (this.user = undefined);

  @action
  setCurrentRole = (role: UserRole) => {
    if (this.user) {
      this.user.currentRole = role;
      this.user.switchRole = this.user.role / role;
    }
  };

  @action
  getCurrentUser = async (silent = false) => {
    const userDto = await UserService.getCurrentUser(silent);
    if (userDto) this.user = User.fromUserDto(userDto);
  };

  @action
  allocationAllStudent = async () => {
    const allocationResult = await UserService.allocationAllStudents();
    if (allocationResult) this.allocationResult = allocationResult;
  };

  @action
  async updateUser(user: User) {
    await UserService.update(user);

    if (user.id === this.user?.id) this.user = user;
  }
  @action
  setHasSpecialNeeds = async (userId: number, hasSpecialNeeds: boolean) => {
    await UserService.setHasSpecialNeeds(userId, hasSpecialNeeds);

    const updateIndex = this.rootStore.schoolStore.connectedUsers.findIndex(
      (u) => u.id === userId
    );
    if (updateIndex >= 0) {
      this.rootStore.schoolStore.connectedUsers[updateIndex] = {
        ...this.rootStore.schoolStore.connectedUsers[updateIndex],
        hasSpecialNeeds,
      };
    }
  };

  @action
  setListStudentOfCourse(students: IUserDto[]) {
    this.listStudentOfCourse = students;
  }

  @action
  setListHighSchoolContactOfCourse(highSchoolContacts: IUserDto[]) {
    this.listHighSchoolContactOfCourse = highSchoolContacts;
  }

  @action
  setListMinifiedStudentOfCourse(students: IUserDto[]) {
    this.listMinifiedStudentOfCourse = students;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setStudent(student: IUserDto) {
    this.student = student;
  }

  @action
  setStudentId(id: number) {
    this.studentId = id;
  }

  @action
  resetStudentId() {
    this.studentId = this.initStudentId;
  }

  @action
  resetStudent() {
    this.resetStudentId();
    this.student = undefined;
  }

  @action
  setContactPerson(user: User) {
    this.contactPerson = user;
  }

  @action
  resetContactPerson() {
    this.contactPerson = undefined;
  }

  @action
  setListContactPerson(users: User[]) {
    this.listContactPerson = users;
  }

  @action
  setListUsers(data: User[]) {
    this.listUsers = data;
  }

  @action
  setTotalCountListUser(total: number) {
    this.totalCountListUser = total;
  }

  @action
  setCreateOrUpdate(data: User) {
    this.createOrUpdate = data;
  }

  @action
  resetCreateOrUpdate() {
    this.createOrUpdate = new User();
  }

  /*public async getUserByCourseIdAndExternalClassIdAndExternalSchoolId(
    id: number,
    externalSchoolId: string,
    externalClassId: string,
    model: PaginationRequest
  ): Promise<IUserDto[]> {
    return await UserService.getUserByCourseIdAndExternalClassIdAndExternalSchoolId(
      id,
      externalSchoolId,
      externalClassId,
      model
    );
  }*/

  public async getAllStudentByCourseId(courseId: number): Promise<IUserDto[]> {
    const students = await UserService.getAllStudentByCourseId(courseId);
    return students;
  }
  @action
  public async getStudentById(
    id: number,
    courseId?: number,
    isMoveStudent?: boolean
  ): Promise<IUserDto> {
    const student = await UserService.getStudentById(
      id,
      courseId,
      isMoveStudent
    );
    this.setStudent(student);
    return student;
  }

  public async getAllHighSchoolContactByCourseId(id: number): Promise<void> {
    const highSchoolContacts =
      await UserService.getAllHighSchoolContactByCourseId(id);
    this.setListHighSchoolContactOfCourse(highSchoolContacts);
  }

  public async getAllStudentMinifiedByCourseId(
    courseId: number,
    studentName?: string
  ): Promise<void> {
    const students = await UserService.getAllStudentMinifiedByCourseId(
      courseId,
      studentName
    );
    this.setListMinifiedStudentOfCourse(students);
  }

  public async getContactPersonByExternalSchoolId(
    externalSchoolId: string
  ): Promise<void> {
    const user = await UserService.getContactPersonByExternalSchoolId(
      externalSchoolId
    );
    this.setContactPerson(user);
  }

  @action
  getUserStudentCourses = async () => {
    if (this.user) {
      const studentCourses = await UserService.getUserStudentCourses(
        this.user.id
      );
      this.user.studentCourses = studentCourses;
    }
  };

  public async importStudents(
    externalSchoolId: string,
    file: File
  ): Promise<number> {
    const imported = await UserService.importStudents(externalSchoolId, file);
    return imported;
  }

  public async downloadImportStudentsTemplate() {
    const template = await UserService.downloadImportStudentsTemplate();
    return template;
  }

  public async downloadAllStudents(
    externalSchoolId?: string,
    projectId?: number
  ) {
    await UserService.downloadAllStudents(externalSchoolId, projectId);
  }

  public async getContactPersonsByExternalSchoolId(
    externalSchoolId: string
  ): Promise<void> {
    const users = await UserService.getContactPersonsByExternalSchoolId(
      externalSchoolId
    );
    this.setListContactPerson(users);
  }

  public async getListUser(model: UserPaginationRequest): Promise<void> {
    this.setLoading(true);
    const { data, count } = await UserService.getListUser(model);
    this.setListUsers(data);
    this.setTotalCountListUser(count);
    this.setLoading(false);
  }

  public async getUser(id: number): Promise<void> {
    const data = await UserService.getById(id);
    if (data) {
      this.setCreateOrUpdate(data);
    }
  }

  public async createUser(user: User): Promise<void> {
    await UserService.add(user);
  }

  public async deleteUser(id: number): Promise<void> {
    await UserService.delete(id);
  }

  public async getAllStudentAspirationByProjectId(
    projectId: number,
    externalSchoolId?: string,
    studentName?: string
  ): Promise<void> {
    try {
      this.setLoading(true);
      const students = await UserService.getAllStudentAspirationByProjectId(
        projectId,
        externalSchoolId,
        studentName
      );

      this.setListStudentOfCourse(students);
    } finally {
      this.setLoading(false);
    }
  }

  public async UpdateStudentList(projectId: number): Promise<void> {
    const students = await UserService.getAllStudentAspirationByProjectId(
      projectId
    );

    this.setListStudentOfCourse(students);
  }
}

export default UserStore;
