import { PaginationRequest } from '@/types/dto/PaginationRequest.dto';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import {
  ProjectAssignDto,
  ProjectAssignPaginationRequest,
} from '@/types/ProjectSurvey';
import { IStudentSurvey, ISurvey } from '@/types/Survey';
import { ISurveyQuestion } from '@/types/SurveyQuestion';
import { prepareGetQuery } from '@/utils/httpUtils';
import BaseService from './BaseService';

class SurveyService extends BaseService<ISurvey> {
  constructor() {
    super('Survey');
  }

  async getById(id: number): Promise<ISurvey | undefined> {
    try {
      const res = await this.http.get(`${id}`);
      return res.data as ISurvey;
    } catch (error) {
      console.error(`Could not get survey by ${id}, cause: ${error}`);
    }
  }

  async createQuestion(
    surveyId: number,
    model: ISurveyQuestion
  ): Promise<ISurveyQuestion> {
    const result = await this.http.post(`${surveyId}/question`, model);
    return result.data;
  }

  async getSurveyById(id: number): Promise<ISurvey> {
    const result = await this.http.get(`byId/${id}`);
    return result.data;
  }

  async deleteQuestion(id: number): Promise<boolean> {
    const result = await this.http.delete(`${id}/question`);
    return result.data;
  }

  async deleteAnswer(id: number): Promise<boolean> {
    const result = await this.http.delete(`${id}/answer`);
    return result.data;
  }

  public async getList(
    model: PaginationRequest
  ): Promise<PaginationResult<ISurvey>> {
    const result = await this.http.get(
      `${prepareGetQuery(model as Record<string, unknown>)}`
    );
    return result.data;
  }

  public async getAll(): Promise<ISurvey[]> {
    const result = await this.http.get(`all`);
    return result.data;
  }

  public async getListProjectAssign(
    model: ProjectAssignPaginationRequest
  ): Promise<PaginationResult<ProjectAssignDto>> {
    const result = await this.http.get(
      `project-assign${prepareGetQuery(model as Record<string, any>)}`
    );
    return result.data;
  }

  async getSurveyByProjectId(
    projectId: number,
    uuid?: string
  ): Promise<IStudentSurvey> {
    const result = await this.http.get(`byProjectId/${projectId}`, {
      params: {
        uuid,
      },
    });
    return result.data;
  }

  async evaluationUpdateSurvey(uuid: string, survey: ISurvey): Promise<void> {
    await this.http.put(`evaluation/${uuid}/update`, survey);
  }

  async getProjectSurveyExcelExport(
    projectId: number,
    surveyId: number
  ): Promise<void> {
    const response = await this.http.get(
      `byProjectId/${projectId}/excel-export`,
      {
        responseType: 'blob',
        params: {
          surveyId,
        },
      }
    );

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });

    const now = new Date();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `project-survey-export-${now.getTime()}.xlsx`;
    link.click();
  }
}

export default new SurveyService();
