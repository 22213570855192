import IMessagePaginationRequest from '@/types/dto/Messages/MessagePaginationRequest.dto';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import Message from '@/types/Message';
import { prepareGetQuery } from '@/utils/httpUtils';
import BaseService from './BaseService';

class MessageService extends BaseService<Message> {
  constructor() {
    super('message');
  }

  public async getMessages(
    req: IMessagePaginationRequest
  ): Promise<PaginationResult<Message>> {
    const res = await this.http.get(
      '/' + prepareGetQuery(req as unknown as Record<string, unknown>)
    );
    return res.data;
  }
}

export default new MessageService();
