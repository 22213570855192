import { observer } from 'mobx-react';
import React from 'react';
import { CourseOptionVGS } from '@/types/enums/CourseOption';
import { Tab, Tabs } from '@material-ui/core';
import CustomSearch from '@/components/CustomSearch';
import useCourseFilterStyles from './style';

interface IComponentProps {
  tabMode: CourseOptionVGS;
  chooseTabCourseOption: (mode: CourseOptionVGS) => void;
  search: string;
  setSearch: (value: string) => void;
  handleSearch: () => void;
}

const CourseFilter = ({
  tabMode,
  chooseTabCourseOption,
  search,
  setSearch,
  handleSearch,
}: IComponentProps) => {
  const classes = useCourseFilterStyles();

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <CustomSearch
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.tabs}>
        <Tabs
          value={tabMode}
          onChange={(
            event: React.ChangeEvent<Record<string, unknown>>,
            newValue: CourseOptionVGS
          ) => {
            chooseTabCourseOption(newValue);
          }}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab
            label="Arrangement"
            value={CourseOptionVGS.EDIT}
            classes={{ selected: classes.activeTab }}
          />
          <Tab
            label="Elev info"
            value={CourseOptionVGS.INFO_STUDENT}
            classes={{ selected: classes.activeTab }}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default observer(CourseFilter);
