import { useQuery } from '@/hooks';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import ProjectList from './ProjectList';

const Projects = () => {
  const isArchived = useQuery('isArchived');
  const history = useHistory();
  const toggleIsArchived = () => {
    history.push(`projects${isArchived ? '' : '?isArchived=true'}`);
  };

  return (
    <>
      <AdminHeader
        title={isArchived ? 'Arkiverte Prosjekter' : 'Prosjekter'}
        actions={
          <Button onClick={toggleIsArchived}>
            Se {isArchived ? 'aktive' : 'arkiverte'}
          </Button>
        }
      />
      <ProjectList />
    </>
  );
};

export default observer(Projects);
