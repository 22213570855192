import { IProject } from '../types/Project';
import BaseService from './BaseService';

class SchoolService extends BaseService<IProject> {
  constructor() {
    super('Project');
  }

  async getCurrent(): Promise<IProject | undefined> {
    try {
      const res = await this.http.get('/current');
      return res.data as IProject;
    } catch (error) {
      console.error('Could not get current project: ' + error);
    }
  }

  async getById(id: number): Promise<IProject | undefined> {
    try {
      const res = await this.http.get(`${id}`);
      return res.data as IProject;
    } catch (error) {
      console.error(`Could not get project by ${id}, cause: ${error}`);
    }
  }

  async getTotalAllocated(id: number | undefined): Promise<number> {
    const result = await this.http.get(`/total/${id}`);
    return result.data;
  }

  async publishProject(id: number | undefined) {
    await this.http.get(`/publishProject/${id}`);
  }

  async updateProject(project: IProject) {
    await this.http.put('/update', {
      ...project,
    });
  }

  async getAssignSurvey(id: number) {
    const result = await this.http.get(`${id}/survey`);
    return result.data;
  }
}

export default new SchoolService();
