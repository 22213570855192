export enum SurveyOption {
  CREATE = 0,
  EDIT = 1,
}

export enum QuestionRight {
  ONLY_ANSWER = 0,
  ONLY_CREATE_EDIT = 1,
  FULL_RIGHT = 2,
}
