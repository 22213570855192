import { observer } from 'mobx-react';
import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { useStores } from '../stores';
import MainLayout from '../views/app/layout/MainLayout';
import NotFoundError from '../views/error/NotFoundError';
import RouteGuard from './RouteGuard';
import { protectedRoutes, publicRoutes } from './routes';

const Router = () => {
  const { userStore } = useStores();
  const { user } = userStore;

  return (
    <Switch>
      {publicRoutes.map((r, i) => (
        <Route
          exact
          path={r.path}
          component={r.component}
          key={`public-route-${i}`}
        />
      ))}
      {user ? (
        <MainLayout>
          {protectedRoutes.map((r, i) => (
            <Route exact path={r.path} key={`protected-route-${i}`}>
              <RouteGuard roles={r.roles}>
                <r.component />
              </RouteGuard>
            </Route>
          ))}
        </MainLayout>
      ) : null}
      <Route path="*" component={NotFoundError} />
    </Switch>
  );
};

export default observer(Router);
