export enum CourseStatus {
  DRAFT = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  PUBLISHED = 5,
}

export const getStatusDisplay = (status: CourseStatus): string => {
  let statusText = '';
  switch (status) {
    case CourseStatus.DRAFT:
      statusText = 'Kladd';
      break;
    case CourseStatus.ACTIVE:
      statusText = 'Klar';
      break;
    case CourseStatus.PUBLISHED:
      statusText = 'Publisert';
      break;
    case CourseStatus.COMPLETED:
      statusText = 'Gjennomført';
      break;
    case CourseStatus.CANCELLED:
      statusText = 'Avlyst';
      break;
  }

  return statusText;
};
