import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      margin: '10px 0',
    },
  },
}));

export default useStyle;
