import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

interface Props {
  open: boolean;
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  rowWrapper: {
    ...flexCol,
  },
  tableRow: {
    transition: '0.3s ease all',
    borderLeft: '3px solid',
    borderColor: ({ open }: Props) =>
      open ? palette.primary.main : 'transparent',
    backgroundColor: ({ open }: Props) =>
      open ? palette.primary[50] : 'transparent',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.primary[50],
    },
    '& .MuiTableCell-root': {
      padding: '12px 16px',
      [breakpoints.down('sm')]: {
        padding: '4px',
      },
    },
  },
  infoChip: {
    backgroundColor: palette.warning.main + '33',
    color: palette.text.hint,
    borderColor: palette.text.hint,
    height: '25px',
    lineHeight: '25px',
  },
  collapseTableRow: {
    transition: '0.3s ease all',
    borderLeft: '3px solid',
    borderColor: ({ open }: Props) =>
      open ? palette.primary[200] : 'transparent',
    backgroundColor: ({ open }: Props) =>
      open ? palette.primary[50] : 'transparent',
  },
  collapse: {
    padding: '12px 0',
    ...flexRow,
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chipList: {
    ...flexRow,
    gap: '8px',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subjectChip: {
    height: '25px',
    lineHeight: '25px',
  },
  checkboxLabel: {
    height: '25px',
  },
}));

export default useStyles;
