import { useStores } from '@/stores';
import MessageTemplate from '@/types/MessageTemplate';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  EditOutlined,
  MailOutlined,
  PhoneIphoneOutlined,
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import EditMessageTemplateDrawer from './EditMessageTemplateDrawer';
import useStyles from './style';
import AdminHeader from "@/views/app/admin/common/AdminHeader";

const Templates: FC = () => {
  const { messageTemplateStore } = useStores();
  const {
    messageTemplates,
    getAllMessageTemplates,
    setCurrentMessageTemplate,
  } = messageTemplateStore;

  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    getAllMessageTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenEdit = (template: MessageTemplate) => {
    setCurrentMessageTemplate(template);
    setOpenDrawer(true);
  };

  return (
    <>
      <header>
        <AdminHeader title="Meldingsmaler" />
      </header>
      <div className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Send på epost/SMS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {messageTemplates.map((messageTemplate) => (
              <TableRow key={messageTemplate.id}>
                <TableCell>{messageTemplate.name}</TableCell>
                <TableCell>
                  {messageTemplate.sendEmail && <MailOutlined />}{' '}
                  {messageTemplate.sendSMS && <PhoneIphoneOutlined />}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenEdit(messageTemplate)}>
                    <EditOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <EditMessageTemplateDrawer open={openDrawer} setOpen={setOpenDrawer} />
    </>
  );
};

export default observer(Templates);
