import React from 'react';
import { useStyles } from './style';
import clsx from 'clsx';

const LegendBarChart = () => {
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(
          classes.legendBarChartSize,
          classes.displayFlex,
          classes.alignItemsFlexEnd,
          classes.marginLeftAuto
        )}
      >
        <div>
          <div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
            <div
              className={clsx(classes.circleLegend, classes.capacityColumn)}
            ></div>
            <div>Tildelt</div>
          </div>
          <div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
            <div
              className={clsx(classes.circleLegend, classes.joinColumn)}
            ></div>
            <div>Kapasitet</div>
          </div>
          {/* <div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
            <div
              className={clsx(classes.circleLegend, classes.joinOverloadColumn)}
            ></div>
            <div className={classes.legendColorOverload}>Liten kapasitet</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LegendBarChart;
