import { AxiosInstance } from 'axios';
import { newHttpInstance } from '../utils/httpUtils';

class AdminDashboardService {
  projectPrefix = '/api/Project';
  studySubjectPrefix = '/api/StudySubject';
  coursePrefix = '/api/Course';
  schoolPrefix = '/api/School';
  http: AxiosInstance;

  constructor() {
    this.http = newHttpInstance();
  }

  public async adminGetCurrentProject() {
    const result = await this.http.get(`${this.projectPrefix}/current`);
    return result.data;
  }

  public async adminGetStudentStatistic(projectId: number) {
    const result = await this.http.get(
      `${this.studySubjectPrefix}/statistic/${projectId}`
    );
    return result.data;
  }

  public async adminGetStudentCourseStatistic(projectId: number) {
    const result = await this.http.get(
      `${this.coursePrefix}/student-statistic/${projectId}`
    );
    return result.data;
  }

  public async adminGetStudySubjectStatistic(projectId: number) {
    const result = await this.http.get(
      `${this.studySubjectPrefix}/capacity-statistic/${projectId}`
    );
    return result.data;
  }

  public async adminGetSubjectSchoolInfo(studySubjectId: number) {
    const result = await this.http.get(
      `${this.schoolPrefix}/studySubject/${studySubjectId}`
    );
    return result.data;
  }
}

export default new AdminDashboardService();
