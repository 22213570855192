import { makeStyles } from '@material-ui/core';

const useVGSStudentInfoStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  studentInformationSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    gap: 16,
  },
  studentInformationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
}));

export default useVGSStudentInfoStyles;
