import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    ...flexCol,
  },
  dateButton: {
    backgroundColor: palette.grey[50],
    height: '52px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: palette.grey[200],
    },
    '& .MuiButton-label': {
      color: palette.primary.main,
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
  },
}));

export default useStyles;
