import { useStores } from '@/stores';
import { emptyStudySubject, StudySubjectCategory } from '@/types/StudySubject';
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ChangeEvent, FormEventHandler } from 'react';
import StudySubjectCategoryChip from '../StudySubjectCategoryChip';
import useStyles from './style';

const StudySubjectDrawer = () => {
  const { stateStore, studySubjectStore, errorStore } = useStores();
  const {
    chosenStudySubject,
    setChosenStudySubject,
    updateStudySubject,
    addStudySubject,
    getAllStudySubjects,
  } = studySubjectStore;
  const { studySubjectDrawerIsOpen, setStudySubjectDrawerIsOpen } = stateStore;
  const { showErrorMessage } = errorStore;

  const classes = useStyles();

  const setChosenStudySubjectCategory = (category: StudySubjectCategory) => {
    setChosenStudySubject({
      ...chosenStudySubject,
      category,
    });
  };

  const setChosenStudySubjectName = (e: ChangeEvent<HTMLInputElement>) => {
    setChosenStudySubject({
      ...chosenStudySubject,
      studySubjectName: e.target.value,
    });
  };

  const setChosenStudySubjectDescription = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setChosenStudySubject({
      ...chosenStudySubject,
      description: e.target.value,
    });
  };

  const setChosenStudySubjectDeactivated = (e: any) => {
    setChosenStudySubject({
      ...chosenStudySubject,
      deactivated: e.target.checked,
    });
  };

  const setChosenStudySubjectHidden = (e: any) => {
    setChosenStudySubject({
      ...chosenStudySubject,
      isHide: e.target.checked,
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!chosenStudySubject.studySubjectName) {
      showErrorMessage('Fyll inn navn');
      return;
    }

    if (!chosenStudySubject.description) {
      showErrorMessage('Fyll inn beskrivelse');
      return;
    }

    if (!chosenStudySubject.category) {
      showErrorMessage('Velg utdanningstype');
      return;
    }

    try {
      if (chosenStudySubject.id) {
        await updateStudySubject(chosenStudySubject);
      } else {
        await addStudySubject(chosenStudySubject);
      }
      setStudySubjectDrawerIsOpen(false);
      setChosenStudySubject(emptyStudySubject);
      await getAllStudySubjects(true);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleClose = () => {
    setStudySubjectDrawerIsOpen(false);
    setChosenStudySubject(emptyStudySubject);
  };

  return (
    <Drawer
      open={studySubjectDrawerIsOpen}
      onClose={handleClose}
      anchor="right"
    >
      <header className={classes.header}>
        <Typography variant="h3">
          {chosenStudySubject.id ? 'Rediger kurs' : 'Opprett kurs'}
        </Typography>
      </header>
      <form className={classes.main} onSubmit={handleSubmit}>
        <Typography variant="h4">Velg utdanningstype</Typography>
        <div className={classes.rowFlex}>
          <StudySubjectCategoryChip
            category={StudySubjectCategory.Studieforberedende}
            isActive={
              chosenStudySubject.category ===
              StudySubjectCategory.Studieforberedende
            }
            setCurrentCategory={setChosenStudySubjectCategory}
          />
          <StudySubjectCategoryChip
            category={StudySubjectCategory.Yrkesfaglig}
            isActive={
              chosenStudySubject.category === StudySubjectCategory.Yrkesfaglig
            }
            setCurrentCategory={setChosenStudySubjectCategory}
          />
        </div>
        <TextField
          label="Navn"
          value={chosenStudySubject.studySubjectName}
          onChange={setChosenStudySubjectName}
        />
        <TextField
          label="Beskrivelse"
          multiline
          rows={10}
          value={chosenStudySubject.description}
          onChange={setChosenStudySubjectDescription}
        />
        <FormControlLabel
          label="Skjul for påmelding "
          onChange={(e: any) => setChosenStudySubjectHidden(e)}
          checked={chosenStudySubject.isHide}
          control={<Checkbox name="studySubjectHidden" color="primary" />}
        />
        <FormControlLabel
          label="Deaktivert"
          onChange={(e: any) => setChosenStudySubjectDeactivated(e)}
          checked={chosenStudySubject.deactivated}
          control={<Checkbox name="studySubjectDeactivated" color="primary" />}
        />
        <div className={classes.rowFlex}>
          <Button type="submit">
            {chosenStudySubject.id ? 'Lagre' : 'Opprett'}
          </Button>
          <Button variant="text">Avbryt</Button>
        </div>
      </form>
    </Drawer>
  );
};

export default observer(StudySubjectDrawer);
