import { makeStyles } from '@material-ui/core';

const evaluationThankStyle = makeStyles(({ palette, breakpoints }) => ({
  rootGrid: {
    height: '100vh',
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.primary.light, //TODO - Change to primary['50'] once UK-24 is merged
    maxHeight: '100%',
  },
  bannerHeaderText: {
    color: palette.primary.dark,
    fontWeight: 500,
    marginTop: 'calc(100vh * 0.1)',
    [breakpoints.down('sm')]: {
      marginTop: 'calc(100vh * 0.03)',
    },
  },
  loginIllustration: {
    width: '100%',
    height: 'auto',
  },
}));

export default evaluationThankStyle;
