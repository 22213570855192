import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { IProject } from '../../../types/Project';

const useStyles = makeStyles(() => ({
  itemText: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface IComponentProps {
  project: IProject;

  goToCourse: (id?: number) => void;
}
const Component = ({ project, goToCourse }: IComponentProps) => {
  const classes = useStyles();
  return (
    <>
      <ListItem onClick={() => goToCourse(project.id)}>
        <ListItemText inset className={classes.itemText}>
          {project.projectName}
        </ListItemText>
      </ListItem>
    </>
  );
};

export const LeftDrawerProjectItem = Component;
