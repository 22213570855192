import { observer } from 'mobx-react';
import React from 'react';
import clsx from 'clsx';
import { Box, Button, Collapse, TableCell, TableRow } from '@material-ui/core';
import { IUserDto } from '@/types/User';
import { useStores } from '@/stores';
import SearchIcon from '@material-ui/icons/Search';
import { IStudentStudySubject } from '@/types/StudySubject';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';

interface IComponentProps {
  row: IUserDto;
  handleOpenManualAssignDialog: () => void;
}

const Row = (props: IComponentProps) => {
  const { row, handleOpenManualAssignDialog } = props;
  const courseClasses = courseStyle();
  const { studySubjectStore, userStore } = useStores();
  const [open, setOpen] = React.useState<boolean>(false);
  const [studentStudySubjects, setStudentStudySubjects] = React.useState<
    IStudentStudySubject[]
  >([]);

  const handleCloseStudent = async (
    open: boolean,
    id: number
  ): Promise<void> => {
    if (open) {
      await studySubjectStore.getStudySubjectsByUserId(id);
      setStudentStudySubjects(studySubjectStore.studentStudySubjects);
    }
    setOpen(open);
  };

  return (
    <>
      <React.Fragment>
        <TableRow
          key={`student_${row.id}`}
          className={clsx({
            [courseClasses.rowCourseDetail]: open,
          })}
        >
          <TableCell
            classes={{
              root: clsx(courseClasses.padding0, courseClasses.borderTable, {
                [courseClasses.borderTableActive]: open,
              }),
            }}
          ></TableCell>
          <TableCell
            classes={{
              root: clsx(
                courseClasses.checkboxPadding,
                courseClasses.widthCheckbox
              ),
            }}
          >
            <CustomCheckBox />
          </TableCell>
          <TableCell
            classes={{
              root: clsx(
                courseClasses.buttonDetailPadding,
                courseClasses.widthButtonDetail
              ),
            }}
          >
            <CustomIconButton
              open={open}
              courseId={row.id}
              handleCloseDetail={handleCloseStudent}
            />
          </TableCell>
          <TableCell
            className={clsx(
              courseClasses.verticalAlignTop,
              courseClasses.textStyle,
              courseClasses.whiteSpaceNowrap
            )}
          >
            <div
              className={clsx(
                courseClasses.heightText,
                courseClasses.marginBottom6
              )}
            >
              <p className={clsx(courseClasses.fontWeight500)}>
                {row.fullName}
              </p>
            </div>
            <div className={courseClasses.heightText}>
              <p>{`${row.school?.schoolName} ${row.class?.fullName}`}</p>
            </div>
          </TableCell>
          <TableCell
            classes={{
              root: clsx(
                courseClasses.paddingLeft4,
                courseClasses.verticalAlignBottom
              ),
            }}
          >
            <SearchIcon classes={{ root: courseClasses.colorIconSearch }} />
          </TableCell>
        </TableRow>
        <TableRow
          key={`student_detail_${row.id}`}
          className={courseClasses.rowCourseDetail}
        >
          <TableCell
            classes={{
              root: clsx(courseClasses.padding0, courseClasses.borderTable),
            }}
          ></TableCell>
          <TableCell colSpan={4} className={courseClasses.padding0}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box key={`box_student_detail_${row.id}`}>
                <div
                  className={clsx(
                    courseClasses.contentStudentStudyBox,
                    courseClasses.contentStudentStudyBoxPadding
                  )}
                >
                  {studentStudySubjects.map((sss) => (
                    <div
                      key={sss.id}
                      className={clsx(
                        courseClasses.backgroundColorFFF,
                        courseClasses.contentStudentStudy
                      )}
                    >
                      {`${sss.priority}. ${sss.studySubject.studySubjectName}`}
                    </div>
                  ))}
                  <Button
                    onClick={() => {
                      userStore.studentAspiration = row;
                      handleOpenManualAssignDialog();
                    }}
                  >
                    Tildel elev
                  </Button>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
};

export default observer(Row);
