import { User } from '@/types/User';
import React from 'react';
import clsx from 'clsx';
import userSettingStyle from '../../style';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Tooltip, Switch } from '@material-ui/core';
import { observer } from 'mobx-react';

interface IComponentProps {
  user: User;
  updateIsActive: (id: number, isActive: boolean) => Promise<void>;
  chooseUser: (id: number) => void;
  userId: number | undefined;
  handleOpenDialogDeleteUser: (id: number) => void;
}

const UserItem = (props: IComponentProps) => {
  const {
    user,
    updateIsActive,
    chooseUser,
    userId,
    handleOpenDialogDeleteUser,
  } = props;
  const classes = userSettingStyle();
  const fullNameRef = React.createRef<HTMLDivElement>();
  const emailRef = React.createRef<HTMLDivElement>();
  const phoneRef = React.createRef<HTMLDivElement>();

  const [isOverflowFullName, setIsOverflowFullName] =
    React.useState<boolean>(false);
  const [isOverflowEmail, setIsOverflowEmail] = React.useState<boolean>(false);
  const [isOverflowPhone, setIsOverflowPhone] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    if (
      fullNameRef.current &&
      fullNameRef.current.clientWidth < fullNameRef.current.scrollWidth
    ) {
      setIsOverflowFullName(true);
    }

    if (
      emailRef.current &&
      emailRef.current.clientWidth < emailRef.current.scrollWidth
    ) {
      setIsOverflowEmail(true);
    }

    if (
      phoneRef.current &&
      phoneRef.current.clientWidth < phoneRef.current.scrollWidth
    ) {
      setIsOverflowPhone(true);
    }
  }, [fullNameRef, emailRef, phoneRef]);

  const beforeHandleDelete = (
    id: number,
    event: React.MouseEvent<HTMLElement>
  ): void => {
    event.stopPropagation();
    handleOpenDialogDeleteUser(id);
  };

  return (
    <>
      <div
        className={clsx(
          classes.boxUserItem,
          classes.backgroundColorFFF,
          classes.displayFlex,
          classes.alignItemsCenter,
          {
            [classes.chooseUser]: userId === user.id,
          }
        )}
        onClick={() => chooseUser(user.id)}
      >
        <Tooltip
          title={user.fullName}
          disableHoverListener={!isOverflowFullName}
          classes={{
            tooltip: clsx(
              classes.margin0,
              classes.padding0,
              classes.backgroundColorTransparent,
              classes.tooltipBox,
              classes.tooltipBoxFullName
            ),
          }}
        >
          <div
            ref={fullNameRef}
            className={clsx(
              classes.userItemFullName,
              classes.textOverflow,
              classes.userItemSpace
            )}
          >
            {user.fullName}
          </div>
        </Tooltip>

        <div
          className={clsx(
            classes.displayFlex,
            classes.alignItemsCenter,
            classes.userItemInfo,
            classes.userItemSpace
          )}
        >
          <EmailOutlinedIcon classes={{ root: classes.iconSize }} />
          <Tooltip
            title={user.emailAddress}
            disableHoverListener={!isOverflowEmail}
            classes={{
              tooltip: clsx(
                classes.margin0,
                classes.padding0,
                classes.backgroundColorTransparent,
                classes.tooltipBox
              ),
            }}
          >
            <div
              ref={emailRef}
              className={clsx(
                classes.userItemEmail,
                classes.textOverflow,
                classes.userItemTextSpace
              )}
            >
              <span className={clsx(classes.userItemText, classes.heightFull)}>
                {user.emailAddress}
              </span>
            </div>
          </Tooltip>
        </div>

        <div
          className={clsx(
            classes.displayFlex,
            classes.alignItemsCenter,
            classes.userItemInfo,
            classes.userItemSpace
          )}
        >
          <PhoneIphoneOutlinedIcon classes={{ root: classes.iconSize }} />
          <Tooltip
            title={user.phoneNumber}
            disableHoverListener={!isOverflowPhone}
            classes={{
              tooltip: clsx(
                classes.margin0,
                classes.padding0,
                classes.backgroundColorTransparent,
                classes.tooltipBox
              ),
            }}
          >
            <div
              ref={phoneRef}
              className={clsx(
                classes.userItemPhone,
                classes.textOverflow,
                classes.userItemTextSpace
              )}
            >
              <span className={clsx(classes.userItemText, classes.heightFull)}>
                {user.phoneNumber}
              </span>
            </div>
          </Tooltip>
        </div>

        <div
          className={clsx(
            classes.marginLeftAuto,
            classes.displayFlex,
            classes.alignItemsCenter
          )}
        >
          <div
            className={clsx(classes.buttonDelete, classes.pointer)}
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              beforeHandleDelete(user.id, event)
            }
          >
            <DeleteForeverOutlinedIcon />
          </div>

          <div
            className={clsx(classes.pointer, classes.buttonChoose, {
              [classes.buttonChooseActive]: userId === user.id,
            })}
            onClick={() => chooseUser(user.id)}
          >
            <CreateOutlinedIcon />
          </div>

          <Switch
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) =>
              await updateIsActive(user.id, event.target.checked as boolean)
            }
            checked={user.isActive}
            color="default"
            classes={{
              switchBase: classes.switchBase,
              track: classes.switchTrack,
            }}
            className={classes.switch}
            inputProps={{
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default observer(UserItem);
