import MessageTemplate from './MessageTemplate';
import { User } from './User';

interface IMessage {
  id: number;
  templateId: number;
  template: MessageTemplate;
  userId: number;
  user: User;
  projectId: number;
  courseId: number;
  smsStatus: MessageStatus;
  emailStatus: MessageStatus;
  emailSubject: string;
  emailBody: string;
  sms: string;
  createdDate?: Date;
}

export default IMessage;

export enum MessageStatus {
  NotSent = 0,
  Sent = 1,
  Failed = 2,
}
