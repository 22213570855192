import { useStores } from '@/stores';
import { User } from '@/types/User';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStudentInformationStyle from './style';

const StudentInformation = () => {
  const classes = useStudentInformationStyle();
  const { schoolStore, userStore, errorStore } = useStores();
  const { currentSchool } = schoolStore;
  const { user } = userStore;
  const { showErrorMessage } = errorStore;
  const history = useHistory();

  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentSchool) schoolStore.getSchoolByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedUser = { ...user } as User;

    if (phonenumber !== '') updatedUser.phoneNumber = phonenumber;
    if (email !== '') updatedUser.emailAddress = email;

    setLoading(true);

    try {
      await userStore.updateUser(updatedUser);
      history.push('/home');
      setLoading(false);
    } catch (error) {
      showErrorMessage('Problem updating personal information.');
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <header>
        <Typography variant="h3">
          Hei og velkommen til kurs i utdanningsprogram.
        </Typography>
        <Typography variant="body1">
          Vennligst se over at din kontaktinformasjon er korrekt.
        </Typography>
      </header>
      <main>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Typography variant="h4">Dine opplysninger</Typography>
          <TextField disabled value={currentSchool?.schoolName} label="Skole" />
          <TextField label="Navn" disabled defaultValue={user?.fullName} />
          <TextField
            label="Mobil"
            required
            defaultValue={user?.phoneNumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
          <TextField
            label="E-post"
            type="email"
            required
            defaultValue={user?.emailAddress}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" disabled={loading}>
            Neste
            {loading && (
              <CircularProgress size={24} className={classes.loadingButton} />
            )}
          </Button>
        </form>
      </main>
    </div>
  );
};

export default StudentInformation;
