export enum MessageTemplateType {
  Both = 1,
  Student = 2,
  Teacher = 3,
}

export const getMessageTemplateTypeDisplay = (
  recipientType: MessageTemplateType
): string => {
  let statusText = '';
  switch (recipientType) {
    case MessageTemplateType.Both:
      statusText = 'Begge';
      break;
    case MessageTemplateType.Student:
      statusText = 'Elev';
      break;
    case MessageTemplateType.Teacher:
      statusText = 'Lærer';
      break;
  }

  return statusText;
};
