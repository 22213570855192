import React from 'react';
import ICourse from '@/types/Course';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { getDateTime } from '@/utils/timeUtils';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { SELECT_ALL, TAKE_STUDENT } from '@/types/dto/PaginationRequest.dto';
import clsx from 'clsx';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';
import CapacityCourse from '@/components/CapacityCourse';
import ButtonCourseStatus from '@/components/ButtonCourseStatus';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { IUserDto } from '@/types/User';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import PriorityStudent from './priority';
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';

interface IComponentProps {
  row: ICourse;
  colSpan: number;
  chooseCourse: (id: number) => void;
  chooseStudent: (courseId: number, studentId: number) => void;
  editCourse: (id: number) => void;
  deleteCourse: (id: number) => Promise<void>;
  duplicateCourse: (id: number) => Promise<void>;
  checkBoxChooseCourse: (isChecked: boolean, courseId: number) => void;
  vgsCourseId: number;
  chooseCourseShowInfoVGS: (courseId: number) => void;
  //loadingDetail: boolean;
  handleOpenMovingMulDialog: (studentIds: number[], course: ICourse) => void;
}

const Row = (props: IComponentProps) => {
  const {
    row,
    colSpan,
    chooseCourse,
    chooseStudent,
    editCourse,
    deleteCourse,
    duplicateCourse,
    checkBoxChooseCourse,
    vgsCourseId,
    chooseCourseShowInfoVGS,
    //loadingDetail,
    handleOpenMovingMulDialog,
  } = props;
  const { courseStore } = useStores();

  const courseClasses = courseStyle();

  const [open, setOpen] = React.useState<boolean>(false);
  const [students, setStudents] = React.useState<IUserDto[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [skip, setSkip] = React.useState<number>(0);
  const [take] = React.useState<number>(TAKE_STUDENT);
  const [courseId, setCourseId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const _getStudentsForDetailCourse = async (
    courseId: number,
    newSkip?: number
  ): Promise<void> => {
    setStudents([]);
    setTotal(0);

    setLoading(true);
    const result = await courseStore.getStudentsForDetailCourse(courseId, {
      skip: newSkip !== undefined || newSkip !== null ? newSkip : skip,
      take,
    });
    setLoading(false);

    setStudents(result.data);
    setTotal(result.count);
  };

  const handleCloseCourseDetail = async (
    open: boolean,
    id: number,
    event?: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    event?.stopPropagation();
    setOpen(open);

    if (open) {
      setCourseId(id);
      await _getStudentsForDetailCourse(id);
    }
  };

  // React.useEffect(() => {
  //   const getDetail = async () => {
  //     if (open && loadingDetail) {
  //       await _getStudentsForDetailCourse(courseId);
  //     }
  //   };
  //   getDetail();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadingDetail]);

  const [checkBoxChooseAllDetail, setCheckBoxChooseAllDetail] =
    React.useState<string>('');
  const [studentIds, setStudentIds] = React.useState<number[]>([]);

  const checkBoxChooseAllDetailCourse = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const ids = students.map((s) => (s.id ? s.id : 0));
      setStudentIds(ids);
      setCheckBoxChooseAllDetail(SELECT_ALL);
    } else {
      setStudentIds([]);
      setCheckBoxChooseAllDetail('');
    }
  };

  const checkBoxChooseDetailCourse = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isChecked = event.target.checked;
    const studentId = +event.target.value;

    if (isChecked) {
      const newStudentIds = [...studentIds, studentId];
      setStudentIds(newStudentIds);
      if (newStudentIds.length === students.length) {
        setCheckBoxChooseAllDetail(SELECT_ALL);
      }
    } else {
      const index = studentIds.indexOf(studentId);
      if (index > -1) {
        studentIds.splice(index, 1);
        setStudentIds([...studentIds]);

        if (checkBoxChooseAllDetail === SELECT_ALL) {
          setCheckBoxChooseAllDetail('');
        }
      }
    }
  };

  const isCheckStudent = (studentId?: number): boolean => {
    if (studentId) {
      const id = studentIds.find((id) => id === studentId);

      if (id && id > 0) {
        return true;
      }
    }
    return false;
  };

  const getPrevStudent = async (): Promise<void> => {
    if (skip - take >= 0) {
      setSkip(skip - take);
      await _getStudentsForDetailCourse(courseId, skip - take);
    }
  };

  const getNextStudent = async (): Promise<void> => {
    if (skip + take < total) {
      setSkip(skip + take);
      await _getStudentsForDetailCourse(courseId, skip + take);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const beforeHandleEdit = (
    event: React.MouseEvent<HTMLElement>,
    courseId: number
  ): void => {
    event.stopPropagation();
    editCourse(courseId);
    handleCloseMenu();
  };

  const beforeHandleDuplicate = async (
    event: React.MouseEvent<HTMLElement>,
    courseId: number
  ): Promise<void> => {
    try {
      event.stopPropagation();
      await duplicateCourse(courseId);
    } catch (error) {
      console.log(`Error duplicate course: ${courseId}, cause: ${error}`);
    } finally {
      handleCloseMenu();
    }
  };

  const beforeHandleDelete = async (
    event: React.MouseEvent<HTMLElement>,
    courseId: number
  ): Promise<void> => {
    try {
      event.stopPropagation();
      await deleteCourse(courseId);
    } catch (error) {
      console.log(`Error delete course: ${courseId}, cause: ${error}`);
    } finally {
      handleCloseMenu();
    }
  };

  const beforeHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation();
    checkBoxChooseCourse(event.target.checked, +event.target.value);
  };

  const isCheck = (courseId: number): boolean => {
    const id = courseStore.courseIds.find((id) => id === courseId);

    if (id && id > 0) {
      return true;
    }
    return false;
  };

  const beforeChooseCourseShowInfoVGS = (
    event: React.MouseEvent<HTMLElement>,
    courseId: number
  ): void => {
    event.stopPropagation();
    chooseCourseShowInfoVGS(row.id);
  };

  return (
    <React.Fragment>
      <TableRow
        key={`course_${row.id}`}
        className={clsx({
          [courseClasses.rowCourseDetail]:
            open || row.id === courseStore.courseId,
        })}
        onClick={() => chooseCourse(row.id)}
      >
        <TableCell
          classes={{
            root: clsx(courseClasses.padding0, courseClasses.borderTable, {
              [courseClasses.borderTableActive]:
                open || row.id === courseStore.courseId,
            }),
          }}
        ></TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.checkboxPadding,
              courseClasses.widthCheckbox
            ),
          }}
        >
          <CustomCheckBox
            value={row.id}
            isChecked={isCheck(row.id)}
            handleChange={beforeHandleChange}
          />
        </TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.buttonDetailPadding,
              courseClasses.widthButtonDetail
            ),
          }}
        >
          <CustomIconButton
            open={open}
            courseId={row.id}
            handleCloseDetail={handleCloseCourseDetail}
          />
        </TableCell>
        <TableCell
          className={clsx(
            courseClasses.verticalAlignTop,
            courseClasses.textStyle
          )}
          classes={{
            root: clsx(courseClasses.tableCellStudySubject),
          }}
        >
          <div
            className={clsx(
              courseClasses.heightText,
              courseClasses.marginBottom6,
              courseClasses.displayContent
            )}
          >
            <p className={courseClasses.fontWeight500}>
              {row.studySubject?.studySubjectName
                ? row.studySubject.studySubjectName
                : 'Ikke definert'}
            </p>
          </div>
          <CapacityCourse
            showVacant={true}
            capacity={row.capacity}
            vacant={row.availableCapacity}
          />
        </TableCell>
        <TableCell
          className={clsx(
            courseClasses.verticalAlignTop,
            courseClasses.textStyle,
            courseClasses.whiteSpaceNowrap
          )}
          classes={{
            root: clsx(courseClasses.tableCellTime),
          }}
        >
          <div className={courseClasses.marginBottom6}>
            <p
              className={clsx(
                courseClasses.fontWeight500,
                courseClasses.textTransformLowercase,
                courseClasses.margin0
              )}
            >
              {getDateTime(row.startDate, 'dd. LLL.')}
            </p>
          </div>
          <div className={courseClasses.heightText}>
            <p>
              {`${getDateTime(row.startDate, 'HH:mm')} - ${getDateTime(
                row.endDate,
                'HH:mm'
              )}`}
            </p>
          </div>
        </TableCell>
        <TableCell
          className={clsx(
            courseClasses.verticalAlignTop,
            courseClasses.textStyle
          )}
          classes={{
            root: courseClasses.tableCellHighSchool,
          }}
        >
          <div className={courseClasses.marginBottom6}>
            <p
              className={clsx(
                courseClasses.fontWeight500,
                courseClasses.margin0
              )}
            >
              {row.school?.schoolName}
            </p>
          </div>
          <div className={courseClasses.heightText}>
            <p>
              {row.courseHolder?.fullName
                ? row.courseHolder.fullName
                : 'Ikke definert'}
            </p>
          </div>
        </TableCell>
        <TableCell>
          <ButtonCourseStatus status={row.status} />
        </TableCell>
        <TableCell>
          <AccessibilityNewIcon
            className={clsx({
              [courseClasses.visibility]: !row.hasSpecialNeeds,
            })}
          />
        </TableCell>
        <TableCell>
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.justifyContentFlexEnd,
              courseClasses.alignItemsCenter,
              courseClasses.whiteSpaceNowrap
            )}
          >
            <div
              className={clsx(
                courseClasses.pointer,
                courseClasses.buttonChooseStudent,
                {
                  [courseClasses.buttonChooseStudentActive]:
                    row.id === vgsCourseId,
                }
              )}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                beforeChooseCourseShowInfoVGS(event, row.id)
              }
            >
              <InsertDriveFileOutlinedIcon />
            </div>

            <>
              <div
                aria-controls={`fade-menu-${row.id}`}
                aria-haspopup="true"
                onClick={handleOpenMenu}
                className={clsx(courseClasses.pointer)}
              >
                <MoreVertOutlinedIcon />
              </div>
              <Menu
                id={`fade-menu-${row.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
              >
                <MenuItem
                  key="edit"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    beforeHandleEdit(event, row.id)
                  }
                >
                  Rediger
                </MenuItem>
                <MenuItem
                  key="duplicate"
                  onClick={async (event: React.MouseEvent<HTMLElement>) =>
                    await beforeHandleDuplicate(event, row.id)
                  }
                >
                  Dupliser
                </MenuItem>
                <MenuItem
                  key="delete"
                  onClick={async (event: React.MouseEvent<HTMLElement>) =>
                    await beforeHandleDelete(event, row.id)
                  }
                >
                  Slett
                </MenuItem>
              </Menu>
            </>
          </div>
        </TableCell>
      </TableRow>
      <TableRow key={`course_detail_${row.id}`}>
        <TableCell colSpan={colSpan} className={courseClasses.padding0}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box key={`box_course_detail_${row.id}`}>
              <Table key={`table_course_detail_${row.id}`}>
                <TableHead className={courseClasses.rowColSpan}>
                  <TableRow>
                    <TableCell
                      classes={{
                        root: clsx(
                          courseClasses.padding0,
                          courseClasses.borderTable
                        ),
                      }}
                    ></TableCell>
                    <TableCell
                      classes={{
                        root: clsx(
                          courseClasses.widthCheckbox,
                          courseClasses.checkboxPadding
                        ),
                      }}
                    >
                      <CustomCheckBox
                        value={SELECT_ALL}
                        isChecked={checkBoxChooseAllDetail === SELECT_ALL}
                        handleChange={checkBoxChooseAllDetailCourse}
                      />
                    </TableCell>
                    <TableCell
                      classes={{
                        root: clsx(
                          courseClasses.widthButtonDetail,
                          courseClasses.buttonDetailPadding
                        ),
                      }}
                    >
                      <CustomIconButton hide={true} />
                    </TableCell>
                    <TableCell
                      className={clsx(
                        courseClasses.colorDetail,
                        courseClasses.paddingHeaderDetailCourse,
                        courseClasses.textTransformUppercase
                      )}
                      classes={{
                        root: clsx(courseClasses.tableCellStudySubject),
                      }}
                    >
                      Skole
                    </TableCell>
                    <TableCell
                      classes={{ root: courseClasses.tableCellTime }}
                      className={clsx(
                        courseClasses.colorDetail,
                        courseClasses.paddingHeaderDetailCourse,
                        courseClasses.textTransformUppercase
                      )}
                    >
                      Klasse
                    </TableCell>
                    <TableCell
                      className={clsx(
                        courseClasses.colorDetail,
                        courseClasses.paddingHeaderDetailCourse,
                        courseClasses.textTransformUppercase
                      )}
                      classes={{
                        root: courseClasses.tableCellHighSchool,
                      }}
                    >
                      Elev
                    </TableCell>
                    <TableCell
                      className={clsx(
                        courseClasses.colorDetail,
                        courseClasses.paddingHeaderDetailCourse,
                        courseClasses.textTransformUppercase
                      )}
                    >
                      Prioritert
                    </TableCell>
                    <TableCell
                      className={courseClasses.paddingHeaderDetailCourse}
                    >
                      <div
                        className={clsx(
                          courseClasses.displayFlex,
                          courseClasses.justifyContentFlexEnd
                        )}
                      >
                        <Button
                          disabled={studentIds.length === 0}
                          variant="contained"
                          color="primary"
                          startIcon={
                            <SwapHorizOutlinedIcon style={{ color: '#fff' }} />
                          }
                          onClick={() =>
                            handleOpenMovingMulDialog(studentIds, row)
                          }
                        >
                          Flytt elev
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={courseClasses.rowCourseDetail}>
                  {!loading ? (
                    <>
                      {students.length > 0 ? (
                        students.map((student) => (
                          <TableRow
                            key={`${student.id}_${
                              student.school ? student.school.externalId : ''
                            }_${student.class ? student.class.externalId : ''}`}
                            onClick={() => {
                              if (student.id) {
                                chooseStudent(row.id, student.id);
                              }
                            }}
                          >
                            <TableCell
                              classes={{
                                root: clsx(
                                  courseClasses.padding0,
                                  courseClasses.borderTable,
                                  courseClasses.borderTableDetailActive
                                ),
                              }}
                            ></TableCell>
                            <TableCell
                              classes={{
                                root: clsx(
                                  courseClasses.widthCheckbox,
                                  courseClasses.checkboxPadding,
                                  courseClasses.paddingRowDetail
                                ),
                              }}
                            >
                              <CustomCheckBox
                                value={student.id}
                                isChecked={isCheckStudent(student.id)}
                                handleChange={checkBoxChooseDetailCourse}
                              />
                            </TableCell>
                            <TableCell
                              classes={{
                                root: clsx(
                                  courseClasses.widthButtonDetail,
                                  courseClasses.buttonDetailPadding,
                                  courseClasses.paddingRowDetail
                                ),
                              }}
                            >
                              <CustomIconButton hide={true} />
                            </TableCell>
                            <TableCell
                              className={clsx(
                                courseClasses.textStyle,
                                courseClasses.whiteSpaceNowrap
                              )}
                            >
                              {student.school
                                ? student.school.schoolName
                                : 'Ikke definert'}
                            </TableCell>
                            <TableCell
                              className={clsx(
                                courseClasses.textStyle,
                                courseClasses.whiteSpaceNowrap
                              )}
                            >
                              {student.class
                                ? student.class.fullName
                                : 'Ikke definert'}
                            </TableCell>
                            <TableCell
                              className={clsx(
                                courseClasses.textStyle,
                                courseClasses.whiteSpaceNowrap
                              )}
                              classes={{
                                root: courseClasses.tableCellHighSchool,
                              }}
                            >
                              {student.fullName}
                            </TableCell>
                            <TableCell>
                              <PriorityStudent
                                studentStudySubjects={
                                  student.studentStudySubjects
                                }
                              />
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <>
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Typography variant="h3">
                                Det er ingen elever i dette kurset enda.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div
                            className={clsx(
                              courseClasses.displayFlex,
                              courseClasses.justifyContentCenter
                            )}
                          >
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {total > TAKE_STUDENT && (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        classes={{
                          root: clsx(
                            courseClasses.paddingBottom0,
                            courseClasses.paddingTop0
                          ),
                        }}
                      >
                        <div
                          className={clsx(
                            courseClasses.displayFlex,
                            courseClasses.justifyContentFlexEnd
                          )}
                        >
                          <IconButton
                            component="span"
                            disabled={skip <= 0}
                            onClick={async () => await getPrevStudent()}
                            className={courseClasses.pointer}
                          >
                            <ArrowBackIosOutlined />
                          </IconButton>

                          <IconButton
                            component="span"
                            disabled={total <= skip + take}
                            onClick={async () => await getNextStudent()}
                            className={courseClasses.pointer}
                          >
                            <ArrowForwardIosOutlined />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default observer(Row);
