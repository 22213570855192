import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  chip: {
    transition: 'background-color ease 0.25s',
    '&:hover': {
      backgroundColor: palette.primary[50],
      cursor: 'pointer',
    },
  },
  activeChip: {
    backgroundColor: palette.primary.main,
    color: '#fff',
  },
}));

export default useStyles;
