import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import React from 'react';
import clsx from 'clsx';
import courseStyle from '@/views/style/courses';
import CustomAutoSearchStudent from '@/components/CustomAutoSearchStudent';
import { Button, Divider, Switch, Typography } from '@material-ui/core';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import { getDateTime } from '@/utils/timeUtils';
import CustomEditor from '@/components/CustomEditor';
import useVGSStudentInfoStyles from './style';
import {
  PhoneIphoneOutlined,
  EmailOutlined,
  InfoOutlined,
  LocationOnOutlined,
  EventOutlined,
  SchoolOutlined,
} from '@material-ui/icons';
import { CreateOrUpdateStudentCourseDto } from '@/types/StudentCourse';
import { CourseStatus } from '@/types/enums/CourseStatus';
interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
}

const InfoStudent = ({ search, setSearch }: IComponentProps) => {
  const {
    userStore,
    courseStore,
    studentCourseStore,
    errorStore,
    projectStore,
  } = useStores();

  const courseClasses = courseStyle();
  const classes = useVGSStudentInfoStyles();

  const handleUpdateStudentCourse = async (): Promise<void> => {
    if (userStore.student && userStore.student.studentCourses) {
      let createOrUpdateStudentCourseDto = new CreateOrUpdateStudentCourseDto();
      createOrUpdateStudentCourseDto = {
        ...createOrUpdateStudentCourseDto,
        ...userStore.student.studentCourses[0],
      };

      if (createOrUpdateStudentCourseDto.id) {
        const result = await studentCourseStore.updateStudentCourse(
          createOrUpdateStudentCourseDto.id,
          createOrUpdateStudentCourseDto
        );
        if (result) {
          errorStore.showErrorMessage('Oppdatering lagret', 'success');
          if (
            userStore.studentId !== userStore.initStudentId &&
            courseStore.courseId !== courseStore.initCourseId
          ) {
            userStore.getStudentById(userStore.studentId, courseStore.courseId);
          }
        }
      }
    }
  };

  const setAttendance = (attendance: boolean): void => {
    if (userStore.student && userStore.student.studentCourses) {
      const updatedStudent = { ...userStore.student };
      if (!updatedStudent.studentCourses) return;
      const studentCourseIndex = updatedStudent.studentCourses.findIndex(
        (sc) => sc.finalAssignedCourseId === courseStore.courseId
      );

      if (studentCourseIndex < 0) return;

      updatedStudent.studentCourses[studentCourseIndex].attendance =
        !attendance;

      userStore.setStudent(updatedStudent);
    }
  };

  const setNote = (note: string): void => {
    if (userStore.student && userStore.student.studentCourses) {
      userStore.setStudent({
        ...userStore.student,
        studentCourses: [
          // TODO - This will override all courses, and needs to be fixed
          {
            ...userStore.student.studentCourses[0],
            note,
          },
        ],
      });
    }
  };

  const cancelInfoStudent = (): void => {
    if (
      userStore.studentId !== userStore.initStudentId &&
      courseStore.courseId !== courseStore.initCourseId
    ) {
      userStore.getStudentById(userStore.studentId, courseStore.courseId);
    }
  };

  const attendance = userStore.student?.studentCourses?.find(
    (c) => c.finalAssignedCourse?.projectId === projectStore.currentProject?.id
  )?.attendance;

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4">Elev info</Typography>
        <CustomAutoSearchStudent
          search={search}
          setSearch={setSearch}
          setStudentId={(id: number) => userStore.setStudentId(id)}
          listStudent={userStore.listMinifiedStudentOfCourse}
        />
        <Divider
          classes={{
            root: clsx(courseClasses.divider, courseClasses.marginTop10),
          }}
        />
        {userStore.student && courseStore.course && (
          <>
            <div className={classes.studentInformationSection}>
              <Typography variant="h3">{userStore.student.fullName}</Typography>
              {userStore.student.hasSpecialNeeds && (
                <div className={classes.studentInformationItem}>
                  <AccessibilityNew />
                  <Typography variant="body2">
                    Eleven har spesielle behov
                  </Typography>
                </div>
              )}
              <div className={classes.studentInformationItem}>
                <div className={classes.studentInformationItem}>
                  <PhoneIphoneOutlined />
                  <Typography variant="body2">
                    {userStore.student.phoneNumber}
                  </Typography>
                </div>

                <div className={classes.studentInformationItem}>
                  <EmailOutlined />
                  <Typography variant="body2">
                    {userStore.student.emailAddress}
                  </Typography>
                </div>
              </div>
              <div className={classes.studentInformationItem}>
                <InfoOutlined />
                <Typography variant="body2">
                  {`${
                    userStore.student.school
                      ? userStore.student.school.schoolName
                      : 'Ikke definert'
                  } skole klasse ${
                    userStore.student.class
                      ? userStore.student.class.fullName
                      : 'Ikke definert'
                  }`}
                </Typography>
              </div>
            </div>

            <Divider
              classes={{
                root: clsx(courseClasses.divider, courseClasses.marginTop10),
              }}
            />
            <div className={classes.studentInformationSection}>
              <Typography variant="h4">Tildelt arrangement</Typography>
              <div className={classes.studentInformationItem}>
                <LocationOnOutlined />
                <Typography variant="body2">
                  {courseStore.course.school.schoolName}
                </Typography>
              </div>
              <div className={classes.studentInformationItem}>
                <EventOutlined />
                <Typography variant="body2">
                  {`${getDateTime(
                    courseStore.course.startDate,
                    'dd. LLL.'
                  )} ${getDateTime(
                    courseStore.course.startDate,
                    'HH:mm'
                  )} - ${getDateTime(courseStore.course.endDate, 'HH:mm')}`}
                </Typography>
              </div>
              <div className={classes.studentInformationItem}>
                <SchoolOutlined />
                <Typography variant="body2">
                  {courseStore.course.studySubject
                    ? courseStore.course.studySubject.studySubjectName
                    : 'Ikke definert'}
                </Typography>
              </div>
              <div className={classes.studentInformationItem}>
                <PhoneIphoneOutlined />
                <Typography variant="body2">
                  {courseStore.course.courseHolder?.fullName}
                </Typography>
              </div>
              <div className={classes.studentInformationItem}>
                <EmailOutlined />
                <Typography variant="body2">
                  {courseStore.course.courseHolder?.emailAddress}
                </Typography>
              </div>
            </div>

            <Divider
              classes={{
                root: clsx(courseClasses.divider, courseClasses.marginTop10),
              }}
            />

            <div className={classes.studentInformationSection}>
              <Typography variant="h4">Tilbakemelding fra arrangør</Typography>
              <div className={classes.studentInformationItem}>
                <Typography variant="body2">Fravær</Typography>
                <Switch
                  disabled={
                    courseStore.course.status === CourseStatus.CANCELLED
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setAttendance(event.target.checked as boolean)
                  }
                  checked={!attendance}
                  color="default"
                  classes={{
                    switchBase: courseClasses.switchBase,
                    track: courseClasses.switchTrack,
                  }}
                />
              </div>
              <div>
                <Typography variant="h5">Merknad</Typography>
                <CustomEditor
                  disabled={
                    courseStore.course.status === CourseStatus.CANCELLED
                  }
                  content={
                    userStore.student.studentCourses &&
                    userStore.student.studentCourses[0]
                      ? userStore.student.studentCourses[0].note
                      : undefined
                  }
                  setContent={setNote}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {userStore.student && courseStore.course && (
        <div
          className={clsx(
            courseClasses.displayFlex,
            courseClasses.optionButtons
          )}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateStudentCourse}
            disabled={courseStore.course.status === CourseStatus.CANCELLED}
          >
            Lagre
          </Button>
          <Button
            variant="text"
            onClick={cancelInfoStudent}
            style={{ marginLeft: '12px' }}
            disabled={courseStore.course.status === CourseStatus.CANCELLED}
          >
            Avbryt
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(InfoStudent);
