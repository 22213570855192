import Message, { MessageStatus } from '@/types/Message';
import { makeStyles } from '@material-ui/core';
import { PhoneIphoneOutlined, EmailOutlined } from '@material-ui/icons';
import { FC } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  failed: {
    color: palette.error.main,
  },
}));

const MessageStatusIcons: FC<{ message: Message }> = ({ message }) => {
  const classes = useStyles();

  return (
    <>
      {message.smsStatus !== MessageStatus.NotSent && (
        <PhoneIphoneOutlined
          className={
            message.smsStatus === MessageStatus.Failed ? classes.failed : ''
          }
        />
      )}
      {message.emailStatus !== MessageStatus.NotSent && (
        <EmailOutlined
          className={
            message.emailStatus === MessageStatus.Failed ? classes.failed : ''
          }
        />
      )}
    </>
  );
};

export default MessageStatusIcons;
