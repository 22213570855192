import axios from 'axios';
import { IUserDto } from '../types/User';
import http from '../utils/httpUtils';

class AccountService {
  async getFeideRedirect(): Promise<string | undefined> {
    try {
      const res = await http.get('/api/account/GenerateFeideRedirect');
      return res.data;
    } catch (error: any) {
      console.error('Error generating Feide redirect URI: ' + error);
    }
  }

  async register(
    username: string,
    password: string
  ): Promise<IUserDto | undefined> {
    try {
      const res = await http.post('/api/account/register', {
        username,
        password,
      });
      return res.data as IUserDto;
    } catch (error: any) {
      console.error('Error registering user: ' + error);
    }
  }

  async login(
    username: string,
    password: string,
    rememberMe: boolean
  ): Promise<IUserDto> {
    try {
      const res = await http.post('/api/account/login', {
        username,
        password,
        rememberMe,
      });
      return res.data as IUserDto;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        throw Error('Feil brukernavn eller passord.');
      } else {
        throw Error('Server error.');
      }
    }
  }

  async logout(): Promise<void> {
    await http.post('/api/account/logout');
  }

  async oidcCallback(code: string) {
    try {
      await http.get('/api/account/oidc-callback?code=' + code);
    } catch (error) {
      console.error('Error logging in with OIDC code');
      throw error;
    }
  }

  async sendLinkToResetPassword(email: string) {
    try {
      await http.get('/api/account/sendLinkToResetPassword?email=' + email);
    } catch (error) {
      console.error('Server error');
    }
  }

  async resetPassword(
    email: string | null,
    newPassword: string,
    currentTime: string | null,
    token: string | null
  ) {
    try {
      await http.post('/api/account/resetPassword', {
        email,
        newPassword,
        currentTime,
        token,
      });
    } catch (error) {
      console.error('Server error');
    }
  }

  async isValidLink(): Promise<boolean> {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const currentTime = urlParams.get('time');
    const token = urlParams.get('token');
    const result = await http.post('/api/account/checkCorrectLink', {
      email,
      currentTime,
      token,
    });
    return result.data;
  }
}

export default new AccountService();
