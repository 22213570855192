import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  content: {
    padding: '0 115px',
    [breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export default useStyles;
