import { useStores } from '@/stores';
import { IUpdateSchoolDto } from '@/types/School';
import IStudySubject, { ISchoolStudySubject } from '@/types/StudySubject';
import { removeDuplicatesBy } from '@/utils/arrayUtils';
import { Checkbox, Chip, FormControlLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { FormEvent, useEffect } from 'react';
import useStyle from './style';

interface IHighSchoolInformation {
  setLoading: (loading: boolean) => void;
  setTab: (tab: string) => void;
}

const HighSchoolInformation = ({
  setLoading,
  setTab,
}: IHighSchoolInformation) => {
  const classes = useStyle();
  const { studySubjectStore, schoolStore, stateStore, errorStore } =
    useStores();
  const { showErrorMessage } = errorStore;
  const { allStudySubjects } = studySubjectStore;
  const { editingSchool, setEditingSchool } = schoolStore;
  const { setSchoolDrawerIsOpen } = stateStore;

  useEffect(() => {
    studySubjectStore.getAllStudySubjects();
  }, [allStudySubjects.length, studySubjectStore]);

  const handleAutoCompleteChange = (value: (string | IStudySubject)[]) =>
    setChosenPrograms(value);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !editingSchool.studySubjects ||
      editingSchool.studySubjects?.length < 1
    ) {
      showErrorMessage('Vennligst velg minst ett kurs.');
      setTab('info');
      return;
    }

    setLoading(true);

    try {
      if (editingSchool.externalId) {
        await schoolStore.updateSchool(editingSchool as IUpdateSchoolDto);
      } else {
        await schoolStore.createSchool(editingSchool);
      }
      setSchoolDrawerIsOpen(false);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const setSchoolName = (name: string) =>
    setEditingSchool({ ...editingSchool, schoolName: name });
  const setZipCode = (zipCode: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, zipCode },
    });
  const setStreet = (street: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, street },
    });
  const setCity = (city: string) =>
    setEditingSchool({
      ...editingSchool,
      address: { ...editingSchool.address, city },
    });
  const setChosenPrograms = (value: (string | IStudySubject)[]) =>
    setEditingSchool({
      ...editingSchool,
      studySubjects: removeDuplicatesBy<ISchoolStudySubject>(
        (v) => v.studySubjectId,
        value.map((v) => ({
          studySubjectId: (v as IStudySubject).id,
          studySubject: v as IStudySubject,
        }))
      ),
    });

  const setDeactivated = (deactivated: boolean) =>
    setEditingSchool({
      ...editingSchool,
      deactivated,
    });

  return (
    <>
      <form
        id="edit-high-school-form"
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <TextField
          label="Navn"
          required
          onChange={(e) => setSchoolName(e.currentTarget.value)}
          value={editingSchool.schoolName}
        />
        <TextField
          label="Gate"
          required
          onChange={(e) => setStreet(e.currentTarget.value)}
          value={editingSchool.address.street}
        />
        <div className={classes.addressWrapper}>
          <TextField
            label="Postnummer"
            required
            onChange={(e) => setZipCode(e.currentTarget.value)}
            value={editingSchool.address.zipCode}
          />
          <TextField
            label="By"
            required
            onChange={(e) => setCity(e.currentTarget.value)}
            value={editingSchool.address.city}
          />
        </div>
        <Autocomplete
          disableCloseOnSelect
          multiple
          options={allStudySubjects.filter(value => !value.isHide)}
          getOptionLabel={(o) => o?.studySubjectName ?? ''}
          renderTags={(value: IStudySubject[], getTagProps) =>
            value.map((option: IStudySubject, index: number) => (
              <Chip
                variant="outlined"
                label={option?.studySubjectName}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(_, value) => handleAutoCompleteChange(value)}
          renderInput={(params) => <TextField {...params} label="Kurs" />}
          value={editingSchool.studySubjects.map((ss) => ss.studySubject)}
        />
        <FormControlLabel
          label="Deaktivert"
          onChange={(e) => {
            setDeactivated((e.target as HTMLInputElement).checked);
          }}
          checked={editingSchool.deactivated}
          control={<Checkbox name="schoolIsDeactivated" color="primary" />}
        />
      </form>
    </>
  );
};

export default observer(HighSchoolInformation);
