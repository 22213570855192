import { useStores } from '@/stores';
import {
  Box,
  Button,
  Drawer,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import useStyles from './style';
import ClearIcon from '@material-ui/icons/Clear';
import { User } from '@/types/User';
import { ControlledInputText } from '@/components/ControlledInputText';
import CustomEditor from '@/components/CustomEditor';
import { CreateUpdateCourseTemplate } from '@/types/CourseTemplate';
import { CircularProgress } from '@material-ui/core';

interface IComponentProps {
  handleClose: () => void;
  setCourseHolderId: (courseHolderId: number) => void;
  setRoomNumber: (roomNumber: string) => void;
  setCourseInfo: (courseInfo: string) => void;
  errorRegisterFormValue: Record<string, boolean>;
  createUpdateCourseTemplate: CreateUpdateCourseTemplate;
  handleCreateOrUpdateCourseTemplate: () => void;
  loading: boolean;
}

const VGSStudySubjectDrawer = (props: IComponentProps) => {
  const {
    handleClose,
    setCourseHolderId,
    setRoomNumber,
    setCourseInfo,
    errorRegisterFormValue,
    createUpdateCourseTemplate,
    handleCreateOrUpdateCourseTemplate,
    loading,
  } = props;

  const { stateStore, studySubjectStore, userStore } = useStores();
  const { vgsStudySubjectDrawerIsOpen } = stateStore;
  const { chosenStudySubject } = studySubjectStore;
  const { listContactPerson } = userStore;

  const classes = useStyles();

  return (
    <>
      <Drawer
        open={vgsStudySubjectDrawerIsOpen}
        onClose={handleClose}
        anchor="right"
      >
        <Box className={classes.content}>
          {!loading ? (
            <>
              <Box className={classes.courseOptionHeader}>
                <Typography variant="h4">Legg til infomasjon</Typography>
                <ClearIcon className={classes.pointer} onClick={handleClose} />
              </Box>

              <Typography variant="h3" className={classes.studySubjectName}>
                {chosenStudySubject.studySubjectName}
              </Typography>

              <FormControl
                variant="filled"
                className={classes.commonBottom}
                error={errorRegisterFormValue.courseHolderId}
              >
                <InputLabel id="contact-person-filter-label">
                  Kontakt person
                </InputLabel>
                <Select
                  labelId="contact-person-filter-label"
                  value={createUpdateCourseTemplate.courseHolderId}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setCourseHolderId(e.target.value as number)
                  }
                  input={<FilledInput />}
                >
                  {listContactPerson.map((u: User) => (
                    <MenuItem key={u.id} value={u.id}>
                      {`${u.fullName}`}
                    </MenuItem>
                  ))}
                </Select>
                {errorRegisterFormValue.courseHolderId && (
                  <FormHelperText>Obligatorisk</FormHelperText>
                )}
              </FormControl>

              <ControlledInputText
                label="Oppmøtested"
                value={createUpdateCourseTemplate.roomNumber}
                setValue={setRoomNumber}
                customClass={classes.commonBottom}
                hasError={errorRegisterFormValue.roomNumber}
                errorText="Obligatorisk"
              />

              <Box className={classes.commonBottom}>
                <CustomEditor
                  content={createUpdateCourseTemplate.courseInfo}
                  setContent={setCourseInfo}
                />
              </Box>

              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreateOrUpdateCourseTemplate()}
                >
                  {!createUpdateCourseTemplate.id ? 'Opprett' : 'Lagre'}
                </Button>
                <Button
                  variant="text"
                  onClick={handleClose}
                  style={{ marginLeft: '12px' }}
                >
                  Avbryt
                </Button>
              </div>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default observer(VGSStudySubjectDrawer);
