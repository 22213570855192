import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CourseFilter from './components/CourseFilter';
import clsx from 'clsx';
import { CourseOptionVGS } from '@/types/enums/CourseOption';
import CourseTable from './components/CourseTable';
import CourseOptions from './components/CourseOptions';
import {
  ASC,
  DESC,
  PER_PAGE_OPTIONS,
  SELECT_ALL,
} from '@/types/dto/PaginationRequest.dto';
import { CourseOrderBy } from '@/types/enums/CourseOrderBy';
import { useStores } from '@/stores';
import { useParams } from 'react-router-dom';
import CustomPagination from '@/components/CustomPagination';
import courseStyle from '@/views/style/courses';
import { CourseStatus } from '@/types/enums/CourseStatus';
import { StepType } from '@/types/StepType';
import { getStepVGS } from '@/utils/dashboardUtils';
import AdminHeader from '../../admin/common/AdminHeader';
import StepStatus from '@/components/StepStatus';
import { scrollTop } from '@/utils/common.utils';

interface UrlParams {
  projectId?: string;
}

const steps: StepType[] = [
  {
    title: 'Planlegging',
  },
  {
    title: 'Kursperiode',
  },
];

const VGSAdminCourses = () => {
  const { projectId } = useParams<UrlParams>();
  const courseClasses = courseStyle();
  const { courseStore, userStore, projectStore, studySubjectStore } =
    useStores();

  const [step, setStep] = useState<number>(0);
  const [tabMode, setTabMode] = useState<CourseOptionVGS>(CourseOptionVGS.EDIT);

  useEffect(() => {
    if (courseStore.courseId !== courseStore.initCourseId) {
      courseStore.getCourseById(courseStore.courseId);

      if (tabMode === CourseOptionVGS.EDIT) {
        userStore.getAllHighSchoolContactByCourseId(courseStore.courseId);
      }
    }
  }, [courseStore, courseStore.courseId, tabMode, userStore]);

  useEffect(() => {
    projectStore.getCurrent();
  }, [projectStore]);

  useEffect(() => {
    const step = getStepVGS(projectStore.currentProject);
    setStep(step);
  }, [projectStore, projectStore.currentProject]);

  const [searchInfoStudent, setSearchInfoStudent] = React.useState<string>('');
  const timeoutRef = React.useRef<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 300)
  );

  const getAllStudentMinifiedByCourseId = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      userStore.getAllStudentMinifiedByCourseId(
        courseStore.courseId,
        searchInfoStudent
      );
    }, 1000);
  }, [userStore, courseStore.courseId, searchInfoStudent]);

  React.useEffect(() => {
    if (tabMode === CourseOptionVGS.INFO_STUDENT) {
      getAllStudentMinifiedByCourseId();
    }
  }, [tabMode, getAllStudentMinifiedByCourseId]);

  React.useEffect(() => {
    if (
      userStore.studentId !== userStore.initStudentId &&
      courseStore.courseId !== courseStore.initCourseId &&
      tabMode === CourseOptionVGS.INFO_STUDENT
    ) {
      userStore.getStudentById(userStore.studentId, courseStore.courseId);
    }
  }, [tabMode, userStore, userStore.studentId, courseStore]);

  const chooseStudent = (courseId: number, studentId: number): void => {
    setTabMode(CourseOptionVGS.INFO_STUDENT);
    userStore.setStudentId(studentId);
    userStore.getStudentById(studentId, courseId);
    courseStore.setCourseId(courseId);
  };
  /** Course option - Infomation Student */

  /** Course option - Course table */
  const [search, setSearch] = React.useState<string>('');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(ASC);
  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(+PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [orderBy, setOrderBy] = React.useState<string>(
    CourseOrderBy.STUDY_SUBJECT_NAME
  );

  const [studySubjectId, setStudySubjectId] = React.useState<number | string>(
    SELECT_ALL
  );

  const handleSearchStudySubjectId = (studySubjectId: number | string) => {
    _resetGetList();
    setStudySubjectId(studySubjectId);
  };

  const [courseStatus, setCourseStatus] = React.useState<CourseStatus | string>(
    SELECT_ALL
  );

  const handleCourseStatus = (status: CourseStatus | string) => {
    _resetGetList();
    setCourseStatus(status);
  };

  const _resetGetList = (): void => {
    setSkip(0);
    setTake(+PER_PAGE_OPTIONS[0]);
    setCurrentPage(1);
    setSortDirection(ASC);
    setSearch('');
  };

  const getListVGS = React.useCallback(
    (search?: string) => {
      scrollTop();
      courseStore.getListVGS({
        skip,
        take,
        search,
        orderBy,
        orderDirection: sortDirection,
        projectId,
        externalSchoolId: userStore.user?.externalSchoolId,
        studySubjectId,
        status: courseStatus,
      });
    },
    [
      skip,
      take,
      orderBy,
      sortDirection,
      courseStore,
      projectId,
      userStore,
      studySubjectId,
      courseStatus,
    ]
  );

  const getStudySubject = React.useCallback(() => {
    if (projectId) {
      studySubjectStore.getStudySubjectByProjectId(+projectId);
    }
  }, [studySubjectStore, projectId]);

  const getCourseStatus = React.useCallback(() => {
    if (projectId) {
      courseStore.getCourseStatusByProjectId(
        +projectId,
        userStore.user?.externalSchoolId
      );
    }
  }, [courseStore, projectId, userStore]);

  React.useEffect(() => {
    getStudySubject();
    getCourseStatus();
    getListVGS();
  }, [getStudySubject, getCourseStatus, getListVGS]);

  const handleSearch = (): void => {
    getListVGS(search);
  };

  const handleSortTable = (orderBy: string): void => {
    setOrderBy(orderBy);
    if (sortDirection === ASC) {
      setSortDirection(DESC);
    } else {
      setSortDirection(ASC);
    }
  };

  const chooseCourse = (id: number): void => {
    setTabMode(CourseOptionVGS.EDIT);
    if (courseStore.courseId === id) return;

    courseStore.resetCourse();
    courseStore.setCourseId(id);
    userStore.resetStudent();
  };

  const changePageOption = (take: number): void => {
    handleChoosePage(1);
    setTake(take);
  };

  const handleChoosePage = (page: number): void => {
    setSkip((page - 1) * take);
    setCurrentPage(page);
  };

  const listParticipantOfCourseExport = async (
    courseId: number
  ): Promise<void> => {
    if (projectId) {
      await courseStore.listParticipantOfCourseExport(+projectId, courseId);
    }
  };

  return (
    <>
      <AdminHeader
        title={projectStore.currentProject?.projectName}
        actions={<StepStatus steps={steps} step={step} />}
        titlePadding={115}
      />
      <CourseFilter
        tabMode={tabMode}
        chooseTabCourseOption={setTabMode}
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
      />
      <div
        className={clsx(
          courseClasses.displayFlex,
          courseClasses.courseContentContainerBackground
        )}
      >
        <div className={clsx(courseClasses.leftContent, courseClasses.space)}>
          <div
            className={clsx(
              courseClasses.overflowXAuto,
              courseClasses.displayGirl
            )}
          >
            <CourseTable
              handleSortTable={handleSortTable}
              orderBy={orderBy}
              sortDirection={sortDirection}
              chooseCourse={chooseCourse}
              chooseStudent={chooseStudent}
              studySubjectId={studySubjectId}
              handleSearchStudySubjectId={handleSearchStudySubjectId}
              courseStatus={courseStatus}
              handleCourseStatus={handleCourseStatus}
              listParticipantOfCourseExport={listParticipantOfCourseExport}
            />
          </div>

          <div className={courseClasses.marginTop10}>
            <CustomPagination
              currentPage={currentPage}
              totalRows={courseStore.totalCount}
              perPage={take}
              handleChoosePage={handleChoosePage}
              changePageOption={changePageOption}
            />
          </div>
        </div>
        <div
          className={clsx(
            courseClasses.rightContent,
            courseClasses.courseOptionStyle,
            courseClasses.backgroundColorFFF
          )}
        >
          <CourseOptions
            tabMode={tabMode}
            searchInfoStudent={searchInfoStudent}
            setSearchInfoStudent={setSearchInfoStudent}
          />
        </div>
      </div>
    </>
  );
};

export default observer(VGSAdminCourses);
