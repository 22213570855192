import { PER_PAGE_OPTIONS } from '@/types/dto/PaginationRequest.dto';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import UserItem from './components/UserItem';
import UserSettingFilter from './components/UserSettingFilter';
import UserSettingHeader from './components/UserSettingHeader';
import userSettingStyle from './style';
import clsx from 'clsx';
import { useStores } from '@/stores';
import CustomPagination from '@/components/CustomPagination';
import CreateOrUpdateUserDrawer from './components/CreateOrUpdateUserDrawer';
import NotifyMessage from '@/components/NotifyMessage';
import { UserRole } from '@/types/UserRole';
import { SchoolType } from '@/types/School';
import {
  validateEmailAddress,
  validatePhoneNumber,
} from '@/utils/validationUtils';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { User } from '@/types/User';
import {
  changeDate,
  getDateFromKarDateType,
  resetTimeZeroOfDate,
} from '@/utils/timeUtils';
import { Color } from '@material-ui/lab';

const UserSettings = () => {
  const classes = userSettingStyle();
  const { userStore, schoolStore } = useStores();
  const { user } = userStore;

  const [search, setSearch] = useState<string>('');
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(+PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [contentError] = useState<Record<string, string>>({
    userName: 'username not valid',
    fullName: 'fullname is not empty',
    emailAddress: 'email not valid',
    phoneNumber: 'phone not valid',
    dateOfBirth: 'birthday not valid',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleSearch = (): void => {
    _resetGetList();

    if (skip === 0 && take === +PER_PAGE_OPTIONS[0]) {
      userStore.getListUser({
        skip: 0,
        take: +PER_PAGE_OPTIONS[0],
        search,
        externalSchoolId: _getExternalSchoolId(),
      });
    }
  };

  const _resetGetList = (): void => {
    setSkip(0);
    setTake(+PER_PAGE_OPTIONS[0]);
    setCurrentPage(1);
  };

  const getListUser = useCallback(
    (search?: string) => {
      userStore.getListUser({
        skip,
        take,
        search,
        externalSchoolId: _getExternalSchoolId(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userStore, skip, take]
  );

  const changePageOption = (take: number): void => {
    handleChoosePage(1);
    setTake(take);
  };

  const handleChoosePage = (page: number): void => {
    setSkip((page - 1) * take);
    setCurrentPage(page);
  };

  const updateIsActive = async (
    id: number,
    isActive: boolean
  ): Promise<void> => {
    const user = userStore.listUsers.find((u) => u.id === id);
    if (user) {
      await userStore.updateUser({ ...user, isActive });
      user.isActive = isActive;
    }
  };

  const [userId, setUserId] = useState<number | undefined>();
  const [createOrUpdateUserDrawerIsOpen, setCreateOrUpdateUserDrawerIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      userStore.getUser(userId);
    }
  }, [userStore, userId]);

  const chooseUser = (id: number): void => {
    if (id === userId) {
      setUserId(undefined);
    } else {
      setUserId(id);
      setCreateOrUpdateUserDrawerIsOpen(true);
    }
  };

  const handleCloseCreateOrUpdateUserDrawer = (open: boolean): void => {
    setUserId(undefined);
    userStore.resetCreateOrUpdate();
    setCreateOrUpdateUserDrawerIsOpen(open);
  };

  const changeUserRole = (role: UserRole): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      role,
    });
  };

  const changeSchool = (externalSchoolId: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      externalSchoolId,
    });
  };

  const changeClass = (externalClassId: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      externalClassId,
    });
  };

  useEffect(() => {

    if (userStore.createOrUpdate.role === UserRole.Admin) {
      schoolStore.getSchoolsForAdmin(userStore.createOrUpdate);
    }
    if (userStore.createOrUpdate.role === UserRole.HighSchoolContact) {
      schoolStore.getSchoolBySchoolType(SchoolType.HighSchool);
    }

    if (
      userStore.createOrUpdate.role === UserRole.Adviser ||
      userStore.createOrUpdate.role === UserRole.Student
    ) {
      schoolStore.getSchoolBySchoolType(SchoolType.SecondarySchool);
    }

    if (
      userStore.createOrUpdate.role === UserRole.Student &&
      !!userStore.createOrUpdate.externalSchoolId
    ) {
      schoolStore.getClassesByExternalSchoolId(
        userStore.createOrUpdate.externalSchoolId
      );
    }
  }, [
    schoolStore,
    userStore.createOrUpdate,
    userStore.createOrUpdate.role,
    userStore.createOrUpdate.externalSchoolId,
  ]);

  const changeDateOfBirth = (data: Date): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      dateOfBirth: data,
    });
  };

  const changeUserName = (data: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      userName: data,
    });
  };

  const changeFullName = (data: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      fullName: data,
    });
  };

  const changeEmailAddress = (data: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      emailAddress: data,
    });
  };

  const changePhoneNumber = (data: string): void => {
    userStore.setCreateOrUpdate({
      ...userStore.createOrUpdate,
      phoneNumber: data,
    });
  };

  const handleCreateOrUpdate = async (): Promise<void> => {
    try {
      if (_validation()) {
        if (userStore.createOrUpdate.id) {
          await userStore.updateUser(userStore.createOrUpdate);
        } else {
          await userStore.createUser(userStore.createOrUpdate);
        }

        handleSearch();
        handleCloseCreateOrUpdateUserDrawer(false);
        setSeverity('success');
        setMessage('Success !!!');
        setOpenNotifyMessage(true);
      }
    } catch (error: any) {
      setSeverity('error');
      setMessage('Error !!!');
      setOpenNotifyMessage(true);
    }
  };

  const _validation = () => {
    let isValid = true;
    let errorObj = {};

    if (
      !userStore.createOrUpdate.userName &&
      userStore.createOrUpdate.userName.length !== 11
    ) {
      isValid = false;
      errorObj = { ...errorObj, userName: contentError.userName };
    }

    if (!userStore.createOrUpdate.fullName) {
      isValid = false;
      errorObj = { ...errorObj, fullName: contentError.fullName };
    }

    if (!validateEmailAddress(userStore.createOrUpdate.emailAddress)) {
      isValid = false;
      errorObj = { ...errorObj, emailAddress: contentError.emailAddress };
    }

    if (!validatePhoneNumber(userStore.createOrUpdate.phoneNumber)) {
      isValid = false;
      errorObj = { ...errorObj, phoneNumber: contentError.phoneNumber };
    }

    if (typeof userStore.createOrUpdate.dateOfBirth === 'string') {
      try {
        const dateOfBirth = getDateFromKarDateType(
          userStore.createOrUpdate.dateOfBirth
        );

        if (dateOfBirth) {
          userStore.createOrUpdate.dateOfBirth = changeDate(
            undefined,
            resetTimeZeroOfDate(dateOfBirth)
          );
        } else {
          throw new Error('Have a error dateOfBirth');
        }
      } catch (error) {
        isValid = false;
        errorObj = { ...errorObj, dateOfBirth: contentError.dateOfBirth };
      }
    } else {
      userStore.createOrUpdate.dateOfBirth = changeDate(
        undefined,
        resetTimeZeroOfDate(userStore.createOrUpdate.dateOfBirth)
      );
    }

    setErrors(errorObj);
    return isValid;
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      await userStore.deleteUser(id);

      handleCloseDialogDeleteUser();
      handleSearch();
      handleCloseCreateOrUpdateUserDrawer(false);
      setSeverity('success');
      setMessage('Success !!!');
      setOpenNotifyMessage(true);
    } catch (error: any) {
      setSeverity('error');
      setMessage('Error !!!');
      setOpenNotifyMessage(true);
    }
  };

  const _getExternalSchoolId = (): string | undefined => {
    if (
      user?.role === UserRole.Adviser ||
      user?.role === UserRole.HighSchoolContact
    ) {
      return user.externalSchoolId;
    }

    return;
  };

  const [openNotifyMessage, setOpenNotifyMessage] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Color>('success');
  const [message, setMessage] = useState<string>('Success !!!');
  const handleCloseNotifyMessage = (): void => {
    setOpenNotifyMessage(false);
  };

  const [openDialogDeleteUser, setOpenDialogDeleteUser] =
    useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<User | undefined>(undefined);
  const handleOpenDialogDeleteUser = (id: number): void => {
    const user = userStore.listUsers.find((u) => u.id === id);
    setDeleteUser(user);
    setOpenDialogDeleteUser(true);
  };
  const handleCloseDialogDeleteUser = (): void => {
    setDeleteUser(undefined);
    setOpenDialogDeleteUser(false);
  };

  const timeoutRef = useRef<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 300)
  );

  const filterByFullName = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      getListUser(search);
    }, 1000);
  }, [getListUser, search]);

  useEffect(() => {
    filterByFullName();
  }, [filterByFullName]);

  return (
    <>
      <UserSettingHeader />
      <UserSettingFilter
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
        handleCloseCreateOrUpdateUserDrawer={
          handleCloseCreateOrUpdateUserDrawer
        }
      />

      <div className={clsx(classes.headerContent, classes.contentUserItem)}>
        {!userStore.loading ? (
          <>
            {userStore.listUsers.map((user) => (
              <UserItem
                key={user.id}
                user={user}
                updateIsActive={updateIsActive}
                chooseUser={chooseUser}
                userId={userId}
                handleOpenDialogDeleteUser={handleOpenDialogDeleteUser}
              />
            ))}

            <CustomPagination
              currentPage={currentPage}
              totalRows={userStore.totalCountListUser}
              perPage={take}
              handleChoosePage={handleChoosePage}
              changePageOption={changePageOption}
            />

            {!userStore.totalCountListUser && (
              <p className={clsx(classes.empty, classes.textAlignCenter)}>
                Det er ingen studenter
              </p>
            )}
          </>
        ) : (
          <Box
            sx={{
              minHeight: '20vh',
            }}
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.justifyContentCenter
            )}
          >
            <CircularProgress />
          </Box>
        )}
      </div>

      <CreateOrUpdateUserDrawer
        createOrUpdateUserDrawerIsOpen={createOrUpdateUserDrawerIsOpen}
        handleCloseCreateOrUpdateUserDrawer={
          handleCloseCreateOrUpdateUserDrawer
        }
        changeUserRole={changeUserRole}
        changeSchool={changeSchool}
        changeClass={changeClass}
        changeDateOfBirth={changeDateOfBirth}
        changeUserName={changeUserName}
        changeFullName={changeFullName}
        changeEmailAddress={changeEmailAddress}
        changePhoneNumber={changePhoneNumber}
        handleCreateOrUpdate={handleCreateOrUpdate}
        errors={errors}
      />

      <NotifyMessage
        open={openNotifyMessage}
        message={message}
        severity={severity}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessage}
      />

      <Dialog
        open={openDialogDeleteUser}
        onClose={handleCloseDialogDeleteUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            classes={{ root: classes.contentDialogDeleteUser }}
          >
            {`Er du sikker på at du vil slette ${deleteUser?.fullName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.paddingDialogAction }}>
          <Button
            variant="text"
            onClick={handleCloseDialogDeleteUser}
            style={{ marginRight: '12px' }}
          >
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (deleteUser && deleteUser.id) {
                await handleDelete(deleteUser.id);
              }
            }}
          >
            Slett
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default observer(UserSettings);
