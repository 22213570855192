import { ControlledInputText } from '@/components/ControlledInputText';
import { ISurveyQuestion } from '@/types/SurveyQuestion';
import clsx from 'clsx';
import React from 'react';
import userSurveyStyle from '../style';

interface IComponentProps {
  question: ISurveyQuestion;
  children: React.ReactElement<any, any>; //JSX.Element;
}

const Question = (props: IComponentProps) => {
  const { question, children } = props;
  const classes = userSurveyStyle();

  return (
    <>
      <div className={classes.fontSizeTitle}>
        {question.title}
        {question.isRequired ? (
          <>
            <span className={classes.fieldRequire}>*</span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.marginTop5}>
        <ControlledInputText
          value={question.subTitle}
          setValue={(value: string) => console.log(value)}
          customClass={clsx(classes.widthFull)}
          inputProps={{
            disableUnderline: true,
            classes: {
              input: classes.questionSubtitle,
              multiline: clsx(
                classes.questionTextArea,
                classes.noBorder,
                classes.padding0
              ),
            },
          }}
          hasMultiline={true}
          variant={'standard'}
          minRows={1}
          maxRows={10}
          disabled={true}
        />
      </div>
      <div className={classes.marginTop5}>
        {/* <Answer
          questionId={question.id ?? 0}
          type={question.type}
          answers={question.answers}
        /> */}
        {children}
      </div>
    </>
  );
};

export default Question;
