import { makeStyles } from '@material-ui/core';

const useMessagesStyle = makeStyles(({ breakpoints }) => ({
  main: {
    margin: '20px 115px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      margin: '10px 5px',
    },
  },
  table: {
    backgroundColor: '#fff',
  },
}));

export default useMessagesStyle;
