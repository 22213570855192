import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette }) => ({
  root: {
    width: '350px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    color: palette.primary.dark,
    padding: '24px',
  },
  divider: {
    margin: '6px 0 16px 0',
  },
  tabAppBar: {
    backgroundColor: 'transparent',
    color: palette.primary.main,
    fontWeight: 400,
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      borderBottom: '2px solid ' + palette.primary.light,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: palette.primary.main,
    },
  },
  tabPanels: {
    overflow: 'auto',
  },
  buttonContainer: {
    boxShadow: '0px -1px 3px rgba(51, 51, 51, 0.2)',
    '& .MuiButton-root': {
      margin: '0 15px 0 0',
    },
    padding: '24px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
  loadingButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyle;
