import MessageService from '@/services/MessageService';
import IMessagePaginationRequest from '@/types/dto/Messages/MessagePaginationRequest.dto';
import IMessage from '@/types/Message';
import { makeAutoObservable } from 'mobx';
import { RootStore } from '.';

class MessageStore {
  public rootStore: RootStore;

  public messages: IMessage[] = [];
  public count = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getMessages = async (req: IMessagePaginationRequest) => {
    const data = await MessageService.getMessages(req);
    if (data) {
      this.messages = data.data;
      this.count = data.count;
    }
  };
}

export default MessageStore;
