export class Base64CustomUploadAdapter {
  editor: any;

  constructor(editor: any) {
    this.editor = editor;
  }

  init() {
    this.editor.plugins.get('FileRepository').createUploadAdapter = (
      loader: any
    ) => {
      return new Adapter(loader);
    };
  }
}

class Adapter {
  loader: any;
  reader: FileReader;

  constructor(loader: any) {
    this.loader = loader;
    this.reader = new FileReader();
  }

  upload() {
    return new Promise((resolve, reject) => {
      const reader = (this.reader = new FileReader());

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', (err) => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then((file: File) => {
        reader.readAsDataURL(file);
      });
    });
  }

  abort() {
    this.reader.abort();
  }
}
