import { KarDateType } from './commons';

export interface IProject {
  id?: number;
  projectName: string;
  coursePeriodStartDate?: KarDateType;
  coursePeriodEndDate?: KarDateType;
  registeringPeriodStartDate?: KarDateType;
  registeringPeriodEndDate?: KarDateType;
  availablePeriodStartDate?: KarDateType;
  availablePeriodEndDate?: KarDateType;
  evaluationPeriodStartDate?: KarDateType;
  evaluationPeriodEndDate?: KarDateType;
  studentIntroductionText: string;
  studentRegistrationText: string;
  isPublished: boolean;
  assignSurveyId: number | null;
}
export const INIT_PROJECT: IProject = {
  projectName: '',
  coursePeriodStartDate: null,
  coursePeriodEndDate: null,
  registeringPeriodStartDate: null,
  registeringPeriodEndDate: null,
  availablePeriodStartDate: null,
  availablePeriodEndDate: null,
  evaluationPeriodStartDate: null,
  evaluationPeriodEndDate: null,
  studentIntroductionText: '',
  studentRegistrationText: '',
  isPublished: false,
  assignSurveyId: null,
};

export const registrationIsActive = (project: IProject) => {
  if (!project.registeringPeriodEndDate || !project.registeringPeriodStartDate)
    return false;

  const start = new Date(project.registeringPeriodStartDate);
  const end = new Date(project.registeringPeriodEndDate);
  const now = new Date();

  return start < now && end > now;
};

export const coursePeriodIsActive = (project: IProject) => {
  if (!project.coursePeriodStartDate || !project.coursePeriodEndDate)
    return false;

  const start = new Date(project.coursePeriodStartDate);
  const end = new Date(project.coursePeriodEndDate);
  const now = new Date();

  return start < now && end > now;
};

export const isAvailable = (project: IProject) => {
  if (!project.availablePeriodStartDate || !project.availablePeriodEndDate)
    return false;

  const start = new Date(project.availablePeriodStartDate);
  const end = new Date(project.availablePeriodEndDate);
  const now = new Date();

  return start < now && end > now;
};

export const evaluationIsActive = (project: IProject) => {
  if (!project.evaluationPeriodStartDate || !project.evaluationPeriodEndDate)
    return false;

  const start = new Date(project.evaluationPeriodStartDate);
  const end = new Date(project.evaluationPeriodEndDate);
  const now = new Date();

  return start < now && end > now;
};
