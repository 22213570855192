import React from 'react';
import userSettingStyle from '../../style';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const UserSettingHeader = () => {
  const classes = userSettingStyle();

  return (
    <>
      <div
        className={clsx(
          classes.header,
          classes.headerContent,
          classes.displayFlex,
          classes.alignItemsCenter
        )}
      >
        <Grid container alignItems="center">
          <HomeOutlinedIcon />
          <span className={classes.headerTitle}>Brukerinnstillinger</span>
        </Grid>
      </div>
    </>
  );
};

export default UserSettingHeader;
