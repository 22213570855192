import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { UserRole } from '@/types/UserRole';
import GUUserSettings from '@/views/app/GUadmin/user-settings';
import VGSUserSettings from '@/views/app/VGSadmin/user-settings';
import AdminUserSettings from '@/views/app/admin/user-settings';

const UserSettingDashboard = () => {
  const { userStore } = useStores();
  const { user } = userStore;

  if (user?.isCurrentlyRole(UserRole.Adviser)) {
    return <GUUserSettings />;
  }

  if (user?.isCurrentlyRole(UserRole.HighSchoolContact)) {
    return <VGSUserSettings />;
  }

  if (user?.isCurrentlyRole(UserRole.Admin)) {
    return <AdminUserSettings />;
  }

  return <></>;
};

export default observer(UserSettingDashboard);
