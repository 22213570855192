import IAddress, { Address } from './Address';
import { IClass } from './Class';
import IContactPerson from './ContactPerson';
import IDistanceAndPriority from './DistanceAndPriority';
import { ISchoolStudySubject } from './StudySubject';
import UnavailableDate from './UnavailableDate';

export enum SchoolType {
  Unknown = 0,
  HighSchool = 1,
  SecondarySchool = 2,
}

export interface ISchool {
  unavailableDates: UnavailableDate[];
  studySubjects: ISchoolStudySubject[];
  contactPeople: IContactPerson[];
  id: number;
  schoolName: string;
  externalId: string;
  address: IAddress;
  schoolType: SchoolType;
  deactivated: boolean;
  classes: IClass[];
}

export default class School {
  unavailableDates: UnavailableDate[];
  studySubjects: ISchoolStudySubject[];
  contactPeople: IContactPerson[];
  schoolName: string;
  externalId?: string | undefined;
  address: IAddress;
  schoolType: SchoolType;
  distanceAndPriorities?: IDistanceAndPriority[];
  deactivated: boolean;
  classes: IClass[];

  constructor() {
    this.unavailableDates = [];
    this.studySubjects = [];
    this.contactPeople = [];
    this.schoolName = '';
    this.address = new Address();
    this.schoolType = SchoolType.Unknown;
    this.distanceAndPriorities = [];
    this.deactivated = false;
    this.classes = [];
  }
}

export interface IUpdateSchoolDto extends School {
  distanceAndPriorities: IDistanceAndPriority[];
}
