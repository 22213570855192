import React from 'react';
import clsx from 'clsx';
import CustomSearch from '@/components/CustomSearch';
import dashboardStyle from '@/views/style/dashboard';

interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
  handleSearch: () => void;
}

const DashboardFilter = (props: IComponentProps) => {
  const { search, setSearch, handleSearch } = props;
  const classes = dashboardStyle();

  return (
    <>
      <div className={clsx(classes.displayFlex, classes.dashboardFilterNav)}>
        <div className={clsx(classes.leftContent, classes.heightFull)}>
          <div className={classes.customSearch}>
            <CustomSearch
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFilter;
