import { CourseOrderBy } from '@/types/enums/CourseOrderBy';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import courseStyle from '@/views/style/courses';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import { ASC } from '@/types/dto/PaginationRequest.dto';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import { useStores } from '@/stores';
import Row from './row';
import { CourseStatus } from '@/types/enums/CourseStatus';
import CustomSelect from '@/components/CustomSelect';
interface IComponentProps {
  handleSortTable: (orderBy: string) => void;
  orderBy: string;
  sortDirection: 'asc' | 'desc';
  chooseCourse: (id: number) => void;
  chooseStudent: (courseId: number, studentId: number) => void;
  studySubjectId: number | string;
  handleSearchStudySubjectId: (studySubjectId: number | string) => void;
  courseStatus: CourseStatus | string;
  handleCourseStatus: (courseStatus: CourseStatus | string) => void;
  listParticipantOfCourseExport: (courseId: number) => Promise<void>;
}

const CourseTable = (props: IComponentProps) => {
  const {
    handleSortTable,
    sortDirection,
    orderBy,
    chooseCourse,
    chooseStudent,
    studySubjectId,
    handleSearchStudySubjectId,
    courseStatus,
    handleCourseStatus,
    listParticipantOfCourseExport,
  } = props;

  const courseClasses = courseStyle();
  const { courseStore, studySubjectStore } = useStores();

  const [numberOfCourses, setNumberOfCourses] = React.useState<number>(0);
  React.useEffect(() => {
    setNumberOfCourses(courseStore.totalCount);
  }, [courseStore, courseStore.totalCount]);

  return (
    <>
      <TableContainer className={courseClasses.backgroundColorFFF}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: clsx(courseClasses.padding0, courseClasses.borderTable),
                }}
              ></TableCell>
              <TableCell classes={{ root: courseClasses.checkboxPadding }}>
                <CustomCheckBox />
              </TableCell>
              <TableCell classes={{ root: courseClasses.buttonDetailPadding }}>
                <CustomIconButton />
              </TableCell>

              <TableCell
                classes={{
                  root: clsx(courseClasses.tableCellStudySubject),
                }}
              >
                <div className={courseClasses.heighHeadCell}>
                  <CustomSelect
                    label={'Alle kurs'}
                    studySubjectId={studySubjectId}
                    studySubjects={studySubjectStore.studySubjectHeadCell}
                    handleSearchStudySubjectId={handleSearchStudySubjectId}
                  />
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Kapasitet
                </p>
              </TableCell>

              <TableCell>
                <div className={courseClasses.heighHeadCell}>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={() => handleSortTable(CourseOrderBy.START_DATE)}
                    IconComponent={() =>
                      sortDirection === ASC &&
                      orderBy === CourseOrderBy.START_DATE ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )
                    }
                    classes={{ root: courseClasses.whiteSpaceNowrap }}
                  >
                    <p className={courseClasses.margin0}>Date</p>
                  </TableSortLabel>
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Tidspunkt
                </p>
              </TableCell>

              <TableCell
                classes={{
                  root: clsx(courseClasses.tableCellHighSchool),
                }}
              >
                <div className={courseClasses.heighHeadCell}>
                  <p className={courseClasses.margin0}>Vgs ansvarlig</p>
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel
                  )}
                >
                  Kontakt person
                </p>
              </TableCell>

              <TableCell>
                <div className={courseClasses.heighHeadCell}>
                  <CustomSelect
                    label={'Alle'}
                    status={courseStatus}
                    courseStatus={courseStore.courseStatusHeadCell}
                    handleCourseStatus={handleCourseStatus}
                  />
                </div>
                <p
                  className={clsx(
                    courseClasses.margin0,
                    courseClasses.stepLabel,
                    courseClasses.whiteSpaceNowrap
                  )}
                >
                  Status
                </p>
              </TableCell>

              <TableCell></TableCell>

              <TableCell>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.justifyContentFlexEnd
                  )}
                >
                  <div className={courseClasses.positionRelative}>
                    <p
                      className={clsx(
                        courseClasses.margin0,
                        courseClasses.stepLabel,
                        courseClasses.whiteSpaceNowrap
                      )}
                    >
                      {numberOfCourses + ' arrangementer'}
                    </p>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!courseStore.loadingCourseTable ? (
              <>
                {courseStore.courses.length > 0 ? (
                  <>
                    {courseStore.courses.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        chooseCourse={chooseCourse}
                        chooseStudent={chooseStudent}
                        listParticipantOfCourseExport={
                          listParticipantOfCourseExport
                        }
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Typography variant="h3">
                          Ingen arrangementer satt opp
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <div
                      className={clsx(
                        courseClasses.displayFlex,
                        courseClasses.justifyContentCenter
                      )}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default observer(CourseTable);
