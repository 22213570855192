import React from 'react';
import useStyles from './style';
import clsx from 'clsx';

interface IComponentProps {
  count: number;
  title: string;
}

const BlockCount = (props: IComponentProps) => {
  const { count, title } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        <div className={classes.countStyle}>{count}</div>
        <div className={clsx(classes.titleStyle, classes.whiteSpaceNowrap)}>
          {title}
        </div>
      </div>
    </>
  );
};

export default BlockCount;
