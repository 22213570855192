import MessageTemplateService from '@/services/MessageTemplateService';
import MessageTemplate from '@/types/MessageTemplate';
import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';

class MessageTemplateStore {
  public rootStore: RootStore;
  public messageTemplates: MessageTemplate[] = [];
  public currentMessageTemplate?: MessageTemplate;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  getAllMessageTemplates = async () => {
    const templates = await MessageTemplateService.getAll();
    if (templates) {
      this.messageTemplates = templates.map((t) => MessageTemplate.fromJson(t));
    }
  };

  @action
  setCurrentMessageTemplate = (template?: MessageTemplate) => {
    this.currentMessageTemplate = template;
  };

  @action
  submitCurrentMessageTemplate = async () => {
    if (this.currentMessageTemplate) {
      try {
        await MessageTemplateService.update(this.currentMessageTemplate);
      } catch (error) {
        this.rootStore.errorStore.showErrorMessage(
          'Det har oppstått et problem med å oppdatere meldingsmalen'
        );
      }
    }
  };
}

export default MessageTemplateStore;
