import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { FormEvent, forwardRef, useState } from 'react';
import { useStores } from '../../../../stores';
import IOpenable from '../../../../types/interfaces/IOpenable';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ForgotPasswordDialog = ({ open, setOpen }: IOpenable) => {
  const [email, setEmail] = useState('');

  const { accountStore } = useStores();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    accountStore.sendLinkToResetPassword(email);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Tilbakestill passord</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fyll inn din e-post så sender vi en lenke hvor du kan tilbakestille
            ditt passord. Husk at lenken er kun tilgjengelig 15min.
          </DialogContentText>
          <TextField
            inputProps={{ 'aria-label': 'forgot-password-input' }}
            autoFocus
            label="E-post"
            type="email"
            fullWidth
            required
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            Avbryt
          </Button>
          <Button type="submit">Send</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
