import { ISurveyQuestion } from '@/types/SurveyQuestion';
import React from 'react';
import userSurveyStyle from '../style';
import clsx from 'clsx';
import { ControlledInputText } from '@/components/ControlledInputText';
import CustomCheckBox from '@/components/CustomCheckBox';
import { Button, MenuItem, Select } from '@material-ui/core';
import {
  getSurveyQuestionDisplay,
  SurveyQuestionType,
} from '@/types/enums/SurveyQuestionType';
import AnswerItem from './answer';
import { QuestionRight } from '@/types/enums/SurveyOption';

interface IComponentProps {
  question: ISurveyQuestion;
  setQuestionTitle?: (questionId: number, title: string) => void;
  setQuestionIsRequired?: (questionId: number, isRequired: boolean) => void;
  setQuestionSubTitle?: (questionId: number, subTitle: string) => void;
  setQuestionType?: (questionId: number, type: SurveyQuestionType) => void;
  handleAddAnswer?: (questionId: number) => Promise<void>;
  handleChangeAnswer: (
    content: string,
    questionId: number,
    answerId: number,
    isChecked?: boolean
  ) => void;
  handleDeleteQuestion?: (questionId: number) => Promise<void>;
  handleDeleteAnswer?: (questionId: number, answerId: number) => Promise<void>;
  right?: QuestionRight;
  isError?: boolean;
}

const QuestionItem = (props: IComponentProps) => {
  const {
    question,
    setQuestionTitle,
    setQuestionIsRequired,
    setQuestionSubTitle,
    setQuestionType,
    handleAddAnswer,
    handleChangeAnswer,
    handleDeleteQuestion,
    handleDeleteAnswer,
    right,
    isError,
  } = props;
  const classes = userSurveyStyle();

  const beforeChangeIsRequired = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (question.id) {
      if (right !== QuestionRight.ONLY_ANSWER && setQuestionIsRequired) {
        setQuestionIsRequired(question.id, event.target.checked);
      }
    }
  };

  return (
    <>
      <div
        className={clsx(classes.boxQuestion, {
          [classes.errorQuestion]: !!isError,
        })}
      >
        <div className={classes.displayFlex}>
          <div className={classes.widthContentQuestion}>
            <div className={classes.displayFlex}>
              <div className={clsx(classes.questionTitle, classes.marginTop10)}>
                Tittel<span className={classes.fieldRequire}>*</span>
              </div>
              <div className={classes.questionContent}>
                <ControlledInputText
                  value={question.title}
                  setValue={(value: string) => {
                    if (question.id) {
                      if (
                        right !== QuestionRight.ONLY_ANSWER &&
                        setQuestionTitle
                      ) {
                        setQuestionTitle(question.id, value);
                      }
                    }
                  }}
                  customClass={clsx(classes.widthFull)}
                  inputProps={{
                    disableUnderline: true,
                    classes: { input: classes.questionInput },
                  }}
                  disabled={right === QuestionRight.ONLY_ANSWER}
                />
              </div>
            </div>
            <div
              className={clsx(
                classes.displayFlex,
                classes.alignItemsCenter,
                classes.marginTop10
              )}
            >
              <div className={classes.questionTitle}></div>
              <div className={classes.questionContent}>
                <div
                  className={clsx(
                    classes.displayFlex,
                    classes.alignItemsCenter
                  )}
                >
                  <div className={classes.spaceCheck}>
                    <CustomCheckBox
                      value={question.isRequired}
                      isChecked={question.isRequired}
                      handleChange={beforeChangeIsRequired}
                      disabled={right === QuestionRight.ONLY_ANSWER}
                    />
                  </div>
                  <div>
                    Må fylles ut<span className={classes.fieldRequire}>*</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx(classes.displayFlex, classes.marginTop10)}>
              <div className={clsx(classes.questionTitle, classes.marginTop10)}>
                Hjelpetekst
              </div>
              <div className={classes.questionContent}>
                <ControlledInputText
                  value={question.subTitle}
                  setValue={(value: string) => {
                    if (question.id) {
                      if (
                        right !== QuestionRight.ONLY_ANSWER &&
                        setQuestionSubTitle
                      ) {
                        setQuestionSubTitle(question.id, value);
                      }
                    }
                  }}
                  customClass={clsx(classes.widthFull)}
                  inputProps={{
                    disableUnderline: true,
                    classes: {
                      multiline: classes.questionTextArea,
                    },
                  }}
                  hasMultiline={true}
                  variant={'standard'}
                  minRows={3}
                  maxRows={10}
                  disabled={right === QuestionRight.ONLY_ANSWER}
                />
              </div>
            </div>
            <div
              className={clsx(
                classes.displayFlex,
                classes.alignItemsCenter,
                classes.marginTop10
              )}
            >
              <div className={classes.questionTitle}>
                Spørsmål Type<span className={classes.fieldRequire}>*</span>
              </div>
              <div className={classes.questionContent}>
                <Select
                  disableUnderline
                  value={question.type}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    if (question.id) {
                      if (
                        right !== QuestionRight.ONLY_ANSWER &&
                        setQuestionType
                      ) {
                        setQuestionType(
                          question.id,
                          e.target.value as SurveyQuestionType
                        );
                      }
                    }
                  }}
                  classes={{
                    root: classes.surveyQuestionType,
                    icon: classes.color500,
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: {
                      paper: classes.surveyQuestionTypePager,
                      list: classes.surveyQuestionTypeList,
                    },
                  }}
                  className={clsx(classes.widthFull)}
                  inputProps={{
                    disabled: right === QuestionRight.ONLY_ANSWER,
                  }}
                >
                  {Object.keys(SurveyQuestionType)
                    .filter(
                      (k: string) =>
                        typeof SurveyQuestionType[k as any] === 'string'
                    )
                    .map((k1: string) => +k1)
                    .map((k2: number) => (
                      <MenuItem
                        key={k2}
                        value={k2}
                        classes={{ root: classes.surveyQuestionTypeMenu }}
                      >
                        {getSurveyQuestionDisplay(k2)}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>

            <div className={classes.marginTop20}>
              <AnswerItem
                questionId={question.id ?? 0}
                type={question.type}
                answers={question.answers}
                handleAddAnswer={handleAddAnswer}
                handleChangeAnswer={handleChangeAnswer}
                handleDeleteAnswer={handleDeleteAnswer}
                right={right}
              />
            </div>
          </div>
          {right !== QuestionRight.ONLY_ANSWER && (
            <div
              className={clsx(
                classes.marginLeftAuto,
                classes.widthButtonDeleteQuestion
              )}
            >
              <Button
                variant="contained"
                color="primary"
                classes={{ containedPrimary: classes.buttonDeleteQuestion }}
                onClick={async () => {
                  if (question.id && handleDeleteQuestion) {
                    await handleDeleteQuestion(question.id);
                  }
                }}
              >
                Slett spørsmål
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionItem;
