import IContactPerson from '@/types/ContactPerson';
import { Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores';
import AddExistingContactPerson from './AddExistingContactPerson';
import AddNewContactPerson from './AddNewContactPerson';
import useStyle from './style';
import { useState } from 'react';
import ContactPersonChip from './ContactPersonChip';

const SelectContactPeople = () => {
  const classes = useStyle();
  const { schoolStore } = useStores();
  const { editingSchool } = schoolStore;
  const { contactPeople } = editingSchool;

  const [addNew, setAddNew] = useState(!editingSchool.externalId);
  const handleDelete = (cp: IContactPerson) => {
    let deleteIndex = -1;
    if (cp.id) {
      deleteIndex = contactPeople.findIndex((p) => p.id === cp.id);
    } else {
      deleteIndex = contactPeople.findIndex(
        (p) => p.user?.emailAddress === cp.user?.emailAddress
      );
    }

    if (deleteIndex > -1) {
      const tempContactPeople = [...contactPeople];

      tempContactPeople.splice(deleteIndex, 1);

      schoolStore.setEditingSchool({
        ...editingSchool,
        contactPeople: tempContactPeople,
      });
    }
  };

  return (
    <div className={classes.addContactPeopleWrapper}>
      {contactPeople.filter((cp) => cp.isMain).length > 0 && (
        <Typography variant="h5" className={classes.subheader}>
          Hovedkontaktperson
        </Typography>
      )}
      {contactPeople
        .filter((cp) => cp.isMain)
        .map((cp, i) => (
          <ContactPersonChip
            contactPerson={cp}
            handleDelete={handleDelete}
            key={'main-contact-person' + i}
          />
        ))}
      <Typography variant="h5" className={classes.subheader}>
        Kontaktpersoner
      </Typography>
      {contactPeople
        .filter((cp) => !cp.isMain)
        .sort((a, b) =>
          (a.user?.fullName ?? '') > (b.user?.fullName ?? '') ? 1 : -1
        )
        .map((cp, i) => {
          return (
            <ContactPersonChip
              contactPerson={cp}
              handleDelete={handleDelete}
              key={'contact-person-' + i}
            />
          );
        })}
      {!addNew ? (
        <>
          <AddExistingContactPerson />
          <Button variant="text" onClick={() => setAddNew(true)}>
            Legg til ny bruker
          </Button>
        </>
      ) : (
        <>
          <AddNewContactPerson />
          {!!editingSchool.externalId && (
            <Button variant="text" onClick={() => setAddNew(false)}>
              Velg eksisterende bruker
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default observer(SelectContactPeople);
