import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    ...flexRow,
    gap: '4px',
    justifyContent: 'flex-start',
  },
  button: {
    padding: '3px 5px',
    '&.is-active': {
      backgroundColor: palette.primary.dark,
      color: '#fff',
    },
  },
}));

export default useStyles;
