import { useStores } from '@/stores';
import { SchoolStatus } from '@/types/enums/SchoolStatus';
import School, { SchoolType } from '@/types/School';
import { Grid, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddOrEditSchoolDrawer from '../common/AddOrEditSchoolDrawer';
import AdminHeader from '../common/AdminHeader';
import AdminSearchBar from '../common/AdminSearchBar';
import SchoolCard from '../common/SchoolCard';
import ShoolStatusFilter from '../common/ShoolStatusFilter';
import ContactsPersonDrawer from './ContactsPersonDrawer';
import ImportStudentsModal from './ImportStudentsModal';
import useStyle from './style';
import UnavailableDatesDrawer from './UnavailableDatesDrawer';

const SecondarySchools = () => {
  const [searchValue, setSearchValue] = useState('');

  const classes = useStyle();
  const { schoolStore, stateStore, userStore } = useStores();
  const { secondarySchools, getPopulatedSecondarySchools, setEditingSchool } =
    schoolStore;

  const handleOpenAddSecondarySchoolDrawer = () => {
    const newSchool = new School();
    newSchool.schoolType = SchoolType.SecondarySchool;

    setEditingSchool(newSchool);
    stateStore.setSchoolDrawerIsOpen(true);
  };

  const [filter, setFilter] = useState<SchoolStatus>(SchoolStatus.ACTIVATE);
  const handleChangeFilter = (value: SchoolStatus): void => {
    setFilter(value);
  };

  useEffect(() => {
    getPopulatedSecondarySchools(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondarySchools.length, filter]);

  const searchFilter = (s: School) =>
    s.schoolName.toLowerCase().includes(searchValue.toLowerCase());
  const filteredSchools = secondarySchools.filter(searchFilter).sort();

  const handleOpenContactsPersonDrawer = (externalSchoolId: string): void => {
    userStore.setListContactPerson([]);
    userStore.getContactPersonsByExternalSchoolId(externalSchoolId);
    stateStore.setContactsPersonDrawerIsOpen(true);
  };

  const history = useHistory();
  const redirectToScreenStudent = (externalId: string): void => {
    schoolStore.setExternalIdSecondarySchool(externalId);
    history.push('/all-students');
  };

  return (
    <>
      <header>
        <AdminHeader title="Ungdomsskoler i Oslo" />
        <AdminSearchBar
          handleSearchValueChange={setSearchValue}
          label="Søk etter skoler ved navn"
        >
          <IconButton onClick={handleOpenAddSecondarySchoolDrawer}>
            <Add />
          </IconButton>
        </AdminSearchBar>
        <ShoolStatusFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
        />
      </header>
      <main className={classes.main}>
        <Grid container spacing={3}>
          {filteredSchools.map((school) => (
            <SchoolCard
              school={school}
              key={school.externalId + '-card'}
              isChoice={true}
              handleOpenContactsPersonDrawer={handleOpenContactsPersonDrawer}
              redirectToScreenStudent={redirectToScreenStudent}
            />
          ))}
        </Grid>
      </main>
      <AddOrEditSchoolDrawer />
      <UnavailableDatesDrawer />
      <ImportStudentsModal />
      <ContactsPersonDrawer />
    </>
  );
};

export default observer(SecondarySchools);
