import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

export default useStyle;
