import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  heightFull: {
    height: '100%',
  },
  widthFull: {
    width: '100%',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  displayFlex: {
    display: 'flex',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
}));

export default useStyles;
