import { useStores } from '@/stores';
import { Drawer, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import useStyle from './style';

const ContactsPersonDrawer = () => {
  const { stateStore, userStore } = useStores();
  const { contactsPersonDrawerIsOpen, setContactsPersonDrawerIsOpen } =
    stateStore;

  const classes = useStyle();

  return (
    <Drawer
      open={contactsPersonDrawerIsOpen}
      onClose={() => setContactsPersonDrawerIsOpen(false)}
      anchor="right"
    >
      <div className={classes.content}>
        <div className={classes.paddingRight24}>
          <Typography variant="h3" className={classes.title}>
            Rediger ungdomsskole
          </Typography>
        </div>

        <div className={classes.listContactPerson}>
          <div className={classes.listContactPersonPadding}>
            {userStore.listContactPerson.map((cp) => (
              <Typography
                variant="h5"
                key={cp.id}
                className={classes.itemContactPerson}
              >
                {cp.fullName}
              </Typography>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default observer(ContactsPersonDrawer);
