import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Button, Divider } from '@material-ui/core';
import { useStores } from '@/stores';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { getDateTime } from '@/utils/timeUtils';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import courseStyle from '@/views/style/courses';
import CustomAutoSearchStudent from '@/components/CustomAutoSearchStudent';

interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
  setStudentId: (id: number) => void;
  //handleOpenMovingDialog: () => void;
}

const InfoStudent = (props: IComponentProps) => {
  const { search, setSearch, setStudentId } = props;
  const { userStore, studySubjectStore, courseStore } = useStores();
  const courseClasses = courseStyle();

  return (
    <>
      <div className={courseClasses.courseOptionPadding}>
        <div className={courseClasses.courseOptionWidth}>
          <div className={clsx(courseClasses.widthFull)}>
            <CustomAutoSearchStudent
              search={search}
              setSearch={setSearch}
              setStudentId={setStudentId}
              listStudent={userStore.listMinifiedStudentOfCourse}
            />
          </div>
          <Divider
            classes={{
              root: clsx(courseClasses.divider, courseClasses.marginTop10),
            }}
          />
          {userStore.student && courseStore.course && (
            <>
              <div className={courseClasses.marginTop20}>
                <div
                  className={clsx(
                    courseClasses.titleDark,
                    courseClasses.studentInfoName
                  )}
                >
                  {userStore.student.fullName}
                </div>
                {userStore.student.hasSpecialNeeds && (
                  <div
                    className={clsx(
                      courseClasses.displayFlex,
                      courseClasses.alignItemsCenter,
                      courseClasses.marginTop17
                    )}
                  >
                    <AccessibilityNewIcon />
                    <div
                      className={clsx(
                        courseClasses.marginLeft17,
                        courseClasses.color500
                      )}
                    >
                      Eleven har spesielle behov
                    </div>
                  </div>
                )}
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop22
                  )}
                >
                  <div
                    className={clsx(
                      courseClasses.displayFlex,
                      courseClasses.alignItemsCenter
                    )}
                  >
                    <PhoneIphoneOutlinedIcon />
                    <span
                      className={clsx(
                        courseClasses.marginLeft14,
                        courseClasses.color500
                      )}
                    >
                      {userStore.student.phoneNumber}
                    </span>
                  </div>

                  <div
                    className={clsx(
                      courseClasses.displayFlex,
                      courseClasses.alignItemsCenter,
                      courseClasses.marginLeft27
                    )}
                  >
                    <EmailOutlinedIcon />
                    <span
                      className={clsx(
                        courseClasses.marginLeft14,
                        courseClasses.color500
                      )}
                    >
                      {userStore.student.emailAddress}
                    </span>
                  </div>
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop22
                  )}
                >
                  <div
                    className={clsx(
                      courseClasses.displayFlex,
                      courseClasses.alignItemsCenter
                    )}
                  >
                    <InfoOutlinedIcon />
                    <span
                      className={clsx(
                        courseClasses.marginLeft14,
                        courseClasses.color500
                      )}
                    >
                      {`${
                        userStore.student.school
                          ? userStore.student.school.schoolName
                          : 'Ikke definert'
                      } skole klasse ${
                        userStore.student.class
                          ? userStore.student.class.fullName
                          : 'Ikke definert'
                      }`}
                    </span>
                  </div>
                </div>
              </div>
              <Divider
                classes={{
                  root: clsx(courseClasses.divider, courseClasses.marginTop14),
                }}
              />
              <div className={courseClasses.marginTop32}>
                <div
                  className={clsx(
                    courseClasses.titleDark,
                    courseClasses.studySubject
                  )}
                >
                  Tildelt kurs
                </div>
                <div
                  className={clsx(
                    courseClasses.contentStudentStudyBox,
                    courseClasses.marginTop22
                  )}
                >
                  {studySubjectStore.studentStudySubjects.map((sss) => (
                    <div
                      key={sss.id}
                      className={clsx(
                        courseClasses.contentStudentStudy,
                        courseClasses.studySubjectDefault,
                        {
                          [courseClasses.studySubjectCurrent]:
                            userStore.student?.studentCourses &&
                            userStore.student?.studentCourses[0] &&
                            userStore.student?.studentCourses[0]
                              .finalAssignedCourse &&
                            userStore.student?.studentCourses[0]
                              .finalAssignedCourse.studySubject &&
                            userStore.student?.studentCourses[0]
                              ?.finalAssignedCourse.school &&
                            sss.studySubjectId ===
                              userStore.student?.studentCourses[0]
                                ?.finalAssignedCourse?.studySubjectId,
                        }
                      )}
                    >
                      {`${sss.priority}. ${sss.studySubject.studySubjectName}`}
                    </div>
                  ))}
                </div>
              </div>
              <Divider
                classes={{
                  root: clsx(courseClasses.divider, courseClasses.marginTop22),
                }}
              />
              <div className={courseClasses.marginTop28}>
                <div
                  className={clsx(
                    courseClasses.titleDark,
                    courseClasses.studySubject
                  )}
                >
                  Tildelt arrangement
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop26
                  )}
                >
                  <LocationOnOutlinedIcon />
                  <span
                    className={clsx(
                      courseClasses.marginLeft14,
                      courseClasses.color500
                    )}
                  >
                    {userStore.student.studentCourses &&
                    userStore.student.studentCourses[0] &&
                    userStore.student.studentCourses[0].finalAssignedCourse &&
                    userStore.student.studentCourses[0].finalAssignedCourse
                      .studySubject &&
                    userStore.student.studentCourses[0]?.finalAssignedCourse
                      .school
                      ? userStore.student?.studentCourses[0]
                          ?.finalAssignedCourse?.school.schoolName
                      : ''}
                  </span>
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop24
                  )}
                >
                  <EventOutlinedIcon />
                  <span
                    className={clsx(
                      courseClasses.marginLeft14,
                      courseClasses.color500
                    )}
                  >
                    {userStore.student.studentCourses &&
                    userStore.student.studentCourses[0] &&
                    userStore.student.studentCourses[0].finalAssignedCourse &&
                    userStore.student.studentCourses[0].finalAssignedCourse
                      .studySubject &&
                    userStore.student.studentCourses[0]?.finalAssignedCourse
                      .school
                      ? getDateTime(
                          userStore.student.studentCourses[0]
                            ?.finalAssignedCourse.startDate,
                          'dd. LLL.'
                        ) +
                        ' ' +
                        getDateTime(
                          userStore.student.studentCourses[0]
                            ?.finalAssignedCourse.startDate,
                          'HH:mm'
                        ) +
                        ' - ' +
                        getDateTime(
                          userStore.student.studentCourses[0]
                            ?.finalAssignedCourse.endDate,
                          'HH:mm'
                        )
                      : ''}
                  </span>
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop24
                  )}
                >
                  <SchoolOutlinedIcon />
                  <span
                    className={clsx(
                      courseClasses.marginLeft14,
                      courseClasses.color500
                    )}
                  >
                    {userStore.student.studentCourses &&
                    userStore.student.studentCourses[0] &&
                    userStore.student.studentCourses[0].finalAssignedCourse &&
                    userStore.student.studentCourses[0].finalAssignedCourse
                      .studySubject &&
                    userStore.student.studentCourses[0]?.finalAssignedCourse
                      .school
                      ? userStore.student.studentCourses[0]?.finalAssignedCourse
                          .studySubject.studySubjectName
                      : ''}
                  </span>
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop24
                  )}
                >
                  <PhoneIphoneOutlinedIcon />
                  <span
                    className={clsx(
                      courseClasses.marginLeft14,
                      courseClasses.color500
                    )}
                  >
                    {userStore.student.studentCourses &&
                    userStore.student.studentCourses[0] &&
                    userStore.student.studentCourses[0].finalAssignedCourse &&
                    userStore.student.studentCourses[0].finalAssignedCourse
                      .studySubject &&
                    userStore.student.studentCourses[0]?.finalAssignedCourse
                      .school
                      ? userStore.student.studentCourses[0]?.finalAssignedCourse
                          .courseHolder?.fullName
                      : ''}
                  </span>
                </div>
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter,
                    courseClasses.marginTop24
                  )}
                >
                  <EmailOutlinedIcon />
                  <span
                    className={clsx(
                      courseClasses.marginLeft14,
                      courseClasses.color500
                    )}
                  >
                    {userStore.student.studentCourses &&
                    userStore.student.studentCourses[0] &&
                    userStore.student.studentCourses[0].finalAssignedCourse &&
                    userStore.student.studentCourses[0].finalAssignedCourse
                      .studySubject &&
                    userStore.student.studentCourses[0]?.finalAssignedCourse
                      .school
                      ? userStore.student.studentCourses[0]?.finalAssignedCourse
                          .courseHolder?.emailAddress
                      : ''}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {userStore.student && courseStore.course && (
        <div
          className={clsx(
            courseClasses.displayFlex,
            courseClasses.optionButtons,
            courseClasses.marginTop20
          )}
        >
          <Button variant="contained" color="primary">
            Lagre
          </Button>
          <Button variant="text" style={{ marginLeft: '12px' }}>
            Avbryt
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(InfoStudent);
