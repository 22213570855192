import IStudySubject, { StudentStudySubject } from '@/types/StudySubject';
import { MenuItem, Select, Input } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { UNDEFINED } from '@/types/dto/PaginationRequest.dto';

interface IComponentProps {
  studentStudySubject: StudentStudySubject | null;
  handleSetFirst?: (studySubjectId: number) => void;
  handleSetSecond?: (studySubjectId: number) => void;
  handleSetThird?: (studySubjectId: number) => void;
  priority: number;
}

const StudentRowStudent = (props: IComponentProps) => {
  const {
    studentStudySubject,
    handleSetFirst,
    handleSetSecond,
    handleSetThird,
    priority,
  } = props;
  const { studySubjectStore } = useStores();
  const classes = useStyles();

  return (
    <div className={classes.contentStudentRowSelect}>
      <div className={classes.title}>{`${priority}. prioritert*`}</div>
      <Select
        disableUnderline
        value={
          studentStudySubject ? studentStudySubject.studySubjectId : UNDEFINED
        }
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
          if (handleSetFirst && priority === 1) {
            handleSetFirst(e.target.value as number);
          }

          if (handleSetSecond && priority === 2) {
            handleSetSecond(e.target.value as number);
          }

          if (handleSetThird && priority === 3) {
            handleSetThird(e.target.value as number);
          }
        }}
        classes={{
          select: classes.select,
          icon: classes.icon,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        IconComponent={ArrowDropDownIcon}
        input={<Input classes={{ root: classes.menuItem }} />}
      >
        <MenuItem key={UNDEFINED} value={UNDEFINED}>
          Velg kurs
        </MenuItem>

        {studySubjectStore.allStudySubjects.map(
          (studySubject: IStudySubject) => (
            <MenuItem key={studySubject.id} value={studySubject.id}>
              {studySubject.studySubjectName}
            </MenuItem>
          )
        )}
      </Select>
    </div>
  );
};

export default observer(StudentRowStudent);
