import { LinearProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import clsx from 'clsx';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

interface IComponentProps {
  capacity: number;
  vacant: number;
  showVacant: boolean;
}

const CapacityCourse = (props: IComponentProps) => {
  const { capacity, vacant, showVacant } = props;
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(
          classes.whiteSpaceNowrap,
          classes.displayFlex,
          classes.alignItemsFlexEnd
        )}
      >
        <div
          className={clsx(
            classes.blockCapacity,
            classes.heightFull,
            classes.backgroundColorTransparent,
            classes.widthCapacityLeft
          )}
        >
          <PersonOutlineIcon
            className={clsx(
              classes.textStyle,
              classes.verticalAlignBottom,
              classes.fontSizeIconCapacity
            )}
          />
          <span
            className={clsx(classes.textStyle, classes.verticalAlignBottom)}
          >
            {capacity}
          </span>
        </div>

        {showVacant && (
          <div
            className={clsx(
              classes.blockCapacity,
              classes.heightFull,
              classes.backgroundColorTransparent,
              classes.widthCapacityRigh
            )}
          >
            <div>
              <span className={classes.textStyle}>
                {vacant >= 0
                  ? `${vacant} ledig`
                  : `${Math.abs(vacant)} overblastet`}
              </span>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={vacant >= 0 ? (vacant * 100) / capacity : 100}
                classes={{
                  barColorPrimary: clsx({ [classes.overloaded]: vacant < 0 }),
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CapacityCourse;
