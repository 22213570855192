import { ComponentType } from 'react';
import {
  AdminRoles,
  AllRoles,
  GURoles,
  SuperAdminRoles,
  UserRole,
} from '../types/UserRole';
import HighSchools from '../views/app/admin/highSchools';
import Dashboard from '../views/app/Dashboard';
import CompleteOIDCLogin from '../views/public/CompleteOIDCLogin';
import Prioritize from '../views/app/student/Prioritize';
import Login from '../views/public/login/Login';
import StudentInformation from '@/views/app/student/StudentInformation';
import SecondarySchools from '@/views/app/admin/secondarySchools';
import StudentStudySubjects from '@/views/app/student/StudentStudySubjects';
import StudentCourseInformation from '@/views/app/student/CourseInformation';
import AdminStudents from '@/views/app/admin/students';
import Templates from '@/views/app/admin/templates';
import Messages from '@/views/app/admin/messages';
import CourseDashboard from '@/views/app/Dashboard/CourseDashboard';
import Projects from '@/views/app/admin/projects';
import GUStudents from '@/views/app/GUadmin/Students';
import ResetPassword from '@/views/public/login/components/ResetPassword';
import UserSettingDashboard from '@/views/app/Dashboard/UserSettingDashboard';
import UserSurveyList from '@/views/app/admin/userSurvey/UserSurveyList';
import CreateSurvey from '@/views/app/admin/userSurvey/Create';
import EditSurvey from '@/views/app/admin/userSurvey/Edit';
import SurveyDetail from '@/views/app/admin/userSurvey/Detail';
import StudySubjectDashboard from '@/views/app/Dashboard/StudySubjectDashboard';
import Evaluation from '@/views/public/evaluation';
import EvaluationThank from '@/views/public/evaluation-thank';

interface Route {
  name: string;
  path: string;
  component: ComponentType<any>;
  exact?: boolean;
}

interface ProtectedRoute extends Route {
  roles: UserRole;
}

export const publicRoutes: Route[] = [
  {
    name: 'Home',
    path: '/',
    component: Login,
  },
  {
    name: 'Completing Login',
    path: '/complete-oidc-login',
    component: CompleteOIDCLogin,
  },
  {
    name: 'Reset Password',
    path: '/resetPassword',
    component: ResetPassword,
  },
  {
    name: 'Evaluation',
    path: '/evaluation/:projectId/:uuid',
    component: Evaluation,
  },
  {
    name: 'Thanks evaluation',
    path: '/evaluation-thank',
    component: EvaluationThank,
  },
];

export const protectedRoutes: ProtectedRoute[] = [
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
    roles: AllRoles,
  },
  {
    name: 'Brukere',
    path: '/settings',
    component: UserSettingDashboard,
    roles: AdminRoles,
  },
  {
    name: 'Prosjekter',
    path: '/projects',
    component: Projects,
    roles: AdminRoles,
  },
  {
    name: 'Kurs',
    path: '/projects/:projectId/courses',
    component: CourseDashboard,
    roles: AdminRoles,
  },
  {
    name: 'Videregående skoler',
    path: '/vgs',
    component: HighSchools,
    roles: SuperAdminRoles,
  },
  {
    name: 'Ungdomsskoler',
    path: '/gu',
    component: SecondarySchools,
    roles: SuperAdminRoles,
  },
  {
    name: 'Elever',
    path: '/all-students',
    component: AdminStudents,
    roles: SuperAdminRoles,
  },
  {
    name: 'Kurs',
    path: '/study-subjects',
    component: StudySubjectDashboard,
    roles: AdminRoles,
  },
  {
    name: 'Templates',
    path: '/templates',
    component: Templates,
    roles: SuperAdminRoles,
  },
  {
    name: 'Utgående meldinger',
    path: '/messages',
    component: Messages,
    roles: SuperAdminRoles,
  },
  {
    name: 'Brukerundersøkelse',
    path: '/user-surveys',
    component: UserSurveyList,
    roles: SuperAdminRoles,
  },
  {
    name: 'CreateSurvey',
    path: '/user-surveys/new',
    component: CreateSurvey,
    roles: SuperAdminRoles,
  },
  {
    name: 'EditSurvey',
    path: '/user-surveys/:surveyId/edit',
    component: EditSurvey,
    roles: SuperAdminRoles,
  },
  {
    name: 'SurveyDetail',
    path: '/user-surveys/:surveyId/detail',
    component: SurveyDetail,
    roles: SuperAdminRoles,
  },
  {
    name: 'Elever',
    path: '/students',
    component: GUStudents,
    roles: GURoles,
  },
  {
    name: 'Prioriter',
    path: '/prioritize',
    component: Prioritize,
    roles: UserRole.Student,
  },
  {
    name: 'Student Information',
    path: '/update-student-information',
    component: StudentInformation,
    roles: UserRole.Student,
  },
  {
    name: 'Study Subjects',
    path: '/student-study-subjects',
    component: StudentStudySubjects,
    roles: UserRole.Student,
  },
  {
    name: 'Course Information',
    path: '/course-information',
    component: StudentCourseInformation,
    roles: UserRole.Student,
  },
];
