import dashboardService from '@/services/DashboardService';
import {
  IStatisticAllocation,
  IStatisticRegistration,
  IStatisticSubjectCapacity,
} from '@/types/StudentStudySubject';
//import { IUserDto } from '@/types/User';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '.';

class DashboardStore {
  rootStore: RootStore;
  statisticCapacityVGS: IStatisticSubjectCapacity[];
  //highSchoolContacts: IUserDto[];
  statisticRegistration: IStatisticRegistration | null;
  statisticAllocation: IStatisticAllocation | null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.statisticCapacityVGS = [];
    //this.highSchoolContacts = [];
    this.statisticRegistration = null;
    this.statisticAllocation = null;

    makeObservable(this, {
      statisticCapacityVGS: observable,
      //highSchoolContacts: observable,
      statisticRegistration: observable,
      statisticAllocation: observable,
      setStatisticCapacityVGS: action,
      //setHighSchoolContacts: action,
      setStatisticRegistration: action,
      setStatisticAllocation: action,
    });
  }

  setStatisticCapacityVGS(data: IStatisticSubjectCapacity[]) {
    this.statisticCapacityVGS = data;
  }

  // setHighSchoolContacts(data: IUserDto[]) {
  //   this.highSchoolContacts = data;
  // }

  setStatisticRegistration(data: IStatisticRegistration) {
    this.statisticRegistration = data;
  }

  setStatisticAllocation(data: IStatisticAllocation) {
    this.statisticAllocation = data;
  }

  async getStatisticCapacityVGS(
    projectId: number,
    externalSchoolId: string
  ): Promise<void> {
    const data = await dashboardService.getStatisticCapacityVGS(
      projectId,
      externalSchoolId
    );
    this.setStatisticCapacityVGS(data);
  }

  /*async getAllHighSchoolContactByStudySubjectId(
    studySubjectId: number,
    externalSchoolId?: string
  ): Promise<void> {
    const data = await dashboardService.getAllHighSchoolContactByStudySubjectId(
      studySubjectId,
      externalSchoolId
    );
    this.setHighSchoolContacts(data);
  }*/

  async getStatisticRegistrationGU(
    projectId: number,
    externalSchoolId: string
  ): Promise<void> {
    const data = await dashboardService.getStatisticRegistrationGU(
      projectId,
      externalSchoolId
    );
    this.setStatisticRegistration(data);
  }

  async getStatisticAllocationGU(
    projectId: number,
    externalSchoolId: string
  ): Promise<void> {
    const data = await dashboardService.getStatisticAllocationGU(
      projectId,
      externalSchoolId
    );
    this.setStatisticAllocation(data);
  }
}

export default DashboardStore;
