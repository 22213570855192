import { CKEditor } from '@ckeditor/ckeditor5-react';
import './index.css';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

interface IComponentProps {
  content: string | null | undefined;
}

const DescriptionSurvey = (props: IComponentProps) => {
  const { content } = props;

  const config = {
    isReadOnly: true,
  };

  return (
    <>
      <div className="description-survey">
        <CKEditor
          editor={Editor}
          data={content ?? ''}
          onReady={(editor: any) => {
            if (editor) {
              editor.setData(content ?? '');
            }
          }}
          config={config}
          disabled={true}
        />
      </div>
    </>
  );
};

export default DescriptionSurvey;
