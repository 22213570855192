import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  optionsDatePicker: {
    width: '100%',
    minHeight: '287px',
    background: palette.grey[100],
    borderRadius: '4px',
    margin: '0 auto',
    padding: '16px',
  },
  boxOutputRange: {
    width: '100%',
    height: '52px',
    borderRadius: '4px',
    display: 'flex',
    padding: '12px 13px',
    backgroundColor: '#f5f5f5',
    alignItems: 'center',
  },
}));

export default useStyles;
