import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette }) => ({
  subheader: {
    color: palette.primary.dark,
    marginBottom: '6px',
  },
  addContactPeopleWrapper: {
    ...flexCol,
    width: '300px',
  },
}));

export default useStyle;
