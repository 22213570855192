import { action } from 'mobx';
import { RootStore } from '.';
import AccountService from '../services/AccountService';
import { User } from '../types/User';
class AccountStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  redirectToFeide = async () => {
    const URI = await AccountService.getFeideRedirect();
    if (URI) window.location.replace(URI);
  };

  @action
  register = async (username: string, password: string): Promise<void> => {
    const user = await AccountService.register(username, password);
    if (user) this.rootStore.userStore.setUser(User.fromUserDto(user));
  };

  @action
  login = async (
    username: string,
    password: string,
    rememberMe = false
  ): Promise<void> => {
    const user = await AccountService.login(username, password, rememberMe);
    if (user) this.rootStore.userStore.setUser(User.fromUserDto(user));
  };

  @action
  logout = async (): Promise<void> => {
    await AccountService.logout();
    this.rootStore.userStore.removeUser();
  };

  @action
  resetPassword = async (email: string | null, newPassword: string, currentTime: string | null, token: string | null): Promise<void> => {
    await AccountService.resetPassword(email, newPassword, currentTime, token);
  };

  @action
  sendLinkToResetPassword = async (email: string): Promise<void> => {
    await AccountService.sendLinkToResetPassword(email);
  };

  @action
  isValidLink = async (): Promise<boolean> => {
    return await AccountService.isValidLink();
  };

  @action
  oidcCallback = async (code: string): Promise<void> => {
    await AccountService.oidcCallback(code);
  };
}

export default AccountStore;
