import { action, computed, makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import ProjectService from '../services/ProjectService';
import { IProject } from '../types/Project';

class ProjectStore {
  public rootStore: RootStore;
  public projects: IProject[] = [];
  public tableList: IProject[] = [];
  public totalCount = 0;
  public totalAllocated = 0;
  public currentProject: IProject | null = null;
  public editingProject: IProject | null = null;
  public currentSurveyId = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async create(project: IProject) {
    await ProjectService.addV2(project);
    return true;
  }

  @action
  async update(project: IProject) {
    await ProjectService.updateProject(project);
    this.setTableList([
      ...this.tableList.map((x) => (x.id !== project.id ? x : { ...project })),
    ]);
    return true;
  }

  async list(query: Record<string, unknown>, isTableFetch = false) {
    const { data, total } = await ProjectService.list(query);

    if (!isTableFetch) {
      this.setProjects(data);
    }

    if (isTableFetch) {
      this.setTableList(data);
      this.setTotalCount(total);
    }
  }

  getActiveProjects() {
    return this.list({});
  }

  getActiveProjectsForTable() {
    return this.list({}, true);
  }

  getArchivedProjects() {
    return this.list({ isArchived: true }, true);
  }

  @action
  setTableList(data: IProject[]) {
    this.tableList = data;
  }

  @action
  setProjects(data: IProject[]) {
    this.projects = data;
  }

  @action
  setTotalCount(total: number) {
    this.totalCount = total;
  }

  @action
  setCurrentProject(project: IProject | null) {
    this.currentProject = project;
  }

  @action
  setCurrentSurveyId(id: number) {
    this.currentSurveyId = id;
  }

  @computed
  get arrangedProjects(): IProject[] {
    let array: IProject[] = [];

    if (this.currentProject) array.push(this.currentProject);

    array = [
      ...array,
      ...this.projects.filter((p) => {
        if (!this.currentProject) return true;
        return p.id !== this.currentProject.id;
      }),
    ];

    return array;
  }

  async getCurrent() {
    const result = await ProjectService.getCurrent();

    if (result) {
      this.setCurrentProject(result);
    }
  }

  reArrangeProjects() {
    let arrangedArray = [];
    this.currentProject && arrangedArray.push(this.currentProject);
    arrangedArray = [
      ...arrangedArray,
      ...this.projects.filter((p) => {
        if (!this.currentProject) return true;
        return this.currentProject.id !== p.id;
      }),
    ];
    this.projects = arrangedArray;
  }

  async getLeftMenuList() {
    this.getActiveProjects();
    this.getCurrent();
  }

  async isCurrentProjectInCoursePeriod() {

    if(this.currentProject?.coursePeriodStartDate && this.currentProject.coursePeriodEndDate){
    const start = new Date(this.currentProject.coursePeriodStartDate);
    const end = new Date( this.currentProject.coursePeriodEndDate);
    const now = new Date();

    return start < now && end > now;}
    return false;
  }

  @action setEdittingProject(project: IProject | null) {
    this.editingProject = project;
  }

  openProjectPanel(project: IProject) {
    this.setEdittingProject(project);
    this.rootStore.stateStore.setDrawerMenuIsOpen(false);
  }

  closeProjectPanel() {
    this.setEdittingProject(null);
  }

  async deleteProject(id: number) {
    await ProjectService.delete(id);
    this.setTableList([...this.tableList.filter((x) => x.id !== id)]);
  }

  async getById(id: number) {
    const result = await ProjectService.getById(id);

    if (result) {
      this.setCurrentProject(result);
    }
  }

  async getAssignSurvey(id: number) {
    const result = await ProjectService.getAssignSurvey(id);

    if (result) {
      this.setCurrentSurveyId(result);
    }
  }

  @action
  async getTotalAllocated(id: number | undefined) {
    this.totalAllocated = await ProjectService.getTotalAllocated(id);
  }
  @action
  async publishProject(id: number | undefined) {
    await ProjectService.publishProject(id);
  }
}

export default ProjectStore;
