import { IProject } from '@/types/Project';

export const getStepVGS = (project: IProject | null): number => {
  let result = -1;
  if (project) {
    const now = new Date();
    // Planlegging
    if (
      project.coursePeriodStartDate &&
      now < new Date(project.coursePeriodStartDate)
    ) {
      result = 0;
    }

    // Kursperiode
    if (
      project.coursePeriodStartDate &&
      project.coursePeriodEndDate &&
      new Date(project.coursePeriodStartDate) <= now &&
      now <= new Date(project.coursePeriodEndDate)
    ) {
      result = 1;
    }
  }

  return result;
};
