import { useStores } from '@/stores';
import { getSchoolAddressLink } from '@/utils/schoolUtils';
import { Box, Card, Link, Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import {
  AccessTimeOutlined,
  PhoneIphoneOutlined,
  RoomOutlined,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import useStudentStyle from '../studentStyle';
import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';

const StudentCourseInformation = () => {
  const classes = useStudentStyle();
  const { userStore, projectStore } = useStores();
  const { user } = userStore;
  const { currentProject } = projectStore;

  const assignedStudentCourse = user?.getAssignedCourse(currentProject?.id);
  const course = assignedStudentCourse?.finalAssignedCourse;
  const mainContactPerson = course?.school.contactPeople.find(
    (cp) => cp.isMain
  );

  const getStatus = (): string => {
    if (course) {
      const courseStatus = course.status;

      if (courseStatus === CourseStatus.CANCELLED) {
        return getStatusDisplay(courseStatus);
      }
    }

    return '';
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <Typography variant="h3" className={classes.header}>
            {course?.studySubject.studySubjectName}
          </Typography>
          <Typography variant="h4">{course?.school.schoolName}</Typography>

          <Typography variant="h5" className={classes.header}>
            <RoomOutlined /> Oppmøtested
          </Typography>
          <Typography variant="body1">{course?.roomNumber}</Typography>
          {!!course?.school && (
            <Link
              underline="always"
              href={getSchoolAddressLink(course?.school)}
              target="_blank"
            >
              <Typography variant="body1">
                {course?.school.address?.street},{' '}
                {course?.school.address?.zipCode},{' '}
                {course?.school.address?.city}
              </Typography>
            </Link>
          )}

          <Typography variant="h5" className={classes.header}>
            <AccessTimeOutlined /> Oppmøtetidspunkt
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">
              {course?.startDate instanceof Date
                ? format(course.startDate, 'dd. MMM    HH:mm') +
                  ' - ' +
                  format(course.endDate, 'HH:mm')
                : course &&
                  format(new Date(course.startDate), 'dd. MMM    HH:mm') +
                    ' - ' +
                    format(new Date(course.endDate), 'HH:mm')}
            </Typography>

            <Typography variant="body1" color="error">
              {getStatus()}
            </Typography>
          </Box>

          {!!mainContactPerson && (
            <>
              <Typography variant="h5" className={classes.header}>
                Kontaktperson
              </Typography>
              <Typography variant="body1">
                {course?.courseHolder?.fullName}
              </Typography>
              <Typography variant="body1">
                <PhoneIphoneOutlined /> {course?.courseHolder?.phoneNumber}
              </Typography>
            </>
          )}

          <Typography variant="h5" className={classes.header}>
            Informasjon
          </Typography>
          <Typography variant="body1">
            {ReactHtmlParser(course?.courseInfo ?? '')}
          </Typography>
        </Card>
      </div>
    </div>
  );
};

export default observer(StudentCourseInformation);
