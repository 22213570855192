import {
  AppBar,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateEditProjectDrawer } from '../../../components/admin/CreateEditProjectDrawer';
import { useStores } from '../../../stores';
import { UserRole } from '../../../types/UserRole';
import { ReactComponent as Logo } from './../../../assets/OsloskolenLogo-hvit.svg';
//import AppBarIcons from './components/AppBarIcons';
import DrawerMenu from './components/DrawerMenu';
import RoleSelector from './components/RoleSelector';
import UserInformation from './components/UserInformation';
import useLayoutStyle from './layoutStyle';

const MainLayout: FC = ({ children }) => {
  const classes = useLayoutStyle();
  const { userStore, stateStore, projectStore } = useStores();
  const { setDrawerMenuIsOpen, setAddProjectFormIsOpen } = stateStore;
  const { user } = userStore;

  const isStudent = user?.currentRole === UserRole.Student;
  const { breakpoints } = useTheme();
  const isExtraSmall = useMediaQuery(breakpoints.down('xs'));

  const history = useHistory();

  const handleStateAddProjectForm = React.useCallback(() => {
    setAddProjectFormIsOpen(!!projectStore.editingProject);
  }, [projectStore.editingProject, setAddProjectFormIsOpen]);

  React.useEffect(() => {
    handleStateAddProjectForm();
  }, [handleStateAddProjectForm]);

  return (
    <>
      {user?.hasMultipleRoles() && user.currentRole < 1 ? (
        <>
          <RoleSelector fullscreen />
        </>
      ) : (
        <>
          <AppBar className={classes.appBar}>
            <Toolbar variant="dense" className={classes.toolBar}>
              {!isStudent && (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  onClick={() => setDrawerMenuIsOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link onClick={() => history.push('/home')}>
                <Logo className={classes.logo} />
              </Link>
              {!isStudent && isExtraSmall ? null : (
                <Typography variant="h5" className={classes.title}>
                  Kurs i utdanningsprogram Oslo
                </Typography>
              )}
              {/* {!isStudent && <AppBarIcons />} */}
              <UserInformation />
            </Toolbar>
          </AppBar>
          <DrawerMenu />
          <main className={classes.main}>{children}</main>
          <CreateEditProjectDrawer
            closeDrawer={() => projectStore.closeProjectPanel()}
            openLeftMenu={() => {
              stateStore.setDrawerMenuIsOpen(true);
            }}
          ></CreateEditProjectDrawer>
        </>
      )}
    </>
  );
};
export default observer(MainLayout);
