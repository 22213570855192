export enum CourseOptionAdmin {
  CREATE_EDIT = 0,
  LIST_STUDENT = 1,
  INFO_STUDENT = 2,
}

export enum CourseOptionVGS {
  EDIT = 0,
  INFO_STUDENT = 1,
}
