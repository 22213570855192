import { ISchool } from '@/types/School';
import { User } from '@/types/User';
import { getSchoolAddressLink } from '@/utils/schoolUtils';
import { Card, IconButton, Link, Typography } from '@material-ui/core';
import { MailOutlined, PhoneIphoneOutlined } from '@material-ui/icons';
import useStyles from './style';

interface ISchoolHoverCardProps {
  school: ISchool;
  contactPerson?: User;
}

const SchoolHoverCard = ({ school, contactPerson }: ISchoolHoverCardProps) => {
  const currentContactPerson =
    contactPerson ?? school.contactPeople.filter((cp) => cp.isMain)[0]?.user;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <Typography variant="h2" className={classes.schoolName}>
          {school.schoolName}
        </Typography>
      </div>
      <Typography>
        <Link
          href={getSchoolAddressLink(school)}
          target="_blank"
          underline="always"
        >
          {school.address.street}, {school.address.zipCode}{' '}
          {school.address.city}
        </Link>
      </Typography>
      {!!currentContactPerson && (
        <div className={classes.contactContainer}>
          <Typography variant="h5" className={classes.contactName}>
            {currentContactPerson.fullName}
          </Typography>
          <div>
            <IconButton
              href={'mailto:' + currentContactPerson.emailAddress}
              target="_blank"
            >
              <MailOutlined />
            </IconButton>
            <IconButton
              href={'tel:' + currentContactPerson.phoneNumber}
              target="_blank"
            >
              <PhoneIphoneOutlined />
            </IconButton>
          </div>
        </div>
      )}
    </Card>
  );
};

export default SchoolHoverCard;
