import { IStudentCourse } from '@/types/StudentCourse';
import { Typography } from '@material-ui/core';

interface ITimeCellProps {
  course?: IStudentCourse;
}

const TimeCell = ({ course }: ITimeCellProps) => {
  const startDate = !!course?.finalAssignedCourse?.startDate
    ? new Date(course?.finalAssignedCourse?.startDate)
    : null;
  const endDate = !!course?.finalAssignedCourse?.endDate
    ? new Date(course?.finalAssignedCourse?.endDate)
    : null;

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        {startDate?.toLocaleDateString('no', {
          day: '2-digit',
          month: 'short',
        })}
      </Typography>
      <Typography variant="body1">
        {startDate?.toLocaleTimeString('no', {
          hour: '2-digit',
          minute: '2-digit',
        })}{' '}
        -{' '}
        {endDate?.toLocaleTimeString('no', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </div>
  );
};

export default TimeCell;
