import { useStores } from '@/stores';
import { getDateTime } from '@/utils/timeUtils';
import courseStyle from '@/views/style/courses';
import { Typography } from '@material-ui/core';
import { EventOutlined, PersonOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';

const EditCourseInformation = () => {
  const { courseStore } = useStores();
  const { course } = courseStore;
  const courseClasses = courseStyle();
  return (
    <>
      <Typography variant="h3">
        {course?.studySubject?.studySubjectName
          ? course.studySubject.studySubjectName
          : 'Ikke definert'}
      </Typography>
      <div className={courseClasses.courseDateAndCapacityWrapper}>
        <div className={courseClasses.courseDateAndCapacity}>
          <EventOutlined />
          <Typography variant="body1">
            {getDateTime(course?.startDate, 'dd. LLL. yyyy')}
          </Typography>
        </div>

        <div className={courseClasses.courseDateAndCapacity}>
          <PersonOutline />
          <Typography variant="body1">{`Kapasitet: ${course?.capacity}`}</Typography>
        </div>
      </div>
    </>
  );
};

export default observer(EditCourseInformation);
