import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import evaluationThankStyle from './style';
import { ReactComponent as LoginIllustration } from '../../../assets/login-illustration.svg';

const EvaluationThank = () => {
  const classes = evaluationThankStyle();
  return (
    <Grid container direction="row" spacing={0} className={classes.rootGrid}>
      <Grid item xs={12} className={classes.banner}>
        <Typography variant={'h1'} className={classes.bannerHeaderText}>
          Tusen takk for din evaluering
        </Typography>
        <LoginIllustration className={classes.loginIllustration} />
      </Grid>
    </Grid>
  );
};

export default EvaluationThank;
