import { useStores } from '@/stores';
import { InputAdornment } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { TimePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';

interface IComponentProps {
  isDisableStartDate?: boolean;
  isDisableEndDate?: boolean;
}

const EditCourseTimes = (props: IComponentProps) => {
  const { isDisableStartDate, isDisableEndDate } = props;
  const { courseStore } = useStores();
  const { course } = courseStore;

  const setStartDate = (date: Date) => {
    course &&
      courseStore.setCourse({
        ...course,
        startDate: date,
      });
  };

  const setEndDate = (date: Date) => {
    course &&
      courseStore.setCourse({
        ...course,
        endDate: date,
      });
  };

  let start = DateTime.fromISO(course?.startDate as unknown as string)
    .toUTC()
    .toJSDate();

  let end = DateTime.fromISO(course?.endDate as unknown as string)
    .toUTC()
    .toJSDate();

  if (course?.startDate instanceof Date) {
    start = DateTime.fromJSDate(course?.startDate).toUTC().toJSDate();
  }
  if (course?.endDate instanceof Date) {
    end = DateTime.fromJSDate(course?.endDate).toUTC().toJSDate();
  }

  return (
    <>
      <TimePicker
        label="Starttidspunkt"
        value={start}
        onChange={(d) => {
          if (d) {
            setStartDate(d.toUTC().toJSDate());
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccessTime />
            </InputAdornment>
          ),
        }}
        ampm={false}
        disabled={isDisableStartDate}
      />
      <TimePicker
        label="Sluttidspunkt"
        value={end}
        onChange={(d) => {
          if (d) setEndDate(d.toUTC().toJSDate());
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccessTime />
            </InputAdornment>
          ),
        }}
        ampm={false}
        disabled={isDisableEndDate}
      />
    </>
  );
};

export default observer(EditCourseTimes);
