import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CircularProgress } from '@material-ui/core';

interface IComponentProps {
  content: string | null | undefined;
  setContent: (content: string) => void;
  disabled?: boolean;
}

const CustomEditor = (props: IComponentProps) => {
  const { content, setContent, disabled } = props;

  const config = {
    toolbar: {
      items: ['undo', 'redo', 'bold', 'alignment', 'link'],
      shouldNotGroupWhenFull: true,
    },
    language: 'nb',
    fontFamily: {
      supportAllValues: true,
    },
  };

  return (
    <>
      <React.Suspense fallback={<CircularProgress />}>
        <CKEditor
          editor={Editor}
          disabled={disabled}
          data={content ?? ''}
          onReady={(editor: any) => {
            if (editor) {
              editor.setData(content ?? '');
            }
          }}
          onChange={(event: any, editor: any) => {
            if (editor) {
              const html = editor.getData();

              setContent(html || '<p></p>');
            }
          }}
          config={config}
        />
      </React.Suspense>
    </>
  );
};

export default CustomEditor;
