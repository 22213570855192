import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { ReactNode } from 'react';
import clsx from 'clsx';

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    backgroundColor: palette.primary['50'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '56px',
    padding: '2px 115px',
    [breakpoints.down('md')]: {
      padding: '2px 5px',
    },
  },
  searchInput: {
    width: '50%',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  pointer: { cursor: 'pointer' },
}));

interface IAdminSearchBarProps {
  handleSearchValueChange: (val: string) => void;
  label: string;
  children?: ReactNode;
  searchStudent?: () => void;
}

const AdminSearchBar = ({
  handleSearchValueChange,
  label,
  children,
  searchStudent,
}: IAdminSearchBarProps) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.searchInput}
        onChange={(e) => handleSearchValueChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Search
                onClick={() => {
                  if (searchStudent) {
                    searchStudent();
                  }
                }}
                classes={{ root: clsx({ [classes.pointer]: !!searchStudent }) }}
              />
            </InputAdornment>
          ),
        }}
        label={label}
      />
      <div className={classes.actions}>{children}</div>
    </div>
  );
};

export default AdminSearchBar;
