import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  content: {
    width: '600px',
    ...flexCol,
    gap: '6px',
    overflow: 'auto',
    justifyContent: 'flex-start',
    padding: '16px',
  },
  courseOptionHeader: {
    ...flexRow,
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  pointer: { cursor: 'pointer' },
  studySubjectName: {
    marginBottom: '24px',
  },
  commonBottom: {
    marginBottom: '16px',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '1rem',
  },
}));

export default useStyles;
