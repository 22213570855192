import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  content: {
    padding: '24px',
    width: '212px',
    height: '98px',
  },
  countStyle: {
    color: palette.grey.A100,
    fontSize: '24px',
    lineHeight: '34px',
  },
  titleStyle: {
    color: palette.primary[900],
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

export default useStyles;
