import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LoginForm from './components/LoginForm';
import useLoginStyle from './style';
import { ReactComponent as LoginIllustration } from '../../../assets/login-illustration.svg';
import Logo from '../../../components/Logo';

const Login = () => {
  const classes = useLoginStyle();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container direction="row" spacing={0} className={classes.rootGrid}>
        <Grid item xs={12} md={7} className={classes.banner}>
          <div className={classes.logoHeader}>
            <Logo />
            <Typography variant="h5">Kurs i utdanningsprogram Oslo</Typography>
          </div>
          <Typography
            variant={isSmall ? 'h2' : 'h1'}
            className={classes.bannerHeaderText}
          >
            Utdanning og yrker
          </Typography>
          <Typography
            variant={isSmall ? 'h5' : 'h4'}
            className={classes.bannerSubheaderText}
          >
            ønsker, drømmer, evner, anlegg og interesser
          </Typography>
          <LoginIllustration className={classes.loginIllustration} />
        </Grid>
        <Grid item xs={12} md={5} className={classes.loginGrid}>
          <LoginForm />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
