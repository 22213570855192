import { StudySubjectCategory } from '@/types/StudySubject';
import { Chip } from '@material-ui/core';
import StudySubjectCategoryChip from '../StudySubjectCategoryChip';
import useStyles from './style';

interface IStudySubjectFilterProps {
  chosenCategory: StudySubjectCategory;
  setChosenCategory: (chosenCategory: StudySubjectCategory) => void;
  deactivatedFilterValue: string;
  setDeactivatedFilterValue: (deactivatedFilterValue: string) => void;
  isHideFilterValue: string;
  setIsHideFilterValue: (isHideFilterValue: string) => void;
  isAdmin: boolean;
}

const StudySubjectFilter = ({
  chosenCategory,
  setChosenCategory,
  deactivatedFilterValue,
  setDeactivatedFilterValue,
  isHideFilterValue,
  setIsHideFilterValue,
  isAdmin,
}: IStudySubjectFilterProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.filterWrapper}>
        <div className={classes.filterChips}>
          <StudySubjectCategoryChip
            label="Alle"
            category={StudySubjectCategory.Unknown}
            isActive={chosenCategory === StudySubjectCategory.Unknown}
            setCurrentCategory={setChosenCategory}
          />
          <StudySubjectCategoryChip
            category={StudySubjectCategory.Studieforberedende}
            isActive={
              chosenCategory === StudySubjectCategory.Studieforberedende
            }
            setCurrentCategory={setChosenCategory}
          />
          <StudySubjectCategoryChip
            category={StudySubjectCategory.Yrkesfaglig}
            isActive={chosenCategory === StudySubjectCategory.Yrkesfaglig}
            setCurrentCategory={setChosenCategory}
          />
        </div>
        {isAdmin && (
          <div className={classes.filterChips}>
            <Chip
              label="Alle"
              className={
                deactivatedFilterValue === 'all'
                  ? classes.activeChip
                  : classes.chip
              }
              clickable={false}
              onClick={() => {
                setDeactivatedFilterValue('all');
                setIsHideFilterValue('all');
              }}
            />
            <Chip
              label="Aktive"
              className={
                deactivatedFilterValue === 'active'
                  ? classes.activeChip
                  : classes.chip
              }
              clickable={false}
              onClick={() => setDeactivatedFilterValue('active')}
            />
            <Chip
              label="Deaktiverte"
              className={
                deactivatedFilterValue === 'deactivated'
                  ? classes.activeChip
                  : classes.chip
              }
              clickable={false}
              onClick={() => setDeactivatedFilterValue('deactivated')}
            />
            <Chip
              label="Skjul for påmelding"
              className={
                isHideFilterValue === 'isHide'
                  ? classes.activeChip
                  : classes.chip
              }
              clickable={false}
              onClick={() => {
                isHideFilterValue === 'isHide'
                  ? setIsHideFilterValue('all')
                  : setIsHideFilterValue('isHide');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StudySubjectFilter;
