import IStudySubject from './StudySubject';
import { User } from './User';

export interface ICourseTemplate {
  id: number;
  studySubjectId: number;
  studySubject: IStudySubject;
  roomNumber: string;
  courseHolderId: number;
  courseHolder: User;
  projectId: number;
  courseInfo: string;
  externalSchoolId: string;
}

export class CreateUpdateCourseTemplate {
  id?: number;
  studySubjectId = 0;
  roomNumber = '';
  courseHolderId = 0;
  projectId = 0;
  courseInfo = '';
  externalSchoolId = '';

  static fromICourseTemplate(
    model: ICourseTemplate
  ): CreateUpdateCourseTemplate {
    const result = new CreateUpdateCourseTemplate();
    result.id = model.id;
    result.studySubjectId = model.studySubjectId;
    result.roomNumber = model.roomNumber;
    result.courseHolderId = model.courseHolderId;
    result.projectId = model.projectId;
    result.courseInfo = model.courseInfo;
    result.externalSchoolId = model.externalSchoolId;
    return result;
  }
}
