import { makeStyles } from '@material-ui/core';

const useNestedStudentTableStyles = makeStyles(() => ({
  nestedWrapperCell: {
    padding: 0,
    border: 'none',
  },
}));

export default useNestedStudentTableStyles;
