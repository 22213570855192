import { useStores } from '@/stores';
import { IStudentStudySubject } from '@/types/StudySubject';
import { User } from '@/types/User';
import {
  TableRow,
  TableCell,
  Collapse,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import useStyles from './style';
import clsx from 'clsx';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { UserRole } from '@/types/UserRole';
import { observer } from 'mobx-react';

interface IStudentRowDropdownProps {
  open: boolean;
  student: User;
  assignedStudentStudySubject?: IStudentStudySubject;
  all: boolean;
  handleOpenChangeRegistration?: () => void;
  handleOpenManualAssign?: () => void;
}

const StudentRowDropdown = ({
  open,
  student,
  assignedStudentStudySubject,
  all,
  handleOpenChangeRegistration,
  handleOpenManualAssign
}: IStudentRowDropdownProps) => {
  const classes = useStyles({ open });

  const { userStore, schoolStore, projectStore } = useStores();
  const { user } = userStore;
  const { setIsSetHasSpecialNeeds } = schoolStore;

  const handleSpecialNeedsChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    await userStore.setHasSpecialNeeds(student.id, e.target.checked);
    setIsSetHasSpecialNeeds(true);
  };

  return (
    <TableRow className={classes.collapseTableRow}>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={all ? 8 : 7}
      >
        <Collapse in={open} unmountOnExit>
          <div className={classes.collapse}>
            <div className={classes.chipList}>
              {student.studentStudySubjects.map((sub) => (
                <Chip
                  className={`${classes.subjectChip} ${
                    !!assignedStudentStudySubject &&
                    assignedStudentStudySubject.studySubjectId !==
                      sub.studySubjectId &&
                    classes.unassignedSubjectChip
                  }`}
                  label={`${sub.priority}. ${sub.studySubject.studySubjectName}`}
                  key={`chip-${sub.id}`}
                />
              ))}
            </div>
            <div className={clsx(classes.displayFlex, classes.boxBtn)}>
              {handleOpenChangeRegistration &&
                user?.currentRole === UserRole.Admin && (
                  <FormControlLabel
                    className={clsx(
                      classes.checkboxLabel,
                      classes.whiteSpaceNowrap
                    )}
                    control={
                      <CreateOutlinedIcon
                        className={classes.btnChangeRegistration}
                      />
                    }
                    label={
                      !student.studentStudySubjects.length
                        ? `Opprett påmelding`
                        : `Endre påmelding`
                    }
                    onClick={() => handleOpenChangeRegistration()}
                  />
                )}

              {student.studentStudySubjects.length === 0 && handleOpenManualAssign && projectStore.currentProject && (projectStore.currentProject.isPublished || projectStore.isCurrentProjectInCoursePeriod())  &&
              user?.currentRole === UserRole.Admin &&  (
                <FormControlLabel
                  className={clsx(
                    classes.checkboxLabel,
                    classes.whiteSpaceNowrap
                  )}
                  control={
                    <CreateOutlinedIcon
                      className={classes.btnChangeRegistration}
                    />
                  }
                  label={`Tildel elev`}
                  onClick={() => handleOpenManualAssign()}
                />
              )}

              <FormControlLabel
                className={clsx(
                  classes.checkboxLabel,
                  classes.whiteSpaceNowrap
                )}
                control={
                  <Checkbox
                    checked={student.hasSpecialNeeds}
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                      await handleSpecialNeedsChange(event);
                    }}
                    color="primary"
                  />
                }
                label="Elev med spesielle behov"
              />
            </div>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default observer(StudentRowDropdown);
