import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

interface Props {
  open: boolean;
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  collapseTableRow: {
    transition: '0.3s ease all',
    borderLeft: '3px solid',
    borderColor: ({ open }: Props) =>
      open ? palette.primary[200] : 'transparent',
    backgroundColor: ({ open }: Props) =>
      open ? palette.primary[50] : 'transparent',
  },
  collapse: {
    padding: '12px 0',
    ...flexRow,
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chipList: {
    ...flexRow,
    gap: '8px',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subjectChip: {
    height: '25px',
    lineHeight: '25px',
  },
  checkboxLabel: {
    height: '25px',
  },
  unassignedSubjectChip: {
    backgroundColor: palette.grey[200],
    borderColor: palette.grey[600],
    color: palette.grey[600],
  },
  displayFlex: {
    display: 'flex',
  },
  btnChangeRegistration: {
    marginRight: '10px',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  boxBtn: {
    marginLeft: '20px',
  },
}));

export default useStyles;
