import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette, typography }) => ({
  content: {
    width: '358px',
    ...flexCol,
    gap: '6px',
    overflow: 'auto',
    justifyContent: 'flex-start',
  },
}));

export default useStyle;
