import { useStores } from '@/stores';
import { CourseOptionVGS } from '@/types/enums/CourseOption';
import { observer } from 'mobx-react';
import React from 'react';
import EditCourse from './EditCourse';
import InfoStudent from './InfoStudent';

interface IComponentProps {
  tabMode: CourseOptionVGS;
  /** Info Student */
  searchInfoStudent: string;
  setSearchInfoStudent: (search: string) => void;
  /** Info Student */
}

const CourseOptions = ({
  tabMode,
  searchInfoStudent,
  setSearchInfoStudent,
}: IComponentProps) => {
  const { courseStore } = useStores();

  return (
    <>
      {tabMode === CourseOptionVGS.EDIT &&
        courseStore.courseId !== courseStore.initCourseId && <EditCourse isFromAdminPanel={false} />}
      {tabMode === CourseOptionVGS.INFO_STUDENT && (
        <InfoStudent
          search={searchInfoStudent}
          setSearch={setSearchInfoStudent}
        />
      )}
    </>
  );
};

export default observer(CourseOptions);
