import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';
import { TableHead, TableRow, TableCell, Typography } from '@material-ui/core';
import { FC } from 'react';
import clsx from 'clsx';

const NestedStudentTableHeader: FC = () => {
  const courseClasses = courseStyle();

  return (
    <TableHead className={courseClasses.rowColSpan}>
      <TableRow>
        <TableCell
          classes={{
            root: clsx(courseClasses.padding0, courseClasses.borderTable),
          }}
        ></TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.widthCheckbox,
              courseClasses.checkboxPadding,
              courseClasses.paddingDetail
            ),
          }}
        >
          <CustomCheckBox hide={true} />
        </TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.widthButtonDetail,
              courseClasses.buttonDetailPadding,
              courseClasses.paddingDetail
            ),
          }}
        >
          <CustomIconButton hide={true} />
        </TableCell>
        <TableCell
          colSpan={2}
          className={clsx(
            courseClasses.colorDetail,
            courseClasses.paddingDetail
          )}
        >
          <Typography variant="body1" className={courseClasses.subTableHeader}>
            Besøkende
          </Typography>
        </TableCell>
        <TableCell
          colSpan={3}
          className={clsx(
            courseClasses.colorDetail,
            courseClasses.paddingDetail
          )}
        >
          <Typography variant="body1" className={courseClasses.subTableHeader}>
            Fravær
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default NestedStudentTableHeader;
