export default interface IStudySubject {
  id: number;
  studySubjectName: string;
  description: string;
  category: StudySubjectCategory;
  deactivated: boolean;
  isHide: boolean;
}

export interface ISchoolStudySubject {
  id?: number;
  studySubjectId: number;
  studySubject: IStudySubject;
}

export interface IContactPeopleSubject {
  contactName: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface ISchoolContact {
  id: number;
  schoolName: string;
}

export interface ISchoolStudySubjectInfo {
  schoolContact: ISchoolContact;
  address: string;
  contactPeople: IContactPeopleSubject;
}

export interface IStudentStudySubject {
  id: number;
  studentId: number;
  priority: number;
  studySubjectId: number;
  studySubject: IStudySubject;
}

export interface IAdminStatisticSubjectCapacity {
  studySubject: IStudySubject;
  capacity: number;
  numberJoinedStudent: number;
}
export enum StudySubjectCategory {
  Unknown = 0,
  Studieforberedende = 1,
  Yrkesfaglig = 2,
}

export const getStudySubjectCategoryName = (category: StudySubjectCategory) =>
  ({ 0: 'Udefinert', 1: 'Studieforberedende', 2: 'Yrkesfaglig' }[category]);

export const emptyStudySubject: IStudySubject = {
  id: 0,
  studySubjectName: '',
  description: '',
  category: StudySubjectCategory.Unknown,
  deactivated: false,
  isHide: false,
};

export class StudentStudySubject {
  id?: number;
  studentId = 0;
  priority = 1;
  studySubjectId = 0;
  projectId = 0;
}
