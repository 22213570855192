import { Button, Paper, Typography } from '@material-ui/core';
import { ExitToAppOutlined, SettingsOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import { getNorwegianRole } from '../../../../types/UserRole';
import RoleSelector from './RoleSelector';
import { useUserInformationDropdownStyles } from './userInformationStyle';

const UserInformationDropdown = () => {
  const classes = useUserInformationDropdownStyles();
  const { userStore, accountStore, schoolStore } = useStores();
  const { user } = userStore;
  const { currentSchool } = schoolStore;
  const history = useHistory();

  useEffect(() => {
    if (!currentSchool?.externalId && user?.externalSchoolId)
      schoolStore.getSchoolByExternalId(user?.externalSchoolId);
  }, [currentSchool?.externalId, schoolStore, user?.externalSchoolId]);

  const logout = async () => {
    await accountStore.logout();
    history.push('/');
  };

  return (
    <Paper elevation={10} square className={classes.root}>
      <header className={classes.header}>
        <Typography variant="subtitle1">
          {currentSchool?.schoolName ?? 'Karriereenheten'}
        </Typography>
        <Button variant="text" size="small" onClick={logout}>
          <Typography>Logg ut</Typography>
          <span className={classes.iconSpan}>
            <ExitToAppOutlined />
          </span>
        </Button>
      </header>
      <main className={classes.main}>
        <Typography variant="h3">{user?.fullName}</Typography>
        <Typography variant="h5">
          <>
            {user ? getNorwegianRole(user.currentRole) : ''}
            {user?.switchRole !== 0 && <RoleSelector />}
          </>
        </Typography>
      </main>
      <footer>
        <Button variant="text" size="small">
          Kontoinstillinger
          <span className={classes.iconSpan}>
            <SettingsOutlined />
          </span>
        </Button>
      </footer>
    </Paper>
  );
};

export default observer(UserInformationDropdown);
