import { makeStyles } from '@material-ui/core';

const useLayoutStyle = makeStyles(({ breakpoints, palette, spacing }) => ({
  main: {
    [`${breakpoints.up('xs')}`]: {
      marginTop: 48,
      minHeight: 'calc(100vh - 48px)',
    },
    backgroundColor: palette.grey[50],
  },
  appBar: {
    flexGrow: 1,
    fill: '#fff',
  },
  toolBar: {
    gap: '16px',
  },
  menuButton: {
    marginRight: spacing(2),
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '36px',
  },
}));

export default useLayoutStyle;
