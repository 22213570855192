import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStores } from '../../../../stores';
import School, { SchoolType } from '../../../../types/School';
import AdminHeader from '../common/AdminHeader';
import AdminSearchBar from '../common/AdminSearchBar';
import AddHighSchoolDrawer from '../common/AddOrEditSchoolDrawer';
import SchoolCard from '../common/SchoolCard';
import useStyle from './style';
import { SchoolStatus } from '@/types/enums/SchoolStatus';
import ShoolStatusFilter from '../common/ShoolStatusFilter';
import { useHistory } from 'react-router-dom';

const HighSchools = () => {
  const [searchValue, setSearchValue] = useState('');
  const [chosenStudySubjects, setChosenStudySubjects] = useState<number[]>([]);

  const {
    studySubjectStore,
    schoolStore,
    stateStore,
    courseStore,
    projectStore,
  } = useStores();
  const { allStudySubjects } = studySubjectStore;
  const { setSchoolDrawerIsOpen } = stateStore;
  const { highSchools, setEditingSchool } = schoolStore;

  const classes = useStyle();

  const [filter, setFilter] = useState<SchoolStatus>(SchoolStatus.ACTIVATE);
  const handleChangeFilter = (value: SchoolStatus): void => {
    setFilter(value);
  };

  useEffect(() => {
    studySubjectStore.getAllStudySubjects();
    schoolStore.getPopulatedHighSchools(filter);
  }, [
    highSchools.length,
    allStudySubjects.length,
    schoolStore,
    studySubjectStore,
    filter,
  ]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChosenStudySubjects(event.target.value as number[]);
  };

  const handleOpenAddHighSchoolDrawer = () => {
    const newSchool = new School();
    newSchool.schoolType = SchoolType.HighSchool;

    setEditingSchool(newSchool);
    setSchoolDrawerIsOpen(true);
  };

  const searchFilter = (s: School) =>
    s.schoolName.toLowerCase().includes(searchValue.toLowerCase());
  const studySubjectFilter = (s: School) => {
    if (chosenStudySubjects.length === 0) return true;

    const schoolStudySubjectIds = s.studySubjects.map(
      (ss) => ss.studySubject.id
    );

    return chosenStudySubjects.some((ss) => schoolStudySubjectIds.includes(ss));
  };

  const filteredSchools = highSchools
    .filter(searchFilter)
    .filter(studySubjectFilter)
    .sort();

  useEffect(() => {
    if (!projectStore.currentProject) {
      projectStore.getCurrent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const redirectToScreenCourse = (externalId: string): void => {
    courseStore.setExternalHighSchoolId(externalId);
    history.push(`/projects/${projectStore.currentProject?.id}/courses`);
  };

  return (
    <>
      <header>
        <AdminHeader title="Videregående skoler i Oslo" />
        <AdminSearchBar
          handleSearchValueChange={setSearchValue}
          label="Søk etter skoler ved navn"
        >
          <IconButton onClick={handleOpenAddHighSchoolDrawer}>
            <Add />
          </IconButton>
        </AdminSearchBar>
      </header>
      <main className={classes.main}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.filterContainer}>
            <div className={classes.studySubjectFilterContainer}>
              <Typography variant="h6" color="primary">
                Filtrer på kurs
              </Typography>
              <FormControl className={classes.studySubjectFilter}>
                <Select
                  variant="filled"
                  multiple
                  value={chosenStudySubjects}
                  onChange={handleChange}
                  renderValue={(selected) =>
                    allStudySubjects
                      .filter((ss) => (selected as number[]).includes(ss.id))
                      .map((ss) => (
                        <Chip
                          variant="outlined"
                          key={'chip-' + ss.id}
                          label={ss.studySubjectName}
                        />
                      ))
                  }
                  MenuProps={{
                    variant: 'menu',
                  }}
                >
                  {allStudySubjects.map((ss) => (
                    <MenuItem key={'menu-item-' + ss.id} value={ss.id}>
                      {ss.studySubjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Typography
              variant="h6"
              color="primary"
              className={classes.counterText}
            >
              Viser {filteredSchools.length} av {highSchools.length}{' '}
              videregående skoler
            </Typography>
          </div>
          <ShoolStatusFilter
            filter={filter}
            handleChangeFilter={handleChangeFilter}
          />
        </div>
        <Grid container spacing={3}>
          {filteredSchools.map((school) => (
            <SchoolCard
              school={school}
              key={school.externalId + '-card'}
              redirectToScreenCourse={redirectToScreenCourse}
            />
          ))}
        </Grid>
      </main>
      <AddHighSchoolDrawer />
    </>
  );
};

export default observer(HighSchools);
