import IStudySubject, {
  IStudentStudySubject,
  StudentStudySubject,
} from '../types/StudySubject';
import BaseService from './BaseService';

class StudySubjectService extends BaseService<IStudySubject> {
  constructor() {
    super('studySubject');
  }

  getAllIncludingDeactivated = async () => {
    try {
      const res = await this.http.get('/all?includeDeactivated=true');
      return res.data as IStudySubject[];
    } catch (error) {
      console.error('Error getting all study subjects: ' + error);
    }
  };

  getByUserId = async (
    userId: number
  ): Promise<IStudentStudySubject[] | undefined> => {
    try {
      const res = await this.http.get('/byUserId/' + userId);
      return res.data as IStudentStudySubject[];
    } catch (error) {
      console.error('Could not get student study subjects by userId:' + error);
    }
  };

  async addOrUpdateStudentPriorities(
    userId: number,
    priorities: number[]
  ): Promise<IStudentStudySubject[] | undefined> {
    try {
      const res = await this.http.post('/updatePriorities', {
        userId,
        orderedStudySubjects: priorities,
      });
      return res.data as IStudentStudySubject[];
    } catch (error) {
      console.error(
        'Error updating students study subject priorities: ' + error
      );
    }
  }

  async getByExternalSchoolId(
    externalSchoolId: string
  ): Promise<IStudySubject[] | undefined> {
    try {
      const res = await this.http.get(
        `/byExternalSchoolId/${externalSchoolId}`
      );
      return res.data as IStudySubject[];
    } catch (error: any) {
      console.error('Error get by external school id: ' + externalSchoolId);
    }
  }

  async getStudySubjectByProjectId(
    projectId: number,
    externalSchoolId?: string
  ): Promise<IStudySubject[] | undefined> {
    try {
      const res = await this.http.get(`/byProjectId/${projectId}`, {
        params: {
          externalSchoolId,
        },
      });
      return res.data as IStudySubject[];
    } catch (error: any) {
      console.error('Error get by project id: ' + projectId);
    }
  }

  async addOrUpdate(
    models: StudentStudySubject[]
  ): Promise<boolean | undefined> {
    try {
      const res = await this.http.post(`/addOrUpdate`, models);
      return res.data as boolean;
    } catch (error: any) {
      console.error('Error addOrUpdate list StudentStudySubject');
    }
  }
}

export default new StudySubjectService();
