import MessageTemplateAttributes from '../enums/MessageTemplateAttribute';
import MessageTemplateName from '../enums/MessageTemplateName';

const attr = MessageTemplateAttributes;
const tem = MessageTemplateName;

const availableMessageTemplateAttributes: {
  [key in MessageTemplateName]: MessageTemplateAttributes[];
} = {
  [tem.StudentAddedPrioritization]: [attr.USER_NAME, attr.LIST_OF_COURSES],
  [tem.StudentAlteredPrioritization]: [attr.USER_NAME, attr.LIST_OF_COURSES],
  [tem.StudentCourseConfirmed]: [
    attr.USER_NAME,
    attr.COURSE_NAME,
    attr.COURSE_SCHOOL_NAME,
    attr.COURSE_SCHOOL_ADDRESS,
    attr.COURSE_ROOM_NUMBER,
    attr.COURSE_DATE_TIME,
  ],
  [tem.StudentEvaluation]: [
    attr.USER_NAME,
    attr.COURSE_NAME,
    attr.COURSE_SCHOOL_NAME,
    attr.COURSE_SCHOOL_ADDRESS,
    attr.COURSE_ROOM_NUMBER,
    attr.COURSE_DATE_TIME,
    attr.EVALUATION_LINK,
  ],
  [tem.GUProritizationClosingReminder]: [
    attr.USER_NAME,
    attr.END_OF_BOOKING,
    attr.PORTAL_LINK,
  ],
  [tem.GUEvaluationMessage]: [attr.USER_NAME, attr.PORTAL_LINK],
  [tem.EventCancelled]: [
    attr.USER_NAME,
    attr.COURSE_NAME,
    attr.COURSE_SCHOOL_NAME,
    attr.COURSE_SCHOOL_ADDRESS,
    attr.COURSE_ROOM_NUMBER,
    attr.COURSE_DATE_TIME,
  ],
};

export default availableMessageTemplateAttributes;
