import { IStatisticSubjectCapacity } from '@/types/StudentStudySubject';
import React from 'react';
import Row from './row';

interface IComponentProps {
  data: IStatisticSubjectCapacity[];
  onClickLabel?: (studySubjectId: number) => void;
}

const CustomBarChart = (props: IComponentProps) => {
  const { data, onClickLabel } = props;
  const [maxCapacity, setMaxCapacity] = React.useState<number>(0);
  const [items, setItems] = React.useState<IStatisticSubjectCapacity[][]>([[]]);

  React.useEffect(() => {
    const max = _getMaxCapacity(data);
    const items = _getItems(data);
    setMaxCapacity(max);
    setItems(items);
  }, [data]);

  const _getItems = (
    data: IStatisticSubjectCapacity[]
  ): IStatisticSubjectCapacity[][] => {
    const items = [];
    let item = [];
    let isFirst = true;
    for (let j = 0; j < data.length; j++) {
      if (isFirst) {
        if (item.length < 9) {
          item.push(data[j]);
        } else {
          items.push(item);
          item = [];
          isFirst = false;
          j--;
        }
      } else {
        if (item.length < 11) {
          item.push(data[j]);
        } else {
          items.push(item);
          item = [];
          item.push(data[j]);
        }
      }
    }

    if (item.length > 0) {
      items.push(item);
    }
    return items;
  };

  const _getMaxCapacity = (data: IStatisticSubjectCapacity[]): number => {
    let max = 0;

    if (data.length > 0) {
      max = data[0].capacity;
      for (let i = 1; i < data.length; i++) {
        if (data[i].capacity > max) {
          max = data[i].capacity;
        }
      }
    }

    return max;
  };

  return (
    <>
      {items.map((item, index) => {
        if (index === 0) {
          return (
            <Row
              key={index}
              maxCapacity={maxCapacity}
              data={item}
              onClickLabel={onClickLabel}
              isLegend={true}
            />
          );
        } else {
          return (
            <Row
              key={index}
              maxCapacity={maxCapacity}
              data={item}
              onClickLabel={onClickLabel}
            />
          );
        }
      })}
    </>
  );
};

export default CustomBarChart;
