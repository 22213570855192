import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  listWrapper: {
    maxWidth: '1300px',
    ...flexCol,
    gap: '6px',
    padding: '0 115px',
    [breakpoints.down('md')]: {
      padding: '0 5px',
    },
  },
}));

export default useStyles;
