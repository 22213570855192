import { createContext, useContext } from 'react';
import AccountStore from './AccountStore';
import AdminDashboardStore from './AdminDashboard.store';
import CourseStore from './CourseStore';
import CourseTemplateStore from './CourseTemplateStore';
import DashboardStore from './DashboardStore';
import ErrorStore from './ErrorStore';
import MessageStore from './MessageStore';
import MessageTemplateStore from './MessageTemplateStore';
import ProjectStore from './ProjectStore';
import SchoolStore from './SchoolStore';
import StateStore from './StateStore';
import StudentCourseStore from './StudentCourseStore';
import StudySubjectStore from './StudySubjectStore';
import UserStore from './UserStore';
import UserSurveyStore from './UserSurveyStore';

export class RootStore {
  public userStore: UserStore;
  public accountStore: AccountStore;
  public stateStore: StateStore;
  public schoolStore: SchoolStore;
  public studySubjectStore: StudySubjectStore;
  public courseStore: CourseStore;
  public projectStore: ProjectStore;
  public errorStore: ErrorStore;
  public adminDashboardStore: AdminDashboardStore;
  public messageTemplateStore: MessageTemplateStore;
  public messageStore: MessageStore;
  public studentCourseStore: StudentCourseStore;
  public dashboardStore: DashboardStore;
  public userSurveyStore: UserSurveyStore;
  public courseTemplateStore: CourseTemplateStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.accountStore = new AccountStore(this);
    this.stateStore = new StateStore(this);
    this.schoolStore = new SchoolStore(this);
    this.studySubjectStore = new StudySubjectStore(this);
    this.courseStore = new CourseStore(this);
    this.projectStore = new ProjectStore(this);
    this.errorStore = new ErrorStore(this);
    this.adminDashboardStore = new AdminDashboardStore(this);
    this.messageTemplateStore = new MessageTemplateStore(this);
    this.messageStore = new MessageStore(this);
    this.studentCourseStore = new StudentCourseStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.userSurveyStore = new UserSurveyStore(this);
    this.courseTemplateStore = new CourseTemplateStore(this);
  }
}

const StoresContext = createContext(new RootStore());

export const useStores = () => useContext(StoresContext);
