import {ControlledInputText} from '@/components/ControlledInputText';
import {useStores} from '@/stores';
import {Button, Drawer, FormControl, InputLabel, MenuItem, Select, Typography,} from '@material-ui/core';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import userSettingStyle from '../../style';
import clsx from 'clsx';
import CustomDatePicker from '@/components/CustomDatePicker';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {getNorwegianRole, UserRole} from '@/types/UserRole';
import School from '@/types/School';
import {IClass} from '@/types/Class';

interface IComponentProps {
  createOrUpdateUserDrawerIsOpen: boolean;
  handleCloseCreateOrUpdateUserDrawer: (open: boolean) => void;
  changeUserRole: (role: UserRole) => void;
  changeSchool: (externalSchoolId: string) => void;
  changeClass: (externalClassId: string) => void;
  changeDateOfBirth: (data: Date) => void;
  changeUserName: (data: string) => void;
  changeFullName: (data: string) => void;
  changeEmailAddress: (data: string) => void;
  changePhoneNumber: (data: string) => void;
  handleCreateOrUpdate: () => Promise<void>;
  errors: Record<string, string>;
}

const CreateOrUpdateUserDrawer = (props: IComponentProps) => {
  const classes = userSettingStyle();
  const { userStore, schoolStore } = useStores();
  const [roles, setRoles] = useState<string[]>([]);
  const { createOrUpdate } = userStore;
  React.useEffect(() => {
    if (userStore.user?.currentRole === UserRole.Admin) {
      setRoles(Object.keys(UserRole));
    }
    if (userStore.user?.role === UserRole.HighSchoolContact) {
      setRoles(['4']);
    }
    if (userStore.user?.role === UserRole.Adviser) {
      setRoles(['1', '2']);
    }
  }, [userStore]);
  const {
    createOrUpdateUserDrawerIsOpen,
    handleCloseCreateOrUpdateUserDrawer,
    changeUserRole,
    changeSchool,
    changeClass,
    changeDateOfBirth,
    changeUserName,
    changeFullName,
    changeEmailAddress,
    changePhoneNumber,
    handleCreateOrUpdate,
    errors,
  } = props;

  return (
    <Drawer
      open={createOrUpdateUserDrawerIsOpen}
      onClose={() => handleCloseCreateOrUpdateUserDrawer(false)}
      anchor="right"
    >
      <div className={classes.contentDrawer}>
        <Typography variant="h3" className={classes.titleDrawer}>
          {userStore.createOrUpdate.id ? `Rediger bruker` : `Opprett ny bruker`}

        </Typography>
        <div className={classes.bodyDrawer}>
          <Typography variant="h4" className={classes.titleFillInfo}>
            Fyll ut opplysninger
          </Typography>

          <ControlledInputText
            label={'Fødselsnummer*'}
            value={createOrUpdate.userName}
            setValue={(value: string) => changeUserName(value)}
            customClass={clsx(classes.widthFull, classes.textFill)}
            hasError={!!errors.userName}
          />

          <CustomDatePicker
            label={'Fødselsdato*'}
            value={createOrUpdate.dateOfBirth}
            onChange={(data: MaterialUiPickersDate) => {
              if (data) {
                changeDateOfBirth(data.toJSDate());
              }
            }}
            customClass={clsx(classes.widthFull, classes.textFill)}
            hasError={!!errors.dateOfBirth}
          />

          <ControlledInputText
            label={'Fullt navn*'}
            value={createOrUpdate.fullName}
            setValue={(value: string) => changeFullName(value)}
            customClass={clsx(classes.widthFull, classes.textFill)}
            hasError={!!errors.fullName}
          />

          <ControlledInputText
            label={'E-post*'}
            value={createOrUpdate.emailAddress}
            setValue={(value: string) => changeEmailAddress(value)}
            customClass={clsx(classes.widthFull, classes.textFill)}
            hasError={!!errors.emailAddress}
          />

          <ControlledInputText
            label={'Mobile*'}
            value={createOrUpdate.phoneNumber}
            setValue={(value: string) => changePhoneNumber(value)}
            customClass={clsx(classes.widthFull, classes.textFill)}
            hasError={!!errors.phoneNumber}
          />

          <div className={clsx(classes.widthFull, classes.textFill)}>
            <FormControl
              variant="filled"
              className={classes.widthFull}
              // disabled={!!userStore.createOrUpdate.id}
            >
              <InputLabel>Brukerrolle</InputLabel>
              <Select
                label={'Brukerrolle'}
                value={createOrUpdate.role}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  changeUserRole(e.target.value as UserRole)
                }
              >
                {roles
                  .filter((k: string) => typeof UserRole[k as any] === 'string')
                  .map((k1: string) => +k1)
                  .map((k2: number) => (
                    <MenuItem key={k2} value={k2}>
                      {getNorwegianRole(k2)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {(userStore.createOrUpdate.role === UserRole.HighSchoolContact ||
            userStore.createOrUpdate.role === UserRole.Adviser ||
            userStore.createOrUpdate.role === UserRole.Admin ||
            userStore.createOrUpdate.role === UserRole.Student) && (
            <div className={clsx(classes.widthFull, classes.textFill)}>
              <FormControl variant="filled" className={classes.widthFull}>
                <InputLabel>Skole</InputLabel>
                <Select
                  label={'Skole'}
                  value={createOrUpdate.externalSchoolId}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    changeSchool(e.target.value as string)
                  }
                >
                  {schoolStore.schoolFilterByUserRole.map((school: School) => (
                    <MenuItem key={school.externalId} value={school.externalId}>
                      {school.schoolName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {userStore.createOrUpdate.role === UserRole.Student && (
            <div className={clsx(classes.widthFull, classes.textFill)}>
              <FormControl variant="filled" className={classes.widthFull}>
                <InputLabel>Klasse</InputLabel>
                <Select
                  label={'Klasse'}
                  value={createOrUpdate.externalClassId}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    changeClass(e.target.value as string)
                  }
                >
                  {schoolStore.listClass.map((classes: IClass) => (
                    <MenuItem
                      key={classes.externalId}
                      value={classes.externalId}
                    >
                      {classes.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* {Object.keys(errors).length > 0 && (
            <div className={clsx(classes.boxWarning, classes.displayFlex)}>
              <div className={classes.contentErrorTitleOnDrawer}>
                <ErrorOutlineOutlinedIcon
                  classes={{
                    root: clsx(classes.iconErrorOnDrawer, classes.iconSize),
                  }}
                />
              </div>
              <div className={classes.contentErrorOnDrawer}>
                <div className={classes.contentErrorTitleOnDrawer}>Fyll ut</div>
                <ul className={classes.contentListErrorOnDrawer}>
                  {Object.values(errors).map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )} */}

          <div className={clsx(classes.displayFlex, classes.groupBtnDrawer)}>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => await handleCreateOrUpdate()}
            >
              {userStore.createOrUpdate.id ? `Lagre` : `Opprett`}
            </Button>
            <Button
              variant="text"
              onClick={() => handleCloseCreateOrUpdateUserDrawer(false)}
              style={{ marginLeft: '12px' }}
            >
              Avbryt
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default observer(CreateOrUpdateUserDrawer);
