import { useStores } from '@/stores';
import {
  AppBar,
  Button,
  CircularProgress,
  Drawer,
  Tab,
  Typography,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { useState } from 'react';
import SelectContactPeople from './SelectContactPeople';
import HighSchoolInformation from './HighSchoolInformation';
import useStyle from './style';
import { SchoolType } from '@/types/School';
import GUInformation from './GUInformation';

const AddOrEditSchoolDrawer = () => {
  const { schoolStore, stateStore } = useStores();
  const { editingSchool } = schoolStore;
  const { schoolDrawerIsOpen, setSchoolDrawerIsOpen } = stateStore;

  const [tabValue, setTabValue] = useState('info');
  const [loading, setLoading] = useState(false);

  const classes = useStyle();

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    schoolStore.resetEditingSchool();
    setSchoolDrawerIsOpen(false);
  };

  const isHighSchool = editingSchool.schoolType === SchoolType.HighSchool;

  return (
    <Drawer anchor="right" open={schoolDrawerIsOpen} onClose={onClose}>
      <Typography variant="h3" className={classes.header}>
        {editingSchool?.externalId
          ? `Rediger ${isHighSchool ? 'VGS' : 'ungdomsskole'}`
          : `Legg til ${isHighSchool ? 'VGS' : 'ungdomsskole'}`}
      </Typography>
      <TabContext value={tabValue}>
        <AppBar position="relative" className={classes.tabAppBar}>
          <TabList onChange={handleTabChange} variant="fullWidth">
            <Tab label="Informasjon" value="info" />
            <Tab label="Kontakt" value="contact" />
          </TabList>
        </AppBar>
        <TabPanel value="info" className={classes.tabPanels}>
          {editingSchool.schoolType === SchoolType.HighSchool ? (
            <HighSchoolInformation
              setLoading={setLoading}
              setTab={setTabValue}
            />
          ) : (
            <GUInformation setLoading={setLoading} />
          )}
        </TabPanel>
        <TabPanel value="contact" className={classes.tabPanels}>
          <SelectContactPeople />
        </TabPanel>
      </TabContext>
      <div className={classes.buttonContainer}>
        <Button
          disabled={loading}
          type="submit"
          form={isHighSchool ? 'edit-high-school-form' : 'edit-gu-form'}
          onClick={() => setTabValue('info')}
        >
          {editingSchool?.externalId ? 'Lagre' : 'Opprett'}
          {loading ? (
            <CircularProgress size={24} className={classes.loadingButton} />
          ) : null}
        </Button>
        <Button variant="text" onClick={onClose}>
          Avbryt
        </Button>
      </div>
    </Drawer>
  );
};

export default observer(AddOrEditSchoolDrawer);
