import courseService from '@/services/CourseService';
import ICourse, {
  CreateUpdateCourse,
  IListRequestQuery,
  State,
} from '@/types/Course';
import {
  PaginationRequest,
  SELECT_ALL,
} from '@/types/dto/PaginationRequest.dto';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import { CourseStatus } from '@/types/enums/CourseStatus';
import { IUserDto } from '@/types/User';
import { getBeginDate, getEndDate } from '@/utils/timeUtils';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from '.';

class CourseStore {
  rootStore: RootStore;
  courses: ICourse[];
  totalCount: number;
  currentCourse: CreateUpdateCourse;
  courseId: number;
  initCourseId = 0;
  course?: ICourse;
  courseStatusHeadCell: CourseStatus[];
  courseIds: number[];
  undoStack: State[];
  redoStack: State[];
  normalStack: number;
  newCourse?: ICourse;
  loadingCourseTable: boolean;
  externalHighSchoolId: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.courses = [];
    this.totalCount = 0;
    this.currentCourse = new CreateUpdateCourse();
    this.courseId = this.initCourseId;
    this.courseStatusHeadCell = [];
    this.courseIds = [];
    this.undoStack = [];
    this.redoStack = [];
    this.normalStack = 0;
    this.loadingCourseTable = false;
    this.externalHighSchoolId = SELECT_ALL;

    makeObservable(this, {
      courses: observable,
      totalCount: observable,
      currentCourse: observable,
      courseId: observable,
      course: observable,
      courseStatusHeadCell: observable,
      courseIds: observable,
      undoStack: observable,
      redoStack: observable,
      normalStack: observable,
      newCourse: observable,
      loadingCourseTable: observable,
      externalHighSchoolId: observable,

      setCourses: action,
      setTotalCount: action,
      setCurrentCourse: action,
      setCourseId: action,
      resetCourseId: action,
      resetCourse: action,
      setCourse: action,
      setCourseStatusHeadCell: action,
      setCourseIds: action,
      resetCourseIds: action,
      insertState: action,
      setNewCourse: action,
      setLoadingCourseTable: action,
      setExternalHighSchoolId: action,
    });
  }

  setCourses(data: ICourse[]) {
    this.courses = data;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setCurrentCourse(course: CreateUpdateCourse) {
    this.currentCourse = course;
  }

  setCourseId(id: number) {
    this.courseId = id;
  }

  resetCourseId() {
    this.courseId = this.initCourseId;
  }

  resetCourse() {
    this.course = undefined;
  }

  setCourse(course: ICourse | undefined) {
    this.course = course;
  }

  setNewCourse(course: ICourse | undefined) {
    this.newCourse = course;
  }

  setCourseStatusHeadCell(data: CourseStatus[]) {
    this.courseStatusHeadCell = data;
  }

  setCourseIds(courseIds: number[]) {
    this.courseIds = courseIds;
  }

  resetCourseIds() {
    this.courseIds = [];
  }

  insertState(state: State): void {
    this.normalStack += 1;
    this.redoStack.push(state);
  }

  setLoadingCourseTable(loading: boolean): void {
    this.loadingCourseTable = loading;
  }

  setExternalHighSchoolId(externalId: string): void {
    this.externalHighSchoolId = externalId;
  }

  async getList(model: IListRequestQuery): Promise<void> {
    model = {
      ...model,
      beginStartDate: getBeginDate(model.beginStartDate),
      endStartDate: getEndDate(model.endStartDate),
    };
    this.setLoadingCourseTable(true);
    this.setCourses([]);
    this.setTotalCount(0);
    const { data, count } = await courseService.getList(model);
    this.setCourses(data);
    this.setTotalCount(count);
    this.setLoadingCourseTable(false);
  }

  async getStudentsForDetailCourse(
    id: number,
    model: PaginationRequest
  ): Promise<PaginationResult<IUserDto>> {
    return await courseService.getStudentsForDetailCourse(id, model);
  }

  async createCourse(model: CreateUpdateCourse): Promise<boolean> {
    return await courseService.createCourse(model);
  }

  async updateCourse(
    id: number,
    model: CreateUpdateCourse
  ): Promise<CreateUpdateCourse> {
    return await courseService.updateCourse(id, model);
  }

  async getUpdateCourseById(id: number): Promise<void> {
    const updateCourse = await courseService.getUpdateCourseById(id);
    this.setCurrentCourse(updateCourse);
  }

  async getCourseById(id: number, isNewCourse?: boolean): Promise<void> {
    if (isNewCourse) {
      this.setNewCourse(undefined);
    } else {
      this.setCourse(undefined);
    }

    const data = await courseService.getCourseById(id);

    if (isNewCourse) {
      this.setNewCourse(data);
    } else {
      this.setCourse(data);
    }
  }

  async getListVGS(model: IListRequestQuery): Promise<void> {
    this.setLoadingCourseTable(true);
    this.setCourses([]);
    this.setTotalCount(0);
    const { data, count } = await courseService.getListVGS(model);
    this.setCourses(data);
    this.setTotalCount(count);
    this.setLoadingCourseTable(false);
  }

  async updateCourseVGS(id: number, model: CreateUpdateCourse): Promise<void> {
    await courseService.updateCourseVGS(id, model);
    const data = await courseService.getCourseById(id);
    this.setCourse(data);
    const index = this.courses.findIndex((c) => c.id === id);
    if (index > -1) {
      this.courses[index] = data;
    }
  }

  async getCourseStatusByProjectId(
    projectId: number,
    externalSchoolId?: string
  ): Promise<void> {
    const data = await courseService.getCourseStatusByProjectId(
      projectId,
      externalSchoolId
    );
    this.setCourseStatusHeadCell(data);
  }

  async deleteCourse(id: number): Promise<State> {
    const result = await courseService.deleteCourse(id);
    return result;
  }

  async duplicateCourse(id: number): Promise<State> {
    const result = await courseService.duplicateCourse(id);
    return result;
  }

  async removeAllAllocations(courseIds: number[]): Promise<State> {
    const result = await courseService.removeAllAllocations(courseIds);
    return result;
  }

  async deleteListCourse(
    projectId: number,
    courseIds: number[]
  ): Promise<State> {
    const result = await courseService.deleteListCourse(projectId, courseIds);
    return result;
  }

  async undoCourse(projectId: number, state: State): Promise<boolean> {
    const result = await courseService.undoCourse(projectId, state);
    return result;
  }

  async redoCourse(projectId: number, state: State): Promise<boolean> {
    const result = await courseService.redoCourse(projectId, state);
    return result;
  }

  async getCourseExcelExport(projectId: number): Promise<void> {
    await courseService.getCourseExcelExport(projectId);
  }

  async updateCourseToUndoOrRedo(
    id: number,
    model: CreateUpdateCourse
  ): Promise<State> {
    const result = await courseService.updateCourseToUndoOrRedo(id, model);
    return result;
  }

  async getCoursesMinified(
    projectId: number,
    studySubjectId?: number
  ): Promise<ICourse[]> {
    const result = await courseService.getCoursesMinified(
      projectId,
      studySubjectId
    );
    return result;
  }

  async listParticipantOfCourseExport(
    projectId: number,
    courseId: number
  ): Promise<void> {
    await courseService.listParticipantOfCourseExport(projectId, courseId);
  }

  async updateStatusMultipleCourse(
    models: CreateUpdateCourse[]
  ): Promise<State> {
    const result = await courseService.updateStatusMultipleCourse(models);
    return result;
  }

  async getAllByProjectId(projectId: number): Promise<ICourse[]> {
    const result = await courseService.getAllByProjectId(projectId);
    return result;
  }
}
export default CourseStore;
