import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide, Typography
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { FormEvent, forwardRef} from 'react';
import { useStores } from '@/stores';

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PublishDialog = (props: IComponentProps) => {
  const { open, setOpen} = props;
  const { projectStore } = useStores();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClose();
    projectStore.publishProject(projectStore.currentProject?.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
          fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Publiser tildelinger</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6">Ønsker du å publisere tildelingene til elevene?</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => handleClose()}>
              Nei
            </Button>
            <Button type="submit" >Ja</Button>
          </DialogActions>
        </form>
      </Dialog>

  );
};

export default PublishDialog;
