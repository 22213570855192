import MessageTemplateAttributes from '../enums/MessageTemplateAttribute';

const attr = MessageTemplateAttributes;
const messageTemplateDataDescriptions: {
  [key in MessageTemplateAttributes]: string;
} = {
  [attr.USER_NAME]: 'Fullt navn på brukeren som meldingen sendes til.',
  [attr.LIST_OF_COURSES]:
    'Ferdig formatert liste over en students tre prioriteringer.',
  [attr.COURSE_NAME]:
    'Navn på utdanningsprogrammet kurset holdes for. Eks: Idrettsfag',
  [attr.COURSE_SCHOOL_ADDRESS]: 'Adresse til skolen med postnummer og by.',
  [attr.COURSE_SCHOOL_NAME]: 'Navn på skolen kurset holdes på.',
  [attr.COURSE_ROOM_NUMBER]: 'Romnummer lagret for kurset.',
  [attr.COURSE_DATE_TIME]: 'Tid og dato for kurset.',
  [attr.END_OF_BOOKING]:
    'Tid og dato når registreringsperioden for prosjektet ender.',
  [attr.EVALUATION_LINK]: 'Link til evaluering av gjennomført kurs.',
  [attr.PORTAL_LINK]: 'Link til portalen.',
};

export default messageTemplateDataDescriptions;
