import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CircularProgress } from '@material-ui/core';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

interface IComponentProps {
  content: string | null | undefined;
}

const CustomEditorReadOnly = (props: IComponentProps) => {
  const { content } = props;

  const config = {
    toolbar: {
      items: [],
      //shouldNotGroupWhenFull: true,
    },
    language: 'nb',
    fontFamily: {
      supportAllValues: true,
    },
  };

  return (
    <>
      <React.Suspense fallback={<CircularProgress />}>
        <CKEditor
          disabled={true}
          editor={Editor}
          data={content ?? ''}
          onReady={(editor: any) => {
            if (editor) {
              editor.setData(content ?? '');
            }
          }}
          config={config}
        />
      </React.Suspense>
    </>
  );
};

export default CustomEditorReadOnly;
