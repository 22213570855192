import { FilledInputProps, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

interface IComponentProps {
  label?: string;
  value: string;
  setValue?: (string: string) => void;
  customClass?: string;

  helperText?: string;

  hasError?: boolean;
  errorText?: string;

  inputProps?: Partial<FilledInputProps>;

  hasMultiline?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
}

const Component = (props: IComponentProps) => {
  const {
    label,
    value,
    setValue,
    customClass,
    helperText,
    hasError,
    errorText,
    inputProps,
    hasMultiline,
    variant,
    minRows,
    maxRows,
    disabled,
  } = props;

  const handleChange = (event: any): void => {
    if (setValue) {
      setValue(event.target.value);
    }
  };

  const [isShowingHelperText] = React.useState<boolean>(
    !!(helperText || errorText)
  );

  const [computedHelperText, setComputedHelperText] = React.useState<string>(
    helperText || ''
  );

  React.useEffect(() => {
    setComputedHelperText(hasError ? errorText || '' : helperText || '');
  }, [hasError, errorText, helperText]);

  return (
    <>
      <TextField
        id={`${label}-${customClass ?? 'CC'}-fill-text-input`}
        label={label}
        variant={variant ? variant : 'filled'}
        value={value}
        onChange={handleChange}
        className={customClass ?? ''}
        helperText={
          isShowingHelperText && computedHelperText ? (
            <>{computedHelperText}</>
          ) : (
            <></>
          )
        }
        FormHelperTextProps={{ variant: 'filled' }}
        error={hasError}
        InputProps={inputProps}
        multiline={hasMultiline}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
      />
    </>
  );
};

export const ControlledInputText = observer(Component);
