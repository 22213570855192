import React from 'react';
import { useStores } from '@/stores';
import { UserRole } from '@/types/UserRole';
import AdminStudySubjects from '../../admin/study-subjects';
import VGSStudySubjects from '../../VGSadmin/study-subjects';
import { observer } from 'mobx-react';

const StudySubjectDashboard = () => {
  const { userStore } = useStores();
  const { user } = userStore;

  if (user?.isCurrentlyRole(UserRole.HighSchoolContact)) {
    return <VGSStudySubjects />;
  }

  if (user?.isCurrentlyRole(UserRole.Admin)) {
    return <AdminStudySubjects />;
  }

  return <></>;
};

export default observer(StudySubjectDashboard);
