import { User } from '@/types/User';
import { TableRow, TableCell, Chip, IconButton } from '@material-ui/core';
import {
  PhoneIphoneOutlined,
  MailOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
  AccessibilityNewOutlined,
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useState } from 'react';
import StudentRowDropdown from '../../common/StudentRowDropdown';
import useStyles from './style';
import StudentRowChangeRegistration from '../../common/StudentRowChangeRegistration';
import { StudentStudySubject } from '@/types/StudySubject';

interface IStudentRegistrationRowProps {
  student: User;
  all: boolean;
  addOrUpdate: (models: StudentStudySubject[]) => Promise<void>;
}

const StudentRegistrationRow = ({
  student,
  all,
  addOrUpdate,
}: IStudentRegistrationRowProps) => {
  const [open, setOpen] = useState(false);
  const [openChangeRegistration, setOpenChangeRegistration] = useState(false);

  const classes = useStyles({ open });

  const handleOpen = () => {
    setOpen(!open);
    setOpenChangeRegistration(
      openChangeRegistration ? !openChangeRegistration : openChangeRegistration
    );
  };

  const handleOpenChangeRegistration = (): void => {
    setOpen(!open);
    setOpenChangeRegistration(!openChangeRegistration);
  };

  return (
    <>
      <TableRow onClick={() => handleOpen()} className={classes.tableRow}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </TableCell>
        <TableCell>{student.fullName}</TableCell>
        <TableCell>{student.class?.fullName}</TableCell>
        {all && <TableCell>{student.school?.schoolName}</TableCell>}
        <TableCell align="right">
          {student.studentStudySubjects.length === 0 ? (
            <Chip className={classes.infoChip} label="Ikke meldt"></Chip>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align="right">
          {student.hasSpecialNeeds && <AccessibilityNewOutlined />}
          <IconButton href={'tel:' + student.phoneNumber}>
            <PhoneIphoneOutlined />
          </IconButton>
          <IconButton href={'mailto:' + student.emailAddress}>
            <MailOutlined />
          </IconButton>
        </TableCell>
      </TableRow>
      <StudentRowDropdown
        open={open}
        student={student}
        all={all}
        handleOpenChangeRegistration={handleOpenChangeRegistration}
      />
      <StudentRowChangeRegistration
        open={openChangeRegistration}
        student={student}
        all={all}
        addOrUpdate={addOrUpdate}
      />
    </>
  );
};

export default observer(StudentRegistrationRow);
