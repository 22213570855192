import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette, typography }) => ({
  content: {
    minWidth: '380px',
    padding: '24px',
    ...flexCol,
    gap: '6px',
    overflow: 'auto',
    justifyContent: 'flex-start',
  },
  form: {
    ...flexCol,
    gap: '6px',
  },
  dateListWrapper: {
    ...flexCol,
    gap: '6px',
    marginBottom: '90px',
    '& MuiChip-root': {
      justifyContent: 'space-between',
    },
  },
  dateListItem: {
    ...flexRow,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listHeader: {
    color: palette.primary.dark,
  },
  buttonContainer: {
    boxShadow: '0px -1px 3px rgba(51, 51, 51, 0.2)',
    '& .MuiButton-root': {
      margin: '0 15px 0 0',
    },
    padding: '24px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
  loadingButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyle;
