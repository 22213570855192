import {
  CreateUpdateCourseTemplate,
  ICourseTemplate,
} from '@/types/CourseTemplate';
import { newHttpInstance } from '@/utils/httpUtils';
import { AxiosInstance } from 'axios';

class CourseTemplateService {
  prefix = '/api/CourseTemplate';
  http: AxiosInstance;

  constructor() {
    this.http = newHttpInstance(this.prefix);
  }

  public async createCourseTemplate(
    model: CreateUpdateCourseTemplate
  ): Promise<boolean> {
    const result = await this.http.post(``, model);
    return result.data;
  }

  public async updateCourseTemplate(
    id: number,
    model: CreateUpdateCourseTemplate
  ): Promise<boolean> {
    const result = await this.http.put(`${id}`, model);
    return result.data;
  }

  public async getCourseTemplate(
    studySubjectId: number,
    externalSchoolId: string
  ): Promise<ICourseTemplate> {
    const result = await this.http.get(``, {
      params: {
        studySubjectId,
        externalSchoolId,
      },
    });
    return result.data;
  }

  public async getCourseTemplates( externalSchoolId: string) : Promise<ICourseTemplate[]>{
    const result = await this.http.get(`course-templates`, {
      params: {
        externalSchoolId,
      },
    });
    return result.data;
    }
}

export default new CourseTemplateService();
