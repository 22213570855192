import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import React from 'react';

interface IComponentProps {
  open: boolean;
  message: string;
  severity: Color;
  duration: number;
  anchorOrigin: SnackbarOrigin;
  handleClose: () => void;
}

const NotifyMessage = (props: IComponentProps) => {
  const { open, message, severity, duration, anchorOrigin, handleClose } =
    props;

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default NotifyMessage;
