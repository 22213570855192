import { IStudentStudySubject } from '@/types/StudySubject';
import React from 'react';
import clsx from 'clsx';
import { showPriority } from '@/types/StudentStudySubject';
import { makeStyles } from '@material-ui/core';

interface IComponentProps {
  studentStudySubjects?: IStudentStudySubject[];
}

const useStyles = makeStyles(({ palette }) => ({
  shapeCircle: {
    borderRadius: '50%',
  },
  positionCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  badgeContent: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    fontSize: '12px',
    border: `1px solid ${palette.info['50']}`,
    color: palette.info['50'],
    background:
      'linear-gradient(0deg, rgba(77, 199, 93, 0.15), rgba(77, 199, 93, 0.15)), #FFFFFF',
  },
  colorTransparent: {
    color: palette.background.default,
  },
}));

const PriorityStudent = (props: IComponentProps) => {
  const { studentStudySubjects } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.badgeContent,
        classes.shapeCircle,
        classes.positionCenter
      )}
    >
      <span
        className={clsx({
          [classes.colorTransparent]: !(
            studentStudySubjects &&
            studentStudySubjects.length > 0 &&
            studentStudySubjects[0].priority
          ),
        })}
      >
        {studentStudySubjects && studentStudySubjects.length > 0
          ? showPriority(studentStudySubjects[0].priority)
          : 'P'}
      </span>
    </div>
  );
};

export default PriorityStudent;
