import React from 'react';
import { observer } from 'mobx-react';
import { FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import {
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useStores } from '@/stores';
import Row from './row';
import School from '@/types/School';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';

interface IComponentProps {
  externalSchoolId: string;
  setExternalSchoolId: (externalSchoolId: string) => void;
  handleOpenManualAssignDialog: () => void;
}

const ListStudent = (props: IComponentProps) => {
  const {
    externalSchoolId,
    setExternalSchoolId,
    handleOpenManualAssignDialog,
  } = props;

  const courseClasses = courseStyle();
  const { userStore, schoolStore } = useStores();

  return (
    <>
      <div className={courseClasses.courseOptionPadding}>
        <div className={courseClasses.courseOptionWidth}>
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.alignItemsCenter
            )}
          >
            <div
              className={clsx(
                courseClasses.optionsTitleSize,
                courseClasses.fontWeight500
              )}
            >
              Påmeldt elever
            </div>
            <div className={courseClasses.marginLeftAuto}>
              <FilterList />
              <ClearIcon />
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key={`select_secondary_school`}>
              <TableCell
                classes={{
                  root: clsx(courseClasses.padding0, courseClasses.borderTable),
                }}
              ></TableCell>
              <TableCell
                classes={{
                  root: clsx(
                    courseClasses.checkboxPadding,
                    courseClasses.widthCheckbox,
                    courseClasses.paddingSelectSecondarySchool
                  ),
                }}
              >
                <CustomCheckBox />
              </TableCell>
              <TableCell
                classes={{
                  root: clsx(
                    courseClasses.buttonDetailPadding,
                    courseClasses.widthButtonDetail,
                    courseClasses.paddingSelectSecondarySchool
                  ),
                }}
              >
                <CustomIconButton />
              </TableCell>
              <TableCell
                colSpan={2}
                classes={{
                  root: courseClasses.paddingSelectSecondarySchool,
                }}
              >
                <div
                  className={clsx(
                    courseClasses.displayFlex,
                    courseClasses.alignItemsCenter
                  )}
                >
                  <Select
                    disableUnderline
                    value={externalSchoolId}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                      setExternalSchoolId(e.target.value as string)
                    }
                    classes={{
                      root: courseClasses.rootChooseSecondarySchool,
                      select: courseClasses.selectChooseSecondarySchool,
                      icon: courseClasses.iconChooseSecondarySchool,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={ExpandMoreIcon}
                    inputProps={{
                      className: courseClasses.listStudentPadding,
                    }}
                  >
                    <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
                      Alle skoler
                    </MenuItem>
                    {schoolStore.secondarySchools.map((school: School) => (
                      <MenuItem
                        key={school.externalId}
                        value={school.externalId}
                      >
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </Select>

                  <div
                    className={clsx(
                      courseClasses.marginLeftAuto,
                      courseClasses.marginRight12,
                      courseClasses.textCountStudent
                    )}
                  >
                    {`${userStore.listStudentOfCourse.length} elever`}
                  </div>
                </div>
              </TableCell>
            </TableRow>
            {!userStore.loading ? (
              <>
                {userStore.listStudentOfCourse.length > 0 ? (
                  <>
                    {userStore.listStudentOfCourse.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        handleOpenManualAssignDialog={
                          handleOpenManualAssignDialog
                        }
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography variant="h3">
                          Ingen elever satt opp
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={5}>
                    <div
                      className={clsx(
                        courseClasses.displayFlex,
                        courseClasses.justifyContentCenter
                      )}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default observer(ListStudent);
