import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      margin: '10px 0',
    },
    width: '302px',
    marginBottom: '84px',
  },
  addressWrapper: {
    ...flexRow,
    gap: '6px',
  },
  dragAndDropSchools: {
    position: 'relative',
    height: '41px',
    backgroundColor: palette.grey[50],
    lineHeight: '41px',
    '&:before': {
      content: '""',
      height: '1px',
      backgroundColor: palette.primary[100],
      position: 'absolute',
      left: '5%',
      bottom: 0,
      width: '90%',
    },
    '&:hover': {
      backgroundColor: '#C6CADE',
      borderBottom: '1px solid ' + palette.primary[600],
      '& $dragIndicator': {
        opacity: 1,
      },
    },
  },
  dragIndicator: {
    opacity: 0,
  },
}));

export default useStyle;
