import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';

class StateStore {
  public rootStore: RootStore;

  public addProjectFormIsOpen = false;
  public drawerMenuIsOpen = false;
  public userInformationDropdownIsOpen = false;
  public schoolDrawerIsOpen = false;
  public unavailableDateDrawerIsOpen = false;
  public studySubjectDrawerIsOpen = false;
  public importStudentModalIsOpen = false;
  public contactsPersonDrawerIsOpen = false;
  public vgsEditCourseDrawerIsOpen = false;
  public vgsCourseInfoDrawerIsOpen = false;
  public vgsStudySubjectDrawerIsOpen = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action toggleAddProjectFormIsOpen = () =>
    (this.addProjectFormIsOpen = !this.addProjectFormIsOpen);

  @action setAddProjectFormIsOpen = (val: boolean): void => {
    this.addProjectFormIsOpen = val;
  };

  @action toggleDrawerMenuIsOpen = () =>
    (this.drawerMenuIsOpen = !this.drawerMenuIsOpen);
  @action setDrawerMenuIsOpen = (val: boolean) => (this.drawerMenuIsOpen = val);

  @action toggleUserInformationDropdownIsOpen = () =>
    (this.userInformationDropdownIsOpen = !this.userInformationDropdownIsOpen);

  @action setUserInformationDropdownIsOpen = (val: boolean) =>
    (this.userInformationDropdownIsOpen = val);

  @action
  setSchoolDrawerIsOpen = (val: boolean) => (this.schoolDrawerIsOpen = val);

  @action
  setUnavailableDateDrawerIsOpen = (val: boolean) =>
    (this.unavailableDateDrawerIsOpen = val);

  @action
  setStudySubjectDrawerIsOpen = (val: boolean) =>
    (this.studySubjectDrawerIsOpen = val);

  @action
  setImportStudentModalIsOpen = (val: boolean) =>
    (this.importStudentModalIsOpen = val);

  @action
  setContactsPersonDrawerIsOpen = (val: boolean) => {
    this.contactsPersonDrawerIsOpen = val;
  };

  @action
  setVgsEditCourseDrawerIsOpen = (val: boolean) => {
    this.vgsEditCourseDrawerIsOpen = val;
  };

  @action
  setVgsCourseInfoDrawerIsOpen = (val: boolean): void => {
    this.vgsCourseInfoDrawerIsOpen = val;
  };

  @action
  setVgsStudySubjectDrawerIsOpen = (val: boolean): void => {
    this.vgsStudySubjectDrawerIsOpen = val;
  };
}

export default StateStore;
