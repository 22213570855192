import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  filterWrapper: {
    ...flexRow,
    justifyContent: 'space-between',
  },
  filterChips: {
    ...flexRow,
    gap: '12px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '12px 0 6px 0',
  },
  chip: {
    transition: 'background-color ease 0.25s',
    '&:hover': {
      backgroundColor: palette.primary[50],
      cursor: 'pointer',
    },
  },
  activeChip: {
    backgroundColor: palette.primary.main,
    color: '#fff',
  },
}));

export default useStyles;
