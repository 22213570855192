import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import React from 'react';
import clsx from 'clsx';
import useStyles from './style';

interface IComponentProps {
  open?: boolean;
  courseId?: number;
  handleCloseDetail?: (
    open: boolean,
    courseId: number,
    event?: React.MouseEvent<HTMLElement>
  ) => Promise<void> | void;
  hide?: boolean;
}

const CustomIconButton = (props: IComponentProps) => {
  const { open, courseId, handleCloseDetail, hide } = props;
  const classes = useStyles();

  return (
    <IconButton
      size="small"
      color="primary"
      onClick={async (event: React.MouseEvent<HTMLElement>) =>
        handleCloseDetail &&
        courseId &&
        (await handleCloseDetail(!open, courseId, event))
      }
      classes={{ root: clsx(classes.root, classes.border) }}
      className={clsx({
        [classes.borderButtonSeek]: open,
        [classes.visibility]: hide === true,
      })}
    >
      {open ? (
        <ExpandLessIcon
          className={clsx(classes.root, {
            [classes.expandLessIcon]: open,
          })}
        />
      ) : (
        <ExpandMoreIcon className={classes.root} />
      )}
    </IconButton>
  );
};

export default CustomIconButton;
