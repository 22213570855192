import LuxonUtils from '@date-io/luxon';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './assets/fonts/OsloSans/OsloSans.css';
import ErrorMessage from './components/ErrorMessage';
import Router from './router/Router';
import theme from './style/defaultTheme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={LuxonUtils} locale="utc">
          <Router />
          <ErrorMessage />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
