import { PER_PAGE_OPTIONS } from '@/types/dto/PaginationRequest.dto';
import { MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import useStyles from './style';
import clsx from 'clsx';

interface IComponentProps {
  currentPage: number;
  totalRows: number;
  perPage: number;
  handleChoosePage: (page: number) => void;
  changePageOption: (perPage: number) => void;
}

const CustomPagination = (props: IComponentProps) => {
  const {
    currentPage,
    totalRows,
    perPage,
    handleChoosePage,
    changePageOption,
  } = props;
  const classes = useStyles();

  const calculatePageNumber = (): number => {
    return Math.ceil(totalRows / perPage);
  };

  return (
    <>
      {totalRows ? (
        <div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
          <Pagination
            page={currentPage}
            count={calculatePageNumber()}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
              handleChoosePage(value)
            }
          />

          <Select
            disableUnderline
            value={perPage}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              changePageOption(event.target.value as number)
            }
            classes={{
              root: classes.rootSelect,
              select: classes.select,
              icon: classes.iconSelect,
            }}
            inputProps={{
              className: classes.selectPadding,
            }}
          >
            {PER_PAGE_OPTIONS.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomPagination;
