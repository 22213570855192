import { useStores } from '@/stores';
import { StepType } from '@/types/StepType';
import { getStepVGS } from '@/utils/dashboardUtils';
import dashboardStyle from '@/views/style/dashboard';
import { observer } from 'mobx-react';
import React from 'react';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Divider } from '@material-ui/core';
import StepStatus from '@/components/StepStatus';
import BlockCount from '@/components/BlockCount';
import CustomCircleChart from '@/components/CustomCircleChart';
import { PublishOutlined } from '@material-ui/icons';

const GUDashboard = () => {
  const { projectStore, userStore, dashboardStore } = useStores();
  const classes = dashboardStyle();

  const [step, setStep] = React.useState<number>(0);
  const [steps] = React.useState<StepType[]>([
    {
      title: 'Planlegging',
    },
    {
      title: 'Kursperiode',
    },
  ]);

  React.useEffect(() => {
    if (!projectStore.currentProject) {
      projectStore.getCurrent();
    }
  }, [projectStore]);

  React.useEffect(() => {
    if (
      userStore.user &&
      userStore.user.externalSchoolId &&
      projectStore.currentProject &&
      projectStore.currentProject.id
    ) {
      dashboardStore.getStatisticRegistrationGU(
        projectStore.currentProject.id,
        userStore.user.externalSchoolId
      );

      dashboardStore.getStatisticAllocationGU(
        projectStore.currentProject.id,
        userStore.user.externalSchoolId
      );
    }
  }, [dashboardStore, userStore, projectStore.currentProject]);

  React.useEffect(() => {
    const step = getStepVGS(projectStore.currentProject);
    setStep(step);
  }, [projectStore, projectStore.currentProject]);

  return (
    <>
      <div className={classes.leftContent}>
        <div className={classes.currentProject}>Aktuelt prosjekt</div>
        <div className={classes.backgroundColorFFF}>
          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div className={classes.currentProjectTitle}>{projectStore.currentProject?.projectName}</div>
            <ArrowForwardIcon classes={{ root: classes.arrowForwardIcon }} />

            <PublishOutlined classes={{ root: classes.marginLeftAuto }} />
          </div>

          <Divider
            classes={{
              root: classes.divider,
            }}
          />

          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div
              className={clsx(
                classes.currentTitle,
                classes.currentTitleWidth,
                classes.marginRight24
              )}
            >
              Status
            </div>
            <StepStatus steps={steps} step={step} />
          </div>

          <Divider
            classes={{
              root: classes.divider,
            }}
          />

          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div
              className={clsx(
                classes.currentTitle,
                classes.currentTitleWidth,
                classes.marginRight24
              )}
            >
              Påmelding
            </div>
            {dashboardStore.statisticRegistration && (
              <>
                <BlockCount
                  count={dashboardStore.statisticRegistration.totalStudent}
                  title={'Antall elev på skole år'}
                />
                <BlockCount
                  count={dashboardStore.statisticRegistration.registeredStudent}
                  title={'Påmeldt elever'}
                />
                <BlockCount
                  count={
                    dashboardStore.statisticRegistration.totalStudent -
                    dashboardStore.statisticRegistration.registeredStudent
                  }
                  title={'Ikke på meldt'}
                />
                <CustomCircleChart
                  statistic={dashboardStore.statisticRegistration}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(GUDashboard);
