import IContactPerson from '@/types/ContactPerson';
import { Chip } from '@material-ui/core';
import useStyle from './style';

interface IContactPersonChipProps {
  contactPerson: IContactPerson;
  handleDelete: (cp: IContactPerson) => void;
}

const ContactPersonChip = ({
  contactPerson,
  handleDelete,
}: IContactPersonChipProps) => {
  const classes = useStyle();

  return (
    <div className={classes.contactPersonChip}>
      <Chip
        variant="outlined"
        label={contactPerson.user?.fullName}
        onDelete={() => handleDelete(contactPerson)}
      />
    </div>
  );
};
export default ContactPersonChip;
