export enum SurveyQuestionType {
  INPUT = 0,
  TEXT_AREA = 1,
  RADIO = 2,
  CHECKBOX = 3,
}

export const getSurveyQuestionDisplay = (type: SurveyQuestionType): string => {
  let statusText = '';
  switch (type) {
    case SurveyQuestionType.INPUT:
      statusText = 'Tekstfelt med en linje';
      break;
    case SurveyQuestionType.TEXT_AREA:
      statusText = 'Tekstboks med flere linjer';
      break;
    case SurveyQuestionType.RADIO:
      statusText = 'Radiogruppe (Flere alternativer, kan bare velge ett)';
      break;
    case SurveyQuestionType.CHECKBOX:
      statusText = 'Flersvars alternativer (Avkrysningsboks)';
      break;
  }

  return statusText;
};
