import { ControlledInputText } from '@/components/ControlledInputText';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
  handleSearch: () => void;
  label?: string;
}

const searchStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '& .MuiFormHelperText-root': {
      marginTop: 0,
    },
  },
  pointer: { cursor: 'pointer' },
  height: { height: '52px' },
}));

const CustomSearch = (props: IComponentProps) => {
  const { search, setSearch, handleSearch, label } = props;
  const classes = searchStyles();

  return (
    <>
      <ControlledInputText
        label={label ?? 'Søk'}
        value={search}
        setValue={(value) => {
          setSearch(value);
        }}
        customClass={classes.root}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlined
                onClick={handleSearch}
                classes={{ root: classes.pointer }}
              />
            </InputAdornment>
          ),
          className: classes.height,
        }}
      />
    </>
  );
};

export default CustomSearch;
