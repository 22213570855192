import { PaginationRequest } from './dto/PaginationRequest.dto';
import { CourseStatus } from './enums/CourseStatus';
import { EventCheckbox } from './enums/EventCheckbox';
import { IProject } from './Project';
import { ISchool } from './School';
import IStudySubject from './StudySubject';
import { User } from './User';

export default interface ICourse {
  id: number;
  studySubjectId: number;
  studySubject: IStudySubject;
  school: ISchool;
  startDate: Date;
  endDate: Date;
  roomNumber: string;
  capacity: number;
  extraCapacity: number;
  numberOfFirstPriority: number;
  numberOfSecondPriority: number;
  numberOfThirdPriority: number;
  courseHolderId: number;
  courseHolder: User;
  projectId: number;
  project: IProject;
  status: CourseStatus;
  availableCapacity: number;
  hasSpecialNeeds: boolean;
  courseInfo: string;
}

export interface IListRequestQuery extends PaginationRequest {
  projectId?: string;
  externalSchoolId?: string;
  studySubjectId?: string | number;
  status?: string | CourseStatus;
  beginStartDate?: Date | null;
  endStartDate?: Date | null;
}
export class CreateUpdateCourse {
  id?: number;
  status = CourseStatus.DRAFT;
  projectId = 0;
  studySubjectId = 0;
  capacity = 20;
  extraCapacity = 0;
  externalSchoolId = '';
  courseHolderId?: number;
  numberOfFirstPriority = 0;
  numberOfSecondPriority = 0;
  numberOfThirdPriority = 0;
  roomNumber = '';
  numberCoursePerDay = 1;
  selectedDays: Date[] = [];
  startDate?: Date;
  endDate?: Date;
  courseInfo?: string;

  static fromICourse(course: ICourse): CreateUpdateCourse {
    const result = new CreateUpdateCourse();
    result.id = course.id;
    result.status = course.status;
    result.projectId = course.projectId;
    result.studySubjectId = course.studySubjectId;
    result.capacity = course.capacity;
    result.extraCapacity = course.extraCapacity;
    result.externalSchoolId = course.school.externalId;
    result.courseHolderId =
      course.courseHolderId > 0 ? course.courseHolderId : undefined;
    result.numberOfFirstPriority = course.numberOfFirstPriority;
    result.numberOfSecondPriority = course.numberOfSecondPriority;
    result.numberOfThirdPriority = course.numberOfThirdPriority;
    result.roomNumber = course.roomNumber;
    // TODO: Look over all times and use timezones correctly
    result.startDate = course.startDate;
    result.selectedDays = [course.startDate];
    result.endDate = course.endDate;
    if (course.startDate instanceof Date) {
      const d = new Date(course.startDate);
      d.setUTCMinutes(
        course.startDate.getUTCMinutes() - course.startDate.getTimezoneOffset()
      );
      result.startDate = d;
      result.selectedDays = [d];
    }

    if (course.endDate instanceof Date) {
      const d = new Date(course.endDate);
      d.setUTCMinutes(
        course.endDate.getUTCMinutes() - course.endDate.getTimezoneOffset()
      );
      result.endDate = d;
    }
    result.courseInfo = course.courseInfo;
    return result;
  }
}

export interface State {
  mode: EventCheckbox;
  courseIds: number[];
  studentCourseIds: number[];
  courseDto: CreateUpdateCourse;
  courseDtos: CreateUpdateCourse[];
}
