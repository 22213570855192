import { makeStyles } from '@material-ui/core';
import { flexRow } from '@/style/common';

interface Props {
  open: boolean;
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  collapseTableRow: {
    transition: '0.3s ease all',
    borderLeft: '3px solid',
    borderColor: ({ open }: Props) =>
      open ? palette.primary[200] : 'transparent',
    backgroundColor: ({ open }: Props) =>
      open ? palette.primary[50] : 'transparent',
  },
  collapse: {
    padding: '12px 0',
    ...flexRow,
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  studentStudySubjectList: {
    ...flexRow,
    gap: '8px',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  displayFlex: {
    display: 'flex',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  boxBtn: {
    marginLeft: '20px',
    alignItems: 'flex-end',
  },
  btn: {
    border: `1px solid ${palette.primary[500]}`,
    padding: '8px 12px',
    height: '36px',
  },
}));

export default useStyles;
