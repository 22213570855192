import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  stepper: {
    backgroundColor: 'transparent',
    width: '400px',
  },
}));

export default useStyles;
