import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  main: {
    margin: '20px 115px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      margin: '10px 5px',
    },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '30px',
    width: '50%',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  studySubjectFilterContainer: {
    flexGrow: 1,
  },
  studySubjectFilter: {
    width: '100%',
    '& .MuiFormLabel-root': {
      lineHeight: 0,
    },
  },
  counterText: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: '30px',
    paddingTop: '20px',
  },
}));

export default useStyle;
