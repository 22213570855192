import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  main: {
    margin: '20px 115px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      margin: '10px 5px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    height: '100%',
    '& .MuiDivider-root': {
      backgroundColor: palette.primary.main,
      height: '50%',
    },
    paddingLeft: '10px',
    color: palette.primary.dark,
  },
  headerText: {
    fontWeight: 400,
  },
}));

export default useStyle;
