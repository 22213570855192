import { useStores } from '@/stores';
import IContactPerson from '@/types/ContactPerson';
import { SchoolType } from '@/types/School';
import { UserRole } from '@/types/UserRole';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import useStyle from './style';

const AddExistingContactPerson = () => {
  const { schoolStore } = useStores();
  const { connectedUsers, editingSchool } = schoolStore;

  const contactPeopleIds = editingSchool.contactPeople.map((cp) => cp.userId);
  const filteredConnectedUsers = connectedUsers.filter(
    (u) => !contactPeopleIds.includes(u.id)
  ).sort((a, b) =>
      (a.fullName ?? '') > (b.fullName ?? '') ? 1 : -1
  );

  const [isMain, setIsMain] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const classes = useStyle();

  useEffect(() => {
    if (editingSchool.externalId)
      schoolStore.getConnectedUsers(
        editingSchool.externalId,
        editingSchool.schoolType === SchoolType.HighSchool
          ? UserRole.HighSchoolContact
          : UserRole.Adviser
      );
  }, [editingSchool.externalId, editingSchool.schoolType, schoolStore]);

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    setSelectedUserId(e.target.value as number);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!connectedUsers.map((u) => u.id).includes(selectedUserId)) return;

    let tempContactPeople = [...editingSchool.contactPeople];

    if (isMain) {
      tempContactPeople = tempContactPeople.map((cp) => ({
        ...cp,
        isMain: false,
      }));
    }

    const newContactPerson: IContactPerson = {
      externalSchoolId: editingSchool.externalId,
      userId: selectedUserId,
      isMain: isMain,
      user: connectedUsers.find((u) => u.id === selectedUserId),
    };

    tempContactPeople.push(newContactPerson);

    schoolStore.setEditingSchool({
      ...editingSchool,
      contactPeople: tempContactPeople,
    });

    setIsMain(false);
    setSelectedUserId(0);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormControl variant="filled">
        <InputLabel id="select-existing-user-label">
          Velg en eksiterende bruker
        </InputLabel>
        <Select
          labelId="select-existing-user-label"
          value={selectedUserId}
          onChange={handleChange}
        >
          {filteredConnectedUsers.length === 0 && (
            <MenuItem disabled>
              Alle tilkoblede brukere er valgt som kontaktpersoner
            </MenuItem>
          )}
          {filteredConnectedUsers.map((cu) => (
            <MenuItem value={cu.id}>{cu.fullName}</MenuItem>
          ))}
        </Select>
        <FormControlLabel
          label="Hovedkontaktperson"
          onChange={(e) => {
            setIsMain((e.target as HTMLInputElement).checked);
          }}
          checked={isMain}
          control={<Checkbox name="mainContactPerson" color="primary" />}
        />
        <Button variant="outlined" type="submit">
          Legg til
        </Button>
      </FormControl>
    </form>
  );
};

export default observer(AddExistingContactPerson);
