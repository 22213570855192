import { useStores } from '@/stores';
import ICourse from '@/types/Course';
import React, { FC } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { getDateTime } from '@/utils/timeUtils';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import courseStyle from '@/views/style/courses';
import ButtonCourseStatus from '@/components/ButtonCourseStatus';
import CapacityCourse from '@/components/CapacityCourse';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { IUserDto } from '@/types/User';
import NestedStudentTable from './NestedStudentTable';
import { CourseStatus } from '@/types/enums/CourseStatus';
import { GetAppOutlined } from '@material-ui/icons';

interface IComponentProps {
  row: ICourse;
  chooseCourse: (id: number) => void;
  chooseStudent: (courseId: number, studentId: number) => void;
  listParticipantOfCourseExport: (courseId: number) => Promise<void>;
}

const Row: FC<IComponentProps> = ({
  row,
  chooseCourse,
  chooseStudent,
  listParticipantOfCourseExport,
}) => {
  const { courseStore, userStore } = useStores();
  const courseClasses = courseStyle();
  const [open, setOpen] = React.useState<boolean>(false);
  const [students, setStudents] = React.useState<IUserDto[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const _getAllStudentByCourseId = async (courseId: number): Promise<void> => {
    setStudents([]);
    setLoading(true);
    const students = await userStore.getAllStudentByCourseId(row.id);
    setStudents(students);
    setLoading(false);
  };

  const handleCloseCourseDetail = async (
    open: boolean,
    courseId: number,
    event?: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    event?.stopPropagation();
    //userStore.setListStudentOfCourse([]);
    setOpen(open);

    if (open) {
      await _getAllStudentByCourseId(row.id);
    }
  };

  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const beforeListParticipantOfCourseExport = async (
    event: React.MouseEvent<HTMLElement>,
    courseId: number
  ): Promise<void> => {
    event.stopPropagation();
    try {
      setLoadingExport(true);
      await listParticipantOfCourseExport(courseId);
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <TableRow
        key={`course_${row.id}`}
        className={clsx(
          {
            [courseClasses.rowCourseDetail]:
              open || row.id === courseStore.courseId,
          },
          courseClasses.hoverPointer
        )}
        onClick={() => chooseCourse(row.id)}
      >
        <TableCell
          classes={{
            root: clsx(courseClasses.padding0, courseClasses.borderTable, {
              [courseClasses.borderTableActive]:
                open || row.id === courseStore.courseId,
            }),
          }}
        ></TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.checkboxPadding,
              courseClasses.widthCheckbox
            ),
          }}
        >
          <CustomCheckBox />
        </TableCell>
        <TableCell
          classes={{
            root: clsx(
              courseClasses.buttonDetailPadding,
              courseClasses.widthButtonDetail
            ),
          }}
        >
          <CustomIconButton
            open={open}
            courseId={row.id}
            handleCloseDetail={handleCloseCourseDetail}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body1" className={courseClasses.highlightedText}>
            {row.studySubject?.studySubjectName
              ? row.studySubject.studySubjectName
              : 'Ikke definert'}
          </Typography>

          <CapacityCourse
            showVacant={
              row.status === CourseStatus.ACTIVE ||
              row.status === CourseStatus.PUBLISHED ||
              row.status === CourseStatus.CANCELLED
            }
            capacity={row.capacity}
            vacant={row.availableCapacity}
          />
        </TableCell>
        <TableCell
          className={clsx(
            courseClasses.verticalAlignTop,
            courseClasses.textStyle,
            courseClasses.whiteSpaceNowrap
          )}
          classes={{
            root: clsx(courseClasses.tableCellTime),
          }}
        >
          <Typography variant="body1" className={courseClasses.highlightedText}>
            {getDateTime(row.startDate, 'dd. LLL.')}
          </Typography>
          <Typography variant="body1">
            {`${getDateTime(row.startDate, 'HH:mm')} - ${getDateTime(
              row.endDate,
              'HH:mm'
            )}`}
          </Typography>
        </TableCell>
        <TableCell
          className={clsx(
            courseClasses.textStyle,
            courseClasses.whiteSpaceNowrap
          )}
        >
          <div className={courseClasses.heightText}>
            {row.courseHolder?.fullName
              ? row.courseHolder.fullName
              : 'Ikke definert'}
          </div>
        </TableCell>
        <TableCell>
          <ButtonCourseStatus status={row.status} />
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.justifyContentFlexEnd,
              courseClasses.alignItemsCenter,
              courseClasses.whiteSpaceNowrap
            )}
          >
            <div
              className={clsx(
                courseClasses.pointer,
                courseClasses.buttonChooseStudent,
                {
                  [courseClasses.visibility]: !row.hasSpecialNeeds,
                }
              )}
            >
              <AccessibilityNewIcon />
            </div>
            <div
              className={clsx(
                courseClasses.pointer,
                courseClasses.buttonChooseStudent,
                {
                  [courseClasses.displayNone]: !(
                    row.status === CourseStatus.ACTIVE ||
                    row.status === CourseStatus.PUBLISHED
                  ),
                }
              )}
              onClick={async (event: React.MouseEvent<HTMLElement>) =>
                await beforeListParticipantOfCourseExport(event, row.id)
              }
            >
              {loadingExport ? (
                <CircularProgress
                  classes={{
                    root: clsx(courseClasses.sizeIconLoadingExportVGS),
                  }}
                />
              ) : (
                <GetAppOutlined />
              )}
            </div>
            <div
              className={clsx(
                courseClasses.pointer,
                courseClasses.buttonChooseStudent
              )}
            >
              <CreateOutlinedIcon />
            </div>
          </div>
        </TableCell>
      </TableRow>
      {(row.status === CourseStatus.ACTIVE ||
        row.status === CourseStatus.PUBLISHED ||
        row.status === CourseStatus.CANCELLED) && (
        <NestedStudentTable
          students={students}
          setStudents={setStudents}
          course={row}
          open={open}
          chooseStudent={chooseStudent}
          loading={loading}
        />
      )}
    </>
  );
};

export default observer(Row);
