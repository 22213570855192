import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsFlexEnd: {
    alignItems: 'flex-end',
  },
  backgroundColorTransparent: {
    backgroundColor: palette.background.default,
  },
  select: {
    '&:focus': {
      backgroundColor: palette.background.default,
    },
  },
  icon: {
    color: palette.primary[500],
  },
  menuItem: {
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontWeight: 500,
  },
}));

export default useStyles;
