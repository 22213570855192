import { flexCol, flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

export const configStyle = {
  paddingHeader: 116,
  marginFilter: 24,
  widthOption: 358,
  paddingOption: 16,
};

const courseStyle = makeStyles(({ breakpoints, palette }) => ({
  header: {
    position: 'relative',
    backgroundColor: palette.primary.light,
    height: '6rem',
  },
  headerContent: {
    paddingLeft: `${configStyle.paddingHeader}px`,
  },
  headerDivider: {
    height: '24px !important',
    width: '1px',
    margin: '0 10px',
    backgroundColor: palette.primary[500],
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 400,
    color: palette.primary[900],
  },
  stepIconActive: {
    color: palette.primary[600],
  },
  stepLabel: {
    font: `normal normal 400 14px Oslo Sans, Roboto, Arial, sans-serif !important`,
    color: `${palette.primary[900]} !important`,
  },
  tabAdmin: {
    minWidth: '118px',
  },
  publishButton: {
    backgroundColor: palette.primary.main,
    height: '48px',
    width: '92px',
    minWidth: '92px',
    maxWidth: '92px',
    color: 'white',
    fontSize: '16px',
    border: 'none',
    padding: '16px',
    marginRight: '19px',
  },
  fontSize14: {
    fontSize: '14px !important',
  },
  fontSize16: {
    fontSize: '16px',
  },
  fontSize18: {
    fontSize: '18px',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight500: {
    fontWeight: 500,
  },
  marginLeft19: {
    marginLeft: '19px',
  },
  marginRight12: {
    marginRight: '12px',
  },
  marginRight20: {
    marginRight: '20px',
  },
  marginRight27: {
    marginRight: '27px',
  },
  marginRight30: {
    marginRight: '30px',
  },
  marginRight40: {
    marginRight: '40px',
  },
  marginRight123: {
    marginRight: '123px',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  marginBottom6: {
    marginBottom: '6px',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
  marginBottom21: {
    marginBottom: '21px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop17: {
    marginTop: '17px',
  },
  marginTop18: {
    marginTop: '18px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  marginTop21: {
    marginTop: '21px',
  },
  marginTop30: {
    marginTop: '30px',
  },
  courseFilterNav: {
    height: '62px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #C6CADE',
    alignItems: 'flex-end',
  },
  leftContent: {
    width: `calc(100% - ${configStyle.widthOption}px)`,
    padding: `0 0 0 ${configStyle.paddingHeader}px`,
  },
  rightContent: {
    minWidth: `${configStyle.widthOption}px`,
    maxWidth: `${configStyle.widthOption}px`,
  },
  tabIndicator: {
    backgroundColor: palette.primary[600],
  },
  customSearch: {
    position: 'relative',
    width: '611px',
    height: '52px',
    padding: `4px 0`,
    boxSizing: 'content-box',
    [breakpoints.down('md')]: {
      width: '500px',
    },
  },
  courseContentContainerBackground: {
    background: 'rgba(0, 0, 0, 0.04)',
  },
  widthCheckbox: {
    width: '39px',
  },
  widthButtonDetail: {
    width: '28px',
  },
  checkboxPadding: {
    paddingRight: '8px',
    paddingLeft: '11px',
  },
  buttonDetailPadding: {
    paddingLeft: '8px',
    paddingRight: 0,
  },
  space: {
    marginRight: `${configStyle.marginFilter}px`,
  },
  rowColSpan: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #C6CADE',
  },
  paddingDetail: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  paddingRowDetail: {
    paddingTop: '13px',
    paddingBottom: '13px',
  },
  paddingSelectSecondarySchool: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  rowCourseDetail: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #EBEAF1',
  },
  displayContent: {
    display: 'contents',
  },
  verticalAlignTop: {
    verticalAlign: 'top',
  },
  colorDetail: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '15px',
    letterSpacing: '0.05em',
    color: palette.primary[900],
  },
  heightText: {
    height: '22px',
    lineHeight: '22px',

    '& p': {
      margin: '0px',
    },
  },
  textStyle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: palette.primary[900],
  },
  tableCellStudySubject: {
    width: '250px',
    minWidth: '250px',
    maxWidth: '250px',
    [breakpoints.up(1440)]: {
      width: '300px',
      minWidth: '300px',
      maxWidth: '300px',
    },
    [breakpoints.up('xl')]: {
      width: '400px',
      minWidth: '400px',
      maxWidth: '400px',
    },
  },
  tableCellTime: {
    width: '150px',
    minWidth: '150px',
    maxWidth: '150px',
    // [breakpoints.up(1440)]: {
    //   width: '300px',
    //   minWidth: '300px',
    //   maxWidth: '300px',
    // },
    // [breakpoints.up('xl')]: {
    //   width: '400px',
    //   minWidth: '400px',
    //   maxWidth: '400px',
    // },
  },
  tableCellHighSchool: {
    width: '165px',
    minWidth: '165px',
    maxWidth: '165px',
    [breakpoints.up(1440)]: {
      width: '300px',
      minWidth: '300px',
      maxWidth: '300px',
    },
    [breakpoints.up('xl')]: {
      width: '400px',
      minWidth: '400px',
      maxWidth: '400px',
    },
  },
  textTransformLowercase: {
    textTransform: 'lowercase',
  },
  courseOptionPadding: {
    padding: `${configStyle.paddingOption}px`,
  },
  courseOptionWidth: {
    width: `calc(${configStyle.widthOption}px - ${
      2 * configStyle.paddingOption
    }px)`,
    padding: `${configStyle.paddingOption}px`,
    paddingBottom: 0,
  },
  courseOptionStyle: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: 0,
  },
  listStudentPadding: {
    padding: '8px 12px',
  },
  overflowXAuto: {
    overflowX: 'auto',
  },
  displayGirl: {
    display: 'grid',
  },
  displayFlex: {
    display: 'flex',
  },
  displayNone: {
    display: 'none !important',
  },
  pointer: { cursor: 'pointer' },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  backgroundColorTransparent: {
    backgroundColor: palette.background.default,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  widthFull: {
    width: '100%',
  },
  heightFull: {
    height: '100%',
  },
  heightLoading: {
    minHeight: '30vh',
  },
  margin0: {
    margin: 0,
  },
  padding0: {
    padding: '0 !important',
  },
  verticalAlignBottom: {
    verticalAlign: 'bottom',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  divider: {
    backgroundColor: palette.primary[200],
    opacity: 1,
  },
  visibility: {
    visibility: 'hidden',
  },
  borderTable: {
    minWidth: '5px',
    maxWidth: '5px',
    width: '5px',
  },
  borderTableActive: {
    backgroundColor: palette.primary['600'],
  },
  borderTableDetailActive: {
    backgroundColor: palette.primary['200'],
  },
  tableEmpty: {
    fontSize: '22px',
    lineHeight: '31px',
    color: palette.primary[900],
    marginTop: '5rem',
  },
  optionsTitleSize: {
    fontSize: '18px',
    color: palette.primary.dark,
  },
  studySubjectName: {
    color: palette.primary['900'],
    fontSize: '22px',
    lineHeight: '31px',
  },
  optionButtons: {
    height: '84px',
    padding: '24px 16px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  titleDark: {
    color: palette.primary.dark,
  },
  studentInfoName: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '31px',
  },
  marginLeft17: {
    marginLeft: '17px',
  },
  marginLeft14: {
    marginLeft: '14px',
  },
  marginTop22: {
    marginTop: '22px',
  },
  marginLeft27: {
    marginLeft: '27px',
  },
  marginTop14: {
    marginTop: '14px',
  },
  color500: {
    color: palette.primary[500],
  },
  marginTop32: {
    marginTop: '32px',
  },
  studySubject: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  paddingLeft4: {
    paddingLeft: '4px',
  },
  paddingRight0: {
    paddingRight: 0,
  },
  paddingBottom0: {
    paddingBottom: 0,
  },
  paddingTop0: {
    paddingTop: 0,
  },
  marginTop28: {
    marginTop: '28px',
  },
  marginTop26: {
    marginTop: '26px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  marginTop47: {
    marginTop: '47px',
  },
  studySubjectDefault: {
    borderColor: palette.grey['600'],
    color: palette.grey['600'],
    backgroundColor: palette.grey['500'],
  },
  studySubjectCurrent: {
    borderColor: palette.info['50'],
    color: palette.info['50'],
    background:
      'linear-gradient(0deg, rgba(77, 199, 93, 0.15), rgba(77, 199, 93, 0.15)), #FFFFFF',
  },
  contentStudentStudyBox: {
    padding: '24px 0px',
  },
  contentStudentStudyBoxPadding: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  contentStudentStudy: {
    padding: '4px 12px',
    borderRadius: '50px',
    border: `1px solid ${palette.primary[500]}`,
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  switchBase: {
    color: palette.primary[500],
  },
  switchTrack: {
    border: `1px solid ${palette.primary[500]}`,
    backgroundColor: palette.primary[500],
  },
  paddingRight24: {
    paddingRight: '24px',
  },
  width64: {
    width: '64px',
  },
  width83: {
    width: '83px',
  },
  width200: {
    minWidth: '200px',
    width: '200px',
  },
  capacityText: {
    marginTop: '12px',
    color: palette.primary.main,
  },
  textCountStudent: {
    color: palette.text.secondary,
  },
  rootChooseSecondarySchool: {
    width: '118px',
    color: palette.primary[600],
  },
  selectChooseSecondarySchool: {
    '&:focus': {
      backgroundColor: palette.background.default,
    },
  },
  iconChooseSecondarySchool: {
    color: palette.primary[600],
  },
  colorIconSearch: {
    color: '#666666',
  },
  boxNoteStudentCourse: {
    backgroundColor: '#F5F5F4',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '0 12px',
    paddingTop: '6px',
    color: palette.primary[500],
  },
  buttonChooseStudent: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  buttonChooseStudentActive: {
    backgroundColor: palette.primary[100],
  },
  subTableHeader: {
    fontWeight: 500,
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  heighHeadCell: {
    height: '28px',
  },
  positionRelative: {
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  informationInputs: {
    ...flexCol,
    gap: '16px',
    marginTop: '21px',
  },
  courseDateAndCapacityWrapper: {
    ...flexRow,
    justifyContent: 'space-between',
    marginTop: '30px',
    padding: '0 12px',
  },
  courseDateAndCapacity: {
    ...flexRow,
    alignItems: 'center',
    gap: '10px',
  },
  courseOptionHeader: {
    ...flexRow,
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  highlightedText: {
    fontWeight: 500,
    color: palette.primary.dark,
  },
  sizeIcon: {
    width: '25px',
    height: '25px',
  },
  sizeIconLoading: {
    width: '100% !important',
    height: '100% !important',
  },
  nestedStudentTableRow: {
    backgroundColor: palette.primary[50],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paddingHeaderDetailCourse: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  textTransformUppercase: {
    textTransform: 'uppercase',
  },
  movingMulDialog: {
    padding: '12px 16px',
  },
  textMovingMulDialog: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: palette.primary[900],
    fontSize: '16px',
  },
  textSchoolUnavailable: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'red',
    fontSize: '16px',
  },
  textNumberStudentMoving: {
    marginTop: '12px',
  },
  titleInfoCourseOld: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  movingMulDialogDivider: {
    margin: '16px 0',
  },
  movingMulDialogSelect: {
    paddingTop: '17.5px',
    paddingBottom: '18.5px',
  },
  movingMulDialogPager: {
    borderRadius: 0,
  },
  movingMulDialogList: {
    padding: 0,
  },
  movingMulDialogMenu: {
    paddingTop: '10px',
    paddingBottom: '11px',
    borderBottom: '1px solid #E0E0E0',
    '&:last-child': {
      borderBottomWidth: '0px',
    },
  },
  movingMulDialogButton: {
    marginTop: '64px',
    marginBottom: '32px',
  },
  notifySuccessWarraper: {
    padding: '16px',
    background: `linear-gradient(0deg, rgba(77, 199, 93, 0.15), rgba(77, 199, 93, 0.15)), #FFFFFF`,
  },
  notifySuccessTitle: {
    color: palette.info['50'],
  },
  sizeIconLoadingExportVGS: {
    width: '25px !important',
    height: '25px !important',
  },
}));

export default courseStyle;
