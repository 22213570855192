import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import StudySubjectService from '../services/StudySubjectService';
import IStudySubject, {
  emptyStudySubject,
  IStudentStudySubject,
  StudentStudySubject,
} from '../types/StudySubject';

class StudySubjectStore {
  public rootStore: RootStore;
  public allStudySubjects: IStudySubject[] = [];
  public chosenStudySubject: IStudySubject = emptyStudySubject;
  public studentStudySubjects: IStudentStudySubject[] = [];
  public studySubjectsBySchoolExternalId: IStudySubject[] = [];
  public studySubjectHeadCell: IStudySubject[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setChosenStudySubject = (studySubject: IStudySubject) => {
    this.chosenStudySubject = studySubject;
  };

  @action
  getAllStudySubjects = async (includeDeactivated = false) => {
    let studySubjects: IStudySubject[] | undefined = undefined;
    if (includeDeactivated) {
      studySubjects = await StudySubjectService.getAllIncludingDeactivated();
    } else {
      studySubjects = await StudySubjectService.getAll();
    }
    if (studySubjects) this.allStudySubjects = studySubjects;
  };

  @action
  getAllStudySubjectsForStudent = async () => {
    let studySubjects: IStudySubject[] | undefined = undefined;
    studySubjects = await StudySubjectService.getAllStudySubjectForStudent();
    if (studySubjects) this.allStudySubjects = studySubjects;
  };

  @action
  getStudySubjectById = async (id: number) => {
    const res = await StudySubjectService.getById(id);
    if (res) this.chosenStudySubject = res;
  };

  @action
  updateStudySubject = async (studySubject: IStudySubject) => {
    await StudySubjectService.update(studySubject);
  };

  @action
  addStudySubject = async (studySubject: IStudySubject): Promise<void> => {
    await StudySubjectService.add(studySubject);

    this.allStudySubjects.push(studySubject);
  };

  @action
  deleteStudySubject = async (id: number): Promise<void> => {
    await StudySubjectService.delete(id);

    const deleteIndex = this.allStudySubjects.findIndex((s) => s.id === id);
    if (deleteIndex > -1) this.allStudySubjects.splice(deleteIndex, 1);
  };

  @action
  getStudySubjectsByUserId = async (userId: number): Promise<void> => {
    const studentStudySubjects = await StudySubjectService.getByUserId(userId);
    if (studentStudySubjects) this.studentStudySubjects = studentStudySubjects;
  };

  @action
  async getStudySubjectsByCurrentUser(): Promise<void> {
    const userId = this.rootStore.userStore.user?.id;
    if (userId) {
      const studentStudySubjects = await StudySubjectService.getByUserId(
        userId
      );
      if (studentStudySubjects)
        this.studentStudySubjects = studentStudySubjects;
    }
  }

  @action
  addOrUpdateStudentPriorities = async (
    userId: number,
    priorities: number[]
  ) => {
    const newStudentStudySubjects =
      await StudySubjectService.addOrUpdateStudentPriorities(
        userId,
        priorities
      );
    if (newStudentStudySubjects)
      this.studentStudySubjects = newStudentStudySubjects;
  };

  @action
  async getStudySubjectName(id: number): Promise<string> {
    if (this.allStudySubjects.length === 0) await this.getAllStudySubjects();

    return (
      this.allStudySubjects.find((sub) => sub.id === id)?.studySubjectName ?? ''
    );
  }

  async getByExternalSchoolId(externalSchoolId: string): Promise<void> {
    const studySubjects = await StudySubjectService.getByExternalSchoolId(
      externalSchoolId
    );

    if (studySubjects) {
      this.studySubjectsBySchoolExternalId = studySubjects;
    }
  }

  async getStudySubjectByProjectId(
    projectId: number,
    externalSchoolId?: string
  ): Promise<void> {
    const studySubjects = await StudySubjectService.getStudySubjectByProjectId(
      projectId,
      externalSchoolId
    );

    if (studySubjects) {
      this.studySubjectHeadCell = studySubjects;
    }
  }

  async addOrUpdate(
    models: StudentStudySubject[]
  ): Promise<boolean | undefined> {
    return await StudySubjectService.addOrUpdate(models);
  }
}

export default StudySubjectStore;
