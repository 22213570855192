import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStyles from './style';
import { TableCell, TableRow, Collapse, Button } from '@material-ui/core';
import { User } from '@/types/User';
import {
  IStudentStudySubject,
  StudentStudySubject,
} from '@/types/StudySubject';
import StudentRowStudent from '../StudentRowSelect';
import clsx from 'clsx';
import { useStores } from '@/stores';

interface IComponentProps {
  open: boolean;
  student: User;
  all: boolean;
  addOrUpdate: (models: StudentStudySubject[]) => Promise<void>;
}

const StudentRowChangeRegistration = (props: IComponentProps) => {
  const { open, student, all, addOrUpdate } = props;
  const { projectStore } = useStores();
  const classes = useStyles({ open });

  const getStudentStudySubject = (
    sss: IStudentStudySubject
  ): StudentStudySubject => {
    const studentStudySubject = new StudentStudySubject();
    return { ...studentStudySubject, ...sss };
  };

  const [studentStudySubjects, setStudentStudySubjects] = useState<
    StudentStudySubject[]
  >(student.studentStudySubjects.map((sss) => getStudentStudySubject(sss)));

  const getStudentStudySubjectByPriority = (
    studentStudySubjects: StudentStudySubject[],
    priority: number
  ): StudentStudySubject | null => {
    if (studentStudySubjects.length) {
      const result = studentStudySubjects.find(
        (sss) => sss.priority === priority
      );
      if (!result) {
        return null;
      }

      return result;
    }

    return null;
  };

  const [first, setFirst] = useState<StudentStudySubject | null>(
    getStudentStudySubjectByPriority(studentStudySubjects, 1)
  );

  const [second, setSecond] = useState<StudentStudySubject | null>(
    getStudentStudySubjectByPriority(studentStudySubjects, 2)
  );

  const [third, setThird] = useState<StudentStudySubject | null>(
    getStudentStudySubjectByPriority(studentStudySubjects, 3)
  );

  const handleSetFirst = (studySubjectId: number): void => {
    if (first) {
      setFirst({ ...first, studySubjectId });
    } else {
      if (projectStore.currentProject && projectStore.currentProject.id) {
        const value: StudentStudySubject = {
          studentId: student.id,
          studySubjectId,
          priority: 1,
          projectId: projectStore.currentProject.id,
        };
        setFirst(value);
      }
    }
  };

  const handleSetSecond = (studySubjectId: number): void => {
    if (second) {
      setSecond({ ...second, studySubjectId });
    } else {
      if (projectStore.currentProject && projectStore.currentProject.id) {
        const value: StudentStudySubject = {
          studentId: student.id,
          studySubjectId,
          priority: 2,
          projectId: projectStore.currentProject.id,
        };
        setSecond(value);
      }
    }
  };

  const handleSetThird = (studySubjectId: number): void => {
    if (third) {
      setThird({ ...third, studySubjectId });
    } else {
      if (projectStore.currentProject && projectStore.currentProject.id) {
        const value: StudentStudySubject = {
          studentId: student.id,
          studySubjectId,
          priority: 3,
          projectId: projectStore.currentProject.id,
        };
        setThird(value);
      }
    }
  };

  const cancelChangeRegistration = () => {
    const result = student.studentStudySubjects.map((sss) =>
      getStudentStudySubject(sss)
    );

    setStudentStudySubjects(result);
    setFirst(getStudentStudySubjectByPriority(result, 1));
    setSecond(getStudentStudySubjectByPriority(result, 2));
    setThird(getStudentStudySubjectByPriority(result, 3));
  };

  const beforeAddOrUpdate = async (): Promise<void> => {
    const models: StudentStudySubject[] = [];

    if (first) {
      models.push(first);
    }

    if (second) {
      models.push(second);
    }

    if (third) {
      models.push(third);
    }

    if (models.length) {
      await addOrUpdate(models);
    }
  };

  return (
    <TableRow className={classes.collapseTableRow}>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={all ? 8 : 7}
      >
        <Collapse in={open} unmountOnExit>
          <div className={classes.collapse}>
            <div className={classes.studentStudySubjectList}>
              <StudentRowStudent
                studentStudySubject={first}
                priority={1}
                handleSetFirst={handleSetFirst}
              />
              <StudentRowStudent
                studentStudySubject={second}
                priority={2}
                handleSetSecond={handleSetSecond}
              />
              <StudentRowStudent
                studentStudySubject={third}
                priority={3}
                handleSetThird={handleSetThird}
              />
            </div>
            <div className={clsx(classes.displayFlex, classes.boxBtn)}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={async () => await beforeAddOrUpdate()}
              >
                Lagre
              </Button>
              <Button
                variant="text"
                style={{ marginLeft: '12px' }}
                className={classes.btn}
                onClick={cancelChangeRegistration}
              >
                Avbryt
              </Button>
            </div>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default observer(StudentRowChangeRegistration);
