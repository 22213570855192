import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  rootSelect: {
    color: palette.primary[900],
  },
  select: {
    '&:focus': {
      backgroundColor: palette.background.default,
    },
  },
  iconSelect: {
    color: palette.primary[500],
  },
  selectPadding: {
    padding: '12px 15px',
  },
}));

export default useStyles;
