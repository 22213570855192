import { useStores } from '@/stores';
import { User } from '@/types/User';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import StudentCourseRow from './StudentCourseRow';
import useStyles from './style';

interface IStudentCourseTableProps {
  loading: boolean;
  students: User[];
  page: number;
  handlePageChange: (e: any, page: number) => void;
  rowsPerPage: number;
  handleRowsPerPageChange: (e: any) => void;
  all: boolean;
  handleOpenManualAssignDialog: (student: User) => void;
}

const StudentCourseTable = ({
  students,
  page,
  handlePageChange,
  rowsPerPage,
  handleRowsPerPageChange,
  loading,
  all,
  handleOpenManualAssignDialog,
}: IStudentCourseTableProps) => {
  const classes = useStyles();
  const { schoolStore } = useStores();
  const { assignedStudentsCount, firstPriorityCount, totalStudentCount } =
    schoolStore;

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Navn</TableCell>
          <TableCell>Klasse</TableCell>
          {all && <TableCell>Skole</TableCell>}
          <TableCell>Status</TableCell>
          <TableCell>Tidspunkt</TableCell>
          <TableCell>Kontaktperson</TableCell>
          <TableCell align="right">Tildelt</TableCell>
          <TableCell align="right">
            <div>
              <Typography variant="body1">
                {assignedStudentsCount} tildelte elever
              </Typography>
              <Typography variant="body1">
                {firstPriorityCount} med 1. prioritet
              </Typography>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableCell colSpan={6} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </TableCell>
        ) : (
          <>
            {students.length > 0 ? (
              <>
                {students.map((student) => (
                  <StudentCourseRow
                    student={student}
                    all={all}
                    key={'tableRow-' + student.id}
                    handleOpenManualAssignDialog={handleOpenManualAssignDialog}
                  />
                ))}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography variant="h3">Det er ingen studenter</Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        )}
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        count={totalStudentCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Studenter per side:"
        nextIconButtonText="Neste side"
        backIconButtonText="Forrige side"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} av ${count !== -1 ? count : `mer enn ${to}`}`
        }
      />
    </Table>
  );
};

export default observer(StudentCourseTable);
