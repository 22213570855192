import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '&:hover': {
      backgroundColor: palette.background.default,
    },
    width: '20px',
    height: '20px',
  },
  border: {
    border: `2px solid ${palette.primary[500]}`,
  },
  borderButtonSeek: {
    borderWidth: '0px',
    backgroundColor: palette.primary[600],
    '&:hover': {
      backgroundColor: palette.primary[600],
      color: palette.primary.A100,
    },
  },
  visibility: {
    visibility: 'hidden',
  },
  expandLessIcon: {
    color: palette.primary.A100,
  },
}));

export default useStyles;
