import courseStyle from '@/views/style/courses';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { Button, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { StepType } from '@/types/StepType';
import StepStatus from '@/components/StepStatus';
import AllocationDialog from '@/views/app/admin/courses/components/AllocationDialog';
import PublishDialog from "@/views/app/admin/courses/components/PublishDialog";

interface IComponentProps {
  steps: StepType[];
  step: number;
  projectName: string;
}

const CourseHeader = (props: IComponentProps) => {
  const { steps, step, projectName } = props;
  const courseClasses = courseStyle();
  const [openAllocationDialog, setOpenAllocationDialog] =
    useState(false);
  const [openPublicDialog, setOpenPublicDialog] =
      useState(false);
  return (
    <div
      className={clsx(
        courseClasses.header,
        courseClasses.headerContent,
        courseClasses.displayFlex,
        courseClasses.alignItemsCenter
      )}
    >
      <Grid container alignItems="center">
        <HomeOutlinedIcon />
        <Divider
          orientation="vertical"
          className={courseClasses.headerDivider}
        />
        <span className={courseClasses.headerTitle}>{projectName}</span>
      </Grid>

      <div className={courseClasses.marginRight30}>
        <StepStatus steps={steps} step={step} />
      </div>

      <Button
        classes={{ root: courseClasses.publishButton }}
        onClick={() => {
          setOpenAllocationDialog(true)
        }}
      >
        Tildel
      </Button>
      <Button
        classes={{ root: courseClasses.publishButton }}
        onClick={() => {
          setOpenPublicDialog(true)
        }}
      >
        Publiser
      </Button>
      <AllocationDialog open={openAllocationDialog} setOpen={setOpenAllocationDialog} />
      <PublishDialog open={openPublicDialog} setOpen={setOpenPublicDialog} />
    </div>
  );
};

export default observer(CourseHeader);
