import { createTheme } from '@material-ui/core';
import { darkBlue, lightBlue, mainBlue } from './colors';

// To be able to get all shades of color in components
import { Color } from '@material-ui/core';
type ColorPartial = Partial<Color>;
declare module '@material-ui/core/styles/createPalette' {
  interface PaletteColor extends ColorPartial {
    customColor?: string;
  }
}

const paletteTheme = createTheme({
  palette: {
    primary: {
      main: mainBlue,
      light: lightBlue,
      dark: darkBlue,
      '50': '#EBEAF1',
      '100': lightBlue,
      '200': '#A2A8C8',
      '500': mainBlue,
      '600': '#1841D3',
      '900': darkBlue,
      A100: '#FFFFFF',
    },
    secondary: {
      main: '#F9C66B',
      light: '#FBDCAA',
    },
    info: {
      main: '#F9C66B',
      light: '#FBDCAA',
      '50': '#156529',
    },
    warning: {
      main: '#FFE600',
      light: '#F9ECEC',
      dark: '#9C3535',
      '50': '#762828',
    },
    success: {
      main: '#4DC75D',
    },
    text: {
      primary: mainBlue,
      secondary: '#6E6893',
      hint: '#7B6D25',
    },
    grey: {
      '50': '#f5f5f4',
      '100': '#EBEAF1',
      '200': '#E5E5E5',
      '500': '#E4E4E4',
      '600': '#8D8D8D',
      '900': '#666666',
      A100: '#263238',
      A200: '#848DB8',
    },
    background: {
      default: 'transparent',
    },
  },
  typography: {
    fontFamily: [
      'Oslo Sans',
      'Open Sans',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: '14px',
    },
    body2: {
      color: mainBlue,
      lineHeight: '16px',
      fontSize: '14px',
      fontWeight: 400,
    },
    h1: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '56px',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '33px',
    },
    h3: {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '30px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '25px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 200,
      lineHeight: '18px',
    },
    button: {
      textTransform: 'none',
    },
  },
});

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 0,
        },
      },
      MuiTextField: {
        root: {
          borderRadius: '4px',
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          borderRadius: '4px',
          color: paletteTheme.palette.primary.main,
        },
        underline: {
          '&:before': {
            left: '1px',
            right: '1px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
            borderRadius: '1px',
          },
          '&:after': {
            left: '1px',
            right: '1px',
            borderRadius: '1px',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: paletteTheme.palette.primary.main,
        },
      },
      MuiToolbar: {
        gutters: {
          [paletteTheme.breakpoints.up('xs')]: {
            paddingRight: 0,
          },
        },
      },
      MuiListItem: {
        root: {
          '&:hover': {
            backgroundColor: paletteTheme.palette.grey[100],
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: paletteTheme.palette.primary.light,
        },
      },
      MuiChip: {
        outlined: {
          borderColor: paletteTheme.palette.primary.main,
          backgroundColor: '#fff',
          color: paletteTheme.palette.primary.main,
        },
        deleteIcon: {
          fill: paletteTheme.palette.primary.main,
        },
      },
      MuiLink: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    props: {
      MuiTextField: {
        variant: 'filled',
      },
      MuiButton: {
        variant: 'contained',
        color: 'primary',
      },
      MuiSvgIcon: {
        color: 'primary',
      },
      MuiChip: {
        variant: 'outlined',
      },
      MuiSelect: {
        variant: 'filled',
      },
      MuiFormControl: {
        variant: 'filled',
      },
    },
  },
  paletteTheme
);
export default theme;
