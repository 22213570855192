import CustomCheckBox from '@/components/CustomCheckBox';
import CustomIconButton from '@/components/CustomIconButton';
import { IUserDto } from '@/types/User';
import SchoolHoverCard from '@/views/app/common/Students/StudentCourseTable/StudentCourseRow/ContactPersonCell/SchoolHoverCard';
import courseStyle from '@/views/style/courses';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { AccessibilityNew, CreateOutlined } from '@material-ui/icons';
import { FC } from 'react';
import clsx from 'clsx';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { CourseStatus } from '@/types/enums/CourseStatus';

interface INestedStudentTableRowProps {
  student: IUserDto;
  selectStudent: (studentId: number) => void;
  toggleAbsence: (student: IUserDto) => void;
  courseStatus?: CourseStatus;
}

const NestedStudentTableRow: FC<INestedStudentTableRowProps> = ({
  student,
  selectStudent,
  toggleAbsence,
  courseStatus,
}) => {
  const courseClasses = courseStyle();
  const { userStore, projectStore } = useStores();

  const handleContactPerson = (externalSchoolId: string): void => {
    userStore.resetContactPerson();
    if (externalSchoolId) {
      userStore.getContactPersonByExternalSchoolId(externalSchoolId);
    }
  };

  const studentCourse = student.studentCourses?.find(
    (c) => c.finalAssignedCourse?.projectId === projectStore.currentProject?.id
  );

  const absence = !studentCourse?.attendance;

  return (
    <TableRow
      key={student.id}
      onClick={() => student.id && selectStudent(student.id)}
      className={courseClasses.nestedStudentTableRow}
    >
      <TableCell
        classes={{
          root: clsx(
            courseClasses.padding0,
            courseClasses.borderTable,
            courseClasses.borderTableDetailActive
          ),
        }}
      ></TableCell>
      <TableCell
        classes={{
          root: clsx(
            courseClasses.widthCheckbox,
            courseClasses.checkboxPadding,
            courseClasses.paddingRowDetail
          ),
        }}
      >
        <CustomCheckBox hide={true} />
      </TableCell>
      <TableCell
        classes={{
          root: clsx(
            courseClasses.widthButtonDetail,
            courseClasses.buttonDetailPadding,
            courseClasses.paddingRowDetail
          ),
        }}
      >
        <CustomIconButton hide={true} />
      </TableCell>
      <TableCell
        classes={{
          root: clsx(
            courseClasses.tableCellStudySubject,
            courseClasses.textStyle,
            courseClasses.paddingRowDetail
          ),
        }}
      >
        {student.fullName}
      </TableCell>
      <TableCell
        classes={{
          root: clsx(courseClasses.paddingRowDetail),
        }}
        onPointerEnter={() => {
          if (student.school && student.school.externalId) {
            handleContactPerson(student.school.externalId);
          }
        }}
      >
        <Tooltip
          interactive
          classes={{
            tooltip: clsx(courseClasses.margin0, courseClasses.padding0),
          }}
          title={
            !!student.school &&
            !!userStore.contactPerson && (
              <SchoolHoverCard
                school={student.school}
                contactPerson={userStore.contactPerson}
              />
            )
          }
        >
          <span className={clsx(courseClasses.pointer)}>
            {`${student.school?.schoolName} ${student.class?.fullName}`}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell
        classes={{
          root: clsx(
            courseClasses.paddingRowDetail,
            courseClasses.textAlignCenter
          ),
        }}
      >
        <CustomCheckBox
          isChecked={absence}
          handleChange={() => toggleAbsence(student)}
          disabled={courseStatus === CourseStatus.CANCELLED}
        />
      </TableCell>
      <TableCell classes={{ root: courseClasses.paddingRowDetail }}></TableCell>
      <TableCell classes={{ root: courseClasses.paddingRowDetail }}>
        <div
          className={clsx(
            courseClasses.displayFlex,
            courseClasses.justifyContentFlexEnd,
            courseClasses.whiteSpaceNowrap,
            courseClasses.alignItemsCenter
          )}
        >
          <div
            className={clsx(
              courseClasses.pointer,
              courseClasses.buttonChooseStudent,
              {
                [courseClasses.visibility]: !student.hasSpecialNeeds,
              }
            )}
          >
            <AccessibilityNew />
          </div>

          <div
            className={clsx(
              courseClasses.pointer,
              courseClasses.buttonChooseStudent,
              {
                [courseClasses.buttonChooseStudentActive]:
                  student.id === userStore.studentId,
              }
            )}
            onClick={() => student.id && selectStudent(student.id)}
          >
            <CreateOutlined />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default observer(NestedStudentTableRow);
