import { useStores } from '@/stores';
import IStudySubject, {
  getStudySubjectCategoryName,
} from '@/types/StudySubject';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { useState } from 'react';
import useStyles from './style';

interface IStudySubjectCardProps {
  studySubject: IStudySubject;
  isAdmin: boolean;
}

const StudySubjectCard = ({
  studySubject,
  isAdmin,
}: IStudySubjectCardProps) => {
  const classes = useStyles();

  const { stateStore, studySubjectStore } = useStores();
  const { setStudySubjectDrawerIsOpen, setVgsStudySubjectDrawerIsOpen } =
    stateStore;
  const { setChosenStudySubject, deleteStudySubject } = studySubjectStore;

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleEdit = () => {
    setChosenStudySubject(studySubject);
    setStudySubjectDrawerIsOpen(true);
  };

  const handleDelete = () => {
    deleteStudySubject(studySubject.id);
    setOpenDeleteConfirmation(false);
  };

  const handleCreateOrEditCourseTemplate = (): void => {
    setChosenStudySubject(studySubject);
    setVgsStudySubjectDrawerIsOpen(true);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.headerWrapper}>
        <div>
          <Typography variant="h5">
            {getStudySubjectCategoryName(studySubject.category)}{' '}
            utdanningsprogram
          </Typography>
          <Typography variant="h3" className={classes.header}>
            {studySubject.studySubjectName}{' '}
          </Typography>
          {studySubject.isHide && (
            <Typography variant="h5" className={classes.deactivated}>
              Skjul for påmelding
            </Typography>
          )}
          {studySubject.deactivated && (
            <Typography variant="h5" className={classes.deactivated}>
              Deaktivert
            </Typography>
          )}
        </div>
        {isAdmin ? (
          <div>
            <IconButton onClick={handleEdit}>
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => setOpenDeleteConfirmation(true)}>
              <DeleteOutlined />
            </IconButton>
          </div>
        ) : (
          <div>
            <IconButton onClick={handleCreateOrEditCourseTemplate}>
              <EditOutlined />
            </IconButton>
          </div>
        )}
      </div>
      {isAdmin && (
      <Typography variant="body1">{studySubject.description}</Typography>)}
      {isAdmin && (
        <Dialog
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
        >
          <DialogTitle>
            <Typography variant="h4">
              Er du sikker på at du vil slette {studySubject.studySubjectName}?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => setOpenDeleteConfirmation(false)}
            >
              Avbryt
            </Button>
            <Button onClick={handleDelete}>Slett</Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};

export default StudySubjectCard;
