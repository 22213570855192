import { makeStyles } from '@material-ui/core';

export const configStyle = {
  widthTitle: 150,
  widthButtonDeleteQuestion: 150,
  paddingContentQuestion: 100,
  widthButtonDeleteAnswerOption: 24,
  widthSurvey: 40,
};

const userSurveyStyle = makeStyles(({ breakpoints, palette }) => ({
  root: {
    padding: '16px 115px',
    [breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  table: {
    backgroundColor: '#fff',
  },
  tableRow: {
    borderLeft: '3px solid transparent',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      borderLeft: '3px solid ' + palette.primary.light,
    },
  },
  container: {
    padding: '0 116px',
    paddingTop: '20px',
  },
  containerQuestion: {
    padding: '20px 120px',
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  marginTop5: {
    marginTop: '5px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  marginTop30: {
    marginTop: '30px',
  },
  marginTop40: {
    marginTop: '40px',
  },
  questionTitle: {
    width: `${configStyle.widthTitle}px`,
  },
  questionContent: {
    width: `calc(100% - ${configStyle.widthTitle}px)`,
  },
  heightFull: {
    height: '100%',
  },
  widthFull: {
    width: '100%',
  },
  color500: {
    color: palette.primary[500],
  },
  surveyInput: {
    padding: '12px 8px',
    fontSize: '16px',
    fontWeight: 500,
    border: '1px solid #E0E0E0',
    backgroundColor: palette.primary.A100,
    color: '#000',
  },
  questionInput: {
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #E0E0E0',
    backgroundColor: palette.primary.A100,
    color: '#000',
  },
  questionTextArea: {
    padding: '12px 8px',
    fontSize: '16px',
    border: '1px solid #E0E0E0',
    backgroundColor: palette.primary.A100,
    color: '#000',
    '&:hover': {
      backgroundColor: palette.primary.A100,
    },
  },
  surveyQuestionType: {
    padding: '12px 8px',
    borderRadius: 0,
    border: '1px solid #E0E0E0',
    backgroundColor: palette.primary.A100,
    color: '#000',
    '&:hover': {
      backgroundColor: palette.primary.A100,
    },
    '&:focus': {
      backgroundColor: palette.primary.A100,
    },
  },
  surveyQuestionTypePager: {
    borderRadius: 0,
  },
  surveyQuestionTypeList: {
    padding: 0,
  },
  surveyQuestionTypeMenu: {
    color: '#000',
    paddingTop: '10px',
    paddingBottom: '11px',
    borderBottom: '1px solid #E0E0E0',
    '&:last-child': {
      borderBottomWidth: '0px',
    },
  },
  addSurveyQuestion: {
    marginTop: '10px',
  },
  padding0: {
    padding: 0,
  },
  fontSize18: {
    fontSize: '18px',
  },
  pointer: { cursor: 'pointer' },
  wrapperDialog: {
    padding: '12px 16px',
  },
  titleAddSurveyQuestionDialog: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: palette.primary[900],
    fontSize: '16px',
  },
  fieldRequire: {
    color: '#d9534f',
  },
  boxQuestion: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '12px 16px',
  },
  buttonDeleteQuestion: {
    backgroundColor: '#d9534f',
    '&:hover': {
      backgroundColor: '#c9302c',
    },
  },
  widthButtonDeleteQuestion: {
    width: `${configStyle.widthButtonDeleteQuestion}px`,
  },
  widthContentQuestion: {
    width: `calc(100% - ${configStyle.widthButtonDeleteQuestion}px)`,
    paddingRight: `${configStyle.paddingContentQuestion}px`,
  },
  spaceCheck: {
    marginRight: '5px',
  },
  buttonDeleteAnswerOption: {
    color: '#d9534f',
    '&:hover': {
      color: '#c9302c',
    },
  },
  widthAnswerOption: {
    width: '60%',
  },
  widthButtonDeleteAnswerOption: {
    width: `${configStyle.widthButtonDeleteAnswerOption}px`,
  },
  widthContentAnswerOption: {
    width: `calc(100% - ${configStyle.widthButtonDeleteAnswerOption}px)`,
  },
  buttonNewSurvey: {
    marginRight: '15px',
  },
  buttonSurveyHome: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  containerSurvey: {
    width: `${configStyle.widthSurvey}%`,
    padding: '10px 15px',
  },
  containerListProject: {
    width: `calc(100% - ${configStyle.widthSurvey}% - 20px)`,
    marginLeft: '20px',
  },
  titleSurvey: {
    color: palette.common.black,
    fontSize: '28px',
  },
  answerOptionInDetail: {
    height: '25px',
  },
  minHeight: {
    minHeight: '50vh',
  },
  errorQuestion: {
    border: '1px solid #f00',
  },
  questionSubtitle: {
    color: '#999',
  },
  noBorder: {
    borderWidth: 0,
  },
  fontSizeTitle: {
    fontSize: '1.4rem',
  },
  dividerStyle: {
    backgroundColor: '#75b86e',
    height: '3px !important',
  },
  marginBottom5: { marginBottom: '5px' },
  marginBottom10: { marginBottom: '10px' },
}));

export default userSurveyStyle;
