import { makeStyles } from '@material-ui/core';
const config = {
  size: 100,
  donutSize: 20,
};

const useStyles = makeStyles(({ palette }) => ({
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  chartContent: {
    minWidth: '260px',
    width: '260px',
  },
  chartSize: {
    width: `${config.size}px`,
    height: `${config.size}px`,
  },
  pieID: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  pie: {
    width: `${config.size}px`,
    height: `${config.size}px`,
    position: 'relative',
    backgroundColor: 'pink',
    borderRadius: '50%',
  },
  pieInner: {
    position: 'absolute',
    zIndex: 1,
    width: `${config.size}px`,
    height: `${config.size}px`,
    borderRadius: '50%',
    top: 0,
    left: 0,
    backgroundColor: palette.background.default,
  },
  pieInnerContent: {
    borderRadius: '50%',
    width: `${config.size - config.donutSize}px`,
    height: `${config.size - config.donutSize}px`,
    backgroundColor: palette.primary.A100,
  },
  slice: {
    position: 'absolute',
    width: `${config.size}px`,
    height: `${config.size}px`,
    clip: `rect(0px, ${config.size}px, ${config.size}px, ${config.size / 2}px)`,
    animation: 'bake-pie 1s',

    '& span': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'black',
      width: `${config.size}px`,
      height: `${config.size}px`,
      borderRadius: '50%',
      clip: `rect(0px, ${config.size}px, ${config.size}px, ${
        config.size / 2
      }px)`,
    },
  },
  pieInnerTextColor: {
    color: palette.warning.dark,
  },
  pieInnerTextPercent: {
    fontSize: '17px',
    lineHeight: '18px',
  },
  pieInnerTextLabel: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  widthFull: {
    width: '100%',
  },
  heightFull: {
    height: '100%',
  },
}));

export default useStyles;
