import CustomDayPickerRange from '@/components/CustomDayPickerRange';
import { useStores } from '@/stores';
import UnavailableDate from '@/types/UnavailableDate';
import {
  Button,
  Chip,
  CircularProgress,
  Drawer,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimeFormatOptions } from 'luxon';
import { observer } from 'mobx-react';
import { FormEvent, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import useStyle from './style';

const UnavailableDatesDrawer = () => {
  const { stateStore, schoolStore, errorStore } = useStores();
  const { editingSchool, setUnavailableDates, saveUnavailableDates } =
    schoolStore;
  const { unavailableDateDrawerIsOpen, setUnavailableDateDrawerIsOpen } =
    stateStore;
  const { showErrorMessage } = errorStore;

  const classes = useStyle();

  const newRange: RangeModifier = {
    from: new Date(),
    to: new Date(),
  };

  const [selection, setSelection] = useState<RangeModifier>(newRange);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const sortedDates = editingSchool.unavailableDates
    .slice()
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!editingSchool.externalId) return;

    if (!selection.from || !selection.to) {
      showErrorMessage('Velg både en start og slutt dato');
      return;
    }
    const unavailableDates = [...editingSchool.unavailableDates];
    const newDate = new UnavailableDate(
      editingSchool.externalId,
      comment,
      selection.from,
      selection.to
    );

    if (unavailableDates.some((ud) => ud.overlapsWith(newDate))) {
      showErrorMessage('Denne datoen overlapper med en annen dato');
      return;
    }

    unavailableDates.push(newDate);

    setUnavailableDates(unavailableDates);

    setComment('');
    setSelection({
      from: newDate.end,
      to: newDate.end,
    } as RangeModifier);
  };

  const handleDelete = (date: UnavailableDate) => {
    let unavailableDates = [...editingSchool.unavailableDates];

    unavailableDates = unavailableDates.filter(
      (ud) => ud.start !== date.start || ud.end !== date.end
    );

    setUnavailableDates(unavailableDates);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      await saveUnavailableDates();
      setLoading(false);
      setUnavailableDateDrawerIsOpen(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const dateOptions: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };

  const onClose = () => {
    setComment('');
    setSelection(newRange);
    setUnavailableDateDrawerIsOpen(false);
  };

  return (
    <Drawer
      open={unavailableDateDrawerIsOpen}
      onClose={() => setUnavailableDateDrawerIsOpen(false)}
      anchor="right"
    >
      <div className={classes.content}>
        <Typography variant="h3">
          Kalender for {editingSchool.schoolName}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Typography variant="h5">Velg uaktuelle dager</Typography>
          <CustomDayPickerRange
            selection={selection}
            setSelection={setSelection}
          />
          <TextField
            label="Kommentar"
            required
            onChange={(e) => setComment(e.currentTarget.value)}
            value={comment}
          />
          <Button type="submit">Legg til</Button>
        </form>
        <div className={classes.dateListWrapper}>
          <Typography variant="h5" className={classes.listHeader}>
            Elever vil ikke bli tildelt i følgende perioder:
          </Typography>
          {sortedDates.map((ud) => (
            <div className={classes.dateListItem}>
              {ud.comment}
              <Chip
                label={
                  <span>
                    {ud.start.toLocaleDateString('nb', dateOptions)}
                    {ud.start.getTime() !== ud.end.getTime() && (
                      <> - {ud.end.toLocaleDateString('no', dateOptions)}</>
                    )}
                  </span>
                }
                onDelete={() => handleDelete(ud)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button disabled={loading} onClick={handleSave}>
          Lagre
          {loading ? (
            <CircularProgress size={24} className={classes.loadingButton} />
          ) : null}
        </Button>
        <Button variant="text" onClick={onClose}>
          Avbryt
        </Button>
      </div>
    </Drawer>
  );
};

export default observer(UnavailableDatesDrawer);
