enum MessageTemplateName {
  StudentAddedPrioritization = 1,
  StudentAlteredPrioritization = 2,
  StudentCourseConfirmed = 3,
  StudentEvaluation = 4,
  GUProritizationClosingReminder = 5,
  GUEvaluationMessage = 6,
  EventCancelled = 7,
}

export default MessageTemplateName;
