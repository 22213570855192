import { INIT_PROJECT } from '@/types/Project';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Add, BookmarksOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { LeftDrawerProjectItem } from '../../../../../components/admin/LeftDrawerProjectItem';
import { useStores } from '../../../../../stores';
import { UserRole } from '../../../../../types/UserRole';

interface IProjectMenuProps {
  goTo: (path: string) => void;
}

const ProjectMenu = ({ goTo }: IProjectMenuProps) => {
  const { userStore, projectStore } = useStores();
  const { user } = userStore;

  const handleAddProjectClick = () => {
    projectStore.openProjectPanel(INIT_PROJECT);
  };

  const goToCourse = (id = 0) => {
    goTo(`/projects/${id}/courses`);
  };

  React.useEffect(() => {
    projectStore.getLeftMenuList();
  }, [projectStore]);

  return (
    <>
      <ListItem className="cursor-pointer" onClick={() => goTo('/projects')}>
        <ListItemIcon>
          <BookmarksOutlined />
        </ListItemIcon>
        <ListItemText primary="Prosjekter" />
      </ListItem>
      {user?.currentRole === UserRole.Admin && (
        <>
          <ListItem button onClick={handleAddProjectClick}>
            <ListItemText inset>
              <Add /> Legg til
            </ListItemText>
          </ListItem>
        </>
      )}
      {projectStore.arrangedProjects.map((project) => (
        <LeftDrawerProjectItem
          project={project}
          key={`left-drawer-project-${project.id}`}
          goToCourse={goToCourse}
        />
      ))}
      <ListItem button onClick={() => goTo('/projects?isArchived=1')}>
        <ListItemText inset>Arkivert</ListItemText>
      </ListItem>
      <Divider />
    </>
  );
};

export default observer(ProjectMenu);
