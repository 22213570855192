import React from 'react';
import userSettingStyle from '../../style';
import clsx from 'clsx';
import CustomSearch from '@/components/CustomSearch';
import AddIcon from '@material-ui/icons/Add';

interface IComponentProps {
  search: string;
  setSearch: (value: string) => void;
  handleSearch: () => void;
  handleCloseCreateOrUpdateUserDrawer: (open: boolean) => void;
}

const UserSettingFilter = (props: IComponentProps) => {
  const {
    search,
    setSearch,
    handleSearch,
    handleCloseCreateOrUpdateUserDrawer,
  } = props;
  const classes = userSettingStyle();

  return (
    <>
      <div className={clsx(classes.displayFlex, classes.filterNav)}>
        <div
          className={clsx(
            classes.leftContent,
            classes.heightFull,
            classes.space
          )}
        >
          <div className={classes.customSearch}>
            <CustomSearch
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              label={'Søk med navn'}
            />
          </div>
        </div>

        <div
          className={clsx(
            classes.rightContent,
            classes.heightFull,
            classes.displayFlex,
            classes.alignItemsCenter
          )}
        >
          <div
            className={clsx(
              classes.displayFlex,
              classes.addNewUserBtn,
              classes.alignItemsCenter,
              classes.pointer
            )}
            onClick={() => handleCloseCreateOrUpdateUserDrawer(true)}
          >
            <AddIcon className={classes.spaceAddIcon} />
            Legg til ny bruker
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettingFilter;
