import { Fade, Tab, Tabs, CircularProgress } from '@material-ui/core';
import { Redo, Undo } from '@material-ui/icons';
import clsx from 'clsx';
import { CourseOptionAdmin } from '@/types/enums/CourseOption';
import courseStyle from '@/views/style/courses';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { GetAppOutlined } from '@material-ui/icons';

interface IComponentProps {
  tabMode: CourseOptionAdmin;
  chooseTabCourseOption: (mode: CourseOptionAdmin) => void;
  removeAllAllocations: () => Promise<void>;
  handleOpenMultipleStatusDialog: () => void;
  deleteListCourse: () => Promise<void>;
  handleUndo: () => Promise<void>;
  handleRedo: () => Promise<void>;
  getCourseExcelExport: () => Promise<void>;
  exportLoading: boolean;
}

const CourseFilter = (props: IComponentProps) => {
  const {
    tabMode,
    chooseTabCourseOption,
    removeAllAllocations,
    deleteListCourse,
    handleOpenMultipleStatusDialog,
    handleUndo,
    handleRedo,
    getCourseExcelExport,
    exportLoading,
  } = props;
  const { courseStore } = useStores();
  const courseClasses = courseStyle();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={clsx(
          courseClasses.displayFlex,
          courseClasses.courseFilterNav
        )}
      >
        <div
          className={clsx(
            courseClasses.leftContent,
            courseClasses.heightFull,
            courseClasses.space
          )}
        >
          <div
            className={clsx(
              courseClasses.displayFlex,
              courseClasses.overflowXAuto,
              courseClasses.heightFull,
              courseClasses.alignItemsCenter
            )}
          >
            <div
              className={clsx(
                courseClasses.marginLeftAuto,
                courseClasses.displayFlex,
                courseClasses.justifyContentFlexEnd,
                courseClasses.alignItemsCenter,
                courseClasses.whiteSpaceNowrap
              )}
            >
              <div
                onClick={async () => {
                  await handleUndo();
                }}
                className={clsx(
                  courseClasses.pointer,
                  courseClasses.sizeIcon,
                  courseClasses.marginRight12,
                  {
                    [courseClasses.disabled]:
                      courseStore.redoStack.length === 0,
                  }
                )}
              >
                <Undo />
              </div>

              <div
                onClick={async () => {
                  await handleRedo();
                }}
                className={clsx(
                  courseClasses.pointer,
                  courseClasses.sizeIcon,
                  courseClasses.marginRight12,
                  {
                    [courseClasses.disabled]:
                      courseStore.undoStack.length === 0,
                  }
                )}
              >
                <Redo />
              </div>
              <div
                onClick={async () => {
                  await getCourseExcelExport();
                }}
                className={clsx(
                  courseClasses.pointer,
                  courseClasses.sizeIcon,
                  courseClasses.marginRight12,
                  {
                    [courseClasses.disabled]: exportLoading,
                  }
                )}
              >
                {exportLoading ? (
                  <CircularProgress
                    classes={{
                      root: clsx(courseClasses.sizeIconLoading),
                    }}
                  />
                ) : (
                  <GetAppOutlined />
                )}
              </div>
              <>
                <div
                  aria-controls={`fade-menu-handle-checkbox`}
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  className={clsx(courseClasses.pointer)}
                >
                  <MoreVertOutlinedIcon />
                </div>
                <Menu
                  id={`fade-menu-handle-checkbox`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={openMenu}
                  onClose={handleCloseMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    key="remove-all-allocations"
                    onClick={async () => {
                      handleCloseMenu();
                      await removeAllAllocations();
                    }}
                  >
                    Fjern tildelinger
                  </MenuItem>
                  <MenuItem
                    key="delete-all-events"
                    onClick={async () => {
                      handleCloseMenu();
                      await deleteListCourse();
                    }}
                  >
                    Slett arrangement
                  </MenuItem>
                  <MenuItem
                    key="change-multiple-status"
                    onClick={() => {
                      handleCloseMenu();
                      handleOpenMultipleStatusDialog();
                    }}
                  >
                    Endre status
                  </MenuItem>
                </Menu>
              </>
            </div>
          </div>
        </div>
        <div
          className={clsx(courseClasses.rightContent, courseClasses.padding0)}
        >
          <Tabs
            value={tabMode}
            indicatorColor="primary"
            onChange={(
              event: React.ChangeEvent<Record<string, unknown>>,
              newValue: CourseOptionAdmin
            ) => {
              chooseTabCourseOption(newValue);
            }}
            className={courseClasses.heightFull}
            classes={{ indicator: courseClasses.tabIndicator }}
          >
            <Tab
              label="Arrangement"
              value={CourseOptionAdmin.CREATE_EDIT}
              classes={{ selected: courseClasses.stepIconActive }}
              className={courseClasses.tabAdmin}
            />
            <Tab
              label="Påmeldt"
              value={CourseOptionAdmin.LIST_STUDENT}
              classes={{ selected: courseClasses.stepIconActive }}
              className={courseClasses.tabAdmin}
            />
            <Tab
              label="Elev info"
              value={CourseOptionAdmin.INFO_STUDENT}
              classes={{ selected: courseClasses.stepIconActive }}
              className={courseClasses.tabAdmin}
            />
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default observer(CourseFilter);
