import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const flex: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

export const flexRow: CSSProperties = {
  ...flex,
  flexDirection: 'row',
};
export const flexCol: CSSProperties = {
  ...flex,
  flexDirection: 'column',
};
