import { useStores } from '@/stores';
import {
  getMessageTemplateTypeDisplay,
  MessageTemplateType,
} from '@/types/enums/MessageTemplateType';
import MessageTemplate from '@/types/MessageTemplate';
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ChangeEvent, FC } from 'react';
import AvailableTemplateData from './AvailableTemplateData';
import MessageTemplateWYSIWYG from './MessageTemplateWYSIWYG';
import useStyles from './style';

interface IEditMessageTEmplateDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditMessageTemplateDrawer: FC<IEditMessageTEmplateDrawerProps> = ({
  open,
  setOpen,
}) => {
  const classes = useStyles();

  const { messageTemplateStore } = useStores();
  const {
    currentMessageTemplate,
    setCurrentMessageTemplate,
    submitCurrentMessageTemplate,
    getAllMessageTemplates,
  } = messageTemplateStore;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        name: event.target.value,
      })
    );
  };

  const handleSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        emailSubject: event.target.value,
      })
    );
  };

  const handleSmsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        sms: event.target.value,
      })
    );
  };

  const handleSendSmsChange = (e: any, checked: boolean) => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        sendSMS: checked,
      })
    );
  };
  const handleSendEmailChange = (e: any, checked: boolean) => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        sendEmail: checked,
      })
    );
  };

  const handleRecipientTypeChange = (value: MessageTemplateType): void => {
    setCurrentMessageTemplate(
      MessageTemplate.fromJson({
        ...currentMessageTemplate,
        recipientType: value,
      })
    );
  };

  const handleSave = async () => {
    await submitCurrentMessageTemplate();
    setOpen(false);
    getAllMessageTemplates();
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <div className={classes.root}>
        <Typography variant="h2">Oppdater meldingsmal</Typography>
        <TextField
          label="Navn på mal"
          value={currentMessageTemplate?.name}
          onChange={handleNameChange}
        />
        <div className={classes.recipientType}>
          <div className={classes.recipientTypeLabel}>
            <Typography variant="h3">Mottakertype:</Typography>
          </div>
          <Select
            value={currentMessageTemplate?.recipientType}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              handleRecipientTypeChange(e.target.value as MessageTemplateType)
            }
            inputProps={{
              className: classes.selectPadding,
            }}
          >
            {Object.keys(MessageTemplateType)
              .filter(
                (k: string) => typeof MessageTemplateType[k as any] === 'string'
              )
              .map((k1: string) => +k1)
              .map((k2: number) => (
                <MenuItem key={k2} value={k2}>
                  {getMessageTemplateTypeDisplay(k2)}
                </MenuItem>
              ))}
          </Select>
        </div>
        <AvailableTemplateData />
        <div className={classes.subheader}>
          <Typography variant="h3">E-post</Typography>
          <FormControlLabel
            label="Send E-post"
            onChange={handleSendEmailChange}
            checked={currentMessageTemplate?.sendEmail}
            control={<Checkbox name="sendEmail" color="primary" />}
          />
        </div>
        <TextField
          label="E-post emne"
          value={currentMessageTemplate?.emailSubject}
          onChange={handleSubjectChange}
        />
        <MessageTemplateWYSIWYG />
        <div className={classes.subheader}>
          <Typography variant="h3">SMS</Typography>
          <FormControlLabel
            label="Send SMS"
            onChange={handleSendSmsChange}
            checked={currentMessageTemplate?.sendSMS}
            control={<Checkbox name="sendSms" color="primary" />}
          />
        </div>
        <TextField
          multiline
          value={currentMessageTemplate?.sms}
          onChange={handleSmsChange}
        />
        <Button onClick={handleSave}>Lagre</Button>
        <Button variant="text" onClick={() => setOpen(false)}>
          Avbryt
        </Button>
      </div>
    </Drawer>
  );
};

export default observer(EditMessageTemplateDrawer);
