export enum UserRole {
  Unknown = 0,
  Student = 1,
  Adviser = 1 << 1,
  HighSchoolContact = 1 << 2,
  Admin = 1 << 3,
}

const singleRoles = [0, 1, 2, 4, 8];

export const isMultipleRoles = (role: UserRole) => !singleRoles.includes(role);
export const isRole = (userRole: UserRole, role: UserRole) => {
  return (userRole & role) > 0;
};

export const getNorwegianRole = (role: UserRole | undefined) => {
  if (role === undefined) return '';

  if (isMultipleRoles(role))
    throw Error(
      'Do not pass multiple roles to getNorwegianRoles. Role passed was ' + role
    );

  if (isRole(role, UserRole.Student)) return 'Elev';
  if (isRole(role, UserRole.Adviser)) return 'Rådgiver';
  if (isRole(role, UserRole.HighSchoolContact)) return 'VGS Kontakt';
  if (isRole(role, UserRole.Admin)) return 'Administrator';

  return 'Ukjent';
};

export const AllRoles =
  UserRole.Student |
  UserRole.Adviser |
  UserRole.HighSchoolContact |
  UserRole.Admin;
export const GURoles = UserRole.Adviser;
export const AdminRoles =
  UserRole.Adviser | UserRole.HighSchoolContact | UserRole.Admin;
export const SuperAdminRoles = UserRole.Admin;
