import { flexRow } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    padding: '16px',
    width: '400px',
  },
  header: {},
  schoolName: {
    color: palette.primary.dark,
  },
  contactContainer: {
    ...flexRow,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },
  contactName: {
    color: palette.primary.dark,
  },
}));

export default useStyles;
