import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Slide,
  Button,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';
import userSurveyStyle from '../../style';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { ISurveyQuestion } from '@/types/SurveyQuestion';
import {
  getSurveyQuestionDisplay,
  SurveyQuestionType,
} from '@/types/enums/SurveyQuestionType';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  handleClose: () => void;
  question: ISurveyQuestion;
  setQuestion: (value: ISurveyQuestion) => void;
  handleAddQuestion: () => Promise<void>;
}

const AddSurveyQuestionDialog = (props: IComponentProps) => {
  const { open, handleClose, question, setQuestion, handleAddQuestion } = props;
  const classes = userSurveyStyle();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
      >
        <div className={classes.wrapperDialog}>
          <DialogTitle classes={{ root: classes.padding0 }}>
            <div
              className={clsx(classes.displayFlex, classes.alignItemsCenter)}
            >
              <div
                className={clsx(
                  classes.titleAddSurveyQuestionDialog,
                  classes.fontSize18
                )}
              >
                Legg til spørsmål
              </div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                classes={{ root: classes.marginLeftAuto }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            classes={{ root: clsx(classes.padding0, classes.marginTop20) }}
          >
            <Select
              disableUnderline
              value={question.type}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setQuestion({
                  ...question,
                  type: e.target.value as SurveyQuestionType,
                });
              }}
              classes={{
                root: classes.surveyQuestionType,
                icon: classes.color500,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.surveyQuestionTypePager,
                  list: classes.surveyQuestionTypeList,
                },
              }}
              className={clsx(classes.widthFull)}
            >
              {Object.keys(SurveyQuestionType)
                .filter(
                  (k: string) =>
                    typeof SurveyQuestionType[k as any] === 'string'
                )
                .map((k1: string) => +k1)
                .map((k2: number) => (
                  <MenuItem
                    key={k2}
                    value={k2}
                    classes={{ root: classes.surveyQuestionTypeMenu }}
                  >
                    {getSurveyQuestionDisplay(k2)}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>
          <DialogContent classes={{ root: classes.padding0 }}>
            <div className={clsx(classes.displayFlex, classes.marginTop20)}>
              <div className={classes.marginLeftAuto}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await handleAddQuestion();
                  }}
                >
                  Lagre
                </Button>
                <Button
                  variant="text"
                  onClick={handleClose}
                  style={{ marginLeft: '12px' }}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default AddSurveyQuestionDialog;
