import React from 'react';
import useStyles from './style';
import clsx from 'clsx';

interface IComponentProps {
  offset: number;
  sliceSize: number;
  colorHEX: string;
}

const SlicePieChart = (props: IComponentProps) => {
  const { offset, sliceSize, colorHEX } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.slice)}
      style={{ transform: `rotate(${offset}deg) translate3d(0,0,0)` }}
    >
      <span
        style={{
          transform: `rotate(${sliceSize}deg) translate3d(0,0,0)`,
          backgroundColor: colorHEX,
        }}
      ></span>
    </div>
  );
};

export default SlicePieChart;
