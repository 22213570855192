import { IClass } from '@/types/Class';
import IDistanceAndPriority from '@/types/DistanceAndPriority';
import IStudentPaginationRequest from '@/types/dto/Schools/StudentPaginationRequest.dto';
import { IStudentPaginationResponse } from '@/types/dto/Schools/StudentPaginationResponse.dto';
import { SchoolStatus } from '@/types/enums/SchoolStatus';
import UnavailableDate from '@/types/UnavailableDate';
import { User } from '@/types/User';
import { UserRole } from '@/types/UserRole';
import School, { SchoolType } from '../types/School';
import http, { prepareGetQuery } from '../utils/httpUtils';
import BaseService from './BaseService';

class SchoolService extends BaseService<School> {
  constructor() {
    super('school');
  }

  async getPopulatedSchools(
    schoolType: SchoolType,
    filter = SchoolStatus.ALL
  ): Promise<School[] | undefined> {
    try {
      let query = '/api/school/populated/' + schoolType;
      query += `?filter=${filter}`;
      const res = await http.get(query);
      return res.data as School[];
    } catch (error) {
      console.error('Error getting populated schools: ' + error);
    }
  }

  async getByExternalId(externalId: string): Promise<School | undefined> {
    try {
      const res = await http.get('/api/school/byExternalId/' + externalId);
      return res.data as School;
    } catch (error) {
      console.error('Error getting school by externalId: ' + error);
    }
  }

  async deleteByExternalId(externalId: string): Promise<void> {
    try {
      await this.http.delete('/delete/' + externalId);
    } catch (error: any) {
      console.error(
        'Error deleting ' + this.typeName + ' with id ' + externalId
      );
    }
  }

  async getConnectedUsers(
    externalId: string,
    userRole: UserRole
  ): Promise<User[] | undefined> {
    try {
      const res = await this.http.get(
        `/connected-users?externalSchoolId=${externalId}&userRole=${userRole}`
      );
      return res.data;
    } catch (error: any) {
      console.error(
        'Error getting users connected to school with externalId ' + externalId
      );
    }
  }

  async getStudents(
    req: IStudentPaginationRequest
  ): Promise<IStudentPaginationResponse | undefined> {
    try {
      const res = await this.http.get(
        '/students' + prepareGetQuery(req as unknown as Record<string, unknown>)
      );
      return res.data;
    } catch (error) {
      console.error('Error getting students: ' + error);
    }
  }

  async getDistanceAndPrioritiesByGUId(
    externalId?: string
  ): Promise<IDistanceAndPriority[] | undefined> {
    try {
      const res = await this.http.get(
        '/distance-priorities-gu?externalId=' + externalId
      );
      return res.data;
    } catch (error: any) {
      console.error(
        'Error getting distance and priorities for school with externalId ' +
          externalId
      );
    }
  }

  async saveUnavailableDates(
    externalId: string,
    unavailableDates: UnavailableDate[]
  ) {
    try {
      await this.http.post('/unavailable-dates', {
        externalId,
        unavailableDates,
      });
    } catch (error: any) {
      console.error(
        'Could not add unavailable dates to school with externalId: ' +
          externalId
      );
    }
  }

  public async getAllSecondarySchoolByProjectId(
    projectId: number
  ): Promise<School[]> {
    const result = await this.http.get(
      `project/${projectId}/all/secondary-school`
    );
    return result.data;
  }

  async getClasses(externalSchoolId: string): Promise<IClass[]> {
    const result = await this.http.get(
      `/classes?externalSchoolId=${externalSchoolId}`
    );
    return result.data;
  }

  async getHighSchoolByProjectId(
    projectId: number,
    externalSchoolId?: string
  ): Promise<School[]> {
    const result = await this.http.get(`byProjectId/${projectId}`, {
      params: {
        externalSchoolId,
      },
    });
    return result.data;
  }

  async getSchoolBySchoolType(schoolType: SchoolType): Promise<School[]> {
    const result = await this.http.get(`bySchoolType/${schoolType}`);
    return result.data;
  }

    async getSchoolsForAdmin(admin: User) {
      const result = await this.http.get(`getSchoolsForAdmin/${admin.id}`);
      return result.data;
    }
}

export default new SchoolService();
