import { flexRow } from '@/style/common';
import { Chip, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  root: {
    ...flexRow,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '2px',
  },
}));

interface IHorizontalChipListProps {
  values: string[];
}

const HorizontalChipList = ({ values }: IHorizontalChipListProps) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      {values.map((s) => (
        <Chip key={s} label={s} />
      ))}
    </div>
  );
};

export default HorizontalChipList;
