import { makeStyles } from '@material-ui/core';

export const configStyle = {
  paddingHeader: 142,
};

const dashboardStyle = makeStyles(({ breakpoints, palette }) => ({
  displayFlex: {
    display: 'flex',
  },
  dashboardFilterNav: {
    height: '56px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #EBEAF1',
    alignItems: 'flex-end',
  },
  leftContent: {
    padding: `0 ${configStyle.paddingHeader}px`,
  },
  heightFull: {
    height: '100%',
  },
  customSearch: {
    position: 'relative',
    width: '611px',
    height: '52px',
    padding: `4px 0`,
    boxSizing: 'content-box',
    [breakpoints.down('md')]: {
      width: '500px',
    },
  },
  currentProject: {
    fontSize: '18px',
    lineHeight: '25px',
    color: palette.grey.A100,
    paddingTop: '13px',
    paddingBottom: '8px',
  },
  pointer: { cursor: 'pointer' },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  contentBlock: {
    padding: '15px 24px',
  },
  currentProjectTitle: {
    fontSize: '24px',
    lineHeight: '34px',
    color: palette.grey.A100,
    marginRight: '12px',
  },
  currentTitle: {
    fontSize: '16px',
    color: palette.primary[900],
  },
  currentTitleWidth: {
    width: '94px',
  },
  marginRight24: {
    marginRight: '24px',
  },
  arrowForwardIcon: {
    color: palette.grey['900'],
  },
  divider: {
    opacity: 1,
    height: '2px !important',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #EBEAF1',
  },
  dividerShort: {
    height: '2px !important',
    backgroundColor: palette.primary[900],
    width: '100px',
    marginTop: '16px',
    marginBottom: '12px',
    opacity: 1,
  },
  listSchoolTitle: {
    color: palette.primary[900],
    fontSize: '22px',
    lineHeight: '31px',
  },
  userCard: {
    padding: '24px 0',
    marginLeft: '-12px',
    marginRight: '-12px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  userCardBlock: {
    margin: '8px 12px',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
}));

export default dashboardStyle;
