import { useStores } from '@/stores';
import IContactPerson from '@/types/ContactPerson';
import { User } from '@/types/User';
import { UserRole } from '@/types/UserRole';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormEvent, useState } from 'react';
import useStyle from './style';

const AddNewContactPerson = () => {
  const classes = useStyle();

  const { schoolStore } = useStores();
  const { editingSchool } = schoolStore;
  const { contactPeople } = editingSchool;

  const [id, setId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState('');
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isMain, setIsMain] = useState(!contactPeople.some((cp) => cp.isMain));

  const newContactPerson: IContactPerson = {
    id,
    externalSchoolId: editingSchool.externalId,
    userId,
    isMain,
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userId) {
      const newUser = new User();
      newContactPerson.user = {
        ...newUser,
        userName,
        fullName,
        emailAddress,
        phoneNumber,
        role: UserRole.HighSchoolContact,
        externalSchoolId: editingSchool.externalId ?? '',
      };
    }

    let tempContactPeople = [...contactPeople];

    if (isMain) {
      tempContactPeople = tempContactPeople.map((cp) => ({
        ...cp,
        isMain: false,
      }));
    }

    if (id) {
      const editIndex = tempContactPeople.findIndex((cp) => cp.id === id);

      tempContactPeople[editIndex] = newContactPerson;
    } else {
      tempContactPeople.push(newContactPerson);
    }

    resetForm();
    schoolStore.setEditingSchool({
      ...editingSchool,
      contactPeople: tempContactPeople,
    });
  };

  const resetForm = () => {
    setId(undefined);
    setUserId(undefined);
    setUserName('');
    setFullName('');
    setEmailAddress('');
    setPhoneNumber('');
    setIsMain(false);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        label="Fødselsnummer"
        required
        onChange={(e) => {
          setUserName(e.currentTarget.value);
        }}
        value={userName}
      />
      <TextField
        label="Fullt Navn"
        required
        onChange={(e) => {
          setFullName(e.currentTarget.value);
        }}
        value={fullName}
      />
      <TextField
        label="E-post"
        type="email"
        required
        onChange={(e) => {
          setEmailAddress(e.currentTarget.value);
        }}
        value={emailAddress}
      />
      <TextField
        label="Mobil"
        required
        onChange={(e) => {
          setPhoneNumber(e.currentTarget.value);
        }}
        value={phoneNumber}
      />
      <FormControlLabel
        label="Hovedkontaktperson"
        onChange={(e) => {
          setIsMain((e.target as HTMLInputElement).checked);
        }}
        checked={isMain}
        control={<Checkbox name="mainContactPerson" color="primary" />}
      />
      <div>
        <Button variant="outlined" type="submit">
          {'Legg til'}
        </Button>
        <Button variant="text" onClick={resetForm}>
          Tøm felter
        </Button>
      </div>
    </form>
  );
};
export default observer(AddNewContactPerson);
