import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  boxFilter: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 115px',
    [breakpoints.down('md')]: {
      padding: '10px 5px',
    },
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  paddingOnBox: {
    paddingRight: '10px',
  },
  btn: {
    border: `2px solid ${palette.primary[500]}`,
    borderRadius: '20px',
    color: palette.primary[500],
    backgroundColor: palette.common.white,
    padding: '4px 8px',
    marginRight: '10px',
    '&:last-child': {
      marginRight: '0px',
    },
    '&:hover': {
      backgroundColor: palette.background.default,
    },
  },
  btnActive: {
    backgroundColor: palette.primary[500],
    color: palette.common.white,
    '&:hover': {
      backgroundColor: palette.primary[500],
    },
  },
}));

export default useStyle;
