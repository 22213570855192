import { makeStyles } from '@material-ui/core';

export const configStyle = {
  widthCapacity: 50,
  marginCapacity: 5,
};

const useStyles = makeStyles(({ palette }) => ({
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsFlexEnd: {
    alignItems: 'flex-end',
  },
  backgroundColorTransparent: {
    backgroundColor: palette.background.default,
  },
  blockCapacity: {
    height: '24px',
    position: 'relative',
  },
  textStyle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: palette.primary[900],
  },
  verticalAlignBottom: {
    verticalAlign: 'bottom',
  },
  fontSizeIconCapacity: {
    fontSize: '20px',
  },
  heightFull: {
    height: '100%',
  },
  widthCapacityLeft: {
    width: `${configStyle.widthCapacity}px`,
    marginRight: `${configStyle.marginCapacity}px`,
  },
  widthCapacityRigh: {
    width: `calc(100% - ${
      configStyle.widthCapacity + configStyle.marginCapacity
    }px)`,
  },
  overloaded: {
    backgroundColor: palette.warning.dark,
  },
}));

export default useStyles;
