import { flexCol } from '@/style/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  editorWrapper: {
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '16px',
    ...flexCol,
    gap: '16px',
    '& .ProseMirror:focus-visible': {
      outline: 'none',
    },
  },
}));

export default useStyles;
