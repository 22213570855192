import { makeStyles } from '@material-ui/core';
import { flexCol } from '@/style/common';

export const configStyle = {
  paddingHeader: 116,
  marginFilter: 24,
  widthOption: 358,
  paddingOption: 16,
};

const userSettingStyle = makeStyles(({ breakpoints, palette }) => ({
  header: {
    position: 'relative',
    backgroundColor: palette.primary.light,
    height: '6rem',
  },
  headerContent: {
    paddingLeft: `${configStyle.paddingHeader}px`,
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 400,
    color: palette.primary[900],
    marginLeft: '27px',
  },
  filterNav: {
    height: '62px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #C6CADE',
    alignItems: 'flex-end',
  },
  leftContent: {
    width: `calc(100% - ${configStyle.widthOption}px)`,
    padding: `0 0 0 ${configStyle.paddingHeader}px`,
  },
  rightContent: {
    minWidth: `${configStyle.widthOption}px`,
    maxWidth: `${configStyle.widthOption}px`,
  },
  space: {
    marginRight: `${configStyle.marginFilter}px`,
  },
  customSearch: {
    position: 'relative',
    width: '611px',
    height: '52px',
    padding: `4px 0`,
    boxSizing: 'content-box',
    [breakpoints.down('md')]: {
      width: '500px',
    },
  },
  addNewUserBtn: {
    height: '36px',
    width: '180px',
    padding: '10px 12px',
    color: palette.primary[500],
    fontSize: '14px',
    letterSpacing: '0.4px',
  },
  spaceAddIcon: {
    marginRight: '12px',
  },
  contentUserItem: {
    marginTop: '18px',
  },
  boxUserItem: {
    height: '73px',
    width: '768px',
    padding: '8px 24px',
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  userItemFullName: {
    width: '225px',
    color: palette.primary[900],
    fontSize: '16px',
    lineHeight: '24px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipBox: {
    color: palette.primary[900],
    fontSize: '12px',
  },
  tooltipBoxFullName: {
    width: '225px',
  },
  userItemEmail: {
    width: '106px',
  },
  userItemPhone: {
    width: '75px',
  },
  userItemInfo: {
    color: palette.primary[500],
    fontSize: '16px',
    lineHeight: '16px',
    padding: '10px 12px',
  },
  userItemText: {
    fontSize: '14px',
  },
  userItemTextSpace: {
    marginLeft: '11px',
  },
  userItemSpace: {
    marginLeft: '5px',

    '&:last-child': {
      marginLeft: '0px',
    },
  },
  iconSize: {
    width: '16px',
    height: '16px',
  },
  buttonDelete: {
    marginRight: '28px',
  },
  buttonChoose: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  buttonChooseActive: {
    backgroundColor: palette.primary[100],
  },
  switch: {
    marginLeft: '18px',
  },
  switchBase: {
    color: palette.primary[600],
  },
  switchTrack: {
    border: `1px solid ${palette.primary[600]}`,
    backgroundColor: palette.primary[600],
  },
  empty: {
    fontSize: '22px',
    lineHeight: '31px',
    color: palette.primary[900],
    marginTop: '5rem',
  },
  contentDrawer: {
    width: '376px',
    ...flexCol,
    gap: '6px',
    overflow: 'auto',
    justifyContent: 'flex-start',
  },
  titleDrawer: {
    padding: '12px 24px',
    color: palette.primary[900],
  },
  chooseUser: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #EBEAF1 !important',
  },
  bodyDrawer: {
    padding: '24px',
  },
  titleFillInfo: {
    color: palette.primary[900],
    marginBottom: '12px',
  },
  textFill: {
    marginBottom: '18px',
  },
  boxWarning: {
    padding: '16px',
    backgroundColor: palette.warning.light,
    borderBottom: `1px solid ${palette.warning.dark}`,
    marginTop: '54px',
    color: palette.warning[50],
  },
  iconErrorOnDrawer: {
    color: palette.warning[50],
  },
  contentErrorOnDrawer: {
    marginLeft: '20px',
  },
  contentErrorTitleOnDrawer: {
    fontWeight: 500,
  },
  contentListErrorOnDrawer: {
    paddingLeft: '1.25rem',
    fontSize: '14px',
  },
  groupBtnDrawer: {
    marginTop: '36px',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  displayFlex: {
    display: 'flex',
  },
  pointer: { cursor: 'pointer' },
  backgroundColorFFF: {
    backgroundColor: palette.primary.A100,
  },
  backgroundColorTransparent: {
    backgroundColor: palette.background.default,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  widthFull: {
    width: '100%',
  },
  heightFull: {
    height: '100%',
  },
  padding0: {
    padding: 0,
  },
  margin0: {
    margin: 0,
  },
  contentDialogDeleteUser: {
    color: palette.primary[500],
    fontSize: '16px',
  },
  paddingDialogAction: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
}));

export default userSettingStyle;
