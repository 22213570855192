import { ControlledInputText } from '@/components/ControlledInputText';
import CustomCheckBox from '@/components/CustomCheckBox';
import CustomRadio from '@/components/CustomRadio';
import { SurveyQuestionType } from '@/types/enums/SurveyQuestionType';
import { ISurveyAnswer } from '@/types/SurveyAnswer';
import React from 'react';
import userSurveyStyle from '../style';
import clsx from 'clsx';

interface IComponentProps {
  questionId: number;
  type: SurveyQuestionType;
  answers: ISurveyAnswer[];
}

const Answer = (props: IComponentProps) => {
  const { questionId, type, answers } = props;
  const classes = userSurveyStyle();

  return (
    <>
      {type === SurveyQuestionType.INPUT ||
      type === SurveyQuestionType.TEXT_AREA ? (
        <>
          {answers.map((answer) => (
            <div key={answer.id ?? 0}>
              <ControlledInputText
                value={answer.content}
                setValue={(value: string) => console.log(value)}
                customClass={clsx(classes.widthFull)}
                inputProps={{
                  disableUnderline: true,
                  classes: {
                    input:
                      type === SurveyQuestionType.INPUT
                        ? classes.questionInput
                        : '',
                    multiline:
                      type === SurveyQuestionType.TEXT_AREA
                        ? classes.questionTextArea
                        : '',
                  },
                }}
                hasMultiline={type === SurveyQuestionType.TEXT_AREA}
                variant={
                  type === SurveyQuestionType.TEXT_AREA ? 'standard' : 'filled'
                }
                minRows={3}
                maxRows={10}
                disabled={true}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {answers.map((answer) => (
            <div
              key={answer.id ?? 0}
              className={clsx(
                classes.displayFlex,
                classes.alignItemsCenter,
                classes.answerOptionInDetail
              )}
            >
              {type === SurveyQuestionType.RADIO ? (
                <div
                  className={clsx(
                    classes.spaceCheck,
                    classes.heightFull,
                    classes.displayFlex,
                    classes.alignItemsCenter
                  )}
                >
                  <CustomRadio
                    name={questionId.toString()}
                    value={answer.id ?? 0}
                    isChecked={!!answer.isChecked}
                    handleChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => console.log(event)}
                    disabled={true}
                  />
                </div>
              ) : (
                <div
                  className={clsx(
                    classes.spaceCheck,
                    classes.heightFull,
                    classes.displayFlex,
                    classes.alignItemsCenter
                  )}
                >
                  <CustomCheckBox
                    value={answer.id ?? 0}
                    isChecked={!!answer.isChecked}
                    handleChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => console.log(event)}
                    disabled={true}
                  />
                </div>
              )}

              <div className={clsx(classes.heightFull)}>{answer.content}</div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Answer;
