import { CourseStatus, getStatusDisplay } from '@/types/enums/CourseStatus';
import School from '@/types/School';
import IStudySubject from '@/types/StudySubject';
import { Input, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';

interface IComponentProps {
  studySubjectId?: number | string;
  studySubjects?: IStudySubject[];
  externalSchoolId?: string;
  schools?: School[];
  status?: CourseStatus | string;
  courseStatus?: CourseStatus[];
  label: string;
  handleSearchStudySubjectId?: (studySubjectId: number | string) => void;
  handleExternalSchoolId?: (externalSchoolId: string) => void;
  handleCourseStatus?: (status: CourseStatus | string) => void;
}

enum SelectStatus {
  STUDY_SUBJECT = 'STUDY_SUBJECT',
  SCHOOL = 'SCHOOL',
  COURSE_STATUS = 'COURSE_STATUS',
}

const CustomSelect = (props: IComponentProps) => {
  const {
    studySubjectId,
    studySubjects,
    externalSchoolId,
    schools,
    status,
    courseStatus,
    label,
    handleSearchStudySubjectId,
    handleExternalSchoolId,
    handleCourseStatus,
  } = props;
  const classes = useStyles();

  const [mode, setMode] = React.useState<SelectStatus | null>(null);

  React.useEffect(() => {
    if (studySubjects) {
      setMode(SelectStatus.STUDY_SUBJECT);
    } else if (externalSchoolId && schools) {
      setMode(SelectStatus.SCHOOL);
    } else if (status && courseStatus) {
      setMode(SelectStatus.COURSE_STATUS);
    }
  }, [
    studySubjectId,
    studySubjects,
    externalSchoolId,
    schools,
    status,
    courseStatus,
  ]);

  const getValue = (): any => {
    if (mode === SelectStatus.STUDY_SUBJECT) {
      return studySubjectId;
    } else if (mode === SelectStatus.SCHOOL) {
      return externalSchoolId;
    } else if (mode === SelectStatus.COURSE_STATUS) {
      return status;
    }
  };

  const changeValue = (value: any): void => {
    if (mode === SelectStatus.STUDY_SUBJECT && handleSearchStudySubjectId) {
      handleSearchStudySubjectId(value);
    } else if (mode === SelectStatus.SCHOOL && handleExternalSchoolId) {
      handleExternalSchoolId(value);
    } else if (mode === SelectStatus.COURSE_STATUS && handleCourseStatus) {
      handleCourseStatus(value);
    }
  };

  return (
    <>
      {mode && (
        <Select
          disableUnderline
          value={getValue()}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
            changeValue(e.target.value)
          }
          classes={{
            select: classes.select,
            icon: classes.icon,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ArrowDropDownIcon}
          input={<Input classes={{ root: classes.menuItem }} />}
        >
          <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
            {label}
          </MenuItem>

          {mode === SelectStatus.STUDY_SUBJECT &&
            studySubjects &&
            studySubjects.map((studySubject: IStudySubject) => (
              <MenuItem key={studySubject.id} value={studySubject.id}>
                {studySubject.studySubjectName}
              </MenuItem>
            ))}

          {mode === SelectStatus.SCHOOL &&
            schools &&
            schools.map((school: School) => (
              <MenuItem key={school.externalId} value={school.externalId}>
                {school.schoolName}
              </MenuItem>
            ))}

          {mode === SelectStatus.COURSE_STATUS &&
            courseStatus &&
            courseStatus.map((status: CourseStatus) => (
              <MenuItem key={status} value={status}>
                {getStatusDisplay(status)}
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
};

export default CustomSelect;
