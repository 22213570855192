import { SurveyQuestionType } from './enums/SurveyQuestionType';
import { ISurveyAnswer } from './SurveyAnswer';

export interface ISurveyQuestion {
  id?: number;
  surveyId?: number;
  isRequired: boolean;
  type: SurveyQuestionType;
  title: string;
  subTitle: string;
  answers: ISurveyAnswer[];
  createdDate?: Date;
}

export const INIT_SURVEY_QUESTION: ISurveyQuestion = {
  isRequired: false,
  type: SurveyQuestionType.INPUT,
  title: '',
  subTitle: '',
  answers: [],
};
