export interface PaginationRequest {
  skip?: number;

  take?: number;

  search?: string;

  orderBy?: string;

  orderDirection?: 'asc' | 'desc';
}

export const PER_PAGE_OPTIONS = ['50', '100'];

export const ASC = 'asc';
export const DESC = 'desc';

export const TAKE_STUDENT = 50;

export const SELECT_ALL = 'all';
export const UNDEFINED = 0;
