import { AxiosInstance } from 'axios';
import { PaginationResult } from '../types/PaginationResult';
import { newHttpInstance, prepareGetQuery } from '../utils/httpUtils';

class BaseService<Type> {
  public http: AxiosInstance;
  public typeName: string;

  constructor(typeName: string) {
    const apiBase = '/api/' + typeName;
    this.typeName = typeName;
    this.http = newHttpInstance(apiBase);
  }

  async getAll(): Promise<Type[] | undefined> {
    try {
      const res = await this.http.get('/all');
      return res.data as Type[];
    } catch (error: any) {
      console.error('Error fetching all ' + this.typeName + 's');
    }
  }

  async getAllStudySubjectForStudent(): Promise<Type[] | undefined> {
    try {
      const res = await this.http.get('/students/all');
      return res.data as Type[];
    } catch (error: any) {
      console.error('Error fetching all ' + this.typeName + 's');
    }
  }

  async list(query: Record<string, unknown>): Promise<PaginationResult<Type>> {
    try {
      const { data } = await this.http.get(`/all${prepareGetQuery(query)}`);

      return { data: (data.data as Type[]) ?? [], total: data.total ?? 0 };
    } catch (error: any) {
      console.error('Error fetching all ' + this.typeName + 's');
      return { data: [], total: 0 };
    }
  }

  async getById(id: number): Promise<Type | undefined> {
    try {
      const res = await this.http.get('/' + id);
      return res.data as Type;
    } catch (error: any) {
      console.error('Error fetching ' + this.typeName + ' with id ' + id);
      throw error;
    }
  }

  async update(value: Type): Promise<void> {
    try {
      await this.http.put('/update', value);
    } catch (error: any) {
      console.error('Error updating ' + this.typeName + value);
      throw error;
    }
  }

  async updateV2(value: Type): Promise<void> {
    await this.http.put('/update', value);
  }

  async add(value: Type): Promise<void> {
    try {
      await this.http.post('/add', value);
    } catch (error: any) {
      console.error('Error adding ' + this.typeName);
      throw error;
    }
  }

  async addV2(value: Type): Promise<void> {
    await this.http.post('/add', value);
  }

  async getCurrent(): Promise<Type | undefined> {
    try {
      const res = await this.http.get('/current');
      return res.data as Type;
    } catch (error: any) {
      console.error('Error fetching current');
    }
  }

  async delete(id: number): Promise<void> {
    try {
      await this.http.delete('/delete/' + id);
    } catch (error: any) {
      console.error('Error deleting ' + this.typeName + ' with id ' + id);
      throw error;
    }
  }
}

export default BaseService;
