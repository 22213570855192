import { observer } from 'mobx-react';
import DashboardFilter from './components/DashboardFilter';
import React from 'react';
import { useStores } from '@/stores';
import dashboardStyle from '@/views/style/dashboard';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Divider } from '@material-ui/core';
import StepStatus from '@/components/StepStatus';
import CustomBarChart from '@/components/CustomBarChart';
//import UserCard from '@/components/UserCard';
import { getStepVGS } from '@/utils/dashboardUtils';
import { StepType } from '@/types/StepType';
import { PublishOutlined } from '@material-ui/icons';

const VGSDashboard = () => {
  const { projectStore, userStore, dashboardStore } = useStores();
  const classes = dashboardStyle();

  const [search, setSearch] = React.useState<string>('');
  const [step, setStep] = React.useState<number>(0);
  const [steps] = React.useState<StepType[]>([
    {
      title: 'Planlegging',
    },
    {
      title: 'Kursperiode',
    },
  ]);

  const handleSearch = (): void => {
    console.log('handle search');
  };

  React.useEffect(() => {
    if (!projectStore.currentProject) {
      projectStore.getCurrent();
    }
  }, [projectStore]);

  React.useEffect(() => {
    if (
      userStore.user &&
      userStore.user.externalSchoolId &&
      projectStore.currentProject &&
      projectStore.currentProject.id
    ) {
      dashboardStore.getStatisticCapacityVGS(
        projectStore.currentProject.id,
        userStore.user.externalSchoolId
      );
    }
  }, [projectStore.currentProject, dashboardStore, userStore]);

  React.useEffect(() => {
    const step = getStepVGS(projectStore.currentProject);
    setStep(step);
  }, [projectStore, projectStore.currentProject]);

  /*const handleGetAllHighSchoolContactByStudySubjectId = (
    studySubjectId: number
  ): void => {
    if (userStore.user && userStore.user.externalSchoolId) {
      dashboardStore.getAllHighSchoolContactByStudySubjectId(
        studySubjectId,
        userStore.user.externalSchoolId
      );
    }
  };*/

  return (
    <>
      <DashboardFilter
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
      />

      <div className={classes.leftContent}>
        <div className={classes.currentProject}>Aktuelt prosjekt</div>
        <div className={classes.backgroundColorFFF}>
          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div className={classes.currentProjectTitle}>
              {projectStore.currentProject?.projectName}
            </div>
            <ArrowForwardIcon classes={{ root: classes.arrowForwardIcon }} />

            <PublishOutlined classes={{ root: classes.marginLeftAuto }} />
          </div>

          <Divider
            classes={{
              root: classes.divider,
            }}
          />

          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div
              className={clsx(
                classes.currentTitle,
                classes.currentTitleWidth,
                classes.marginRight24
              )}
            >
              Status
            </div>
            <StepStatus steps={steps} step={step} />
          </div>

          <Divider
            classes={{
              root: classes.divider,
            }}
          />

          <div
            className={clsx(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.contentBlock
            )}
          >
            <div className={classes.currentTitle}>
              Studieforberedende: kapasitet vs. tildelt
            </div>
          </div>

          <div className={classes.contentBlock}>
            <CustomBarChart
              data={dashboardStore.statisticCapacityVGS}
              //onClickLabel={handleGetAllHighSchoolContactByStudySubjectId}
            />
          </div>
        </div>

        {/* <Divider
          classes={{
            root: classes.dividerShort,
          }}
        />

        <div className={classes.listSchoolTitle}>Idrettsfag ansvarlig</div>
        <div className={classes.userCard}>
          {dashboardStore.highSchoolContacts.map((user) => (
            <div className={classes.userCardBlock}>
              <UserCard user={user} />
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default observer(VGSDashboard);
