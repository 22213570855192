import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  backgroundColorTransparent: {
    backgroundColor: palette.background.default,
  },
  stepLineWidth: {
    minWidth: '20px',
  },
  stepLabel: {
    font: `normal normal 400 14px Oslo Sans, Roboto, Arial, sans-serif !important`,
    color: `${palette.primary[900]} !important`,
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  fontSize14: {
    fontSize: '14px',
  },
  stepIconActive: {
    color: `${palette.primary[600]} !important`,
  },
}));

export default useStyles;
