import { IStatisticSubjectCapacity } from '@/types/StudentStudySubject';
import React from 'react';
import ItemBarChart from './item';
import LegendBarChart from './legend';
import { useStyles } from './style';

interface IComponentProps {
  data: IStatisticSubjectCapacity[];
  maxCapacity: number;
  isLegend?: boolean;
  onClickLabel?: (studySubjectId: number) => void;
}

const Row = (props: IComponentProps) => {
  const { data, maxCapacity, isLegend, onClickLabel } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.displayFlex}>
        <div className={classes.overflowXAuto}>
          <div className={classes.displayFlex}>
            {data.map((item) => (
              <ItemBarChart
                key={`${item.capacity}_${item.numberJoinedStudent}_${item.highSchoolName}_${item.studySubject.id}`}
                maxCapacity={maxCapacity}
                data={item}
                onClickLabel={onClickLabel}
              />
            ))}
          </div>
        </div>
        {!!isLegend && <LegendBarChart />}
      </div>
    </>
  );
};

export default Row;
