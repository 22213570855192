import { useStores } from '@/stores';
import { INIT_PROJECT } from '@/types/Project';
import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import RandomBackground from '../../../../assets/images/background.png';

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    minHeight: '70vh',
    background: `url(${RandomBackground})`,
    backgroundPosition: 'center',
    backgroundSize: '724px 493px',
    backgroundRepeat: 'no-repeat',
  },
  paper: {
    height: '227px',
    width: '414px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    flexDirection: 'column',
    '& button': {
      marginTop: '36px',
      color: palette.primary,
    },
  },
}));

const Component: FC<{ isArchived: boolean }> = ({ isArchived }) => {
  const classes = useStyles();

  const { projectStore } = useStores();

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.wrapper}
        direction="row"
      >
        <Grid item>
          <Paper elevation={3} className={classes.paper}>
            <Typography align="center" variant="h3">
              Du har ingen {isArchived ? 'arkiverte' : 'aktive'} prosjekter
            </Typography>
            {!isArchived && (
              <Button
                color="secondary"
                onClick={() => projectStore.openProjectPanel(INIT_PROJECT)}
              >
                Opprett nytt prosjekt
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export const NoCurrentProject = observer(Component);
