import { useStores } from '@/stores';
import { CreateOrUpdateStudentCourseDto } from '@/types/StudentCourse';
import { IUserDto } from '@/types/User';
import courseStyle from '@/views/style/courses';
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Table,
  Typography,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { FC } from 'react';
import NestedStudentTableHeader from './NestedStudentTableHeader';
import NestedStudentTableRow from './NestedStudentTableRow';
import useNestedStudentTableStyles from './style';
import clsx from 'clsx';
import ICourse from '@/types/Course';

interface INestedStudentTableProps {
  course: ICourse;
  students: IUserDto[];
  setStudents: (students: IUserDto[]) => void;
  open: boolean;
  chooseStudent: (courseId: number, studentId: number) => void;
  loading: boolean;
}

const NestedStudentTable: FC<INestedStudentTableProps> = ({
  students,
  setStudents,
  course,
  open,
  chooseStudent,
  loading,
}) => {
  const classes = useNestedStudentTableStyles();
  const courseClasses = courseStyle();
  const {
    studentCourseStore,
    userStore,
    courseStore,
    projectStore,
    errorStore,
  } = useStores();

  const selectStudent = (studentId: number) =>
    chooseStudent(course.id, studentId);

  const toggleAbsence = async (student: IUserDto) => {
    if (!student.studentCourses) return;

    const studentCourse =
      !!student.studentCourses &&
      student.studentCourses.find(
        (c) =>
          c.finalAssignedCourse?.projectId === projectStore.currentProject?.id
      );
    const createOrUpdateStudentCourseDto: CreateOrUpdateStudentCourseDto = {
      ...studentCourse,
      attendance: !studentCourse?.attendance,
    };

    if (createOrUpdateStudentCourseDto.id) {
      const result = await studentCourseStore.updateStudentCourse(
        createOrUpdateStudentCourseDto.id,
        createOrUpdateStudentCourseDto
      );

      if (result) {
        courseStore.setCourseId(course.id);
        errorStore.showErrorMessage(
          'Fravær oppdatert for ' + student.fullName,
          'success'
        );
        if (student.id) {
          await userStore.getStudentById(student.id, course.id);
          if (userStore.student) {
            const i = students.findIndex((s) => s.id === userStore.student?.id);
            if (i >= 0) {
              const currentStudents = [...students];
              currentStudents[i] = userStore.student;
              setStudents(currentStudents);
            }
          }
        }
      }
    }
  };

  return (
    <TableRow key={`course_detail_${course.id}`}>
      <TableCell colSpan={9} className={classes.nestedWrapperCell}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box key={`box_course_detail_${course.id}`}>
            <Table key={`table_course_detail_${course.id}`}>
              <NestedStudentTableHeader />
              <TableBody>
                {!loading ? (
                  <>
                    {students.length ? (
                      students
                        .sort((a, b) =>
                          (a.fullName ?? '') > (b.fullName ?? '') ? 1 : -1
                        )
                        .map((student) => (
                          <NestedStudentTableRow
                            student={student}
                            selectStudent={selectStudent}
                            toggleAbsence={toggleAbsence}
                            courseStatus={course.status}
                            key={student.id}
                          />
                        ))
                    ) : (
                      <TableRow className={courseClasses.nestedStudentTableRow}>
                        <TableCell colSpan={8}>
                          <Typography variant="h3">
                            Det er ingen elever i dette kurset enda.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    <TableRow className={courseClasses.nestedStudentTableRow}>
                      <TableCell colSpan={8}>
                        <div
                          className={clsx(
                            courseClasses.displayFlex,
                            courseClasses.justifyContentCenter
                          )}
                        >
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default observer(NestedStudentTable);
