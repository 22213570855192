class UnavailableDate {
  id?: number;
  externalSchoolId: string;
  start: Date;
  end: Date;
  comment: string;

  constructor(
    externalSchoolId: string,
    comment: string,
    start = new Date(),
    end = new Date(),
    id = 0
  ) {
    this.externalSchoolId = externalSchoolId;
    this.start = new Date(start);
    this.end = new Date(end);
    this.comment = comment;
    this.id = id;
  }

  overlapsWith(otherDate: UnavailableDate) {
    return this.start <= otherDate.end && this.end >= otherDate.start;
  }

  static mapFromDto(ud: UnavailableDate) {
    return new UnavailableDate(
      ud.externalSchoolId,
      ud.comment,
      ud.start,
      ud.end,
      ud.id
    );
  }
}

export default UnavailableDate;
