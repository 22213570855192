import { StepType } from '@/types/StepType';
import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './style';
interface IComponentProps {
  steps: StepType[];
  step: number;
}

const StepStatus = (props: IComponentProps) => {
  const { steps, step } = props;
  const classes = useStyles();

  return (
    <>
      <Stepper
        activeStep={step}
        classes={{
          root: classes.backgroundColorTransparent,
        }}
        connector={
          <StepConnector
            classes={{
              root: classes.stepLineWidth,
            }}
          />
        }
      >
        {steps.map((item, index) => (
          <Step key={item.title} completed={index < step}>
            <StepLabel
              classes={{
                active: classes.stepLabel,
                root: clsx(classes.whiteSpaceNowrap, classes.stepLabel),
              }}
              StepIconProps={{
                classes: {
                  active: classes.stepIconActive,
                },
              }}
            >
              {item.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default StepStatus;
