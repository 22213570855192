import ICourse from './Course';

export interface IStudentCourse {
  id?: number;
  note?: string;
  finalAssignedCourse?: ICourse;
  attendance?: boolean;
  studentId?: number;
  finalAssignedCourseId?: number;
}

export class CreateOrUpdateStudentCourseDto implements IStudentCourse {
  id?: number;
  note?: string;
  attendance?: boolean;
  studentId?: number;
  finalAssignedCourseId?: number;
}
