import {
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  LinearProgress,
  Button
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import courseStyle from '@/views/style/courses';
import ICourse from '@/types/Course';
import { getDateTime } from '@/utils/timeUtils';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';
import IStudySubject from '@/types/StudySubject';
import {useStores} from "@/stores";
import {ISchool} from "@/types/School";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  handleCloseMovingMulDialog: () => void;
  studentIds: number[];
  course: ICourse; // Old course
  studySubjects: IStudySubject[];
  studySubjectId: number | string;
  handleChooseStudySubjectId: (studySubjectId: number | string) => void;
  courses: ICourse[];
  newCourseId: number | string;
  handleChooseNewCourseId: (newCourseId: number | string) => void;
  loadingNewCourse: boolean;
  movingAssignMul: () => Promise<void>;
}

const MovingMulDialog = (props: IComponentProps) => {
  const {
    open,
    handleCloseMovingMulDialog,
    studentIds,
    course,
    studySubjects,
    studySubjectId,
    handleChooseStudySubjectId,
    courses,
    newCourseId,
    handleChooseNewCourseId,
    loadingNewCourse,
    movingAssignMul
  } = props;
  const courseClasses = courseStyle();
  const [newCourse, setNewCourse] = useState<ICourse>();
  const [schools, setSchools] = useState<ISchool[]>();
  let schoolName = '';


  const { userStore } = useStores();
  const getContentMenu = (value: unknown): string => {
    if (typeof value === 'string' && value === SELECT_ALL) {
      return 'Finn nytt arrangement';
    }

    if (typeof value === 'number') {
      const course = courses.find((c) => c.id === value);
      if (course) {
        return course.school.schoolName + ' - ' +getDateTime(course.startDate, 'dd. LLL.') + ' ' + getDateTime(course.startDate, 'HH:mm') + '-' +  getDateTime(course.endDate, 'HH:mm');
      }
    }
    return '';
  };

  React.useEffect(() => {
    async function extracted() {
      const schools: ISchool[] = [];
      for (const studentId of studentIds) {
        const student = await userStore.getStudentById(studentId);
        if (student != null && student.school != null)
          schools.push(student.school);
      }
      setSchools(schools);
    }
    extracted();
  }, [studentIds, userStore]);

  const isSchoolAvailableForThisDate = (date: Date): boolean => {
    let ret = true;
    const dDate = new Date(date);
    if (schools != null) {
      for (let i = 0; i < schools.length; i++) {
        const school = schools[i];
        if (school.unavailableDates != null)
          // eslint-disable-next-line
          school.unavailableDates.forEach(ud => {
            if (new Date(ud.start) <= dDate && dDate <= new Date(ud.end)) {
              schoolName = school.schoolName;
              ret = false;
            }
          });
      }
    }
    return ret;
  };

  function handleChooseNewCourse(value: any) {
    handleChooseNewCourseId(value);
    const course = courses.find((c) => c.id === value);
    setNewCourse(course);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseMovingMulDialog}
        TransitionComponent={Transition}
        fullWidth
      >
        <div className={courseClasses.movingMulDialog}>
          <DialogTitle classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.displayFlex,
                courseClasses.alignItemsCenter
              )}
            >
              <div
                className={clsx(
                  courseClasses.textMovingMulDialog,
                  courseClasses.fontSize18
                )}
              >
                Flytt deltaker
              </div>
              <IconButton
                aria-label='close'
                onClick={handleCloseMovingMulDialog}
                classes={{ root: courseClasses.marginLeftAuto }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.textMovingMulDialog,
                courseClasses.textNumberStudentMoving
              )}
            >
              Antall deltakere som flyttes: {studentIds.length}
            </div>

            <div
              className={clsx(
                courseClasses.textMovingMulDialog,
                courseClasses.titleInfoCourseOld
              )}
            >
              Fra:
            </div>
            <div className={clsx(courseClasses.textMovingMulDialog)}>
              {course.studySubject.studySubjectName}
            </div>
            <div className={clsx(courseClasses.textMovingMulDialog)}>
              {course.school.schoolName}
            </div>
            <div className={clsx(courseClasses.textMovingMulDialog)}>
              {getDateTime(course.startDate, 'dd. LLL.')} {' '}
              {getDateTime(course.startDate, 'HH:mm')} -
              {getDateTime(course.endDate, 'HH:mm')}
            </div>
          </DialogContent>
          <Divider className={clsx(courseClasses.movingMulDialogDivider)} />
          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.textMovingMulDialog,
                courseClasses.marginBottom16
              )}
            >
              Til:
            </div>
            <Select
              disableUnderline
              value={studySubjectId}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleChooseStudySubjectId(e.target.value as any)
              }
              classes={{
                root: courseClasses.movingMulDialogSelect,
                icon: courseClasses.color500
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null,
                classes: {
                  paper: courseClasses.movingMulDialogPager,
                  list: courseClasses.movingMulDialogList
                }
              }}
              className={clsx(
                courseClasses.widthFull,
                courseClasses.marginBottom16
              )}
            >
              <MenuItem
                key={SELECT_ALL}
                value={SELECT_ALL}
                classes={{ root: courseClasses.movingMulDialogMenu }}
              >
                Finn kurs
              </MenuItem>

              {studySubjects.map((studySubject: IStudySubject) => (
                <MenuItem
                  key={studySubject.id}
                  value={studySubject.id}
                  classes={{ root: courseClasses.movingMulDialogMenu }}
                >
                  {studySubject.studySubjectName}
                </MenuItem>
              ))}
            </Select>

            {!loadingNewCourse ? (
              <Select
                disableUnderline
                value={newCourseId}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  handleChooseNewCourse(e.target.value as any)
                }
                classes={{
                  root: courseClasses.movingMulDialogSelect,
                  icon: courseClasses.color500
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: courseClasses.movingMulDialogPager,
                    list: courseClasses.movingMulDialogList
                  }
                }}
                className={clsx(
                  courseClasses.widthFull,
                  courseClasses.marginBottom16
                )}
                renderValue={(value: unknown) => getContentMenu(value)}
              >
                {courses.sort((a, b) => {
                  if ((a.school.schoolName ?? '') > (b.school.schoolName ?? '')) {
                    return 1;
                  }
                  if ((a.school.schoolName ?? '') < (b.school.schoolName ?? '')) {
                    return -1;
                  }
                  return (a.startDate ?? '') > (b.startDate ?? '') ? 1 : -1;
                }).map((course: ICourse) => (
                  <MenuItem
                    key={course.id}
                    value={course.id}
                    classes={{ root: courseClasses.movingMulDialogMenu }}
                  >
                    <div className={courseClasses.widthFull}>
                      <div className={courseClasses.textMovingMulDialog}>
                        {course.school.schoolName}
                      </div>
                      <div className={courseClasses.displayFlex}>
                        <div
                          className={clsx(
                            courseClasses.textMovingMulDialog,
                            courseClasses.fontSize14
                          )}
                        >
                          {`${getDateTime(
                            course.startDate,
                            'dd LLLL'
                          )} | kl. ${getDateTime(
                            course.startDate,
                            'HH:mm'
                          )} - ${getDateTime(course.endDate, 'HH:mm')}`}
                        </div>
                        <div
                          className={clsx(
                            courseClasses.marginLeftAuto,
                            courseClasses.textMovingMulDialog,
                            courseClasses.fontSize14
                          )}
                        >
                          {`${course.capacity - course.availableCapacity}/${
                            course.capacity
                          }`}
                        </div>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <LinearProgress />
            )}

            {/*{newCourse != null && !isSchoolAvailableForThisDate(newCourse.startDate) ? 'Hmmm': ''}*/}

            {newCourse != null && !isSchoolAvailableForThisDate(newCourse.startDate) && <div
                className={clsx(
                    courseClasses.textSchoolUnavailable
                )}
            >
              {schoolName} er utilgjenglig denne dagen.</div>}
          </DialogContent>
          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.displayFlex,
                courseClasses.movingMulDialogButton
                //courseClasses.marginTop20
              )}
            >
              <div className={courseClasses.marginLeftAuto}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={async () => {
                    await movingAssignMul();
                  }}
                >
                  Flytt
                </Button>
                <Button
                  variant='text'
                  onClick={handleCloseMovingMulDialog}
                  style={{ marginLeft: '12px' }}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default MovingMulDialog;
