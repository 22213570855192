import availableMessageTemplateAttributes from './constants/AvailableMessageTemplateAttributes';
import messageTemplateDataDescriptions from './constants/MessageTemplateDataDescription';
import MessageTemplateAttribute from './enums/MessageTemplateAttribute';
import MessageTemplateName from './enums/MessageTemplateName';
import { MessageTemplateType } from './enums/MessageTemplateType';

class MessageTemplate {
  constructor(
    public id: number,
    public templateName: MessageTemplateName,
    public name: string,
    public emailSubject: string,
    public emailBody: string,
    public sms: string,
    public sendEmail: boolean,
    public sendSMS: boolean,
    public recipientType: MessageTemplateType
  ) {}

  public static fromJson(json: any): MessageTemplate {
    return new MessageTemplate(
      json.id,
      json.templateName,
      json.name,
      json.emailSubject,
      json.emailBody,
      json.sms,
      json.sendEmail,
      json.sendSMS,
      json.recipientType
    );
  }

  getAvailableAttributes(): {
    key: MessageTemplateAttribute;
    description: string;
  }[] {
    return availableMessageTemplateAttributes[this.templateName].map(
      (attribute) => ({
        key: attribute,
        description: messageTemplateDataDescriptions[attribute],
      })
    );
  }
}

export default MessageTemplate;
