import CustomDayPickerRange from '@/components/CustomDayPickerRange';
import courseStyle from '@/views/style/courses';
import { Dialog, DialogContent, Slide, Button } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';
import { RangeModifier } from 'react-day-picker';
import clsx from 'clsx';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  handleCloseDialog: () => void;
  rangeStartDate: RangeModifier;
  setRangeStartDate: (range: RangeModifier) => void;
  handleFilterStartDate: () => void;
}

const DialogFilterDate = (props: IComponentProps) => {
  const {
    open,
    handleCloseDialog,
    rangeStartDate,
    setRangeStartDate,
    handleFilterStartDate,
  } = props;
  const courseClasses = courseStyle();

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseDialog()}
      TransitionComponent={Transition}
    >
      <DialogContent classes={{ root: courseClasses.padding0 }}>
        <CustomDayPickerRange
          selection={rangeStartDate}
          setSelection={setRangeStartDate}
        />
      </DialogContent>
      <DialogContent classes={{ root: courseClasses.padding0 }}>
        <div
          className={clsx(courseClasses.displayFlex)}
          style={{ padding: '12px 16px' }}
        >
          <div className={courseClasses.marginLeftAuto}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (rangeStartDate.from && rangeStartDate.to) {
                  handleFilterStartDate();
                }
              }}
              disabled={!(rangeStartDate.from && rangeStartDate.to)}
            >
              Søk
            </Button>
            <Button
              variant="text"
              onClick={handleCloseDialog}
              style={{ marginLeft: '12px' }}
            >
              Avbryt
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogFilterDate;
