import { makeStyles } from '@material-ui/core';

const useCourseFilterStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 116,
    backgroundColor: '#C6CADEAA',
  },
  search: {
    position: 'relative',
    width: '611px',
    height: '52px',
    padding: `4px 0`,
    boxSizing: 'content-box',
    [breakpoints.down('md')]: {
      width: '500px',
    },
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 358,
    '& .MuiTabs-root': {
      width: '100%',
      '& .MuiTabs-flexContainer': {
        width: '100%',
        height: '100%',
        '& .MuiTab-root': {
          flexGrow: 1,
        },
      },
    },
  },
  activeTab: {
    color: palette.primary.dark,
  },
  tabIndicator: {
    backgroundColor: palette.primary.main,
  },
}));

export default useCourseFilterStyles;
