import {
  Typography,
  Card,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import { hasDuplicates } from '../../../../utils/arrayUtils';
import SavedDialog from './SavedDialog';
import useStyle from './style';

const Prioritize = () => {
  const classes = useStyle();
  const { studySubjectStore, userStore, errorStore, projectStore } =
    useStores();
  const { currentProject } = projectStore;
  const { user } = userStore;
  const {
    allStudySubjects,
    getAllStudySubjectsForStudent,
    studentStudySubjects,
    addOrUpdateStudentPriorities,
  } = studySubjectStore;
  const { showErrorMessage } = errorStore;

  const [firstPriority, setFirstPriority] = useState<string>('');
  const [secondPriority, setSecondPriority] = useState<string>('');
  const [thirdPriority, setThirdPriority] = useState<string>('');
  const [showSavedDialog, setShowSavedDialog] = useState(false);

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllStudySubjectsForStudent();
    studySubjectStore.getStudySubjectsByCurrentUser();
    const sortedStudentStudySubjects = studentStudySubjects.sort(
      (a, b) => a.priority - b.priority
    );

    if (!currentProject) projectStore.getCurrent();

    if (sortedStudentStudySubjects.length === 3) {
      setFirstPriority(
        sortedStudentStudySubjects[0].studySubject.id.toString()
      );
      setSecondPriority(
        sortedStudentStudySubjects[1].studySubject.id.toString()
      );
      setThirdPriority(
        sortedStudentStudySubjects[2].studySubject.id.toString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const studySubjectMenuItems = allStudySubjects.map((studySubject) => (
    <MenuItem value={studySubject.id}>{studySubject.studySubjectName}</MenuItem>
  ));

  const handlePriorities = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    const orderedPriorities = [
      firstPriority,
      secondPriority,
      thirdPriority,
    ].map(Number);

    if (hasDuplicates(orderedPriorities)) {
      showErrorMessage(
        'Du kan ikke velge samme kurs mer enn én gang.'
      );
      setLoading(false);
      return;
    }

    if (user) {
      await addOrUpdateStudentPriorities(user.id, orderedPriorities);
      setShowSavedDialog(true);
      setLoading(false);
    }
  };

  const reset = () => {
    setFirstPriority('');
    setSecondPriority('');
    setThirdPriority('');
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <header>
          <Typography variant="h3" className={classes.header}>
            Prioriterte kurs
          </Typography>
        </header>
        <main>
          <Card className={classes.card}>
            <Typography variant="h4">Velg 3 kurs</Typography>
            <Typography variant="body1">
              {currentProject?.studentRegistrationText}
            </Typography>
            <Link
              underline="always"
              onClick={() => history.push('/student-study-subjects')}
            >
              <Typography variant="h5">
                Les mer om kursene her
              </Typography>
            </Link>
            <form onSubmit={handlePriorities} className={classes.form}>
              <FormControl variant="filled" required>
                <InputLabel id="first-priority-label">1. Prioritet</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  labelId="first-priority-label"
                  value={firstPriority}
                  onChange={(e) => setFirstPriority(e.target.value as string)}
                >
                  {studySubjectMenuItems}
                </Select>
              </FormControl>
              <FormControl variant="filled" required>
                <InputLabel id="second-priority-label">2. Prioritet</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  labelId="second-priority-label"
                  value={secondPriority}
                  onChange={(e) => setSecondPriority(e.target.value as string)}
                >
                  {studySubjectMenuItems}
                </Select>
              </FormControl>
              <FormControl variant="filled" required>
                <InputLabel id="third-priority-label">3. Prioritet</InputLabel>
                <Select
                  labelId="third-priority-label"
                  value={thirdPriority}
                  onChange={(e) => setThirdPriority(e.target.value as string)}
                >
                  {studySubjectMenuItems}
                </Select>
              </FormControl>
              <div>
                <Button
                  type="submit"
                  color="secondary"
                  size="large"
                  disabled={loading}
                >
                  Registrer
                  {loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.loadingButton}
                    />
                  ) : null}
                </Button>
                <Button variant="text" size="large" onClick={reset}>
                  Nullstill
                </Button>
              </div>
            </form>
          </Card>
        </main>
        <SavedDialog open={showSavedDialog} setOpen={setShowSavedDialog} />
      </div>
    </div>
  );
};

export default observer(Prioritize);
