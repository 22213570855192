import {
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  DialogContent,
  Select,
  MenuItem,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import courseStyle from '@/views/style/courses';
import ICourse from '@/types/Course';
import { getDateTime } from '@/utils/timeUtils';
import { SELECT_ALL } from '@/types/dto/PaginationRequest.dto';
import IStudySubject from '@/types/StudySubject';
import { User } from '@/types/User';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IComponentProps {
  open: boolean;
  handleCloseDialog: () => void;
  student: User;
  studySubjects: IStudySubject[];
  studySubjectId: number | string;
  courses: ICourse[];
  courseId: number | string;
  handleChooseStudySubjectId: (
    studySubjectId: number | string
  ) => Promise<void>;
  loadingNewCourse: boolean;
  handleChooseCourseId: (courseId: number | string) => void;
  manualAssign: () => Promise<void>;
}

const ManualAssignAllocationForStudentWithoutRegisterDialog = (
  props: IComponentProps
) => {
  const {
    open,
    handleCloseDialog,
    student,
    studySubjects,
    studySubjectId,
    courses,
    courseId,
    handleChooseStudySubjectId,
    handleChooseCourseId,
    manualAssign,
    loadingNewCourse,
  } = props;
  const courseClasses = courseStyle();

  const getContentMenu = (value: unknown): string => {
    if (typeof value === 'string' && value === SELECT_ALL) {
      return 'Finn arrangement';
    }

    if (typeof value === 'number') {
      const course = courses.find((c) => c.id === value);
      if (course) {
        return course.school.schoolName;
      }
    }
    return '';
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        fullWidth
      >
        <div className={courseClasses.movingMulDialog}>
          <DialogTitle classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.displayFlex,
                courseClasses.alignItemsCenter
              )}
            >
              <div
                className={clsx(
                  courseClasses.textMovingMulDialog,
                  courseClasses.fontSize18
                )}
              >
                Tildel elev
              </div>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                classes={{ root: courseClasses.marginLeftAuto }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent classes={{ root: courseClasses.padding0 }}>
            <div
              className={clsx(
                courseClasses.textMovingMulDialog,
                courseClasses.marginBottom16
              )}
            >
              {student.fullName} skal tildeles
            </div>
            <Select
              disableUnderline
              value={studySubjectId}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleChooseStudySubjectId(e.target.value as any)
              }
              classes={{
                root: courseClasses.movingMulDialogSelect,
                icon: courseClasses.color500,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: courseClasses.movingMulDialogPager,
                  list: courseClasses.movingMulDialogList,
                },
              }}
              className={clsx(
                courseClasses.widthFull,
                courseClasses.marginBottom16
              )}
            >
              <MenuItem
                key={SELECT_ALL}
                value={SELECT_ALL}
                classes={{ root: courseClasses.movingMulDialogMenu }}
              >
                Finn kurs
              </MenuItem>

              {studySubjects.map((studySubject: IStudySubject) => (
                <MenuItem
                  key={studySubject.id}
                  value={studySubject.id}
                  classes={{ root: courseClasses.movingMulDialogMenu }}
                >
                  {studySubject.studySubjectName}
                </MenuItem>
              ))}
            </Select>

            {!loadingNewCourse ? (
              <Select
                disableUnderline
                value={courseId}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  handleChooseCourseId(e.target.value as any)
                }
                classes={{
                  root: courseClasses.movingMulDialogSelect,
                  icon: courseClasses.color500,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: courseClasses.movingMulDialogPager,
                    list: courseClasses.movingMulDialogList,
                  },
                }}
                className={clsx(
                  courseClasses.widthFull,
                  courseClasses.marginBottom16
                )}
                renderValue={(value: unknown) => getContentMenu(value)}
              >
                {courses
                  .sort((a, b) => {
                    if (
                      (a.school.schoolName ?? '') > (b.school.schoolName ?? '')
                    ) {
                      return 1;
                    }
                    if (
                      (a.school.schoolName ?? '') < (b.school.schoolName ?? '')
                    ) {
                      return -1;
                    }
                    return (a.startDate ?? '') > (b.startDate ?? '') ? 1 : -1;
                  })
                  .map((course: ICourse) => (
                    <MenuItem
                      key={course.id}
                      value={course.id}
                      classes={{ root: courseClasses.movingMulDialogMenu }}
                    >
                      <div className={courseClasses.widthFull}>
                        <div className={courseClasses.textMovingMulDialog}>
                          {course.school.schoolName}
                        </div>
                        <div className={courseClasses.displayFlex}>
                          <div
                            className={clsx(
                              courseClasses.textMovingMulDialog,
                              courseClasses.fontSize14
                            )}
                          >
                            {`${getDateTime(
                              course.startDate,
                              'dd LLLL'
                            )} | kl. ${getDateTime(
                              course.startDate,
                              'HH:mm'
                            )} - ${getDateTime(course.endDate, 'HH:mm')}`}
                          </div>
                          <div
                            className={clsx(
                              courseClasses.marginLeftAuto,
                              courseClasses.textMovingMulDialog,
                              courseClasses.fontSize14
                            )}
                          >
                            {`${course.capacity - course.availableCapacity}/${
                              course.capacity
                            }`}
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <div className={courseClasses.marginBottom16}>
                <LinearProgress />
              </div>
            )}
            <div className={clsx(courseClasses.displayFlex)}>
              <div className={courseClasses.marginLeftAuto}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={manualAssign}
                >
                  Tildel
                </Button>
                <Button
                  variant="text"
                  onClick={handleCloseDialog}
                  style={{ marginLeft: '12px' }}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default ManualAssignAllocationForStudentWithoutRegisterDialog;
