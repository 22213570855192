import adminDashboardService from '@/services/AdminDashboard.service';
import { INIT_PROJECT, IProject } from '@/types/Project';
import {
  IAdminStatisticSubjectCapacity,
  ISchoolStudySubjectInfo,
} from '@/types/StudySubject';
import { makeObservable, observable } from 'mobx';
import { RootStore } from '.';

class AdminDashboardStore {
  rootStore: RootStore;
  project: IProject;
  totalStudent: number;
  registeredStudent: number;
  totalAssignedStudent: number;
  totalCourse: number;
  numberFirstPriorityStudent: number;
  numberSecondPriorityStudent: number;
  numberThirdPriorityStudent: number;
  capacityStore: IAdminStatisticSubjectCapacity[];
  schoolStore: ISchoolStudySubjectInfo[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.project = INIT_PROJECT;
    this.totalStudent = 0;
    this.registeredStudent = 0;
    this.totalAssignedStudent = 0;
    this.totalCourse = 0;
    this.numberFirstPriorityStudent = 0;
    this.numberSecondPriorityStudent = 0;
    this.numberThirdPriorityStudent = 0;
    this.capacityStore = [];
    this.schoolStore = [];

    makeObservable(this, {
      project: observable,
      totalStudent: observable,
      registeredStudent: observable,
      totalAssignedStudent: observable,
      totalCourse: observable,
      numberFirstPriorityStudent: observable,
      numberSecondPriorityStudent: observable,
      numberThirdPriorityStudent: observable,
      capacityStore: observable,
      schoolStore: observable,
    });
  }

  setProject(data: IProject) {
    this.project = data;
  }

  setStudentStatistic(totalStudent: number, registeredStudent: number) {
    this.totalStudent = totalStudent;
    this.registeredStudent = registeredStudent;
  }

  setStudentCourseStatistic(
    totalAssignedStudent: number,
    totalCourse: number,
    numberFirstPriorityStudent: number,
    numberSecondPriorityStudent: number,
    numberThirdPriorityStudent: number
  ) {
    this.totalAssignedStudent = totalAssignedStudent;
    this.totalCourse = totalCourse;
    this.numberFirstPriorityStudent = numberFirstPriorityStudent;
    this.numberSecondPriorityStudent = numberSecondPriorityStudent;
    this.numberThirdPriorityStudent = numberThirdPriorityStudent;
  }

  setStudySubjectStatistic(capacityStore: IAdminStatisticSubjectCapacity[]) {
    this.capacityStore = capacityStore;
  }

  setSubjectSchoolInfo(schoolStore: ISchoolStudySubjectInfo[]) {
    this.schoolStore = schoolStore;
  }

  async adminGetCurrentProject() {
    const result = await adminDashboardService.adminGetCurrentProject();
    this.setProject(result);
  }

  async adminGetStudentStatistic() {
    if (this.project.id) {
      const { totalStudent, registeredStudent } =
        await adminDashboardService.adminGetStudentStatistic(this.project.id);
      this.setStudentStatistic(totalStudent, registeredStudent);
    }
  }

  async adminGetStudentCourseStatistic() {
    if (this.project.id) {
      const {
        totalAssignedStudent,
        totalCourse,
        numberFirstPriorityStudent,
        numberSecondPriorityStudent,
        numberThirdPriorityStudent,
      } = await adminDashboardService.adminGetStudentCourseStatistic(
        this.project.id
      );

      this.setStudentCourseStatistic(
        totalAssignedStudent,
        totalCourse,
        numberFirstPriorityStudent,
        numberSecondPriorityStudent,
        numberThirdPriorityStudent
      );
    }
  }

  async adminGetStudySubjectStatistic() {
    if (this.project.id) {
      const capacityStore =
        await adminDashboardService.adminGetStudySubjectStatistic(
          this.project.id
        );

      this.setStudySubjectStatistic(capacityStore);
    }
  }

  async adminGetSubjectSchool(studySubjectId: number) {
    const schoolInfo = await adminDashboardService.adminGetSubjectSchoolInfo(
      studySubjectId
    );
    this.setSubjectSchoolInfo(schoolInfo);
  }
}

export default AdminDashboardStore;
