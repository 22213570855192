export const hasDuplicates = (array: any[]): boolean => {
  return array.length !== new Set(array).size;
};

export const removeDuplicatesBy = <T>(keyFn: (val: T) => any, array: T[]) => {
  const mySet = new Set();
  return array.filter(function (x) {
    const key = keyFn(x),
      isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
};
