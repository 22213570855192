import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import {
  PER_PAGE_OPTIONS,
  SELECT_ALL,
  UNDEFINED,
} from '@/types/dto/PaginationRequest.dto';
import CourseHeader from './components/CourseHeader';
import CourseFilter from './components/CourseFilter';
import CourseTable from './components/CourseTable';
import CourseOptions from './components/CourseOptions';
import { useParams } from 'react-router-dom';
import { CourseStatus } from '@/types/enums/CourseStatus';
import { DateUtils, DayModifiers, RangeModifier } from 'react-day-picker';
import ICourse, { CreateUpdateCourse, State } from '@/types/Course';
import NotifyMessage from '@/components/NotifyMessage';
import clsx from 'clsx';
import { CourseOptionAdmin } from '@/types/enums/CourseOption';
import CustomPagination from '@/components/CustomPagination';
import courseStyle from '@/views/style/courses';
import { StepType } from '@/types/StepType';
import VGSEditCourseDrawer from './components/VGSEditCourseDrawer';
import { EventCheckbox } from '@/types/enums/EventCheckbox';
//import MovingDialog from './components/MovingDialog';
import { changeDate } from '@/utils/timeUtils';
import ManualAssignDialog from './components/ManualAssignDialog';
import { scrollTop } from '@/utils/common.utils';
import MovingMulDialog from './components/MovingMulDialog';
import NotifySuccessMovingStudent from './components/MovingMulDialog/notify-success';
import ChangeMultipleStatusDialog from './components/ChangeMultipleStatusDialog';
import DialogFilterDate from './components/DialogFilterDate';

interface UrlParams {
  projectId?: string;
}

const AdminCourses = () => {
  const { projectId } = useParams<UrlParams>();
  const courseClasses = courseStyle();

  const {
    courseStore,
    schoolStore,
    studySubjectStore,
    projectStore,
    userStore,
    stateStore,
    studentCourseStore,
  } = useStores();

  const [steps] = React.useState<StepType[]>([
    {
      title: 'Planlegging', // 0
    },
    {
      title: 'Kursperiode', // 1
    },
    {
      title: 'Evaluering', // 2
    },
  ]);
  const [step, setStep] = React.useState<number>(-1);
  React.useEffect(() => {
    if (projectStore.currentProject) {
      const project = projectStore.currentProject;
      const now = new Date();

      if (
        project.registeringPeriodStartDate &&
        project.registeringPeriodEndDate
      ) {
        if (
          new Date(project.registeringPeriodStartDate) <= now &&
          now <= new Date(project.registeringPeriodEndDate)
        ) {
          setStep(0);
          return;
        }
      }

      if (project.coursePeriodStartDate && project.coursePeriodEndDate) {
        if (
          new Date(project.coursePeriodStartDate) <= now &&
          now <= new Date(project.coursePeriodEndDate)
        ) {
          setStep(1);
          return;
        }
      }

      if (
        project.evaluationPeriodStartDate &&
        project.evaluationPeriodEndDate
      ) {
        if (
          new Date(project.evaluationPeriodStartDate) <= now &&
          now <= new Date(project.evaluationPeriodEndDate)
        ) {
          setStep(2);
          return;
        }
      }
    }
  }, [projectStore, projectStore.currentProject]);

  /** Course table */
  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(+PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [openNotifyMessage, setOpenNotifyMessage] =
    React.useState<boolean>(false);
  const handleCloseNotifyMessage = () => {
    setOpenNotifyMessage(false);
  };
  const [openNotifyMessageWarning, setOpenNotifyMessageWarning] =
    React.useState<boolean>(false);
  const handleCloseNotifyMessageWarning = () => {
    setOpenNotifyMessageWarning(false);
  };
  const [openNotifyMessageFail, setOpenNotifyMessageFail] =
    React.useState<boolean>(false);
  const handleCloseNotifyMessageFail = () => {
    setOpenNotifyMessageFail(false);
  };

  const [studySubjectId, setStudySubjectId] = React.useState<number | string>(
    SELECT_ALL
  );

  const handleSearchStudySubjectId = (studySubjectId: number | string) => {
    _resetGetList();
    setStudySubjectId(studySubjectId);
  };

  const [externalHighSchoolId, setExternalHighSchoolId] =
    React.useState<string>(courseStore.externalHighSchoolId);

  const handleExternalHighSchoolId = (externalHighSchoolId: string) => {
    _resetGetList();
    setExternalHighSchoolId(externalHighSchoolId);
  };

  const [courseStatus, setCourseStatus] = React.useState<CourseStatus | string>(
    SELECT_ALL
  );

  const handleCourseStatus = (status: CourseStatus | string) => {
    _resetGetList();
    setCourseStatus(status);
  };

  const _resetGetList = (): void => {
    setSkip(0);
    setTake(+PER_PAGE_OPTIONS[0]);
    setCurrentPage(1);
  };

  const getList = React.useCallback(
    () => {
      scrollTop();
      _resetChooseCheckbox();

      const projectIdNr = projectStore.currentProject?.id
        ? Number(projectStore.currentProject?.id)
        : 0;
      userStore.UpdateStudentList(projectIdNr);
      courseStore.getList({
        skip,
        take,
        projectId,
        studySubjectId,
        externalSchoolId: externalHighSchoolId,
        status: courseStatus,
        beginStartDate: rangeStartDate.from,
        endStartDate: rangeStartDate.to,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      skip,
      take,
      courseStore,
      projectId,
      studySubjectId,
      externalHighSchoolId,
      courseStatus,
    ]
  );

  const getStudySubject = React.useCallback(() => {
    if (projectId) {
      studySubjectStore.getStudySubjectByProjectId(+projectId);
    }
  }, [studySubjectStore, projectId]);

  const getHighSchool = React.useCallback(() => {
    if (projectId) {
      schoolStore.getHighSchoolByProjectId(+projectId);
    }
  }, [schoolStore, projectId]);

  const getCourseStatus = React.useCallback(() => {
    if (projectId) {
      courseStore.getCourseStatusByProjectId(+projectId);
    }
  }, [courseStore, projectId]);

  React.useEffect(() => {
    getStudySubject();
    getHighSchool();
    getCourseStatus();
    getList();
  }, [getStudySubject, getHighSchool, getCourseStatus, getList]);

  const changePageOption = (take: number): void => {
    handleChoosePage(1);
    setTake(take);
  };

  const handleChoosePage = (page: number): void => {
    setSkip((page - 1) * take);
    setCurrentPage(page);
  };

  const chooseCourse = (id: number): void => {
    if (courseStore.courseId === id) {
      switch (tabMode) {
        case CourseOptionAdmin.CREATE_EDIT:
          _cancelCreateCourse();
          courseStore.resetCourseId();
          break;
        case CourseOptionAdmin.LIST_STUDENT:
          break;
        case CourseOptionAdmin.INFO_STUDENT:
          break;
      }

      return;
    }

    courseStore.setCourseId(id);
  };

  const [isUndoOrRedo, setIsUndoOrRedo] = React.useState<boolean>(false);
  const editCourse = (id: number): void => {
    chooseCourse(id);
    setIsUndoOrRedo(true);
  };

  const deleteCourse = async (id: number): Promise<void> => {
    try {
      const result = await courseStore.deleteCourse(id);
      _insertState(result);
      setOpenNotifyMessage(true);
      _resetGetListCallApi();
    } catch (error) {
      throw error;
    }
  };

  const duplicateCourse = async (id: number): Promise<void> => {
    try {
      const result = await courseStore.duplicateCourse(id);
      _insertState(result);
      setOpenNotifyMessage(true);
      getList();
    } catch (error) {
      throw error;
    }
  };

  const [checkBoxChooseAll, setCheckBoxChooseAll] = React.useState<string>('');
  const checkBoxChooseAllCourse = (isChecked: boolean): void => {
    if (isChecked) {
      const courseIds = courseStore.courses.map((c) => c.id);
      courseStore.setCourseIds(courseIds);
      setCheckBoxChooseAll(SELECT_ALL);
    } else {
      courseStore.resetCourseIds();
      setCheckBoxChooseAll('');
    }
  };

  const checkBoxChooseCourse = (isChecked: boolean, courseId: number): void => {
    if (isChecked) {
      courseStore.courseIds.push(courseId);
      if (courseStore.courseIds.length === courseStore.courses.length) {
        setCheckBoxChooseAll(SELECT_ALL);
      }
    } else {
      const index = courseStore.courseIds.indexOf(courseId);
      if (index > -1) {
        courseStore.courseIds.splice(index, 1);

        if (checkBoxChooseAll === SELECT_ALL) {
          setCheckBoxChooseAll('');
        }
      }
    }
  };

  const [openDialogFilterDate, setOpenDialogFilterDate] =
    React.useState<boolean>(false);
  const [rangeStartDate, setRangeStartDate] = React.useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const handleCloseDialogFilterDate = (): void => {
    setOpenDialogFilterDate(false);
  };
  const handleOpenDialogFilterDate = (): void => {
    setOpenDialogFilterDate(true);
  };
  const handleFilterStartDate = (): void => {
    courseStore.getList({
      skip,
      take,
      projectId,
      studySubjectId,
      externalSchoolId: externalHighSchoolId,
      status: courseStatus,
      beginStartDate: rangeStartDate.from,
      endStartDate: rangeStartDate.to,
    });
    handleCloseDialogFilterDate();
  };
  /** Course table */

  /** Course option */
  const [tabMode, setTabMode] = React.useState<CourseOptionAdmin>(
    CourseOptionAdmin.CREATE_EDIT
  );
  const chooseTabCourseOption = (mode: CourseOptionAdmin) => {
    setTabMode(mode);
  };

  const removeAllAllocations = async (): Promise<void> => {
    if (courseStore.courseIds.length) {
      await courseStore.removeAllAllocations(courseStore.courseIds);

      // _insertState(result);
      _resetGetListCallApi();
      setOpenNotifyMessage(true);
    } else {
      setOpenNotifyMessageWarning(true);
    }
  };

  const deleteListCourse = async (): Promise<void> => {
    if (projectId && courseStore.courseIds.length) {
      const result = await courseStore.deleteListCourse(
        +projectId,
        courseStore.courseIds
      );

      _insertState(result);
      _resetGetListCallApi();
      setOpenNotifyMessage(true);
    } else {
      setOpenNotifyMessageWarning(true);
    }
  };

  const [statusNew, setStatusNew] = React.useState<CourseStatus>(
    CourseStatus.DRAFT
  );
  const updateStatusMultipleCourse = async (): Promise<void> => {
    handleCloseMultipleStatusDialog();
    if (courseStore.courseIds.length) {
      const courseDtos = courseStore.courseIds.map((id) => {
        return { ...new CreateUpdateCourse(), id, status: statusNew };
      });
      const result = await courseStore.updateStatusMultipleCourse(courseDtos);

      _insertState(result);
      setCheckBoxChooseAll('');
      //_resetGetListCallApi();
      getList();
      setOpenNotifyMessage(true);
    } else {
      setOpenNotifyMessageWarning(true);
    }
  };

  const [openMultipleStatusDialog, setOpenMultipleStatusDialog] =
    React.useState<boolean>(false);
  const handleCloseMultipleStatusDialog = (): void => {
    setOpenMultipleStatusDialog(false);
  };
  const handleOpenMultipleStatusDialog = (): void => {
    setOpenMultipleStatusDialog(true);
  };

  const _insertState = (state: State): void => {
    if (courseStore.normalStack === 0) {
      courseStore.undoStack = [];
    }
    courseStore.insertState(state);
  };

  const _resetGetListCallApi = (): void => {
    _resetGetList();
    _resetChooseCheckbox();
    setStudySubjectId(SELECT_ALL);
    setExternalHighSchoolId(SELECT_ALL);
    setCourseStatus(SELECT_ALL);
    setRangeStartDate({ from: undefined, to: undefined });

    courseStore.getList({
      skip: 0,
      take: +PER_PAGE_OPTIONS[0],
      search: '',
      projectId,
      studySubjectId: SELECT_ALL,
      externalSchoolId: SELECT_ALL,
      status: SELECT_ALL,
      beginStartDate: undefined,
      endStartDate: undefined,
    });
  };

  const _resetChooseCheckbox = (): void => {
    courseStore.resetCourseIds();
    if (checkBoxChooseAll === SELECT_ALL) {
      setCheckBoxChooseAll('');
    }
  };

  const handleUndo = async (): Promise<void> => {
    const state = courseStore.redoStack.pop();
    if (projectId && state) {
      if (state.mode === EventCheckbox.EDIT_COURSE) {
        const result = await courseStore.updateCourseToUndoOrRedo(
          state.courseIds[0],
          state.courseDto
        );
        courseStore.undoStack.push(result);
      } else if (state.mode === EventCheckbox.UPDATE_STATUS_MULTIPLE_EVENT) {
        const result = await courseStore.updateStatusMultipleCourse(
          state.courseDtos
        );
        courseStore.undoStack.push(result);
      } else {
        await courseStore.undoCourse(+projectId, state);
        courseStore.undoStack.push(state);
      }

      --courseStore.normalStack;
      _resetGetListCallApi();
    }
  };

  const handleRedo = async (): Promise<void> => {
    const state = courseStore.undoStack.pop();
    if (projectId && state) {
      if (state.mode === EventCheckbox.EDIT_COURSE) {
        const result = await courseStore.updateCourseToUndoOrRedo(
          state.courseIds[0],
          state.courseDto
        );
        courseStore.redoStack.push(result);
      } else if (state.mode === EventCheckbox.UPDATE_STATUS_MULTIPLE_EVENT) {
        const result = await courseStore.updateStatusMultipleCourse(
          state.courseDtos
        );
        courseStore.redoStack.push(result);
      } else {
        await courseStore.redoCourse(+projectId, state);
        courseStore.redoStack.push(state);
      }

      ++courseStore.normalStack;
      _resetGetListCallApi();
    }
  };

  const [exportLoading, setExportLoading] = React.useState(false);
  const getCourseExcelExport = async (): Promise<void> => {
    if (projectId) {
      try {
        setExportLoading(true);
        await courseStore.getCourseExcelExport(+projectId);
        setExportLoading(false);
      } catch (err) {
        setExportLoading(false);
      }
    }
  };

  /** Course option - Create or Edit Course  */
  const [numberCoursePerDay, setNumberCoursePerDay] = React.useState<number>(1);
  const [isCheckedChooseExtraCapacity, setIsCheckedChooseExtraCapacity] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (projectId) {
      projectStore.getById(+projectId);
    }
  }, [projectStore, projectId]);

  React.useEffect(() => {
    if (
      tabMode === CourseOptionAdmin.CREATE_EDIT &&
      courseStore.courseId !== courseStore.initCourseId
    ) {
      courseStore.getUpdateCourseById(courseStore.courseId);
    }

    if (
      (tabMode === CourseOptionAdmin.INFO_STUDENT ||
        tabMode === CourseOptionAdmin.LIST_STUDENT) &&
      courseStore.courseId !== courseStore.initCourseId
    ) {
      courseStore.getCourseById(courseStore.courseId);
    }
  }, [courseStore, courseStore.courseId, tabMode]);

  React.useEffect(() => {
    schoolStore.getPopulatedHighSchools();
  }, [schoolStore, schoolStore.getPopulatedHighSchools]);

  React.useEffect(() => {
    if (courseStore.currentCourse.externalSchoolId) {
      studySubjectStore.getByExternalSchoolId(
        courseStore.currentCourse.externalSchoolId
      );
    }
  }, [
    studySubjectStore,
    studySubjectStore.getByExternalSchoolId,
    courseStore.currentCourse.externalSchoolId,
  ]);

  React.useEffect(() => {
    if (courseStore.currentCourse.startDate) {
      setSelectedDays(new Date(courseStore.currentCourse.startDate));
    }
  }, [courseStore.currentCourse, courseStore.currentCourse.startDate]);

  const resetNumberCoursePerDay = (): void => {
    setNumberCoursePerDay(1);
  };

  const setStatus = (value: CourseStatus): void => {
    courseStore.setCurrentCourse({
      ...courseStore.currentCourse,
      status: value,
    });
  };

  const setSchool = (value: string): void => {
    courseStore.setCurrentCourse({
      ...courseStore.currentCourse,
      externalSchoolId: value,
    });
  };

  const setStudySubject = (value: number): void => {
    courseStore.setCurrentCourse({
      ...courseStore.currentCourse,
      studySubjectId: value,
    });
  };

  const setCapacity = (value: number): void => {
    courseStore.setCurrentCourse({
      ...courseStore.currentCourse,
      capacity: value,
    });
  };

  const setExtraCapacity = (value: number): void => {
    if (!isCheckedChooseExtraCapacity) {
      if (courseStore.currentCourse.id) {
        value = courseStore.currentCourse.extraCapacity;
      } else {
        value = 0;
      }
    }

    courseStore.setCurrentCourse({
      ...courseStore.currentCourse,
      extraCapacity: value,
    });
  };

  const handleChangeChooseExtraCapacity = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsCheckedChooseExtraCapacity(event.target.checked);

    if (!event.target.checked) {
      setExtraCapacity(0);
    }
  };

  const [selectedDays, setSelectedDays] = React.useState<Date | Date[]>([]);
  const handleSelectedDays = (day: Date, modifiers: DayModifiers): void => {
    const { selected } = modifiers;
    if (Array.isArray(selectedDays)) {
      const sds = selectedDays.concat();
      if (selected) {
        const selectedIndex = sds.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        sds.splice(selectedIndex, 1);
      } else {
        sds.push(day);
      }
      setSelectedDays(sds);
    } else {
      setSelectedDays(day);
    }
  };

  const handleCreateCourse = async (): Promise<void> => {
    if (projectId) {
      try {
        let model: CreateUpdateCourse = {
          ...courseStore.currentCourse,
          projectId: +projectId,
          numberCoursePerDay,
        };

        let result;
        if (courseStore.currentCourse.id && !Array.isArray(selectedDays)) {
          model = {
            ...model,
            startDate: changeDate(model.startDate, selectedDays),
          };

          if (isUndoOrRedo) {
            result = await courseStore.updateCourseToUndoOrRedo(
              courseStore.currentCourse.id,
              model
            );
            _insertState(result);
            courseStore.setCurrentCourse(model);
          } else {
            result = await courseStore.updateCourse(
              courseStore.currentCourse.id,
              model
            );
            courseStore.setCurrentCourse(result);
          }
          courseStore.getList({
            skip,
            take,
            projectId,
            studySubjectId,
            externalSchoolId: externalHighSchoolId,
            status: courseStatus,
            beginStartDate: rangeStartDate.from,
            endStartDate: rangeStartDate.to,
          });
          _resetChooseCheckbox();
        } else {
          model = {
            ...model,
            selectedDays: Array.isArray(selectedDays) ? selectedDays : [],
          };

          result = await courseStore.createCourse(model);
          if (result) {
            _resetCourse();
          }
        }

        if (result) {
          setOpenNotifyMessage(true);
        }
      } catch (error: any) {
        console.log(error);
        setOpenNotifyMessageFail(true);
      }
    }
  };

  const _cancelCreateCourse = () => {
    courseStore.setCurrentCourse(new CreateUpdateCourse());
    setNumberCoursePerDay(1);
    setSelectedDays([]);
    setIsCheckedChooseExtraCapacity(false);
  };

  const _resetCourse = (): void => {
    _cancelCreateCourse();

    // Reset get list
    let isChange = false;
    if (skip !== 0) {
      setSkip(0);
      isChange = true;
    }

    if (take !== +PER_PAGE_OPTIONS[0]) {
      setTake(+PER_PAGE_OPTIONS[0]);
      isChange = true;
    }

    if (currentPage !== 1) {
      setCurrentPage(1);
      isChange = true;
    }

    if (studySubjectId !== SELECT_ALL) {
      setStudySubjectId(SELECT_ALL);
      isChange = true;
    }

    if (externalHighSchoolId !== SELECT_ALL) {
      setExternalHighSchoolId(SELECT_ALL);
      isChange = true;
    }

    if (courseStatus !== SELECT_ALL) {
      setCourseStatus(SELECT_ALL);
      isChange = true;
    }

    if (!rangeStartDate.from || !rangeStartDate.to) {
      setRangeStartDate({ from: undefined, to: undefined });
    }

    if (!isChange) {
      _resetChooseCheckbox();
      courseStore.getList({
        skip: 0,
        take: +PER_PAGE_OPTIONS[0],
        projectId,
        studySubjectId: SELECT_ALL,
        externalSchoolId: SELECT_ALL,
        status: SELECT_ALL,
        beginStartDate: undefined,
        endStartDate: undefined,
      });
    }
  };
  /** Course option - Create or Edit Course  */

  /** Course option - List Student Course  */
  const [externalSchoolId, setExternalSchoolId] =
    React.useState<string>(SELECT_ALL);

  const getAllStudent = React.useCallback(() => {
    if (projectId) {
      userStore.getAllStudentAspirationByProjectId(
        +projectId,
        externalSchoolId
      );
    }
  }, [userStore, projectId, externalSchoolId]);

  React.useEffect(() => {
    if (projectId && tabMode === CourseOptionAdmin.LIST_STUDENT) {
      getAllStudent();
      schoolStore.getAllSecondarySchoolByProjectId(+projectId);
    }
  }, [
    schoolStore,
    projectId,
    courseStore.initCourseId,
    tabMode,
    getAllStudent,
  ]);
  /** Course option - List Student Course  */
  /** Course option - Infomation Student */
  const [searchInfoStudent, setSearchInfoStudent] = React.useState<string>('');
  const [studentId, setStudentId] = React.useState<number>(0);
  const timeoutRef = React.useRef<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 300)
  );

  const getAllStudentMinifiedByCourseId = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      userStore.getAllStudentMinifiedByCourseId(
        courseStore.courseId,
        searchInfoStudent
      );
    }, 1000);
  }, [userStore, courseStore.courseId, searchInfoStudent]);

  React.useEffect(() => {
    if (tabMode === CourseOptionAdmin.INFO_STUDENT) {
      getAllStudentMinifiedByCourseId();
    }
  }, [tabMode, getAllStudentMinifiedByCourseId]);

  React.useEffect(() => {
    if (
      studentId !== 0 &&
      courseStore.courseId !== courseStore.initCourseId &&
      tabMode === CourseOptionAdmin.INFO_STUDENT
    ) {
      userStore.getStudentById(studentId, courseStore.courseId, true);
      studySubjectStore.getStudySubjectsByUserId(studentId);
    }
  }, [tabMode, userStore, studySubjectStore, studentId, courseStore]);

  React.useEffect(() => {
    if (
      courseStore.courseIds.length === 1 &&
      courseStore.courseIds[0] !== courseStore.courseId &&
      tabMode === CourseOptionAdmin.INFO_STUDENT
    ) {
      courseStore.setNewCourse(undefined);
      courseStore.getCourseById(courseStore.courseIds[0], true);
    }
  }, [tabMode, courseStore, courseStore.courseIds.length]);

  const chooseStudent = (courseId: number, studentId: number): void => {
    setStudentId(studentId);
    courseStore.setCourseId(courseId);
    setTabMode(CourseOptionAdmin.INFO_STUDENT);
  };

  /** Course option - Infomation Student */
  /** Course option */

  /** VGS edit course drawer */
  const [vgsCourseId, setVgsCourseId] = React.useState<number>(UNDEFINED);
  const chooseCourseShowInfoVGS = (courseId: number): void => {
    setVgsCourseId(courseId);
    stateStore.setVgsEditCourseDrawerIsOpen(true);
  };
  const handleCloseVgsEditCourseDrawer = (): void => {
    setVgsCourseId(UNDEFINED);
    stateStore.setVgsEditCourseDrawerIsOpen(false);
    setUpdateCourse(new CreateUpdateCourse());
    courseStore.resetCourse();
  };
  React.useEffect(() => {
    if (vgsCourseId !== UNDEFINED) {
      courseStore.getCourseById(vgsCourseId);
      userStore.getAllHighSchoolContactByCourseId(vgsCourseId);
    }
  }, [userStore, courseStore, vgsCourseId]);

  const [updateCourse, setUpdateCourse] = React.useState<CreateUpdateCourse>(
    new CreateUpdateCourse()
  );
  const setStartDate = (startDate: Date) => {
    setUpdateCourse({ ...updateCourse, startDate });

    if (courseStore.course) {
      courseStore.setCourse({ ...courseStore.course, startDate });
    }
  };

  const setEndDate = (endDate: Date) => {
    setUpdateCourse({ ...updateCourse, endDate });

    if (courseStore.course) {
      courseStore.setCourse({ ...courseStore.course, endDate });
    }
  };

  const setCourseHolderId = (courseHolderId: number) => {
    setUpdateCourse({ ...updateCourse, courseHolderId });

    if (courseStore.course) {
      courseStore.setCourse({ ...courseStore.course, courseHolderId });
    }
  };

  const setRoomNumber = (roomNumber: string) => {
    setUpdateCourse({ ...updateCourse, roomNumber });

    if (courseStore.course) {
      courseStore.setCourse({ ...courseStore.course, roomNumber });
    }
  };

  const setCourseInfo = (courseInfo: string) => {
    setUpdateCourse({ ...updateCourse, courseInfo });

    if (courseStore.course) {
      courseStore.setCourse({ ...courseStore.course, courseInfo });
    }
  };

  const handleEditCourse = async (): Promise<void> => {
    if (vgsCourseId !== UNDEFINED) {
      await courseStore.updateCourseVGS(vgsCourseId, updateCourse);
      handleCloseVgsEditCourseDrawer();
      setOpenNotifyMessage(true);
    }
  };

  const cancelEditCourse = (): void => {
    handleCloseVgsEditCourseDrawer();
  };
  /** VGS edit course drawer */

  /** Move student */
  // const [loadingDetail, setLoadingDetail] = React.useState<boolean>(false);
  // const [openMovingDialog, setOpenMovingDialog] =
  //   React.useState<boolean>(false);
  // const handleCloseMovingDialog = (): void => {
  //   setOpenMovingDialog(false);
  // };

  // const handleOpenMovingDialog = (): void => {
  //   setOpenMovingDialog(true);
  // };

  // const movingAssign = async (): Promise<void> => {
  //   if (
  //     userStore.student &&
  //     userStore.student.id &&
  //     courseStore.course &&
  //     courseStore.newCourse
  //   ) {
  //     await studentCourseStore.movingAssign(
  //       userStore.student.id,
  //       courseStore.course.id,
  //       courseStore.newCourse.id
  //     );
  //     courseStore.resetCourseIds();
  //     courseStore.setNewCourse(undefined);
  //     getList();
  //     setLoadingDetail(true);
  //     handleCloseMovingDialog();
  //     setOpenNotifyMessage(true);
  //   }
  // };
  /** Move student */

  /** ManualAssignDialog */
  const [openManualAssignDialog, setOpenManualAssignDialog] =
    React.useState<boolean>(false);
  const handleCloseManualAssignDialog = (): void => {
    setOpenManualAssignDialog(false);
  };

  const handleOpenManualAssignDialog = (): void => {
    setOpenManualAssignDialog(true);
  };

  const manualAssignStudentToCourse = async (): Promise<void> => {
    if (userStore.studentAspiration) {
      await studentCourseStore.manualAssignStudentToCourse(
        userStore.studentAspiration.id,
        courseStore.course?.id
      );

      userStore.studentAspiration = undefined;
      getList();
      handleCloseManualAssignDialog();
      setOpenNotifyMessage(true);
    }
  };
  /** ManualAssignDialog */

  /** Move multiple student */
  const [openMovingMulDialog, setOpenMovingMulDialog] =
    React.useState<boolean>(true);
  const [studentIds, setStudentIds] = React.useState<number[]>([]);
  const [course, setCourse] = React.useState<ICourse | undefined>(undefined);
  const [mulStudySubjectId, setMulStudySubjectId] = React.useState<
    number | string
  >(SELECT_ALL);
  const [mulCourses, setMulCourse] = React.useState<ICourse[]>([]);
  const [newCourseId, setNewCourseId] = React.useState<number | string>(
    SELECT_ALL
  );
  const [newCourse, setNewCourse] = React.useState<ICourse | undefined>(
    undefined
  );
  const [loadingNewCourse, setLoadingNewCourse] =
    React.useState<boolean>(false);
  const [openNotifySuccess, setOpenNotifySuccess] =
    React.useState<boolean>(false);

  const handleCloseMovingMulDialog = (): void => {
    setOpenMovingMulDialog(false);
  };

  const getCoursesMinified = async () => {
    if (projectId) {
      try {
        setLoadingNewCourse(true);
        const courses = await courseStore.getCoursesMinified(
          +projectId,
          mulStudySubjectId !== SELECT_ALL ? +mulStudySubjectId : undefined
        );

        if (course) {
          const indexOldCourse = courses.map((c) => c.id).indexOf(course.id);
          if (indexOldCourse > -1) {
            courses.splice(indexOldCourse, 1);
          }
        }

        setMulCourse(courses);
        setLoadingNewCourse(false);
      } catch (error) {
        console.log(
          `Error get list course by projectId and studySubjectId: ${error}`
        );
        setLoadingNewCourse(false);
      }
    }
  };

  React.useEffect(() => {
    getCoursesMinified();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mulStudySubjectId]);

  const handleOpenMovingMulDialog = (
    studentIds: number[],
    course: ICourse
  ): void => {
    setStudentIds(studentIds);
    setCourse(course);
    if (course.studySubjectId !== mulStudySubjectId) {
      setMulStudySubjectId(course.studySubjectId);
      setNewCourseId(SELECT_ALL);
    }
    setOpenMovingMulDialog(true);
  };

  const handleChooseStudySubjectId = (
    studySubjectId: number | string
  ): void => {
    setMulStudySubjectId(studySubjectId);
    setNewCourseId(SELECT_ALL);
    setMulCourse([]);
  };

  const handleChooseNewCourseId = (newCourseId: number | string): void => {
    const newCourse = mulCourses.find((c) => c.id === newCourseId);
    setNewCourse(newCourse);
    setNewCourseId(newCourseId);
  };

  const movingAssignMul = async (): Promise<void> => {
    if (studentIds.length && course && newCourseId !== SELECT_ALL) {
      await studentCourseStore.movingAssignMul(
        studentIds,
        course.id,
        +newCourseId
      );
      handleCloseMovingMulDialog();
      handleOpenNotifySuccess();
    }
  };

  const handleOpenNotifySuccess = (): void => {
    setOpenNotifySuccess(true);
  };

  const handleCloseNotifySuccess = (): void => {
    setOpenNotifySuccess(false);
    setMulCourse([]);
    setMulStudySubjectId(SELECT_ALL);
    setNewCourseId(SELECT_ALL);
    setNewCourse(undefined);
    getList();
  };
  /** Move multiple student */

  return (
    <>
      <CourseHeader
        steps={steps}
        step={step}
        projectName={
          projectStore.currentProject
            ? projectStore.currentProject?.projectName
            : ''
        }
      />
      <CourseFilter
        tabMode={tabMode}
        chooseTabCourseOption={chooseTabCourseOption}
        removeAllAllocations={removeAllAllocations}
        deleteListCourse={deleteListCourse}
        handleOpenMultipleStatusDialog={handleOpenMultipleStatusDialog}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        getCourseExcelExport={getCourseExcelExport}
        exportLoading={exportLoading}
      />
      <div
        className={clsx(
          courseClasses.displayFlex,
          courseClasses.courseContentContainerBackground
        )}
      >
        <div className={clsx(courseClasses.leftContent, courseClasses.space)}>
          <div
            className={clsx(
              courseClasses.overflowXAuto,
              courseClasses.displayGirl
            )}
          >
            <CourseTable
              chooseCourse={chooseCourse}
              chooseStudent={chooseStudent}
              studySubjectId={studySubjectId}
              handleSearchStudySubjectId={handleSearchStudySubjectId}
              externalHighSchoolId={externalHighSchoolId}
              handleExternalHighSchoolId={handleExternalHighSchoolId}
              courseStatus={courseStatus}
              handleCourseStatus={handleCourseStatus}
              editCourse={editCourse}
              deleteCourse={deleteCourse}
              duplicateCourse={duplicateCourse}
              checkBoxChooseCourse={checkBoxChooseCourse}
              checkBoxChooseAll={checkBoxChooseAll}
              checkBoxChooseAllCourse={checkBoxChooseAllCourse}
              /** VGS edit course drawer */
              vgsCourseId={vgsCourseId}
              chooseCourseShowInfoVGS={chooseCourseShowInfoVGS}
              /** VGS edit course drawer */
              // /** Move student */
              // loadingDetail={loadingDetail}
              // /** Move student */
              /** Move multiple student */
              handleOpenMovingMulDialog={handleOpenMovingMulDialog}
              /** Move multiple student */
              /** Add filter startDate */
              handleOpenDialogFilterDate={handleOpenDialogFilterDate}
              /** Add filter startDate */
            />
          </div>

          <div className={courseClasses.marginTop10}>
            <CustomPagination
              currentPage={currentPage}
              totalRows={courseStore.totalCount}
              perPage={take}
              handleChoosePage={handleChoosePage}
              changePageOption={changePageOption}
            />
          </div>
        </div>
        <div
          className={clsx(
            courseClasses.rightContent,
            courseClasses.courseOptionStyle,
            courseClasses.backgroundColorFFF
          )}
        >
          <CourseOptions
            /** Create Edit course */
            tabMode={tabMode}
            numberCoursePerDay={numberCoursePerDay}
            isCheckedChooseExtraCapacity={isCheckedChooseExtraCapacity}
            handleChangeChooseExtraCapacity={handleChangeChooseExtraCapacity}
            setNumberCoursePerDay={setNumberCoursePerDay}
            resetNumberCoursePerDay={resetNumberCoursePerDay}
            setStatus={setStatus}
            setSchool={setSchool}
            setStudySubject={setStudySubject}
            setCapacity={setCapacity}
            setExtraCapacity={setExtraCapacity}
            selectedDays={selectedDays}
            handleSelectedDays={handleSelectedDays}
            handleCreateCourse={handleCreateCourse}
            cancelCreateCourse={_cancelCreateCourse}
            /** Create Edit course */
            /** List Student */
            externalSchoolId={externalSchoolId}
            setExternalSchoolId={setExternalSchoolId}
            handleOpenManualAssignDialog={handleOpenManualAssignDialog}
            /** List Student */
            /** Info Student */
            searchInfoStudent={searchInfoStudent}
            setSearchInfoStudent={setSearchInfoStudent}
            setStudentId={setStudentId}
            //handleOpenMovingDialog={handleOpenMovingDialog}
            /** Info Student */
          />
        </div>
      </div>
      <NotifyMessage
        open={openNotifyMessage}
        message={'Success !!!'}
        severity={'success'}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessage}
      />
      <NotifyMessage
        open={openNotifyMessageWarning}
        message={'Velg minst et arrangement !!!'}
        severity={'warning'}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessageWarning}
      />
      <VGSEditCourseDrawer
        handleCloseVgsEditCourseDrawer={handleCloseVgsEditCourseDrawer}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setCourseHolderId={setCourseHolderId}
        setRoomNumber={setRoomNumber}
        setCourseInfo={setCourseInfo}
        handleEditCourse={handleEditCourse}
        cancelEditCourse={cancelEditCourse}
      />

      {/* {userStore.student && courseStore.course && courseStore.newCourse && (
        <MovingDialog
          open={openMovingDialog}
          handleCloseMovingDialog={handleCloseMovingDialog}
          student={userStore.student}
          newCourse={courseStore.newCourse}
          movingAssign={movingAssign}
        ></MovingDialog>
      )} */}

      <NotifyMessage
        open={openNotifyMessageFail}
        message={'Fail !!!'}
        severity={'error'}
        duration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={handleCloseNotifyMessageFail}
      />

      {userStore.studentAspiration && courseStore.course && (
        <ManualAssignDialog
          open={openManualAssignDialog}
          handleCloseManualAssignDialog={handleCloseManualAssignDialog}
          student={userStore.studentAspiration}
          course={courseStore.course}
          manualAssignStudentToCourse={manualAssignStudentToCourse}
        ></ManualAssignDialog>
      )}

      {studentIds.length && course && (
        <MovingMulDialog
          open={openMovingMulDialog}
          handleCloseMovingMulDialog={handleCloseMovingMulDialog}
          studentIds={studentIds}
          course={course}
          studySubjects={studySubjectStore.studySubjectHeadCell}
          studySubjectId={mulStudySubjectId}
          handleChooseStudySubjectId={handleChooseStudySubjectId}
          handleChooseNewCourseId={handleChooseNewCourseId}
          courses={mulCourses}
          newCourseId={newCourseId}
          loadingNewCourse={loadingNewCourse}
          movingAssignMul={movingAssignMul}
        />
      )}

      {studentIds.length && course && newCourse && (
        <NotifySuccessMovingStudent
          open={openNotifySuccess}
          numberStudent={studentIds.length}
          oldCourse={course}
          newCourse={newCourse}
          handleClose={handleCloseNotifySuccess}
        />
      )}

      <ChangeMultipleStatusDialog
        open={openMultipleStatusDialog}
        statusNew={statusNew}
        handleCloseDialog={handleCloseMultipleStatusDialog}
        updateStatusMultipleCourse={updateStatusMultipleCourse}
        setStatusNew={setStatusNew}
      />

      <DialogFilterDate
        open={openDialogFilterDate}
        handleCloseDialog={handleCloseDialogFilterDate}
        rangeStartDate={rangeStartDate}
        setRangeStartDate={setRangeStartDate}
        handleFilterStartDate={handleFilterStartDate}
      />
    </>
  );
};

export default observer(AdminCourses);
