import SurveyService from '@/services/SurveyService';
import { PaginationRequest } from '@/types/dto/PaginationRequest.dto';
import { PaginationResult } from '@/types/dto/PaginationResult.dto';
import {
  ProjectAssignDto,
  ProjectAssignPaginationRequest,
} from '@/types/ProjectSurvey';
import { IStudentSurvey, ISurvey } from '@/types/Survey';
//import { ISurveyAnswer } from '@/types/SurveyAnswer';
import { ISurveyQuestion } from '@/types/SurveyQuestion';
import { action, computed, makeAutoObservable } from 'mobx';
import { RootStore } from '.';

class UserSurveyStore {
  public rootStore: RootStore;
  public surveys: ISurvey[] = [];
  public tableList: ISurvey[] = [];
  public totalCount = 0;
  public currentSurvey: ISurvey | null = null;
  public editingSurvey: ISurvey | null = null;
  public loadingTableList = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async create(survey: ISurvey): Promise<boolean> {
    await SurveyService.addV2(survey);
    return true;
  }

  @action
  async update(survey: ISurvey): Promise<boolean> {
    await SurveyService.updateV2(survey);
    return true;
  }

  async getAll() {
    const data = await SurveyService.getAll();
    if (data) {
      this.setSurveys(data);
    }
  }

  @action
  setTableList(data: ISurvey[]) {
    this.tableList = data;
  }

  @action
  setSurveys(data: ISurvey[]) {
    this.surveys = data;
  }

  @action
  setTotalCount(total: number) {
    this.totalCount = total;
  }

  @action
  setCurrentSurvey(survey: ISurvey | null) {
    this.currentSurvey = survey;
  }

  @computed
  get arrangedSurveys(): ISurvey[] {
    let array: ISurvey[] = [];

    if (this.currentSurvey) array.push(this.currentSurvey);

    array = [
      ...array,
      ...this.surveys.filter((s) => {
        if (!this.currentSurvey) return true;
        return s.id !== this.currentSurvey.id;
      }),
    ];

    return array;
  }

  reArrangeSurveys() {
    let arrangedArray = [];
    this.currentSurvey && arrangedArray.push(this.currentSurvey);
    arrangedArray = [
      ...arrangedArray,
      ...this.surveys.filter((s) => {
        if (!this.currentSurvey) return true;
        return this.currentSurvey.id !== s.id;
      }),
    ];
    this.surveys = arrangedArray;
  }

  getActiveSurveysForTable() {
    return this.getAll();
  }

  @action setEdittingSurvey(survey: ISurvey | null) {
    this.editingSurvey = survey;
  }

  openSurveyPanel(survey: ISurvey) {
    this.setEdittingSurvey(survey);
    this.rootStore.stateStore.setDrawerMenuIsOpen(false);
  }

  closeProjectPanel() {
    this.setEdittingSurvey(null);
  }

  async deleteSurvey(id: number) {
    const result = await SurveyService.delete(id);
    return result;
  }

  async getById(id: number) {
    const result = await SurveyService.getById(id);

    if (result) {
      this.setCurrentSurvey(result);
    }
  }

  async createQuestion(
    surveyId: number,
    model: ISurveyQuestion
  ): Promise<ISurveyQuestion> {
    const result = await SurveyService.createQuestion(surveyId, model);
    return result;
  }

  async getSurveyById(id: number): Promise<ISurvey> {
    const result = await SurveyService.getSurveyById(id);
    return result;
  }

  async deleteQuestion(id: number): Promise<boolean> {
    const result = await SurveyService.deleteQuestion(id);
    return result;
  }

  async deleteAnswer(id: number): Promise<boolean> {
    const result = await SurveyService.deleteAnswer(id);
    return result;
  }

  public async getList(model: PaginationRequest): Promise<void> {
    this.loadingTableList = true;
    const result = await SurveyService.getList(model);
    this.setTableList(result.data);
    this.setTotalCount(result.count);
    this.loadingTableList = false;
  }

  public async getListProjectAssign(
    model: ProjectAssignPaginationRequest
  ): Promise<PaginationResult<ProjectAssignDto>> {
    const result = await SurveyService.getListProjectAssign(model);
    return result;
  }

  async getSurveyByProjectId(
    projectId: number,
    uuid?: string
  ): Promise<IStudentSurvey> {
    const result = await SurveyService.getSurveyByProjectId(projectId, uuid);
    return result;
  }

  async evaluationUpdateSurvey(
    uuid: string,
    survey: ISurvey
  ): Promise<boolean> {
    await SurveyService.evaluationUpdateSurvey(uuid, survey);
    return true;
  }

  async getProjectSurveyExcelExport(
    projectId: number,
    surveyId: number
  ): Promise<void> {
    await SurveyService.getProjectSurveyExcelExport(projectId, surveyId);
  }
}

export default UserSurveyStore;
