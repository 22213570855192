import { KarDateType } from '../types/commons';
import { DateTime } from 'luxon';

interface OptionalDateFromTo {
  from?: KarDateType;
  to?: KarDateType;
}

export const generateStringFromToDate = ({ from, to }: OptionalDateFromTo) => {
  if (!from || !to) return '';

  let fromString = '';
  from instanceof Date
    ? (fromString = DateTime.fromJSDate(from).toISODate())
    : (fromString = DateTime.fromJSDate(new Date(from)).toISODate());

  let toString = '';
  to instanceof Date
    ? (toString = DateTime.fromJSDate(to).toISODate())
    : (toString = DateTime.fromJSDate(new Date(to)).toISODate());

  return `${fromString} - ${toString}`;
};

export const getDateTime = (time: KarDateType, format: string): string => {
  if (time) {
    if (typeof time === 'object') {
      time = time.toISOString();
    }

    return DateTime.fromISO(time as string)
      .setLocale('nb')
      .toFormat(format);
  }

  return '';
};

export const getDateFromKarDateType = (value?: KarDateType): Date | null => {
  if (!value) return null;
  return new Date(value);
};

export const changeDate = (oldDate: Date | undefined, newDate: Date): Date => {
  if (oldDate) {
    oldDate = new Date(oldDate);
    newDate.setHours(oldDate.getHours());
    newDate.setMinutes(oldDate.getMinutes());
    newDate.setSeconds(oldDate.getSeconds());
    newDate.setMilliseconds(oldDate.getMilliseconds());
  }

  const newDateUTC = DateTime.utc(
    newDate.getFullYear(),
    newDate.getMonth() + 1,
    newDate.getDate(),
    newDate.getHours(),
    newDate.getMinutes(),
    newDate.getSeconds(),
    newDate.getMilliseconds()
  ).toJSDate();

  return newDateUTC;
};

export const resetTimeZeroOfDate = (date: Date): Date => {
  date = new Date(date);
  const newDate = DateTime.local(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    0,
    0,
    0,
    0
  ).toJSDate();

  return newDate;
};

export const getTime = (date?: Date): number => {
  if (date) {
    return new Date(date).getTime();
  }

  return new Date().getTime();
};

export const getBeginDate = (
  date: Date | undefined | null
): Date | undefined | null => {
  if (date) {
    date = new Date(date);
    const newDateUTC = DateTime.utc(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      0,
      0,
      0
    ).toJSDate();

    return newDateUTC;
  }

  return date;
};

export const getEndDate = (
  date: Date | undefined | null
): Date | undefined | null => {
  if (date) {
    date = new Date(date);
    const newDateUTC = DateTime.utc(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      23,
      59,
      59
    ).toJSDate();

    return newDateUTC;
  }

  return date;
};
