export enum AddressType {
    Unknown = 0,
    Private = 1,
    School = 2
}

export default interface IAddress {
    id?: number
    street: string
    zipCode: string
    city: string
    country: string
    addressType: AddressType 
}

export class Address implements IAddress {
    id?: number | undefined;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    addressType: AddressType;

    constructor() {
        this.street = '';
        this.zipCode = '';
        this.city = '';
        this.country = '';
        this.addressType = AddressType.Unknown
    }
}

