import { Checkbox } from '@material-ui/core';
import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import useStyles from './style';

interface IComponentProps {
  isChecked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hide?: boolean;
  disabled?: boolean;
  value?: any;
}

const CustomCheckBox = (props: IComponentProps) => {
  const { isChecked, handleChange, hide, disabled, value } = props;
  const classes = useStyles();

  return (
    <Checkbox
      color="primary"
      classes={{ root: clsx(classes.root, classes.size) }}
      className={clsx({
        [classes.visibility]: hide === true,
      })}
      checkedIcon={
        <CheckBoxIcon className={clsx(classes.root, classes.color)} />
      }
      checked={isChecked}
      onChange={handleChange}
      disabled={disabled}
      value={value}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
    />
  );
};

export default CustomCheckBox;
