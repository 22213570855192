import { ISurvey } from '@/types/Survey';
import {
  Button,
  Drawer,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useReducer } from 'react';
import { useStores } from '../../../stores';
import { INIT_PROJECT, IProject } from '../../../types/Project';
import { ControlledInputText } from '../../ControlledInputText';
import CollapsableDateRangePicker from './CollapsableDateRangePicker';
import useStyles from './style';
import reducer, { ProjectActionType } from './utils';

interface IComponentProps {
  closeDrawer: () => void;
  openLeftMenu: () => void;
}

const Component = (props: IComponentProps) => {
  const { closeDrawer, openLeftMenu } = props;
  const { stateStore, projectStore, errorStore, userSurveyStore } = useStores();
  const { showErrorMessage } = errorStore;
  const { addProjectFormIsOpen } = stateStore;
  const [assignSurveyId, setAssignSurveyId] = React.useState<number | null>(
    null
  );
  const classes = useStyles();

  const [createEditProject, projectDispatch] = useReducer(reducer, {
    ...INIT_PROJECT,
  });

  const [hasError, setHasError] = React.useState<boolean>(false);

  const handleCreateEdit = async (): Promise<void> => {
    if (!createEditProject.projectName) {
      return setHasError(true);
    }
    setHasError(false);
    const data: IProject = {
      ...createEditProject,
      assignSurveyId,
    };

    try {
      const result = !data.id
        ? await projectStore.create(data)
        : await projectStore.update(data);

      if (!data.id && result) {
        handleCloseDrawer();
        openLeftMenu();
      }

      if (data.id && result) {
        handleCloseDrawer();
      }
    } catch (error: any) {
      if (error.ErrorMessage) {
        showErrorMessage(error.ErrorMessage, undefined, 2000);
      }
    }
  };

  const handleCloseDrawer = () => {
    closeDrawer();
  };

  const handleProjectNameInput = (value: string): void => {
    projectDispatch({
      type: ProjectActionType.SET_PROJECT_NAME,
      payload: value,
    });
    setHasError(false);
  };

  React.useEffect(() => {
    projectDispatch({
      type: ProjectActionType.SET,
      payload: { ...(projectStore.editingProject ?? INIT_PROJECT) },
    });
  }, [projectStore.editingProject]);

  const getListSurvey = React.useCallback(() => {
    userSurveyStore.getAll();

    if (createEditProject.id) {
      projectStore.getAssignSurvey(createEditProject.id ?? 0);
    }
  }, [createEditProject.id, projectStore, userSurveyStore]);

  React.useEffect(() => {
    getListSurvey();
    setAssignSurveyId(projectStore.currentSurveyId);
  }, [getListSurvey, projectStore.currentSurveyId]);

  const assignSurvey = (surveyId: number) => {
    setAssignSurveyId(surveyId !== 0 ? surveyId : null);
  };

  return (
    <Drawer
      anchor="right"
      open={addProjectFormIsOpen}
      onClose={handleCloseDrawer}
      className={classes.drawer}
    >
      <h3 className={classes.title}>Opplysninger om prosjekt</h3>
      <ControlledInputText
        label="Navn"
        value={createEditProject.projectName}
        setValue={handleProjectNameInput}
        customClass={classes.inputs}
        helperText="Kort forklaring for definering av prosjekt navn."
        hasError={hasError}
        errorText="Obligatorisk"
      />
      <CollapsableDateRangePicker
        title="Synlighetsperiode"
        subtitle="Periode der prosjektet er synlig for andre enn administratorer"
        startDate={createEditProject.availablePeriodStartDate}
        setStartDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_AVAILABLE_START,
            payload: d,
          })
        }
        endDate={createEditProject.availablePeriodEndDate}
        setEndDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_AVAILABLE_END,
            payload: d,
          })
        }
      />
      <CollapsableDateRangePicker
        title="Påmeldingsperiode"
        subtitle="Periode som er åpen for påmelding av deltakere"
        startDate={createEditProject.registeringPeriodStartDate}
        setStartDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_REGISTERING_START,
            payload: d,
          })
        }
        endDate={createEditProject.registeringPeriodEndDate}
        setEndDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_REGISTERING_END,
            payload: d,
          })
        }
      />
      <CollapsableDateRangePicker
        title="Kursperiode"
        subtitle="Periode for gjennomføring av kurs"
        startDate={createEditProject.coursePeriodStartDate}
        setStartDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_COURSE_START,
            payload: d,
          })
        }
        endDate={createEditProject.coursePeriodEndDate}
        setEndDate={(d) =>
          projectDispatch({
            type: ProjectActionType.SET_COURSE_END,
            payload: d,
          })
        }
      />
      <TextField
        label="Introduksjonstekst til elev"
        value={createEditProject.studentIntroductionText}
        multiline
        onChange={(e) =>
          projectDispatch({
            type: ProjectActionType.SET_STUDENT_INTRODUCTION_TEXT,
            payload: e.target.value,
          })
        }
        helperText="Du kan bruke {endOfRegistrationDate} for å vise sluttdato for registreringsperioden."
        className={classes.noMarginHelperText}
      />

      <TextField
        label="Påmeldingstekst til elev"
        value={createEditProject.studentRegistrationText}
        multiline
        onChange={(e) =>
          projectDispatch({
            type: ProjectActionType.SET_STUDENT_REGISTRATION_TEXT,
            payload: e.target.value,
          })
        }
      />
      <Typography>Brukerundersøkelse</Typography>
      <Select
        label={'Brukerundersøkelse'}
        value={assignSurveyId}
        onChange={(e: any) => assignSurvey(e.target.value)}
      >
        <MenuItem key={0} value={0}>
          {'None'}
        </MenuItem>
        {userSurveyStore.surveys.length > 0 &&
          userSurveyStore.surveys.map((s: ISurvey) => (
            <MenuItem key={s.id} value={s.id}>
              {s.title}
            </MenuItem>
          ))}
      </Select>

      <div className={classes.buttonWrapper}>
        <Button color="secondary" onClick={handleCreateEdit}>
          <Typography>
            {!projectStore.editingProject?.id
              ? 'Opprett prosjekt'
              : 'Redigere prosjekt'}
          </Typography>
        </Button>
        <Button variant="text" onClick={handleCloseDrawer}>
          Avbryt
        </Button>
      </div>
    </Drawer>
  );
};

export const CreateEditProjectDrawer = observer(Component);
