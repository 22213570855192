import { Button, Card, Chip, Link, Typography } from '@material-ui/core';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../stores';
import { registrationIsActive } from '../../../../types/Project';
import useStudentStyle from '../studentStyle';

const StudentDashboard = () => {
  const { userStore, schoolStore, studySubjectStore, projectStore } =
    useStores();
  const { getStudySubjectsByUserId, studentStudySubjects } = studySubjectStore;
  const { user, getUserStudentCourses } = userStore;
  const { currentProject } = projectStore;
  const { currentSchool, getSchoolByExternalId } = schoolStore;

  const classes = useStudentStyle();
  const history = useHistory();

  const getStudentDashboard = useCallback(() => {
    async function fetchCurrentProject() {
      if (!currentProject) {
        await projectStore.getCurrent();
      }
    }
    if (user) {
      if (!currentSchool) getSchoolByExternalId(user?.externalSchoolId);
      getStudySubjectsByUserId(user?.id);
      getUserStudentCourses();
    }
    fetchCurrentProject();
  }, [
    currentProject,
    currentSchool,
    getSchoolByExternalId,
    getStudySubjectsByUserId,
    getUserStudentCourses,
    projectStore,
    user,
  ]);

  useEffect(() => {
    getStudentDashboard();

    if (user && user.studentCourses.length > 0) {
      // setIsAssigned(true);
    }
  }, [getStudentDashboard, user]);

  const assignedCourse = user?.getAssignedCourse(currentProject?.id);
  const published = projectStore.currentProject?.isPublished;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <header>
          <Typography variant="h3" className={classes.header}>
            Dine opplysninger
          </Typography>
        </header>
        <main className={classes.main}>
          <Card className={classes.card}>
            <Typography variant="h4" className={classes.header}>
              Informasjon om deg
            </Typography>
            <Typography variant="h5">{user?.fullName}</Typography>
            <Typography variant="h5">{currentSchool?.schoolName}</Typography>
            <Typography variant="h5">{user?.phoneNumber}</Typography>
            <Typography variant="h5">{user?.emailAddress}</Typography>
            <Button
              variant="text"
              startIcon={<EditOutlined />}
              className={classes.editButton}
              onClick={() => history.push('/update-student-information')}
            >
              Rediger
            </Button>
          </Card>
          {published &&
            assignedCourse !== null &&
            assignedCourse !== undefined && (
              <Card className={classes.card}>
                <Typography variant="h5">
                  Du har blitt tildelt følgende kurs:
                </Typography>
                <Typography variant="h4" className={classes.header}>
                  {
                    assignedCourse?.finalAssignedCourse?.studySubject
                      .studySubjectName
                  }{' '}
                  - {assignedCourse?.finalAssignedCourse?.school.schoolName}
                </Typography>
                <Button
                  variant="text"
                  startIcon={<InfoOutlined />}
                  className={classes.editButton}
                  onClick={() => history.push('/course-information')}
                >
                  Informasjon
                </Button>
              </Card>
            )}
          {!published && !!currentProject &&(
            <> {
                 currentProject.registeringPeriodEndDate &&
                 registrationIsActive(currentProject) && (
              <Card className={classes.card}>
                <div>
                  <Typography variant="h4" className={classes.header}>
                    Dine prioriterte kurs
                  </Typography>

                      <Typography variant="body1">
                        {currentProject.studentIntroductionText?.replaceAll(
                          '{endOfRegistrationDate}',
                          new Date(
                            currentProject.registeringPeriodEndDate
                          ).toLocaleDateString('no-NO', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                          })
                        )}
                      </Typography>


                  {studentStudySubjects.map((subject) => (
                        <Chip
                          key={subject.studySubjectId}
                          variant="outlined"
                          label={`${subject.priority}. ${subject.studySubject.studySubjectName}`}
                        />
                      ))}
                </div>

                    <Button
                      variant="text"
                      startIcon={<EditOutlined />}
                      className={classes.editButton}
                      onClick={() => history.push('/prioritize')}
                    >
                      {studentStudySubjects.length > 0
                        ? 'Endre påmelding'
                        : 'Opprett påmelding'}
                    </Button>
              </Card> )}

              {!!currentProject && !registrationIsActive(currentProject) && (
                <Card className={classes.card}>
                  <Typography variant="h4" className={classes.header}>
                    Påmelding behandles
                  </Typography>
                  <Typography variant="h5">
                    Du vil bli varslet når du har blitt tildelt et kurs.
                  </Typography>
                </Card>
              )}
            </>
          )}
          <Card className={classes.card}>
            <Link
              underline="always"
              onClick={() => history.push('/student-study-subjects')}
            >
              <Typography variant="h5" className={classes.readMore}>
                Les mer om kursene her
              </Typography>
            </Link>

            <Link
              underline="always"
              onClick={() => {
                const win = window.open('https://vilbli.no', '_blank');
                if (win) {
                  win.focus();
                }
              }}
            >
              <Typography variant="h5" className={classes.readMore}>
                For å lese mer om utdanningsprogrammene gå til vilbli.no
              </Typography>
            </Link>
          </Card>
        </main>
      </div>
    </div>
  );
};

export default observer(StudentDashboard);
